import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import MultipleSelectDropDown from '../../../../Component/DropDowns'
import { advSummary, pubSummary, intradayAdvColumns, intradayColumns, IntradayPubGroupItem , IntradayAdvGroupItem} from './constant'
import { areaStudyDropdown, intraDayTimeLineDropDown } from '../../../../Mock/Dashboard Data/OverallData'
import { calculateMetrics, createBarIntraChartJson, debounce, groupByCampaign, reduceClickData, ReducedClickData, reduceIntraTableData, pubIntraDayChartOptions, advIntraDayChartOptions } from '../../../../Store/Slices/Publisher-role/Dashboard/utils'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import 'chartjs-adapter-moment';
import IntraChart from '../../../../Mock/Dashboard Data/ChartTimeTest'
import { getPubDashboardOverallTableReports, getPubDashboardReports } from '../../../../service/Publisher'
import SkeletonLoader from '../../../../Component/Skeleton Loader'
import { getAdvDashboardReports, getAdvertiserDashboardOverallTableReports } from '../../../../service/Advertiser'
import { MyGrid } from './Overall'
import DxGrid from '../../../../Component/Data Grid/DxGrid'


ChartJS.register(
  ...registerables
);

interface dropdownMenus {
  label: string;
  value: string
}
interface IIntraday {
  campaignDropDownMenus: dropdownMenus[]
  defaultBarDataSet: any
  dashboardType: 'advertiser' | 'publisher'
}
export const Intraday = (props: IIntraday) => {
  const [summaryData, setSummaryData] = useState<any>()
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[] | []>(props.campaignDropDownMenus.map(item => item.value))
  const [selectedAreaStudy, setSelectedAreaStudyValue] = useState(areaStudyDropdown.map(name => name.value));
  // const [selectedCampaign, setSelectedCampaign]=useState<any>(overallPageData.selectedCampaigns)
  const [barChartData, setBarChartData] = useState<any>()
  // const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState(intraDayTimeLineDropDown[0].value)
  // const overallPageData = useAppSelector((state: any) => state.overallData)
  const [contentLoading, setContentLoading] = useState(true)
  const [gridColumns, setGridColumns] = useState(intradayColumns);
  const [rows, setRows] = useState<any>()
  const [summaryItem,setSummaryItem]=useState(IntradayPubGroupItem);
  const [chartOptions,setChartOptions]=useState(pubIntraDayChartOptions);
  const handelSelectedCampaigns = (value: string[]) => {
    setSelectedCampaigns(value)
    // dispatch(updateSelectedCampaigns(value))
  }

  const fetchReports = async (queryParams: any, barDataSet: any) => {
    setContentLoading(true)
    const publisherChartApiPath = "publisher/dashboard"
    const publisherTableApiPath = "publisher/overall"

    const advertiserChartApiPath = "advertiser/dashboard"
    const advertiserTableApiPath = "advertiser/overall"
    try {

      let fetchRef: any[] = []
      if (props.dashboardType === "publisher") {
        fetchRef = [
          getPubDashboardReports(publisherChartApiPath, queryParams),
          getPubDashboardOverallTableReports(publisherTableApiPath, queryParams)
        ]
        setGridColumns(intradayColumns)
        setChartOptions(pubIntraDayChartOptions)
      } else if (props.dashboardType === "advertiser") {
        // assign adv Dashboard
        fetchRef = [
          getAdvDashboardReports(advertiserChartApiPath, queryParams),
          getAdvertiserDashboardOverallTableReports(advertiserTableApiPath, queryParams)
        ]
        setChartOptions(advIntraDayChartOptions)
        setGridColumns(intradayAdvColumns)
      }
      const [response, groupByDateRes] = await Promise.all(fetchRef)

      const apiResponse = response.map((item: any) => {
        // var momentDate = moment.utc(item['Date/Time']);
        return {
          //  "Date": item['Date3'],
          "Date2": item['Date2'],
          "TotalCommission": parseFloat(item["Total Commission"]),
          "campaignName": item.campaignName,
          "CLICKS": parseInt(item.CLICKS),
        }
      }).sort((a: any, b: any) => {
        const dateA = new Date(a.Date2);
        const dateB = new Date(b.Date2);
        return dateA.getTime() - dateB.getTime();
      });

      const jdata = groupByCampaign(apiResponse)

      const output: ReducedClickData[] = reduceClickData(jdata);
      const chartData = createBarIntraChartJson(output, barDataSet)
      setBarChartData(chartData)

      groupByDateRes.data.forEach((entry: any) => {
        entry.Ad_Requests = parseInt(entry.Ad_Requests);
        entry["Avg position"] = parseFloat(entry["Avg position"]);
        entry.CLICKS = parseInt(entry.CLICKS);
        entry.CPC = parseFloat(entry.CPC);
        entry["Total Commission"] = parseFloat(entry["Total Commission"]);


      });

      // console.log("RES:::table:::::::::::intraday", groupByDateRes.data)
      const tableGroup = groupByCampaign(groupByDateRes.data)
      // console.log("TABLE group based on campaigns", tableGroup)
      const groupData = reduceIntraTableData(tableGroup, props.dashboardType)
      
      const sd: any = calculateMetrics(groupByDateRes.data)
      const newCTR = (sd.totalClicks / groupByDateRes.total_request_count) * 100
      const newSummaryTotal = {
        ...sd,
        "totalRequests": groupByDateRes.total_request_count?groupByDateRes.total_request_count:sd.totalRequests,
        "finalAverageCTR":groupByDateRes.total_request_count? `${newCTR.toFixed(2)} %`: sd.finalAverageCTR

      }

      if (props.dashboardType === 'advertiser') {

        const advTotal = advSummary.map((item) => {
          return {
            ...item,
            label: item.label,
            value: item.value + "" + newSummaryTotal[item.key]
          }
        })
        const rows = groupData.map((item: any, index: number) => {
          const CTR = item['CTR'] * 100
  
          return {
            ID: item.campaignId?item.campaignId:index,
            ...item,
            "Date/Time": item['Date'],
            // "Date/Time": momentDate.format("MM-DD-YYYY"),
            "Total Spent": `$ ${item['Total Commission'].toFixed(2)}`,
            "CTR": `${CTR.toFixed(2)} %`,
            "RPC": `$ ${item.CPC.toFixed(2)}`,
            // "Date2":new Date(item['Date/Time'])
          }
        })
        // Aggregate the campaign data
        // const aggregatedData = aggregateCampaignData(rows);
        setRows(rows.filter((item: any) => item.campaignName !== ""))
        setSummaryData(advTotal)
        setSummaryItem(IntradayAdvGroupItem)
      
      } else if (props.dashboardType === 'publisher') {
        const pubTotal = pubSummary.map((item) => {
          return {
            ...item,
            label: item.label,
            value: item.value + "" + newSummaryTotal[item.key]
          }
        })
        const rows = groupData.map((item: any, index: number) => {
          const CTR = item['CTR'] * 100
  
          return {
            ID: item.campaignId?item.campaignId:index,
            ...item,
            "Date/Time": item['Date'],
            // "Date/Time": momentDate.format("MM-DD-YYYY"),
            "Total Commission": `$ ${item['Total Commission'].toFixed(2)}`,
            "CTR": `${CTR.toFixed(2)} %`,
            "CPC": `$ ${item.CPC.toFixed(2)}`,
            // "Date2":new Date(item['Date/Time'])
          }
        })
        setRows(rows.filter((item: any) => item.campaignName !== ""))
        setSummaryData(pubTotal)
        setSummaryItem(IntradayPubGroupItem)
      
      }
      setContentLoading(false)
    } catch (err) {
      console.log("err", err)

      setContentLoading(true)
      // setLoading(true)
    }
  }
  const debounceOnChange = React.useCallback(debounce((queryParams: any, barDataSet: any) => {
    fetchReports(queryParams, barDataSet);
  }, 600), []);
  //backgroundColor: "#0AE"
  useEffect(() => {
    const pubId = localStorage.getItem('userSortid');
    // console.log('user ID ', pubId)
    let lastFiveCharacters
    if (pubId) {
      lastFiveCharacters = pubId.slice(-5);
      // console.log('pub id', pubId)
    }

    const queryParams: any = {
      mPubID: lastFiveCharacters,
      mPubCampaignID: selectedCampaigns.join(','),
      areaStudy: selectedAreaStudy.join(','),
      chartType: "intraDay"
    }
    // console.log("date ragne ", dateRange)
    const { mPubCampaignID, areaStudy } = queryParams
    if (dateRange && mPubCampaignID && areaStudy) {
      // console.log("QP:::::::::::::::", queryParams)
      debounceOnChange(queryParams, props.defaultBarDataSet)
    }


  }, [selectedCampaigns, selectedAreaStudy])
  return (
    <Paper elevation={3} className="overall-page-container card">
      <Box p={3}>
        <Box display="flex" justifyContent="space-between" mb={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">Intra day</Typography>
            <Typography className="card-sub-heading">
              Reports
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2} mb={3}>

          <Grid item xs={12} lg={4}>
            <MultipleSelectDropDown dropdownLabel="Area Study" dropdownData={areaStudyDropdown} dropDownValue={selectedAreaStudy} setDropDownValue={setSelectedAreaStudyValue} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MultipleSelectDropDown dropdownLabel="Campaign" dropdownData={props.campaignDropDownMenus} dropDownValue={selectedCampaigns} setDropDownValue={handelSelectedCampaigns} />
          </Grid>
        </Grid>
        <Box className="main-section-charts-table">
          <Paper className='chart-box' sx={{ marginBottom: "24px" }}>
            {
              contentLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='400px' /> :
                (barChartData.labels.length > 0 ? <IntraChart chartData={barChartData} chartOptions={chartOptions} /> :
                  <Box className='no-data-message-container'>

                    No Data found
                  </Box>)

            }

          </Paper>

          {
            contentLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='40px' /> :

              <Box mb={3} sx={{ justifyContent: "center" }}>
                <MyGrid summaryData={summaryData} />
              </Box>
          }
          <Paper className="data-grid" sx={{ marginTop: "3px", height: '100%' }}>
            {
              contentLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='400px' /> :
                <DxGrid rows={rows} columns={gridColumns} summaryItem={summaryItem} />
            }
          </Paper>


        </Box>


      </Box>

    </Paper>
  )
}


