import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


interface IAreaCard{
    imageUrl:string;
    headerContent:string;
    description:string
}

export default function AreaCard(props:IAreaCard) {
  return (
    <Card sx={{ width:'100%' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={props.imageUrl}
          // alt="green iguana"
          sx={{objectFit:'contain'}}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
          {props.headerContent}
          </Typography>
           <div dangerouslySetInnerHTML={{ __html:props.description }}  className="list-api-desc"></div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}