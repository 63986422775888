import React, {useState} from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TableSortLabel,
    Box,
    Modal,
    Button,
    Typography,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteVendorDialog from './DeleteAlert';
import SnackbarMessage  from '../../../Component/Snackbar'
import EditIcon from '@mui/icons-material/Edit';
import { deleteVendor } from '../../../service/Vendor';
import InfoIcon from '@mui/icons-material/Info';
const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    backgroundColor: "#EAECF0",
                    color: "#737791"
                },

            },
        },
    },
});

const vendorHeadCell = [
    {
        id: 'Name',
        label: "Vendor Name",
        numeric: false,
        key: "displayName"
    },
    {
        id: 'Group',
        label: "Group",
        numeric: false,
        key: "GroupName"
    },
    {
        id: 'CreationDate',
        label: "Creation Date",
        numeric: false,
        key: "CreationDate"
    },
    {
        id: 'advertiserCount',
        label: "Advertiser count",
        numeric: false,
        key: "advertiserCount"
    },
    {
        id: 'userCount',
        label: "User count",
        numeric: false,
        key: "userCount"
    },
    
    {
        id: "LastModifiedDate",
        label: "LastModified Date",
        numeric: false,
        key: "LastModifiedDate"
    },



]

interface IVendorTable {
    data: any[],
    deleteHandler: (groupName: string) => void;
    refreshTable:()=>void
    editHandler:(vendor:any)=>void
    doEdit?:true
}
const VendorTable = (props: IVendorTable) => {
    const [deleteDOpen, setDeleteDOpen] = useState(false);
    
    const [groupName, setGroupName]=useState('')
    const [deleteSuccess, setDeleteSuccess]=useState('')
    const [deleteError, setDeleteError]=useState<any>('')
    const [snackbarOpen, setSnackbarOpen]=useState<any>(false)

    const handleDelete=(groupName:string)=>{
        setDeleteDOpen(true)
        setGroupName(groupName)
    }
    const handleConfirm=async ()=>{
        if(groupName){
            try{
                //  const res=await deleteGroup(groupName)
                const res=await deleteVendor(groupName)
                // const res=await deleteGroup('fsjfnlskjfnsnfknsf kn')
                setDeleteDOpen(false)
                setDeleteSuccess(`${groupName} is deleted successfully` )
                // props.refreshTable();
                setSnackbarOpen(true)
               
            }catch(error:any){
                 console.log("error", JSON.stringify(error))
                setDeleteDOpen(false)
                setDeleteError(error.message)
                 setSnackbarOpen(true)
            }
            
        }
    }

    const modalCloseHandler=()=>{
        setDeleteDOpen(false);
        setGroupName('')
        setSnackbarOpen(false)
        props.refreshTable();
    }

    // const handleEdit=(vendor:any)=>{
       
    // }
   
    console.log("Data vendor group", props.data)
    return (
        <ThemeProvider theme={theme} >
            <TableContainer component={Paper}>
                <Box className='no-more-tables responsive-tbl'>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    vendorHeadCell.map((item, index) => {
                                        return (
                                            <TableCell
                                                key={item.id}
                                                //   align={headCell.numeric ? 'right' : 'left'}
                                                align={'left'}
                                            //   sortDirection={orderBy === item.id ? order : false} 
                                            >

                                                <TableSortLabel
                                                // active={orderBy === item.id}
                                                // direction={orderBy === item.id ? order : 'asc'}
                                                // onClick={(e) => {
                                                //   handleRequestSort(item.id)
                                                // }}
                                                >
                                                    {item.label}
                                                    {/* {orderBy === item.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null} */}
                                                </TableSortLabel>


                                            </TableCell>
                                        )
                                    })
                                }

                             {props.doEdit &&  <TableCell >Action</TableCell>}  
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                props.data.map((vendor, index) => (
                                    <TableRow key={index} className='userTables'>
                                         <TableCell data-title="Group Name">
                                            {vendor.displayName}
                                        </TableCell>
                                        <TableCell data-title="Group Name">
                                            {vendor.GroupName}
                                        </TableCell>
                                        <TableCell data-title="Create date">
                                            {vendor.CreationDate}
                                        </TableCell>
                                        <TableCell data-title="adv count">
                                            {vendor.advertiserCount}
                                        </TableCell>
                                        <TableCell data-title="user count">
                                            {vendor.userCount}
                                        </TableCell>
                                        <TableCell data-title="Last Modified Data">
                                            {vendor.LastModifiedDate}
                                        </TableCell>
                                        <TableCell>
                                        <IconButton size="small">
                                                <Tooltip title="Info"><InfoIcon color='primary' onClick={() => props.editHandler(vendor)} /></Tooltip>
                                            </IconButton>
                                            <IconButton size="small">
                                                <Tooltip title="Delete"><DeleteIcon color='error' onClick={() => handleDelete(vendor.GroupName)} /></Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                )
                            }
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>

            <DeleteVendorDialog open={deleteDOpen}  closeHandler={modalCloseHandler} 
            submitHandler={handleConfirm}
            alertText={groupName}
            successMessage={deleteSuccess}
            errorMessage={deleteError}
             isSnackbarOpen={snackbarOpen}
            />
            
        </ThemeProvider>
    )
}

export default VendorTable