import React, { FC, useEffect, useRef, useState } from 'react'
import MMTabs from '../../../../Component/Tabs/MMTabs'
import { stateRulesData } from '../../../../Mock/GeoTargetingData'
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector, updateStateForEditAction } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { createTcpaCampAsync } from '../../../../Store/Slices/tcpaCampSlice';

// const StateRules = () => {
  const StateRules: FC<any> = ({ submitRef, handleNext }) => {
    const location = useLocation();
    const dispatch: any = useAppDispatch();
    const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);
    const currentState = useAppSelector(campaignFormSelector)
    const excludeStates = useAppSelector((state) => state.CreateAdvCampaignReducer.value.excludeStates);

    const [responseValue, setResponseValue] = useState<null | any>(null)
    const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
    const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit} = tcpaCampSlice
    const hasRun = useRef(false);
    
  const generateData=(key:string)=>{
      return [

      ]
  }


  return (
    <div>
          <MMTabs tabContent={stateRulesData} />
  </div>

  )
}

export default StateRules