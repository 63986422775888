import React, { useEffect, useState } from 'react'

import { updateExcludePublisher } from '../../../../Store/Slices/CreateAdvCampaignSlice'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, OutlinedInput, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { IOSSwitch } from '../../../../Component/Table/MMTable'
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks'
import { ICampaign, SubCampaign } from '../../../../Store/sliceInteface'
import { useLocation } from 'react-router-dom'


interface IMediaSourceTabContent {
    data: ICampaign[] | any[]
}
const MediaSourceTabContent: React.FC<IMediaSourceTabContent> = (props) => {
    const { data } = props
    const location = useLocation();
//  console.log("MEDIA SOURCE DATA", data)
    const dispatch = useAppDispatch()
    const [rowFormData, setRowFormData] = useState<SubCampaign[] | any[]>(data.length > 0 ? data[0]?.newCampaigns : []);
    const [isSelectAll, setSelectAll] = useState(false);
    const handleBidInputField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {

        const { name, value } = event.target;
        setRowFormData(prevData => prevData.map(item => (item.SORTID === id ? { ...item, [name]: value } : item)));
        // if( positiveNumberRegex.test(value)){
        //     setRowFormData(prevData => prevData.map(item => (item.id === id ? { ...item, [name]: value } : item)));
        // }

    }

    const handleStatus = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        //console.log("id status;;;;media source" , id)
        const { name, checked } = event.target;
        setRowFormData(prevData => prevData.map(item => (item.SORTID === id ? { ...item, [name]: checked } : item)));

    }
    const checkSelectAllStatus = () => {
        // const { yourData } = this.state; // Replace with the actual name of your data array
        const allSelected = rowFormData.every(item => item.enable === true);
        const allDeselected = rowFormData.every(item => item.enable === false);
        //console.log("all selected", allSelected)
        if (allSelected) {
            setSelectAll(true)
        } else {
            setSelectAll(false);
        }
        //  else if (allDeselected) {
        //   setSelectAll(false);
        // }

        return allSelected;
    };
    useEffect(() => {
        if (rowFormData.length >= 1) {
            const allSelected = checkSelectAllStatus()
            dispatch(updateExcludePublisher({
                data: rowFormData,
                allSelected: allSelected
            }))
        }

    }, [rowFormData])

    const [sortedRowFormData, setSortedRowFormData] = useState<SubCampaign[]>([]);

    useEffect(() => {
        // Sort the rowFormData array based on the title field
        const sortedData = [...rowFormData].sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();

            // Use localeCompare to sort strings that contain numbers
            return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
        });

        setSortedRowFormData(sortedData);
    }, [rowFormData]);

    // console.log("Row form data exclude publisher", rowFormData);
    const handelSelectAllCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (event.target.checked) {
            setRowFormData(prevData => prevData.map(item => ({ ...item, enable: checked })));
        } else {

            setRowFormData(prevData => prevData.map(item => ({ ...item, enable: checked })));
        }
    }


    return (
        <React.Fragment>
            <Paper>
                <Box className='select-all-container' px={3} >
                    <Box className="header-message">
                           <h3 style={{fontWeight:'600'}}> {data.length > 0 ? data[0]?.title:""}</h3>
                        </Box>
                        <FormGroup className='select-all-toggle-container'>
                            <FormControlLabel 
                                control={<IOSSwitch sx={{ m: 1 }} name='status' checked={isSelectAll} onChange={handelSelectAllCheckBox} />}
                                label="Select All"
                                className='select-all-toggle-container-label'
                                sx={{flexDirection:"row-reverse"}}
                            />
                        </FormGroup>
             
                </Box>
                <Box className='no-more-tables responsive-tbl'>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Pub Campaign Name</TableCell>

                            <TableCell sx={{ paddingLeft: "30px", visibility:( location.pathname != '/createTcpaCamp' && location.pathname != '/editTcpaCamp') ? 'visible' : 'hidden' }}>Bid Adjustment %</TableCell>

             {/* <TableCell sx={{ paddingLeft: "30px",  visibility: location.pathname !== ('/createTcpaCamp' || '/editTcpaCamp' )? 'visible' : 'hidden'}}>Bid Adjustment %</TableCell> */}
                            <TableCell >Status</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortedRowFormData.map((item: SubCampaign, index: number) => {
                                return (
                                    <TableRow key={index} >
                                        <TableCell data-title='Pub Campaign Name'>{item.title}</TableCell>
                                        <TableCell data-title='Bid Adjustment %'>
                                            <FormControl sx={{ width: { xs: '100%', md: '30%' }, my: 1, visibility: ( location.pathname != '/createTcpaCamp' && location.pathname != '/editTcpaCamp') ? 'visible' : 'hidden' }}>
                                                <InputLabel htmlFor="outlined-adornment-amount">Adjustment %</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                    label="Adjustment %"
                                                    value={item.bid}
                                                    // name={}
                                                    name='bid'
                                                    onChange={(event) => {
                                                        handleBidInputField(event, item.SORTID)
                                                    }}
                                                    sx={{
                                                        // Adjust padding for the value inside OutlinedInput
                                                        "& input": {
                                                          padding:( "12px 14px 12px 0px" )
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell data-title='Status'>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{ m: 1 }} checked={item.enable} name='enable' onChange={
                                                        (e) => {
                                                            handleStatus(e, item.SORTID)
                                                        }
                                                    } />}
                                                    label=""
                                                />
                                            </FormGroup>
                                        </TableCell>



                                    </TableRow>
                                )
                            })
                        }


                    </TableBody>
                </Table>
                </Box>
            </Paper>



        </React.Fragment>

    )
}

// export default MediaSourceTabContent




const MediaSourceTabContentWrapper: React.FC<IMediaSourceTabContent> = (props) => {
    return (
        <>
            {
                props.data?.length > 0 ? <MediaSourceTabContent data={props.data} /> : <Box>
                    <Typography style={{ 'textAlign': 'center' }}>No campaign exists.</Typography>
                </Box>
            }
        </>
    )
}


export default MediaSourceTabContentWrapper