
// Your input data
import { scheduling_jsonData } from '../SchedulingData';
import React, { useState, useRef , useEffect,useCallback} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useAppDispatch , useAppSelector} from '../../Store/hooks'
import { campaignFormSelector, updateScheduling } from '../../Store/Slices/CreateAdvCampaignSlice'
// Your input data
import _debounce from 'lodash/debounce';
// Define types
interface TimeSlot {
  include: boolean;
}

interface DaySchedule {
  [timeSlot: string]: TimeSlot;
}

interface SchedulingData {
  [day: string]: DaySchedule;
}


const selectAllCell = (): SchedulingData => {
  const days = Object.keys(scheduling_jsonData);
//   const timeSlots = generateTimeSlots();
const timeSlots = Object.keys(scheduling_jsonData["Monday"]);

  const initialState: SchedulingData = {};
  days.forEach(day => {
    initialState[day] = {};
    timeSlots.forEach(slot => {
      initialState[day][slot] = { include: true };
    });
  });

  return initialState;
};
// Function to create an initial state based on input data
const DeselectAllCell = (): SchedulingData => {
  const days = Object.keys(scheduling_jsonData);
//   const timeSlots = generateTimeSlots();
const timeSlots = Object.keys(scheduling_jsonData["Monday"]);

  const initialState: SchedulingData = {};
  days.forEach(day => {
    initialState[day] = {};
    timeSlots.forEach(slot => {
      initialState[day][slot] = { include: false };
    });
  });

  return initialState;
};

// Main component
const SchedulingTable: React.FC = () => {
  const campaignFormData=useAppSelector(campaignFormSelector)
  const [tableState, setTableState] = useState<SchedulingData>(campaignFormData.value.scheduling);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const startCell = useRef<{ day: string; timeSlot: string } | null>(null);
  
  const dispatch=useAppDispatch();

  // Function to handle cell click
  // const handleCellClick = (day: string, timeSlot: string): void => {
  //   // setTableState(prevState => {
  //   //   const newState:any = { ...prevState };
  //   //   newState[day][timeSlot].include = !prevState[day][timeSlot].include;
  //   //   return newState;
  //   // });

  //   setTableState((prevState) => {
  //       return {
  //         ...prevState,
  //         [day]: {
  //           ...prevState[day],
  //           [timeSlot]: {
  //             ...prevState[day][timeSlot],
  //             include: !prevState[day][timeSlot].include,
  //           },
  //         },
  //       };
  //     });
      
  // };


  // const handleCellClick = (day: string, timeSlot: string): void => {
  //   if (isMouseDown) {
  //     // If mouse is down, treat it as dragging
  //     handleCellDragOver({},day, timeSlot);
  //   } else {
  //     // If mouse is up, treat it as a simple click
  //    setTimeout(()=>{
  //     setTableState((prevState) => ({
  //       ...prevState,
  //       [day]: {
  //         ...prevState[day],
  //         [timeSlot]: {
  //           ...prevState[day][timeSlot],
  //           include: !prevState[day][timeSlot].include,
  //         },
  //       },
  //     }));
      
  //    },700)
  //   }
  // };

  const handleCellClick = (day: string, timeSlot: string): void => {
    if (isMouseDown) {
      // If mouse is down, treat it as dragging
      handleCellDragOver({},day, timeSlot);
    } else {
      // If mouse is up, treat it as a simple click
      const newState = {
        ...tableState,
        [day]: {
          ...tableState[day],
          [timeSlot]: {
            ...tableState[day][timeSlot],
            include: !tableState[day][timeSlot].include,
          },
        },
      };

      // Use delayedSetTableState to update state after a delay
      delayedSetTableState(newState);
    }
  };

useEffect(()=>{
  dispatch(updateScheduling(tableState))
},[tableState])


const [btnTextName, setBtnTextName]=useState<string>("DESELECT ALL");
const handelDeselectAll=()=>{
    const deselectAll=DeselectAllCell();
    // console.log("deselctALl", deselectAll);
    setTableState(deselectAll);
    setBtnTextName("SELECT ALL")
}

const handelSelectAll=()=>{
    const selectAll=selectAllCell();
    // console.log("deselctALl", deselectAll);
    setTableState(selectAll);
    setBtnTextName("DESELECT ALL")
}

  // Function to handle cell drag start
  const handleCellDragStart = (day: string, timeSlot: string): void => {
    setIsMouseDown(true);
    startCell.current = { day, timeSlot };
  };

  // Function to handle cell drag over
  const handleCellDragOver = (event :any ,day: string, timeSlot: string): void => {
    if (isMouseDown && startCell.current) {
      const startDay = startCell.current.day;
      const startTimeSlot = startCell.current.timeSlot;
  // console.log("startDay", startDay);
  // console.log("startTimeSlot", startTimeSlot)
  // console.log("event",event)



  //       setTableState(newState)
    setTimeout(()=>{
      setTableState(prevState => {
        const newState:any = JSON.parse(JSON.stringify(prevState))
        // console.log("new state", newState);
        const daysInRange = days.slice(
          Math.min(days.indexOf(startDay), days.indexOf(day)),
          Math.max(days.indexOf(startDay), days.indexOf(day)) + 1
        );
        const timeSlotsInRange = timeSlots.slice(
          Math.min(timeSlots.indexOf(startTimeSlot), timeSlots.indexOf(timeSlot)),
          Math.max(timeSlots.indexOf(startTimeSlot), timeSlots.indexOf(timeSlot)) + 1
        );


        // console.log("day in  range", daysInRange) 
        //  console.log("time in  range", timeSlotsInRange)
       
         
        daysInRange.forEach(d => {
          timeSlotsInRange.forEach(slot => {
            newState[d][slot].include =!tableState[d][slot].include
          });
        });

        return newState;
      });

    },800)
    }
  };

  // Function to handle mouse up
  const handleMouseUp = (): void => {
    setIsMouseDown(false);
    startCell.current = null;
  };
  const delayedSetTableState = useCallback(
    _debounce((newState: SchedulingData) => {
      setTableState(newState);
    }, 500), // Adjust the delay time as needed
    []
  );

  // console.log("IsMouse Down", isMouseDown)
  const customSort = (a:string, b:string) => {
    const order = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    return order.indexOf(a) - order.indexOf(b);
};
  const days = Object.keys(tableState).sort(customSort);
  // console.log("Days", days)

  // console.log("table state", tableState)
  
  


  const timeSlots = Object.keys(tableState["Monday"]);
  return (
    <div onMouseUp={handleMouseUp}>
        <Button onClick={() => btnTextName==="DESELECT ALL"?handelDeselectAll():handelSelectAll() }
         sx={{
          backgroundColor: "#00AAEE",

          "&.MuiButtonBase-root:hover": {
            bgcolor:  "#00AAEE !important"
          },
          "&:hover": { backgroundColor:  "#00AAEE" },
          border: "1px solid #0AE",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          borderRadius: "8px",
          color: "#ffff",
          height: "45px"
        }}
        >{btnTextName==="DESELECT ALL"?"DESELECT ALL":"SELECT ALL"}</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {days.map(day => (
                <TableCell key={day}>{day}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {timeSlots.map(timeSlot => (
              <TableRow key={timeSlot}>
                <TableCell>{timeSlot}</TableCell>
                {days.map(day => {
                  let bgColor='white';
                  if(tableState[day][timeSlot]){
                    bgColor=tableState[day][timeSlot]?.include ? 'lightblue' : 'white'
                  }
                 return(
                  <TableCell
                  key={`${day}-${timeSlot}`}
                  onMouseDown={() => handleCellDragStart(day, timeSlot)}
                  onMouseEnter={(e) => handleCellDragOver(e,day, timeSlot)}
                  onClick={() => handleCellClick(day, timeSlot)}
                  // onMouseDown={()=>console.log("Mouse DOwn")}
                  // onMouseEnter={(e) =>console.log("evnet ", e)}
                  // onClick={()=>{ console.log("Element clicked!");}}
                  style={{
                    cursor: 'pointer',
                    
                    backgroundColor: bgColor,
                   userSelect: 'none'
                  }}

                  
                  
                  
                >
                  {/* Render your text here based on selected state */}
                   { 
                   tableState[day][timeSlot] &&
                   (tableState[day][timeSlot]?.include && 'Campaign On')}
                </TableCell>

                 )})}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    
      {/* <div>{renderSelectedText()}</div> */}
    </div>
  );
};

export default SchedulingTable;
