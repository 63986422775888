import { Box, Fab, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IProgramUIData } from '../../Mock/ProgramTargetingData';
// import Box from '@mui/material/Box';
import NavigationIcon from '@mui/icons-material/Navigation';
import MenuIcon from '@mui/icons-material/Menu';

interface TabPanelProps {
  children: React.ReactNode;
  // children:()=> JSX.Element;
  index: number;
  value: number;
  childrenProps?: string
}


// interface ImmTabs{

// }
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const ChildComponent = children
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {/* <Typography>{children}</Typography> */}
          {ChildComponent}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number, name: string, value: string) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    name: name,
  };
}

interface ImmTabs {
  tabContent: IProgramUIData[];
  generateTabPanelContentHandler?: (key?: string) => any[] | undefined
}


export default function MMTabs(props: ImmTabs) {
  const { tabContent, generateTabPanelContentHandler } = props
  const [value, setValue] = React.useState(0);
  // const [selectedAreaProgramValue, setSelectedAreaProgramValue]=useState(generateMProgramList())
  const [selectedAreaProgramValue, setSelectedAreaProgramValue] = useState(generateTabPanelContentHandler ? generateTabPanelContentHandler() : [])
  const [showTabs, setShowTabs] = useState(false); // State to manage visibility of tabs

  const isScreenAbove1300 = useMediaQuery('(min-width:1300px)');
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const tabContentValue = newValue + 1
    const mPrograms = generateTabPanelContentHandler ? generateTabPanelContentHandler(tabContentValue.toString()) : []
    // console.log("mPrograms", mPrograms)
    // if (mPrograms) {
    //   setSelectedAreaProgramValue(mPrograms)
    // }
    setSelectedAreaProgramValue(mPrograms)
    // setShowTabs(false);
    if (!isScreenAbove1300) {
      setShowTabs(false);
    }
  };
  // console.log('tabContentValue====',tabContent);

  const toggleTabsVisibility = () => {
    setShowTabs(!showTabs);
  };

  // console.log("selectedAreaProgramValue",selectedAreaProgramValue)
    // Using useMediaQuery to detect screen width
   

    useEffect(() => {
      // Automatically close the tabs if the screen width is below 1300px
      if (isScreenAbove1300) {
        setShowTabs(true);
      } else {
        setShowTabs(false);
      }
    }, [isScreenAbove1300]);

    // console.log("tab content", tabContent)
  return (
    <Box
      sx={{  position: 'relative', display: 'flex' }}
    >
      {showTabs &&( <Box sx={{
        height: '70vh', flexBasis:"20%", padding:"10px", 
      }}
    // display='none'
        className='mm-tab-container'>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {
            tabContent.map((item, index) => {
              return (
                <Tab label={item.label} {...a11yProps(index, item.label, item.value)} className={`mm-tab ${index === value ? "active" : ""} `} />
              )
            })
          }

        </Tabs>
      </Box>)}

      <Box className="mm-tabpanel-container" sx={{
        height: '75vh', flexBasis:"100%", padding:"11.5px"
      }}>
        {
          tabContent.map((item, index) => {
            return (
              <TabPanel value={value} index={index} >
                {<item.content data={selectedAreaProgramValue} />}
              </TabPanel>
            )
          })
        }
      </Box>
      <Box sx={{
    position: 'fixed',
    bottom: '92px',
    right: '12px',
    zIndex: 999 // Ensure it's above other elements
  }}>
   { !isScreenAbove1300 &&  <Fab onClick={toggleTabsVisibility} variant="extended" size="small" color="primary">
    <MenuIcon sx={{ mr: 1 }} />
          {
          tabContent.length>=1 &&
         ( tabContent[0].label == 'Business & Finance' ? 'Programs' : (tabContent[0].label == 'Campus Type' ? 'Audience' : (tabContent[0].label == 'State Rules' ? 'Rules' :'Media' )))
          
          
          }
    </Fab>}
  </Box>

    </Box>
  );
}
