import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { campaignFormSelector, setSelectedPopUpAdv, storeSelectedAdvertiser } from '../../../Store/Slices/CreateAdvCampaignSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../Store/hooks';
import { fetchAdvertiserDataAction } from '../../../Store/Actions';
import { AppDispatch } from '../../../Store';
import { setTcpaVertical } from '../../../Store/Slices/tcpaCampSlice';
import { fetchAllAdvertiserData } from '../../../service/Advertiser';
import { CircleLoader } from 'react-spinners';

interface CreateCampaignPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: (selectedOption: string) => void;
}

const CreateCampaignPopup: React.FC<CreateCampaignPopupProps> = ({ isOpen, onClose, onProceed }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const campaignFormData = useAppSelector(campaignFormSelector)
  const { pageLoading, advertisers, advertiserList, isEdit, isClone,selectedPopUpAdv } = campaignFormData
  const dispatch = useDispatch<AppDispatch>();

const advAPIPath = "advertisers"
useEffect(() => {
  if(isOpen){
    // fetchAdvertisers()
    dispatch(fetchAdvertiserDataAction(advAPIPath, { ID: 'advertiser' }));
  }

}, [isOpen]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    dispatch(setSelectedPopUpAdv(0));
    setSelectedOption(event.target.value);
    dispatch(setTcpaVertical(event.target.value));
  };
  const handleSelectAdv = (event: any) => {
    // console.log('event.target.value',event.target.value)
    dispatch(setSelectedPopUpAdv(event.target.value));
  };

  const handleProceed = () => {
    if (selectedOption) {
      onProceed(selectedOption);
    }
  };

  const handleCancel =()=>{
    setSelectedOption('');
    dispatch(setSelectedPopUpAdv(0));;
onClose();
  }

  return (
    <Dialog open={isOpen} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }}  maxWidth="xs" fullWidth>

    
      {pageLoading?<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px',  height:'100%' }}><CircleLoader loading={pageLoading}  color="#00BFFF" size={80}  /></div> : 
        <>
      <DialogTitle>Select Vertical Type</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{marginTop:'9px'}} >
              <InputLabel id="vertical-type-label">Vertical Type</InputLabel>
              <Select
                labelId="vertical-type-label"
                value={selectedOption}
                onChange={handleSelectChange}
                label="Vertical Type"
              >
                {/* <MenuItem value=""><em>Select</em></MenuItem> */}
                <MenuItem value="EDU">EDU</MenuItem>
                <MenuItem value="AUTO">AUTO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{marginTop:'9px'}} >
              <InputLabel id="adv-type-label">Advertisers</InputLabel>
              <Select
              disabled={!selectedOption}
                labelId="adv-type-label"
                value={selectedPopUpAdv}
                onChange={handleSelectAdv}
                label="Advertisers"
              >
               {/* {advertiserList.map((item: any, index: number) => {
                return (
                  <MenuItem value={item.advertiserID} key={item.advertiserID}>{item.advName}</MenuItem>
                )
              })} */}
               {advertiserList
        .filter((item: any) => item.vertical === selectedOption) // Filter by vertical
        .map((item: any) => (
          <MenuItem value={item.advertiserID} key={item.advertiserID}>
            {item.advName}
          </MenuItem>
        ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProceed} disabled={!selectedPopUpAdv} variant="contained" color="primary">
          OK
        </Button>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
      </>
}
    
    </Dialog>
  );
};

export default CreateCampaignPopup;
