import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, FormGroup, FormControlLabel, Tooltip, Snackbar, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Order, advTable } from './tableInterface';
import { EnhancedTableHead } from './EnhancedTable';
import { useLocation, useNavigate } from 'react-router-dom';
import ScrollComp from '../InfiniteScroll/infiniteScrollComp';
// import CreateAdvCampaigns from '../../View/Advertiser/Stepper Form/CreateAdvCampaigns';
import { AdvActionIcons } from '../../View/Advertiser/Account';
import { PubActionIcons } from '../../View/Publisher/Account';
import { useAppDispatch, useAppSelector } from "../../Store/hooks"
import MuiAlert from '@mui/material/Alert';
import './table.css';
import { AdvCampActionIcons } from '../../View/Advertiser/Campaigns';
import axios from 'axios';
import { baseURLConfig } from '../../config/envConfig';
import { setIsoLoading } from '../../Store/Slices/advertiserTabApisData';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { generateHeader } from '../../service/Auth';
import { PubUserCamActionIcons } from '../../View/Publisher/PublisherUserCampign';
import { SEOActionIcons } from '../../View/CMS/WebsiteCms/CreateSEO';
import { CircleLoader } from 'react-spinners';
import { StatusUpdateToggle } from './statusUpdateToggle';
import { MBrandActionIcons } from '../../View/External APIS/BrandMapping';
import DownloadIcon from '@mui/icons-material/Download';
//  import { PubliData } from './tableInterface';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ImportExportCampaigns from '../../View/Advertiser/Export Mprogam';
import { TcpaActionIcons } from '../../View/TCPAcampaign/TCPAcampaigns';
import { PubCamActionIcons } from '../../View/Publisher/publisherCampAction';
const baseURL = baseURLConfig.baseURl;
const API_BASE_URL = `${baseURL}/publishers`;
export const excludeKeys = [ 'error','isOFF'];
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const getValue = (obj: T, key: keyof T): string | Date => {
    const value = obj[key];

    // Check if the value is a string and can be converted to a date
    if (typeof value === 'string') {
      const dateValue = new Date(value);
      // Check if the conversion to a valid date succeeded
      if (!isNaN(dateValue.getTime())) {
        return dateValue;
      }
    }

    // If it's not a string or not convertible to a date, treat it as a string
    return String(value).toLowerCase();
  };

  const aValue = getValue(a, orderBy);
  const bValue = getValue(b, orderBy);


  // console.log("aValue ", aValue)
  // console.log("bValue ", bValue)
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort<T>(array: any[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  // console.log("stabilized this", stabilizedThis)
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


// Theme configuration
const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          backgroundColor: "#EAECF0",
          color: "#737791"
        },

      },
    },
  },
});


//create interface like this it should render dynamic value for different pages. 
// Table component
const MMTable: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const fullAdvAccData = useAppSelector((state) => state.advertiserAccApi.fullData);
  const fullPubAccData = useAppSelector((state) => state.publisherAccApi.fullData);
  const fullPubCampData = useAppSelector((state) => state.publisherCampApi.fullDataCamp);
  // const fullCampData = useAppSelector((state) => state.advertiserCampApi.fullDataCamp);
  const advCampaignsLimitData = useAppSelector((state) => state.advertiserCampApi.campaignDataWithLimits);
  const tcpaFullData = useAppSelector((state) => state.tcpaCampSlice.tcpaFullData);
  const fetchFlagAdvCamp = useAppSelector((state) => state.advertiserCampApi.fetchFlagAdvCamp);
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);
  const isoLoading = useAppSelector((state) => state.advertiserAccApi.isoLoading);
  const noDataFlag = useAppSelector((state) => state.advertiserAccApi.noDataFlag);
  const { tableData, tableHeadCell, fetchMoreData, hasMoreData, isLoadingMore, editComponentNavigation, fetchInitialData, searchInput } = props
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof advTable>('Advertiser_Name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const seoLoading = useAppSelector((state) => state.seoHandlerApi.loading);

//  const downloadUrl = 'https://mm-publisher-raw-report.s3.us-east-1.amazonaws.com/dummy-report_Banner%20Edge%20Media.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAUXKKIUMOXCFAW4MY%2F20240322%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240322T125136Z&X-Amz-Expires=900&X-Amz-Signature=25020451010b0a047178344e9e1b3e49b61c70d4bb0af7cbfa1d1659da188447&X-Amz-SignedHeaders=host&x-id=GetObject'
  const location = useLocation();
  // console.log("Tab;e head cell", tableHeadCell)
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof advTable,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const visibleRows = React.useMemo(
    () => stableSort(tableData, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ),
    [order, orderBy, tableData, page, rowsPerPage],
  );
  const headerCellsNew: string[] = Object.keys(tableData[0] ? tableData[0] : [])
  // console.log("header cell new", headerCellsNew,tableData)



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleFetchMore = () => {
    if (!isLoadingMore && hasMoreData) {
      fetchMoreData();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const getClassName = (row:any) => {
    if (row.error) {
      return 'highlighted-row';
    } else if (row.isOFF ||row?.capFlag ||row?.budgetFlag) {
      return 'paused';
    }
    return '';
  };

  const getToolTipLabel = (row: any) => {
    // Initialize label
    let label = '';
  
    // Check for errors in the row
    if (row.error) {
      label = 'Error! in uploaded CSV file.';
    } else {
      // Initialize labelArray to store parts of the label
      const labelArray: string[] = [];
  
      // Check conditions for paused state
      
      if (row.isOFF) {
        labelArray.push('Scheduling');
      }
      if (row?.capFlag) {
        labelArray.push('Caps');
      }
      if (row?.budgetFlag) {
        labelArray.push('Budget');
      }
  
      // If any condition for paused state is true, create the label
      if (labelArray.length > 0) {
        label = 'Paused - ' + labelArray.join('/');
      }
    }
  
    return label;
  };
  // console.log("visible rows", visibleRows)
  const renderTableBody = () => {
// console.log('mmtable1')
    return (

      <TableBody >
        {visibleRows.map((row: any,index:any) => {          ///visiblerow will come here
// const highlightClass = row.error ? "highlighted-row" : (row.isOFF ? "paused" : "");

 const highlightClass=getClassName(row);
          return (  <Tooltip key={row.campaign_id} title={getToolTipLabel(row)} placement="left">  
          <TableRow className={highlightClass}>
            {headerCellsNew.map((item: any, headCellIndex: number) => {
              if(!excludeKeys.includes(item)){
              if (item === 'Status' && (location.pathname != "/advertisePageHandler" && location.pathname != "/mPartner-activation" && location.pathname != "/raw-report" && location.pathname !='/createTcpaCamp')) {
                return <TableCell key={item} style={{ padding: '4px', lineHeight: '1', paddingLeft: '20px' }} data-title={item}>
                  <FormGroup>
                    <FormControlLabel
                    // disabled={location.pathname === "/TCPA-campaigns"}
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={row[item] === 'Active'}
                        //  value={iso}
                        onChange={() => {
                          StatusUpdateToggle(
                            row,
                            setSuccessMessage,
                            setErrorMessage,
                            setSnackbarOpen,
                            fetchInitialData,
                            tableData,
                            dispatch,
                            fullAdvAccData,
                            fullPubAccData,
                            fullPubCampData,
                            advCampaignsLimitData,
                            tcpaFullData,
                            location,
                            searchInput,
                            searchFilterData
                          )
                        }} />}
                      label=""
                    />
                  </FormGroup>
                </TableCell>
              }
              else if (item === 'download' && (location.pathname === "/raw-report" || location.pathname === "/adv-raw-report")) {
                // console.log('item====', row[item])
                return <TableCell key={item} style={{ padding: '4px', lineHeight: '1', paddingLeft: '15px' }} data-title={item}>
                 <Tooltip title="Download Report">
                  <IconButton
                    onClick={()=> window.open(row.download, '_self')}
                    color='primary'
                    disabled={row.rawStatus === 'In-Progress' || row.rawStatus === 'No Data Found'} >
                    <DownloadIcon sx={{fontSize:"larger"}}/> </IconButton></Tooltip>
                </TableCell>
              } else if (item === 'status' && location.pathname === "/advertisePageHandler") {
                return <TableCell key={item} style={{ padding: '4px', lineHeight: '1', paddingLeft: '20px' }} data-title={item}>
                <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }}
                      checked={row[item]}
                      //  value={iso}
                      onChange={() => {
                        props?.handleOnChange(row)
                      }} />}
                    label=""
                  />
                </FormGroup>
              </TableCell>
              }
              else if (item === 'Status' && location.pathname === "/createTcpaCamp") {
                return <TableCell key={item} style={{ padding: '4px', lineHeight: '1', paddingLeft: '15px' }} data-title={item}>
                <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }}
                       checked={row[item] === 'Active'}
                      //  value={iso}
                      onChange={() => {
                        // console.log(row)
                      }} />}
                    label=""
                  />
                </FormGroup>
              </TableCell>
              }
              else {
                return (
                  <TableCell data-title={tableHeadCell[headCellIndex].label} >{row[item]}</TableCell>
                )
              }
            }else{
              return null;
            }
            })}
            <TableCell data-title="Action" sx={{whiteSpace:'nowrap'}}>
              {props?.hasEdit &&(
                 <Tooltip title="Edit">
                 <EditIcon
                   onClick={ () => {
               props?.onEdit(row);
                   }}
                   color="primary"
                 />
               </Tooltip>
              )}
              {(location.pathname === '/mPartner-activation' || location.pathname === '/usermPartnerActivation' || location.pathname === '/mPub-margins' || location.pathname === '/cpc-threshold')  && <>
                <Tooltip title="Edit">
                  <EditIcon
                    onClick={async () => {
                      try {
                        dispatch(setIsoLoading(true));
                        const headers = await generateHeader();
                        const res = await axios.get(`${baseURLConfig.baseURl}/publishers/campaign?publisherID=${row?.publisherID}`, { headers });

                        const sortedCampaignData = res.data.sort((a:any,b:any) => {
                          // Compare the campaign names and sort them alphabetically
                          if (a.campaignname.toLowerCase() < b.campaignname.toLowerCase()) {
                            return -1; // a comes before b
                          }
                          if (a.campaignname.toLowerCase() > b.campaignname.toLowerCase()) {
                            return 1; // a comes after b
                          }
                          return 0; // names are equal
                        });

                        if (res && res.data.length > 0) {
                          navigate(editComponentNavigation, { state: { mPubId: row?.publisherID, ress: sortedCampaignData } });
                          dispatch(setIsoLoading(false));
                        } else {
                          dispatch(setIsoLoading(false));
                          setSnackbarOpen(true);
                          setErrorMessage('No Campaign under this publisher!');
                        }
                      } catch (error) {
                        dispatch(setIsoLoading(false));
                        console.error("Error fetching data:", error);
                      }
                    }}
                    color="primary"
                  />
                </Tooltip>

              </>}

              {location.pathname === '/cms-website' && <>
                <SEOActionIcons SEOID={row.website} fetchInitialData={fetchInitialData} reload={props.reload} />
               

              </>}
              {
                (location.pathname === '/advertiser-campaigns' || location.pathname === '/' && row.campaign_id) &&
                <>
                  <Tooltip title="Clone"><ContentCopyOutlinedIcon onClick={() =>
                  //  setOpenPopup(true)
                  {
                    props?.cloneHandler(row?.campaign_id)
                    navigate(editComponentNavigation, { state: { campaignId: row?.campaign_id } })
                  }

                  }
                    style={{ color: '#1976D2', marginRight: '5px' }} />
                  </Tooltip>

                  <Tooltip title="Edit">
                    <EditIcon onClick={() =>
                    //  setOpenPopup(true)
                    {
                      props?.editHandler(row?.campaign_id)
                      navigate(editComponentNavigation, { state: { campaignId: row?.campaign_id } })
                    }

                    } color="primary" />

                  </Tooltip>
                 
                    
                  <ImportExportCampaigns   editHandler={props?.editHandler}  campaignId={row?.campaign_id}  fileError={row?.error} />
                </>
              }

              {/* {
                  (location.pathname === '/TCPA-campaigns' && row.tcpaCampaign_id) &&
                  <Tooltip title="Edit">
                    <EditIcon onClick={() =>
                    //  setOpenPopup(true)
                    {
                      props?.editHandler(row?.tcpaCampaign_id)
                      navigate(editComponentNavigation, { state: { tcpaCampaignId: row?.tcpaCampaign_id } })
                    }

                    } color="primary" />

                  </Tooltip>
                } */}

              {(location.pathname === "/advertiser-campaigns" || location.pathname === '/' && row.campaign_id) &&
                <AdvCampActionIcons CamID={row.campaign_id} fetchInitialData={fetchInitialData} searchInputDe={searchInput} />
              }
              {(location.pathname === "/advertiser-accounts" && row.Adv_ID) &&
                <AdvActionIcons AdvID={row.Adv_ID} fetchInitialData={fetchInitialData} />
              }
              {(location.pathname === "/publisher-accounts" && row.Publisher_ID) &&
                <PubActionIcons PubID={row.Publisher_ID} fetchInitialData={fetchInitialData} />
              }
              {(location.pathname === "/publisher-campaigns" && row.campaign_id) &&
                <PubCamActionIcons CamID={row.campaign_id} fetchInitialData={fetchInitialData} searchInputDe={searchInput} />
              }
              {(location.pathname === "/publisherCampaigns" && row.campaign_id) &&
                <PubUserCamActionIcons CamID={row.campaign_id} fetchInitialData={fetchInitialData} searchInputDe={searchInput} />
              }
              {(location.pathname === "/brand-mapping" && row) &&
                <MBrandActionIcons mBrandID={row.mBrandID} fetchInitialData={fetchInitialData} searchInputDe={searchInput} />
              }
              {(location.pathname === "/TCPA-campaigns" && row) &&
                <TcpaActionIcons tcpaCampData={row} searchInputDe={searchInput}  editHandler={props.editHandler} setReload={props.reload}/>
              }
              {/* {
                (location.pathname === "/advertiser-campaigns" && row.campaign_id) &&
                <ContentCopyOutlinedIcon style={{ color: '#1976D2' }}></ContentCopyOutlinedIcon>
              } */}
            </TableCell>
           
          </TableRow>
           </Tooltip>
          )
        })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: 53 * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    )
  }
  return (
    <ThemeProvider theme={theme} >
      <TableContainer style={{ position: 'relative' }}>
        {isoLoading && (
          <div className="loading-border">
            <div className="loading-bar" />
          </div>
        )}
        <Box className='no-more-tables responsive-tbl'>
          <Table size="small" >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={tableHeadCell}
            />
            {renderTableBody()}
          </Table>
        </Box>
      </TableContainer >
    
      {seoLoading &&
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <CircleLoader color="#00BFFF" loading={seoLoading ? true : false} size={60} />
        </div>
      }
      {(hasMoreData) &&
        < ScrollComp datalength={tableData.length}
          fetchMore={handleFetchMore} hasMore={hasMoreData}
          LoadingMore={isLoadingMore} />
      }
      {(noDataFlag === true) && <p style={{ textAlign: 'center', paddingBottom: '10px', marginTop: '-5px', fontSize: 'large' }}>No Result Found</p>}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>

    </ThemeProvider>
  );
};

export default MMTable;


export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
