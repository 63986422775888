import React, { FC, useEffect } from 'react';
import { Box, Grid, Typography, FormControlLabel, Paper } from '@mui/material';
import { useFormik } from 'formik';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import { setTempData } from '../../../../Store/Slices/SEOWebsiteInfoApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { updateWebSiteContent } from '../../../../Services/seoApis';
import { setApiLoading, setSnackbarOpen, setSuccessMessage } from '../../../../Store/Slices/SEOHandlerApisSlice';

const Widget: FC<any> = ({ submitRef,handleNext }) => {
  const websiteInfoFormData = useAppSelector(state => state.websiteInfoApi);
  const dispatch = useAppDispatch();
  const formik = useFormik<any>({
    initialValues: {
      onlineSchoolsMatching: websiteInfoFormData?.onlineSchoolsMatching,
      requestInformation: websiteInfoFormData?.requestInformation,
      isGetInformationAccessible:websiteInfoFormData?.isGetInformationAccessible||false
    },
    onSubmit: (values) => {
      dispatch(setApiLoading(true));
      dispatch(
        setTempData({ ...websiteInfoFormData, ...values })
      );
console.log('values',values)
      const updateContent = async () => {
        try {
         await updateWebSiteContent({ ...websiteInfoFormData, ...values });
         dispatch(setSuccessMessage('Updated Widget'));
         dispatch(setSnackbarOpen(true));
         handleNext();
        } catch (error) {
          console.error('Error fetching data:', error);
      dispatch(setApiLoading(false));
        }
      dispatch(setApiLoading(false));
      };

    updateContent();
    },
    //  validationSchema: websiteInfoValidation,
  });

  const { handleSubmit } = formik;

  useEffect(() => {
    submitRef.current = () => { handleSubmit() }
  }, []);

  useEffect(() => {
    console.log('websiteInfoFormData', websiteInfoFormData);
  }, [websiteInfoFormData])

  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, [])

  return (
    <Paper elevation={3} className="ad-campaign-form">
      <Box sx={{ m: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography style={{ fontWeight: '500' }}>
              Online Schools Matching Your Search
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ ml: 1 }}
                  name="onlineSchoolsMatching"
                  checked={formik.values.onlineSchoolsMatching}
                  onChange={(e) => {
                    formik.handleChange(e);
                    // dispatch(
                    //   setToggleField({ field: 'onlineSchoolsMatching' })
                    // );
                  }}
                />
              }
              label=""
              sx={{ ml: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography style={{ fontWeight: '500' }}>Request Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ ml: 1 }}
                  name="requestInformation"
                  checked={formik.values.requestInformation}
                  onChange={(e) => {
                    formik.handleChange(e);
                    // dispatch(
                    //   setToggleField({ field: 'requestInformation' })
                    // );
                  }}
                />
              }
              label=""
              sx={{ ml: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography style={{ fontWeight: '500' }}>Is Get Information Accessible</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ ml: 1 }}
                  name="isGetInformationAccessible"
                  checked={formik.values.isGetInformationAccessible}
                  onChange={(e) => {
                    formik.handleChange(e);
                    // dispatch(
                    //   setToggleField({ field: 'requestInformation' })
                    // );
                  }}
                />
              }
              label=""
              sx={{ ml: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Widget;