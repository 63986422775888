import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
// sx={{display:'flex', alignItems:'center' , justifyContent:'center' , height:'100vh'}}
const ClickToLeads = () => {
  return (
    <Paper elevation={3} className="overall-page-container card">
      <Box p={3}>
    
        <Box sx={{display:'flex', alignItems:'center' , justifyContent:'center' , height:'95vh'}}>
        
        
      <Typography sx={{fontSize:"5rem"}}  className="card-heading">Coming Soon!</Typography> 
        </Box>
      </Box>
      </Paper>
  )
}

export default ClickToLeads