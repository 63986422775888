import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router} from 'react-router-dom'
import { Provider } from 'react-redux/es/exports'
import { store } from './Store/index';
import { Amplify, Auth } from "aws-amplify";
import { awsConfig } from './config/envConfig';

Amplify.configure(awsConfig)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
  <Router>
    <App />
  </Router>
  </Provider>
);

