import React from 'react'
import Skeleton from '@mui/material/Skeleton';

 interface skeletonI{
   variant: "text" | "rectangular" | "rounded" | "circular" | undefined
   width:string;
   height:string
 }
const SkeletonLoader:React.FC<skeletonI> = ({variant, width, height}) => {
    // width={210} height={60}
  return (
    <Skeleton  variant={variant} width={width} height={height}  animation="wave"    sx={{ bgcolor: '#F1F1F1' }}/>
  )
}

export default SkeletonLoader