export const advertiserPageMock = {
  postUrl: 'test-posturl',
  formQuestion: [
    {
      questions: [
        {
          postKey: 'highestEducationName',
          isRequired: false,
          formFieldType: 'radio',
          labelText: 'Highest Level',
          options: [
            {
              value: '1',
              key: 'High School Diploma',
            },
            {
              value: '6',
              key: 'Associates Completed',
            },
            {
              value: '7',
              key: 'Bachelors Completed',
            },
            {
              value: '8',
              key: 'Grad Program Completed',
            },
            {
              value: '2',
              key: 'No High School Diploma',
            },
            {
              value: '3',
              key: 'GED Completed',
            },
          ],
          headingLabel:
            "What's The Highest Level of Education You've Completed?",
          status: true,
        },
        {
          postKey: 'highestEducationYear',
          isRequired: false,
          formFieldType: 'year',
          labelText: 'Highest Education',
          options: [],
          headingLabel: 'What is Your High School Grad Year?',
          status: true,
        },
      ],
      id: '1',
      label: 'Step 1',
    },
    {
      questions: [
        {
          postKey: 'military',
          isRequired: false,
          formFieldType: 'radio',
          labelText: 'Affiliated Military',
          options: [
            {
              value: 'Yes',
              key: 'Yes',
            },
            {
              value: 'No',
              key: 'No',
            },
          ],
          headingLabel: 'Are You Affiliated with the Military?',
          status: true,
        },
        {
          postKey: 'militaryAffiliationName',
          isRequired: false,
          formFieldType: 'dropdown',
          labelText: 'Military Affiliation',
          options: [
            {
              value: '2',
              key: 'Active Duty',
            },
            {
              value: '3',
              key: 'Veteran',
            },
            {
              value: '4',
              key: 'Guard',
            },
            {
              value: '5',
              key: 'Spouse',
            },
            {
              value: '6',
              key: 'Dependent',
            },
          ],
          headingLabel: 'Excellent! How are you affiliated?',
          status: true,
        },
      ],
      id: '2',
      label: 'Step 2',
    },
    {
      questions: [
        {
          postKey: 'duration',
          isRequired: false,
          formFieldType: 'radio',
          labelText: ' Start Classes',
          options: [
            {
              value: '1',
              key: 'immediately',
            },
            {
              value: '2',
              key: '1-3 Months',
            },
            {
              value: '3',
              key: '4-6 Months',
            },
            {
              value: '4',
              key: '7-12 Months',
            },
            {
              value: '5',
              key: 'Later than 1 year',
            },
            {
              value: '6',
              key: 'Unknown',
            },
          ],
          headingLabel: 'When Would You Like to Start Classes?',
          status: true,
        },
      ],
      id: '3',
      label: 'Step 3',
    },
    {
      questions: [
        {
          postKey: 'userGender',
          isRequired: false,
          formFieldType: 'radio',
          labelText: 'Gender',
          options: [
            {
              value: 'M',
              key: 'Male',
            },
            {
              value: 'F',
              key: 'Female',
            },
          ],
          headingLabel: 'Choose Your Gender',
          status: true,
        },
      ],
      id: '4',
      label: 'Step 4',
    },
  ],
  primaryTextColor: '',
  urlPath: 'testUrlPath',
  hiddenField: [
    // {
    //   value: '',
    //   key: '',
    // },
  ],
  programContent: [
    {
      type: 'Certificate/Training Programs',
      value: 1,
      areaStudy: [
        {
          mProgram: [
            {
              description: "Bachelor's in Accounting",
              value: '101',
              status: true,
            },
            {
              description: "Bachelor's in Business Administration",
              value: '102',
              status: true,
            },
            {
              description: "Bachelor's in Digital Marketing & Advertising",
              value: '103',
              status: true,
            },
            {
              description: "Bachelor's in Finance",
              value: '104',
              status: true,
            },
            {
              description: "Bachelor's in Human Resources",
              value: '105',
              status: true,
            },
            {
              description: "Bachelor's in Management & Leadership",
              value: '106',
              status: true,
            },
          ],
          areaStudyLabel: 'Business',
          areaStudyId: '1',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Criminal Justice',
          areaStudyId: '2',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Psychology',
          areaStudyId: '3',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Technology',
          areaStudy: '4',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Healthcare',
          areaStudyId: '5',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Education & Teaching',
          areaStudyId: '6',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Vocational Training',
          areaStudyId: '7',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Liberal Arts',
          areaStudyId: '8',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'General',
          areaStudyId: '9',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Nursing',
          areaStudyId: '10',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Media & Communications',
          areaStudyId: '11',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Visual Arts & Graphic Design',
          areaStudyId: '12',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Religious Studies',
          areaStudyId: '13',
          enable: true,
        },
      ],
    },
    {
      type: 'Associates Degrees',
      value: 2,
      areaStudy: [
        {
          mProgram: [],
          areaStudyLabel: 'Business',
          areaStudyId: '1',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Criminal Justice',
          areaStudyId: '2',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Psychology',
          areaStudyId: '3',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Technology',
          areaStudy: '4',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Healthcare',
          areaStudyId: '5',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Education & Teaching',
          areaStudyId: '6',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Vocational Training',
          areaStudyId: '7',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Liberal Arts',
          areaStudyId: '8',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'General',
          areaStudyId: '9',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Nursing',
          areaStudyId: '10',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Media & Communications',
          areaStudyId: '11',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Visual Arts & Graphic Design',
          areaStudyId: '12',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Religious Studies',
          areaStudyId: '13',
          enable: true,
        },
      ],
    },
    {
      type: "Bachelor's Degrees",
      value: 3,
      areaStudy: [
        {
          mProgram: [],
          areaStudyLabel: 'Business',
          areaStudyId: '1',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Criminal Justice',
          areaStudyId: '2',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Psychology',
          areaStudyId: '3',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Technology',
          areaStudy: '4',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Healthcare',
          areaStudyId: '5',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Education & Teaching',
          areaStudyId: '6',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Vocational Training',
          areaStudyId: '7',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Liberal Arts',
          areaStudyId: '8',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'General',
          areaStudyId: '9',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Nursing',
          areaStudyId: '10',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Media & Communications',
          areaStudyId: '11',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Visual Arts & Graphic Design',
          areaStudyId: '12',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Religious Studies',
          areaStudyId: '13',
          enable: true,
        },
      ],
    },
    {
      type: "Master's Degrees",
      value: 4,
      areaStudy: [
        {
          mProgram: [],
          areaStudyLabel: 'Business',
          areaStudyId: '1',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Criminal Justice',
          areaStudyId: '2',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Psychology',
          areaStudyId: '3',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Technology',
          areaStudy: '4',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Healthcare',
          areaStudyId: '5',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Education & Teaching',
          areaStudyId: '6',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Vocational Training',
          areaStudyId: '7',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Liberal Arts',
          areaStudyId: '8',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'General',
          areaStudyId: '9',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Nursing',
          areaStudyId: '10',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Media & Communications',
          areaStudyId: '11',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Visual Arts & Graphic Design',
          areaStudyId: '12',
          enable: true,
        },
        {
          mProgram: [],
          areaStudyLabel: 'Religious Studies',
          areaStudyId: '13',
          enable: true,
        },
      ],
    },
  ],
  shortDescription: 'Lorem Ipsum Short Description',
  description: 'Lorem Ipsum Description',
  backgroundColor: '',
  tcpaText: 'Test TCPA Text',
  navBarColor: '',
  primaryColor: '',
};

export const program = [
  {
    type: 'Certificate/Training Programs',
    value: 1,
    areaStudy: [
      {
        enable: true,
        areaStudyLabel: 'Business',
        areaStudyId: '1',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Criminal Justice',
        areaStudyId: '2',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Psychology',
        areaStudyId: '3',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Technology',
        areaStudy: '4',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Healthcare',
        areaStudyId: '5',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Education & Teaching',
        areaStudyId: '6',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Vocational Training',
        areaStudyId: '7',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Liberal Arts',
        areaStudyId: '8',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'General',
        areaStudyId: '9',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Nursing',
        areaStudyId: '10',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Media & Communications',
        areaStudyId: '11',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Visual Arts & Graphic Design',
        areaStudyId: '12',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Religious Studies',
        areaStudyId: '13',
        mProgram: [],
      },
    ],
  },
  {
    type: 'Associates Degrees',
    value: 2,
    areaStudy: [
      {
        enable: true,
        areaStudyLabel: 'Business',
        areaStudyId: '1',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Criminal Justice',
        areaStudyId: '2',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Psychology',
        areaStudyId: '3',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Technology',
        areaStudy: '4',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Healthcare',
        areaStudyId: '5',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Education & Teaching',
        areaStudyId: '6',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Vocational Training',
        areaStudyId: '7',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Liberal Arts',
        areaStudyId: '8',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'General',
        areaStudyId: '9',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Nursing',
        areaStudyId: '10',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Media & Communications',
        areaStudyId: '11',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Visual Arts & Graphic Design',
        areaStudyId: '12',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Religious Studies',
        areaStudyId: '13',
        mProgram: [],
      },
    ],
  },
  {
    type: `Bachelor's Degrees`,
    value: 3,
    areaStudy: [
      {
        enable: true,
        areaStudyLabel: 'Business',
        areaStudyId: '1',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Criminal Justice',
        areaStudyId: '2',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Psychology',
        areaStudyId: '3',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Technology',
        areaStudy: '4',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Healthcare',
        areaStudyId: '5',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Education & Teaching',
        areaStudyId: '6',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Vocational Training',
        areaStudyId: '7',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Liberal Arts',
        areaStudyId: '8',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'General',
        areaStudyId: '9',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Nursing',
        areaStudyId: '10',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Media & Communications',
        areaStudyId: '11',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Visual Arts & Graphic Design',
        areaStudyId: '12',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Religious Studies',
        areaStudyId: '13',
        mProgram: [],
      },
    ],
  },
  {
    type: `Master's Degrees`,
    value: 4,
    areaStudy: [
      {
        enable: true,
        areaStudyLabel: 'Business',
        areaStudyId: '1',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Criminal Justice',
        areaStudyId: '2',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Psychology',
        areaStudyId: '3',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Technology',
        areaStudy: '4',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Healthcare',
        areaStudyId: '5',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Education & Teaching',
        areaStudyId: '6',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Vocational Training',
        areaStudyId: '7',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Liberal Arts',
        areaStudyId: '8',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'General',
        areaStudyId: '9',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Nursing',
        areaStudyId: '10',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Media & Communications',
        areaStudyId: '11',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Visual Arts & Graphic Design',
        areaStudyId: '12',
        mProgram: [],
      },
      {
        enable: true,
        areaStudyLabel: 'Religious Studies',
        areaStudyId: '13',
        mProgram: [],
      },
    ],
  },
];
