import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSEOConfig } from "../../Services/seoApis";

interface seoHandlerState {
    seoTitle: string;
    seoCanonicalUrl: string;
    ogTitle: string;
    ogLocale: string;
    ogType: string;
    twitterTitle: string;
    twitterSite: string;
    twitterCard: string;
    seoTableData:any;
    seoApiData:any;
    loading: boolean;
    successMessage:string;
    errorMessage:string;
    snackbarOpen:boolean;
    seoFormTableData:[];
    twitterDescription:string;
    seoImage:string;
    twitterImage:string;
    seoDescription:string;
    seoKeywords: [];
    id: string;
    areaMprogramData:[];
    degreeIntrestNew:[];
    cmsEditFlag:boolean;
    apiLoading:boolean;

}

const initialCampState: seoHandlerState = {
    seoTitle: '',
    seoCanonicalUrl: '',
    ogTitle: '',
    ogLocale: '',
    ogType: '',
    twitterTitle: '',
    twitterSite: '',
    twitterCard: '',
    seoTableData: [],
    seoApiData: [],
    loading: false,
    successMessage: '',
    errorMessage: '',
    snackbarOpen: false,
    seoFormTableData: [],
    twitterDescription: "",
    seoImage: "",
    twitterImage: "",
    seoDescription: "",
    seoKeywords: [],
    id: '',
    areaMprogramData: [],
    degreeIntrestNew: [],
    cmsEditFlag: false,
    apiLoading: false,
};



const seoHandlerApiSlice = createSlice({
    name: 'seoHandlerList',
    initialState: initialCampState,
    reducers: {
        setSeoDialogInfoField: (state, action: PayloadAction<{field: keyof seoHandlerState, value: string | boolean }>) => {
            const { field, value } = action.payload;
            (state as any)[field] = value;
          },
          setSeoKeywords: (state, action: PayloadAction<any>) => {
            state.seoKeywords = action.payload;
            
        },
        setSeoData: (state, action: PayloadAction<Partial<seoHandlerState>>) => {
            // Update multiple properties based on payload
            Object.assign(state, action.payload);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setSeoTableData: (state, action: PayloadAction<any>) => {
            state.seoTableData = action.payload;
            
        },
        setSeoFormTableData: (state, action: PayloadAction<any>) => {
            state.seoFormTableData = action.payload;
            
        },
        setSeoApiFullData: (state, action: PayloadAction<any>) => {
            state.seoApiData = action.payload;
        },
        setSuccessMessage: (state, action: PayloadAction<any>) => {
            state.successMessage = action.payload;
        },
        setErrorMessage: (state, action: PayloadAction<any>) => {
            state.errorMessage = action.payload;
        },
        setSnackbarOpen: (state, action: PayloadAction<any>) => {
            state.snackbarOpen = action.payload;
        },
        setApiLoading: (state, action: PayloadAction<any>) => {
            state.apiLoading = action.payload;
        },
        setAreaMprogramData: (state, action: PayloadAction<any>) => {
            state.areaMprogramData = action.payload;
            
        },
        setDegreeInterest : (state, action: PayloadAction<any>) => {
            state.degreeIntrestNew = action.payload;
            
        },
        setCmsEditFlag: (state, action: PayloadAction<boolean>) => {
            state.cmsEditFlag = action.payload;
          },
    },
});

export const { setSeoData, setLoading, setSeoTableData, setSeoDialogInfoField,setSeoApiFullData,setSuccessMessage, setSnackbarOpen,setErrorMessage,setApiLoading,setSeoFormTableData, setAreaMprogramData,setDegreeInterest,setCmsEditFlag} = seoHandlerApiSlice.actions;
export const seoHandlerApi = seoHandlerApiSlice.reducer;




export const createSEOAsync = createAsyncThunk(
    'seoHandlerApi/createSeo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await createSEOConfig(payload);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);