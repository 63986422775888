import React from 'react';
import { ISvgIcons } from './AdvertiserSVGIcon'

const SEOIcon: React.FC<ISvgIcons> = ({ iconColor }) => {
    return (
    <svg fill="#000000" height="32px" width="32px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.00 512.00">
         <path d="m437.5,239.9c1-7.3 2.1-13.5 2.1-20.8 0-70.6-57.2-127.7-128-127.7-37.5,0-71.8,15.6-95.7,42.6-13.5-7.3-29.1-11.4-44.7-11.4-48.9,0-89.5,35.3-96.8,83.1-38.4,18.6-63.4,58.1-63.4,101.7 0,62.3 51,113.2 113.4,113.2h281.9c52,0 93.6-41.5 94.7-92.4 5.68434e-14-40.5-26-75.8-63.5-88.3zm-31.2,159.9h-281.9c-51,0-92.6-41.5-92.6-92.4 0-37.4 21.8-70.6 56.2-85.1 3.1-1 6.2-4.2 6.2-8.3 3.1-40.5 35.4-70.6 75.9-70.6 15.6,0 29.1,4.2 43.7,10.4 4.2,3.1 10.4,2.1 13.5-2.1 19.8-26 51-41.5 84.3-41.5 59.3,0 107.2,47.8 107.2,106.9 0,8.3-1,17.6-3.1,26-2.8,9.2 4.2,12.5 7.3,13.5 33.3,7.3 56.2,36.3 56.2,70.6 0,40.4-32.3,72.6-72.9,72.6z"  fill={iconColor}></path> 
         <path d="m161.1,232.3c11.5,0 20.9,9.3 20.9,20.8 0,3.9 3.1,7 7,7s7-3.1 7-7c0-19.2-15.7-34.8-34.9-34.8-19.3,0-34.9,15.6-34.9,34.8s15.7,34.8 34.9,34.8c11.5,0 20.9,9.3 20.9,20.8 0,11.5-9.4,20.8-20.9,20.8s-20.9-9.3-20.9-20.8c0-3.9-3.1-7-7-7-3.9,0-7,3.1-7,7 0,19.2 15.7,34.8 34.9,34.8 19.3,0 34.9-15.6 34.9-34.8s-15.7-34.7-34.9-34.7c-11.5,0-20.9-9.3-20.9-20.8s9.4-20.9 20.9-20.9z"  fill={iconColor}></path> 
         <path d="M276,232.3c3.9,0,7-3.1,7-7s-3.1-7-7-7h-56.6c-3.9,0-7,3.1-7,7v111.4c0,3.9,3.1,7,7,7H276c3.9,0,7-3.1,7-7s-3.1-7-7-7 h-49.6V288H276c3.9,0,7-3.1,7-7s-3.1-7-7-7h-49.6v-41.7H276z"  fill={iconColor}></path>
          <path d="m338.7,218.3c-13,0-25.1,6.8-34.1,19.2-8.4,11.7-13.1,27.1-13.1,43.5s4.6,31.8 13.1,43.5c8.9,12.4 21,19.2 34.1,19.2s25.1-6.8 34.1-19.2c8.4-11.7 13.1-27.1 13.1-43.5s-4.6-31.8-13.1-43.5c-9-12.4-21.1-19.2-34.1-19.2zm0,111.3c-18.3,0-33.1-21.8-33.1-48.7 0-26.8 14.9-48.7 33.1-48.7 18.3,0 33.1,21.8 33.1,48.7 0,26.9-14.8,48.7-33.1,48.7z"  fill={iconColor}>
        </path></svg>
     );
};

export default SEOIcon;
