import React, { FC, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  InputAdornment,
  Input,
  Button,
  Paper,
  TextareaAutosize,
  FormGroup,
  IconButton,
  CardMedia,
  Tooltip,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import MuiAlert from '@mui/material/Alert';
import { useFormik, FormikProvider, FieldArray, getIn } from 'formik';
import debounce from 'lodash/debounce';
import { uploadSEOToS3 } from '../../../../../service/S3 Upload';
import htmlToDraft from 'html-to-draftjs';
import { updateAdvMBrandPortalData } from '../../../../../Services/seoApis';
import draftToHtml from 'draftjs-to-html';

const infoValidation = Yup.lazy(values => {
  return Yup.object().shape({
    primaryColor: Yup.string().required('Please select Primary Color'),
    urlPath: Yup.string().required('Please enter Url Path'),
    postUrl: Yup.string().required('Please enter Post Url'),
    tcpaText: Yup.string().required('Please enter TCPA Text'),
    // advertiserMessage: values.advertiserDisToggle === true ? Yup.string().required('Please enter an advertiser message') : Yup.string().nullable(),
    // advertiserLink: values.advertiserDisToggle === true ? Yup.string().required('Please enter an advertiser link') : Yup.string().nullable(),
    // areaStudy: Yup.string().required('Please select an area study'),
    // degreeInterest: Yup.string().required('Please select a degree interest'),
    // mProgram: Yup.string().required('Please select a mProgram'),
    //  widgetButton: Yup.string().required('Please enter a widget button text'),
    //  bannerRequestInfo: Yup.string().required('Please enter a banner request information text'),
    // //  uploadImage1: Yup.string().required('Please upload footer logo'),
    // //  uploadImage2: Yup.string().required('Please upload an image'),
    // favIcon: Yup.string().required('Please upload a favicon'),
    // advertiserDisclosure: values.advertiserDisToggle === true ? Yup.string().required('Please enter advertiser disclosure text') : Yup.string().nullable(),
    // //  footerContent: values.footerContentToggle === true ? Yup.string().required('Please enter some footer contents') : Yup.string().nullable(),
    // //    footerLinksToggle: Yup.boolean(),
    // //  footerLinks: values.footerLinksToggle === true ?
    // //     Yup.array().of(
    // //         Yup.object().shape({
    // //             key: Yup.string().required('Key is required'),
    // //             value: Yup.string().required('Value is required')
    // //         })
    // //     ).required('footerLinks is required') :
    // //     Yup.array().nullable()
  });
});
export const Info: FC<any> = ({ submitRef, formData, handleFormSubmit }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const formik: any = useFormik<any>({
    initialValues: {
      ...formData,
      urlPath: formData?.mBrandName
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, ''),
      hiddenField: formData?.hiddenField || [],
    },
    onSubmit: handleFormSubmit,
    validationSchema: infoValidation,
  });

  const debouncedSetFieldValue = useCallback(
    debounce((fieldName, value) => {
      formik.setFieldValue(fieldName, value);
    }, 10),
    []
  );

  useEffect(() => {
    loadInitialValue(formData?.description);
    submitRef.current = () => {
      formik?.handleSubmit();
    };
  }, []);

  const handleEditorFocus = () => {
    formik.handleBlur('description');
  };

  const loadInitialValue = (initialContent: string) => {
    if (initialContent) {
      const contentBlock = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
    }
  };

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    rawContentState?.blocks.forEach(value => {
      if (value.text) {
        formik.setFieldValue('description', draftToHtml(rawContentState));
      }
    });
  }, [editorState]);

  return (
    <Paper elevation={3} className="ad-campaign-form">
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                sx={{ background: '#ffffff' }}
                label="M Brand"
                onChange={() => {}}
                value={formData?.mBrandName}
                onBlur={() => {}}
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="nav-bar-picker"
              label="Nav Bar Color"
              name="navBarColor"
              value={formik.values.navBarColor}
              variant="outlined"
              fullWidth
              onChange={(e: any) => {
                debouncedSetFieldValue('navBarColor', e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <input
                      type="color"
                      value={formik.values.navBarColor}
                      onChange={(e: any) => {
                        debouncedSetFieldValue('navBarColor', e.target.value);
                      }}
                      style={{
                        width: '40px',
                        height: '40px',
                        padding: 0,
                        margin: 0,
                        border: 'none',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="background-color-picker"
              label="Background Color"
              name="backgroundColor"
              value={formik.values.backgroundColor}
              variant="outlined"
              fullWidth
              onChange={(e: any) => {
                debouncedSetFieldValue('backgroundColor', e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <input
                      type="color"
                      value={formik.values.backgroundColor}
                      onChange={(e: any) => {
                        debouncedSetFieldValue(
                          'backgroundColor',
                          e.target.value
                        );
                      }}
                      style={{
                        width: '40px',
                        height: '40px',
                        padding: 0,
                        margin: 0,
                        border: 'none',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="primary-color-picker"
              label="Primary Color"
              name="primaryColor"
              value={formik.values.primaryColor}
              variant="outlined"
              fullWidth
              onChange={(e: any) => {
                debouncedSetFieldValue('primaryColor', e.target.value);
              }}
              error={formik.errors.primaryColor && formik.touched.primaryColor}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <input
                      type="color"
                      value={formik.values.primaryColor}
                      onChange={(e: any) => {
                        debouncedSetFieldValue('primaryColor', e.target.value);
                      }}
                      style={{
                        width: '40px',
                        height: '40px',
                        padding: 0,
                        margin: 0,
                        border: 'none',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {formik.errors.primaryColor && formik.touched.primaryColor ? (
              <Box
                color="#db2424"
                sx={{
                  fontSize: '13px',
                }}
              >
                {formik.errors.primaryColor}
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="URL Path"
              name="urlPath"
              variant="outlined"
              value={formik.values.urlPath}
              onChange={e => {
                formik.handleChange(e);
              }}
              error={formik.errors.urlPath && formik.touched.urlPath}
              onBlur={formik.handleBlur}
              fullWidth
              inputProps={{ readOnly: true }}
            />
               {formik.errors.urlPath && formik.touched.urlPath ? (
              <Box
                color="#db2424"
                sx={{
                  fontSize: '13px',
                }}
              >
                {formik.errors.urlPath}
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Posting URL"
              name="postUrl"
              variant="outlined"
              value={formik.values.postUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.postUrl && formik.touched.postUrl}
              fullWidth
            />
                  {formik.errors.postUrl && formik.touched.postUrl ? (
              <Box
                color="#db2424"
                sx={{
                  fontSize: '13px',
                }}
              >
                {formik.errors.postUrl}
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              minRows={2}
              label="TCPA Text"
              name="tcpaText"
              value={formik.values.tcpaText}
              multiline
              maxRows={4}
              fullWidth
              error={formik.errors.tcpaText && formik.touched.tcpaText}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
                  {formik.errors.tcpaText && formik.touched.tcpaText ? (
              <Box
                color="#db2424"
                sx={{
                  fontSize: '13px',
                }}
              >
                {formik.errors.tcpaText}
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapper"
              editorClassName="editor"
              // onEditorStateChange={setEditorState}
              onEditorStateChange={editorState => {
                setEditorState(editorState);
              }}
              onFocus={handleEditorFocus}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'emoji',
                  'remove',
                  'history',
                ],
              }}
            />
          </Grid>
        </Grid>
        <Box>
          <FieldArray
            name="hiddenField"
            render={arrayHelpers => (
              <>
                {formik.values?.hiddenField?.map((item: any, index: number) => (
                  <div key={index}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        position: 'relative',
                        marginTop: '10px',
                      }}
                    >
                      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
                        <TextField
                          label="Hidden Field Key"
                          variant="outlined"
                          name={`hiddenField.${index}.key`} // Fixed name here
                          value={item.key}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          style={{ marginTop: '3%' }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
                        <TextField
                          label="Hidden Field Value"
                          variant="outlined"
                          name={`hiddenField.${index}.value`} // Fixed name here
                          value={item.value}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          style={{ marginTop: '3%' }}
                        />
                      </Grid>
                      {index > 0 && (
                        <IconButton
                          aria-label="remove"
                          onClick={() => arrayHelpers.remove(index)}
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                          }}
                        >
                          <CancelIcon sx={{ color: '#f45d5d', fontSize: 40 }} />
                        </IconButton>
                      )}
                    </Grid>
                  </div>
                ))}
                <Button
                  aria-label="add"
                  variant="contained"
                  size="small"
                  onClick={() => arrayHelpers.push({ key: '', value: '' })}
                  sx={{
                    textDecoration: 'none',
                    // color: '#18c0e2',
                    // fontSize: '15px',
                    // fontWeight: '500',
                    cursor: 'pointer',
                    mt: 2,
                  }}
                >
                  Add Hidden Value
                </Button>
              </>
            )}
          />
        </Box>
      </FormikProvider>
    </Paper>
  );
};
