import React, { FC, useEffect, useRef, useState } from 'react'
import MMTabs from '../../../../Component/Tabs/MMTabs'
import { stateRulesData, tcpaStateRulesData } from '../../../../Mock/GeoTargetingData'
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector, updateStateForEditAction } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { createTcpaCampAsync, setSnackbarMessage, setSnackbarOpen, setSnackbarType, setTcpaCreateJson, setTcpaEditJson } from '../../../../Store/Slices/tcpaCampSlice';
import { CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_UPDATE_SUCCESS } from '../../../../Mock/MessageContstant';
import { ErrorMessage } from 'formik';

// const StateRules = () => {
  const StateRules: FC<any> = ({ submitRef, handleNext }) => {
    const location = useLocation();
    const dispatch: any = useAppDispatch();
    const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);
    const currentState = useAppSelector(campaignFormSelector)
    // const excludeStates = useAppSelector((state) => state.CreateAdvCampaignReducer.value.excludeStates);

    const [responseValue, setResponseValue] = useState<null | any>(null)
    const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
    const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit,excludeStates,zipRules} = tcpaCampSlice
    const hasRun = useRef(false);
    
  const generateData=(key:string)=>{
      return [

      ]
  }


  useEffect(() => {
    if (location.pathname === '/createTcpaCamp' || location.pathname === '/editTcpaCamp') 
      {submitRef.current = () => {
      handleSubmit();
    }}

   
  }, [excludeStates,tcpaCampSlice.anyState,zipRules.zipCode]);

  const handleSubmit =async ()=>{
    let requestPayload;
    requestPayload = {
      ...responseValue,
        excludeStates: tcpaCampSlice.excludeStates,
        zipRules: tcpaCampSlice.zipRules,
        stateRules: tcpaCampSlice.stateRules,
        anyState: tcpaCampSlice.anyState,
        campaignType: "tcpaCampaign",
        tcpaCampaignId: tcpaEditFlag? tcpaCampaignIdOnEdit: tcpaCampId,
    }
          try{
          const response = await dispatch(createTcpaCampAsync({payload: requestPayload, method: 'put' }));
          if(response){
            tcpaEditFlag && dispatch(setTcpaEditJson(response.payload.data))
            !tcpaEditFlag && dispatch(setTcpaCreateJson(response.payload.data))
            dispatch(setSnackbarOpen(true))
            dispatch(setSnackbarType("success"))
            dispatch(setSnackbarMessage(tcpaEditFlag ? CAMPAIGN_UPDATE_SUCCESS : CAMPAIGN_CREATE_SUCCESS ))
            handleNext()
        }} catch(err){
          console.error(err);
          dispatch(setSnackbarOpen(true))
          dispatch(setSnackbarType("error"))
          dispatch(setSnackbarMessage(ErrorMessage))
        }
  }


  return (
    <div>
          <MMTabs tabContent={tcpaStateRulesData} />
  </div>

  )
}

export default StateRules