/* Code generated with AutoHTML Plugin for Figma */
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import "./ResetPassword.scss";
import { useState , useRef} from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../Store';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { decreaseActiveStep, getOTP, handelResetStep, handelUserNameError, increaseActiveStep, storeUserName } from "../../../Store/Slices/Auth/forgetPasswordSlice";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Auth } from 'aws-amplify';
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
type firstForm={
    userName:string;
     userNameHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
     userNameError:string
     buttonHandler:()=>void
   }
   
   
type passwordInputFieldComponent={
    newPassword:string;
    confirmPassword:string;
    handelNewPassword:(event: React.ChangeEvent<HTMLInputElement>) => void;
    handelConfirmPassword:(event: React.ChangeEvent<HTMLInputElement>) => void;
    confirmPasswordError:string;
    newPasswordError:string
    submitPasswordHandler:any
}


type otpInputField={
    otpValue:string
    // handleChange:(event: React.ChangeEvent<HTMLInputElement>, index:number)=>void
    handleChange:any;
    handleKeyDown:(event: React.KeyboardEvent<HTMLInputElement>, index:number)=>void
    inputRefs?: any
    buttonHandler:()=>void
}
export const ForgotPassword = ({ ...props }) => {
    const navigate=useNavigate();
    const dispatch=useAppDispatch();
  const userState=useAppSelector((state:RootState)=>state.forgetPasswordAuth);
    // Hooks for stateManagement
    // const [userName, setUserName]=useState<string>('')
    const [newPassword, setNewPassword]=useState<string>('');
    const [confirmPassword, setConfirmPassword]=useState<string>('');
    const [otp, setOtp]=useState<string>("")
    const [userNameError, setUserNameError]=useState<string>("")
    const [otpError, setOtpError]=useState<string>('')
    const [newPasswordError, setNewPasswordError]=useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError]=useState<string>('');
    // const [activeStep, setActiveStep]=useState<number>(0)
    //  const [otp, setOtp] = useState<string>('');
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

 
  const {getOTPLoading, user, activeStep, formErrors, userName}=userState;

     const handelOTPFormInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digit characters

    if (sanitizedValue.length <= 1) {
      setOtp(sanitizedValue);

      if (sanitizedValue.length === 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus(); // Shift focus to the next input field
      } else if (sanitizedValue.length === 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1]?.focus(); // Shift focus to the previous input field
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus(); // Shift focus to the previous input field on Backspace key press
    }
  };
    const handelNewPassword=(event: React.ChangeEvent<HTMLInputElement>)=>{
        if (!passwordRegex.test(event.target.value)) {
            // console.log("track:::::::::::::::")
            setNewPasswordError( "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter")
          } else {
            setNewPasswordError("")
    
          }
   setNewPassword(event.target.value)

    }

    const handelConfirmPassword=(event: React.ChangeEvent<HTMLInputElement>)=>{
        if (!passwordRegex.test(event.target.value)) {
            // console.log("track:::::::::::::::")
            setConfirmPasswordError( "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter")
          } else {
            setConfirmPasswordError("")
    
          }
   setConfirmPassword(event.target.value)

    }

    // console.log("userState in forgot password", userState)
 const handelUserName=(event:React.ChangeEvent<HTMLInputElement>)=>{
  const value=event.target.value;
  if (value.length <= 3) {
    setUserNameError('Username must be at least 4 characters long');
    dispatch(handelUserNameError('Username must be at least 4 characters long'))
  } else {
    setUserNameError(''); // Reset the error state if it's valid
    dispatch(handelUserNameError(''))
  }
    // setUserName(value);
    dispatch(storeUserName(value))
 }
 
 const handelPageBackNavigation=()=>{
    // console.log("backCLick 1", activeStep)
    // setActiveStep((prev)=>prev-1)
   dispatch( decreaseActiveStep('decrease'))
    if(!activeStep){
      handleReset();
    }
 }


 const submitResetPassword=(event:any)=>{
 event.preventDefault();
     Auth.forgotPasswordSubmit(userName, otp,newPassword).then((data)=>{
        
           handleReset()
           
      }).catch((err)=>{
        console.log("err", err)
      })
 }
 const handelContinueButton=async()=>{
  if(activeStep===0){
    if(userState.userName.length<= 3){
      setUserNameError('Username must be at least 4 characters long');
      dispatch(handelUserNameError('Username must be at least 4 characters long'))
    }else{
      await dispatch( getOTP(userState.userName))
    }


    
  }if(activeStep===1){
    dispatch(increaseActiveStep(''))
  }if(activeStep===2){
  }

  



 }

const handelOtp=(newValue:any)=>{
setOtp(newValue)
}
 
const handleReset = () => {
  // setActiveStep(0);
  dispatch(handelResetStep())
  navigate('/login')
};


 function getStepContent(step:number) {
    switch (step) {
      case 0:
        return <UserNameField userName={userName} userNameHandler={ handelUserName} userNameError={formErrors.userNameError} buttonHandler={handelContinueButton}/>
      case 1:
        return <OtpInputField  otpValue={otp} handleChange={handelOtp} handleKeyDown={handleKeyDown} inputRefs={inputRefs} buttonHandler={handelContinueButton}/>
      case 2:
        return <PassWordInputField  confirmPasswordError={confirmPasswordError} newPassword={newPassword} confirmPassword={confirmPassword} handelNewPassword={handelNewPassword}  handelConfirmPassword={handelConfirmPassword} newPasswordError={newPasswordError} submitPasswordHandler={submitResetPassword}/>
      default:
        return 'Unknown step';
    }
  }

    return (
    <Box className="forgot-password">
      <img
        className="forgot-password__kisspng-digital-marketing-digital-media-content-marketing-5-cc-1079-e-397486-2"
        src="images/login-bg.png"
        alt="bg-mm-img"
      />

      <Box className="forgot-password__media-matchers-logo">
         <img className='mm-logo' alt='mm-logo' src='images/MM-Logo-1.svg'/>
      </Box>

    {/* <UserNameField userName={userName} userNameHandler={ handelUserName} userNameError="" buttonHandler={handelContinueButton} /> */}
  {getStepContent(activeStep)}
      <div className="forgot-password__frame-1000002770" onClick={handelPageBackNavigation}>
        <div className="forgot-password__frame">
          <svg
            className="forgot-password__group4"
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3333 6.16669H3.67832L8.08915 1.75586C8.16874 1.67899 8.23223 1.58703 8.2759 1.48536C8.31958 1.38369 8.34257 1.27434 8.34353 1.16369C8.34449 1.05305 8.3234 0.943312 8.2815 0.840899C8.2396 0.738485 8.17772 0.645442 8.09948 0.567198C8.02124 0.488954 7.92819 0.427076 7.82578 0.385175C7.72337 0.343275 7.61363 0.32219 7.50298 0.323152C7.39233 0.324113 7.28298 0.347102 7.18131 0.390776C7.07964 0.43445 6.98769 0.497935 6.91082 0.577527L1.07748 6.41086C0.921259 6.56713 0.833496 6.77906 0.833496 7.00003C0.833496 7.221 0.921259 7.43292 1.07748 7.58919L6.91082 13.4225C7.06799 13.5743 7.27849 13.6583 7.49699 13.6564C7.71548 13.6545 7.92449 13.5669 8.079 13.4124C8.23351 13.2579 8.32115 13.0489 8.32305 12.8304C8.32495 12.6119 8.24095 12.4014 8.08915 12.2442L3.67832 7.83336H18.3333C18.5543 7.83336 18.7663 7.74556 18.9226 7.58928C19.0789 7.433 19.1667 7.22104 19.1667 7.00003C19.1667 6.77901 19.0789 6.56705 18.9226 6.41077C18.7663 6.25449 18.5543 6.16669 18.3333 6.16669Z"
              fill="#00AAEE"
            />
          </svg>
        </div>

        <div className="forgot-password__back">Back</div>
      </div>
    </Box>
  );
};


const UserNameField:React.FC<firstForm>=({userName, userNameHandler, userNameError , buttonHandler})=>{
  // console.log("userName error",userNameError.length)
  // console.log("userName error",userNameError)
  return (

        <div className="forgot-password__box">
        {/* <Box display="flex" alignItems="center" textAlign="center" className="forgot-password__frame-1000002748">
          <div className="forgot-password__forgot-password2">
            Forgot Password
          </div>

          <div className="forgot-password__please-enter-your-details-below-to-continue">
            Please enter your details below to continue
          </div>
        </Box> */}
         <Box display="flex" className="login__frame-1000002748">
        <Typography className="card-headingg">Forgot Password</Typography>
            <Typography className="card-sub-heading">
            Please enter your details below to continue
            </Typography>
        </Box>

        <div className="forgot-password__frame-1000002747">
            <TextField
           error={userNameError.length>0}
          id="filled-error-helper-text"
          label="User name"
        
          helperText={userNameError}
          variant="outlined"
          name="email"
          onChange={userNameHandler}
          sx={{ width:"100%"}}
          value={userName}
          
        />
        <Button className="forgot-password__button-base" onClick={buttonHandler}>Continue</Button> 
        </div>
      </div>
    )
}

const OtpInputField:React.FC<otpInputField>=({otpValue, handleChange, handleKeyDown, inputRefs, buttonHandler})=>{
    return (

        <div className="forgot-password__box">
        <div className="forgot-password__frame-1000002748">
          <div className="forgot-password__forgot-password2">
          Verify OTP
          </div>

          <div className="forgot-password__please-enter-your-details-below-to-continue">
          Please enter OTP that you received on email
          </div>
        </div>

        <div className="forgot-password__frame-1000002747">
        <Box >
         <MuiOtpInput value={otpValue} onChange={handleChange}   length={6} />
        </Box>
        
        <Button className="forgot-password__button-base" onClick={buttonHandler}>Continue</Button> 
        </div>
      </div>
    )
}


const PassWordInputField:React.FC<passwordInputFieldComponent>=(props)=>{
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  
    const handleMouseDownPassword = (event:any) => {
      event.preventDefault();
    };
    

    const {newPassword, confirmPassword, newPasswordError, handelConfirmPassword, submitPasswordHandler, confirmPasswordError, handelNewPassword}=props
   return(
  <Box className="forgot-password__box">
     <div className="forgot-password__frame-1000002748">
    <div className="forgot-password__forgot-password2">
    Reset Your Password
    </div>

    <div className="forgot-password__please-enter-your-details-below-to-continue">
    Please enter new password to continue
    </div>
  </div>
  <Box className='password_container'>
    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              className='password-text-input'
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            //   onChange={(event)=>{dispatch(handelNewPassword(event.target.value))}}
              label="Password"
              value={newPassword}
              onChange={handelNewPassword}
             error={newPasswordError.length>0}
              
            />
          </FormControl>
           <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
           <InputLabel htmlFor="outlined-adornment-password"> Confirm Password</InputLabel>
           <OutlinedInput
             id="outlined-adornment-password"
             type={showPassword1 ? 'text' : 'password'}
             className='password-text-input'
             endAdornment={
               <InputAdornment position="end">
                 <IconButton
                   aria-label="toggle password visibility"
                   onClick={handleClickShowPassword1}
                   onMouseDown={handleMouseDownPassword}
                   edge="end"
                 >
                   {showPassword ? <VisibilityOff /> : <Visibility />}
                 </IconButton>
               </InputAdornment>
             }
            //  onChange={(event)=>{dispatch(handelConfirmPassword(event.target.value))}}
             label="Confirm Password"
             onChange={handelConfirmPassword}
             value={confirmPassword}
             error={confirmPasswordError.length>0}
            //  inputRef={confirmPasswordRef}
           />
         </FormControl>
         
         {/* <Typography>{passwordError}</Typography> */}
        
         </Box>
         <Button className="forgot-password__button-base" onClick={submitPasswordHandler}>Reset Password</Button> 
  </Box>
    
   
   )
        }



