import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
  DialogActions,
  InputLabel,
  Typography,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useFormik,
  FormikProvider,
  FieldArray,
  ErrorMessage,
  getIn,
} from 'formik';
import * as Yup from 'yup';
import {
  setFaqs,
  setToggleField,
} from '../../../../../Store/Slices/CmsProgramContentDataSlice';
import { useAppDispatch, useAppSelector } from '../../../../../Store/hooks';
import { faqValidationSchema } from '../../../validation';
import { getFaqData, updateFaqData } from '../../../../../Services/seoApis';
import { CircleLoader } from 'react-spinners';
import { setApiLoading, setSnackbarOpen, setSuccessMessage } from '../../../../../Store/Slices/SEOHandlerApisSlice';

interface AddEditFAQContent {
  // faqTitle: string;
  // faqPara: string;
  faqs: { key: string; value: string }[];
  faqToggle: boolean;
  websiteDomainName: string;
  areaStudy: any;
  mProgram: any;
}

const AddEditFAQContent: FC<any> = ({
  openPopup,
  setDialogOpen,
  selectedValue,
}) => {
  // const [openPopup, setOpenPopup] = useState(false);
  // const [faqs, setFaqs] = useState<AddEditFAQContent[]>([{ faqTitle: '', faqPara: '' }]);
  const [dataFlag, setDataFlag] = useState<boolean>(true);
  const programContentState = useAppSelector(state => state.programContentApi);
 const apiLoading = useAppSelector((state) => state.seoHandlerApi.apiLoading);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const formik = useFormik<AddEditFAQContent>({
    initialValues: {
      faqs: [{ key: '', value: '' }],
      faqToggle: false,
      websiteDomainName: selectedValue?.websiteDomainName,
      areaStudy: selectedValue?.areaStudy,
      mProgram: selectedValue?.mProgram,
    },
    onSubmit: values => {
      // console.log('values', values);
      dispatch(setApiLoading(true));

      const updateData = async () => {
        try {
          const getData = await updateFaqData(values);
          dispatch(setSuccessMessage('Updated FAQ Settings'));
         dispatch(setSnackbarOpen(true));
          setDialogOpen(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setDialogOpen(false);
      dispatch(setApiLoading(false));
        }
      dispatch(setApiLoading(false));
      };
      updateData();
    },
    validationSchema: faqValidationSchema,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const getData = await getFaqData(
          `?websiteDomainName=${selectedValue?.websiteDomainName}&areaStudy=${selectedValue?.areaStudy}&mProgram=${selectedValue?.mProgram}`
        );
        if (getData?.length) {
          console.log('getData', getData[0]);
          formik.setValues(getData[0]);
          setDataFlag(false);
          //   setChips(getData[0]?.seoKeywords)
        }
        else{
          setDataFlag(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataFlag(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '100%',
            minWidth: '400px!important',
          },
        }}
        BackdropProps={{ style: { opacity: '1' } }}
      >
         {dataFlag ? (
      <Box className="loading-spinner-container" sx={{
        minHeight: "500px",
        minWidth:'500px',
      }}>
        <CircleLoader color="#00BFFF" loading={dataFlag ? true : false} size={60} />
      </Box>
    ) :
       (<> <DialogTitle>FAQ Settings</DialogTitle>
        <div className="close-button" onClick={handleClose}>
          <CloseIcon style={{ fontSize: '32px' }} />
        </div>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <FormikProvider value={formik}>
              <Grid container spacing={2}>
                {/* {formik.values.faqToggle && ( */}
                <Grid item xs={12}>
                  <FieldArray
                    name="faqs"
                    render={arrayHelpers => (
                      <>
                        {formik.values.faqs.map((url: any, index: any) => {
                          const touchedKey = getIn(
                            formik.touched,
                            `faqs.${index}.key`
                          );
                          const errorKey = getIn(
                            formik.errors,
                            `faqs.${index}.key`
                          );
                          const touchedValue = getIn(
                            formik.touched,
                            `faqs.${index}.value`
                          );
                          const errorValue = getIn(
                            formik.errors,
                            `faqs.${index}.value`
                          );

                          return (
                            <div key={index}>
                              <InputLabel
                                style={{
                                  fontWeight: 'bold',
                                  marginBottom: '10px',
                                }}
                              >
                                Default URL Values {index + 1}
                              </InputLabel>

                              <div
                                style={{
                                  position: 'relative',
                                  marginBottom: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: '20px',
                                }}
                              >
                                <div
                                  style={{
                                    position: 'relative',
                                    flex: 1,
                                    marginBottom: '10px',
                                    border: '2px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '4% 3%',
                                  }}
                                >
                                  <TextField
                                    label="Faq Title"
                                    variant="outlined"
                                    name={`faqs[${index}].key`}
                                    value={formik.values.faqs[index].key}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    error={touchedKey && Boolean(errorKey)}
                                    //   helperText={
                                    //     touchedKey && errorKey ? errorKey : ''
                                    //   }
                                  />
                                  {errorKey && touchedKey ? (
                                    <Typography
                                      component="span"
                                      sx={{ color: '#db2424' }}
                                    >
                                      {errorKey}
                                    </Typography>
                                  ) : null}
                                  <TextField
                                    label="Faq Para"
                                    variant="outlined"
                                    name={`faqs[${index}].value`}
                                    value={formik.values.faqs[index].value}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    style={{ marginTop: '3%' }}
                                    error={touchedValue && Boolean(errorValue)}
                                    //   helperText={
                                    //     touchedValue && errorValue ? errorValue : ''
                                    //   }
                                  />
                                  {errorValue && touchedValue ? (
                                    <Box color="#db2424">{errorValue}</Box>
                                  ) : null}
                                  {/* <Box color="#db2424">
                                <ErrorMessage
                                  name={`defaultUrl.${index}.value`}
                                />
                              </Box> */}
                                </div>
                                {index > 0 && (
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() => arrayHelpers.remove(index)}
                                    style={{
                                      position: 'absolute',
                                      top: '-10px',
                                      right: '-10px',
                                      backgroundColor: 'white',
                                      borderRadius: '50%',
                                    }}
                                  >
                                    <CancelIcon
                                      sx={{ color: '#f45d5d', fontSize: 40 }}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <span
                          onClick={() =>
                            arrayHelpers.push({ key: '', para: '' })
                          }
                          style={{
                            textDecoration: 'none',
                            color: '#18c0e2',
                            fontSize: '20px',
                            fontWeight: '500',
                            cursor: 'pointer',
                          }}
                        >
                          + Add More URL
                        </span>
                      </>
                    )}
                  />
                  <Grid item xs={12} lg={4} mt={1} mb={1}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.faqToggle}
                          onChange={formik.handleChange}
                          name="faqToggle"
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          style={{ fontSize: '18px' }}
                        >
                          Status
                        </Typography>
                      }
                      labelPlacement="start"
                      value={formik.values.faqToggle}
                      onChange={() => {
                        formik.setFieldValue(
                          'faqToggle',
                          !formik.values.faqToggle
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                {/* )} */}
              </Grid>
            </FormikProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" gap="16px">
            <Button onClick={handleClose}> Cancel </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              variant="contained"
              color="primary"
            >
              {
          apiLoading ? <CircularProgress color="inherit" size={24} /> : 'UPDATE'
        }
            </Button>
          </Box>
        </DialogActions> </>)
      }
      </Dialog>
    </>
  );
};
export default AddEditFAQContent;
