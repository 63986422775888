import React, { useEffect, useMemo } from 'react';
import PublisherStagingJSON from '../../Mock/PublishersStaging.json';
import { Box, Paper, Typography } from '@mui/material';
import MMTable from '../../Component/Table/MMTable';
import { cpcThresholdHeadCell, mPubMarginHeadCell } from '../../Component/Table/tableInterface';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { setCpcData, setData, setFlag } from '../../Store/Slices/PublisherTabApisData';
import {
  setNoDataFlag,
  setScrollNext,
  setSearchFilterData,
  setSearchInput,
} from '../../Store/Slices/advertiserTabApisData';
import Search from '../../Component/Search/Search';

// import { mpartnerActivationHeadCells } from '../../Component/Table/tableInterface';
import {
  fetchCpcApiData,
  fetcCpcCompApiData
} from '../../Services/mPartnerActivation';
import { useLocation } from 'react-router-dom';

const CpcThreshold = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const publisherTableData = useAppSelector(
    state => state.publisherAccApi.cpcData
  );
  const compApiData = useAppSelector(
    state => state.publisherAccApi.compApiCpcData
  );
  const isLoading = useAppSelector(state => state.publisherAccApi.loading);
  const currentCursor = useAppSelector(
    state => state.publisherAccApi.currentCursor
  );
  const hasMoreData = useAppSelector(
    state => state.publisherAccApi.hasMoreData
  );
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const searchInput = useAppSelector(
    state => state.advertiserAccApi.searchInput
  );

  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setSearchFilterData([]));
    if (!searchInput) {
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      fetchInitialData();
      // fetchCompApiData(dispatch);
    }
  }, [dispatch, searchInput]);

  useEffect(() => {
    dispatch(setSearchInput(''));
    fetcCpcCompApiData(dispatch,location);
  }, []);

  const fetchInitialData = () => {
    fetchCpcApiData(dispatch, null,location).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchCpcApiData(dispatch, currentCursor,location);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      let filteredDataa = compApiData.filter(function (item: any) {
        return (
          item.publisherID?.toString().toLowerCase().includes(userInput) ||
          item.publisherName?.toLowerCase().includes(userInput) ||
          // item.website?.toString().toLocaleLowerCase().includes(userInput) ||
          item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      console.log(filteredDataa)
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else {
        dispatch(setNoDataFlag(false));
      }
      dispatch(setCpcData(filteredDataa));
    } else {
      dispatch(setCpcData(publisherTableData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch(setSearchInput(value));
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchInput, searchTable]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchInput) {
        searchTable(searchInput);
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, [searchInput]);
  //--------------end search

  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className="card-heading">CPC Threshold</Typography>
            <Typography className="card-sub-heading">
            CPC Threshold Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Search value={searchInput} searchHandler={handleSearchInput} />
          </Box>
        </Box>
        <MMTable
          tableData={publisherTableData}
          tableHeadCell={cpcThresholdHeadCell}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
        //   hasEdit
        //   onEdit={(row:any) => {
        //     console.log(row);
        //   }}
         editComponentNavigation="/cpc-threshold-config"
        />
      </Paper>
    </React.Fragment>
  );
};

export default CpcThreshold;
