import React, { useEffect, useState } from 'react'
import { Paper, Box, Typography } from '@mui/material'
import { getAdvByVendor } from '../../../service/Vendor'
import CircleLoader from 'react-spinners/CircleLoader';
import VendorAccountTable from './VendorAccountTable';
import Search from '../../../Component/Search/Search';
interface Column{
  id:string;
  label:string;
  sortable:boolean
}
const columns: Column[] = [
  { id: 'status', label: 'Status', sortable: true },
  { id: 'mPubID', label: 'Advertiser ID', sortable: true },
  { id: 'advName', label: 'Advertiser', sortable: true },
  { id: 'vendorName', label: 'Vendor Name', sortable: true },
  { id: 'brand', label: 'Brand', sortable: true },
  { id: 'updatedOn', label: 'Updated On', sortable: true },
  
  { id: 'createdOn', label: 'Created On', sortable: true },
];

interface IadvertiserTable{
vendorName:string;
mPubID:string;
brand:string;
SORTID:string
createdOn:string;
updatedOn:string;
status:string;
}
const VendorAd = () => {

  const [advData, setData] = useState< any[]>([])
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      let vendorName = localStorage.getItem("userSortid");
      if (vendorName) {
        const res: any = await getAdvByVendor(vendorName)
        // console.log("res", res);
        setData(res?.result.map((item:any, index:number)=>{
           return {
            vendorName:item.vendorName,
            mPubID:item.mPubID,
            brand:item.brand,
            advName:item.values.companyName,
            createdOn:item.createdOn,
            updatedOn:item.updatedOn,
            status:item.status

           }
        }))
        setLoading(false)
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    fetchData()


  }, [])

const [searchValue, setSearchValue]=useState('')
const handelSearch=(event:React.ChangeEvent<HTMLInputElement>)=>{
  setSearchValue(event.target.value)
}

// useEffect(() => {
//   // console.log(searchValue)


// }, [searchValue])
  return (
    <Paper elevation={3} className="card">
      <Box className="user_Container">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>

            <Typography className="card-heading">Advertiser List</Typography>
            <Typography className="card-sub-heading">
              Advertiser Summary
            </Typography>

          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Search value={searchValue} searchHandler={handelSearch} />
           
          </Box>
        </Box>
        
        <Box className='user_body' >
          {loading ? <Box className="loading-spinner-container" sx={{
            minHeight: "700px"
          }}>

            <CircleLoader color="#00BFFF" loading={loading ? true : false} size={60} />
          </Box> :<VendorAccountTable data={advData}  columns={columns} />
          }  </Box>
      </Box>
    </Paper>
  )
}

export default VendorAd

