import React, { useEffect, useState } from 'react';
import {
  Formik,
  Form,
  Field,
  useFormik,
  FormikProvider,
  FieldArray,
  getIn,
} from 'formik';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  InputAdornment,
  Input,
  Button,
  Paper,
  TextareaAutosize,
  FormGroup,
  IconButton,
  CardMedia,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';

const optionSchema = Yup.object({
  key: Yup.string().required('Option key is required'),
  value: Yup.string().required('Option value is required'),
});

const validationSchema = Yup.object().shape({
  formFieldType: Yup.string().required('Filed Type is required'),
  labelText: Yup.string().required('Label is required'),
  postKey: Yup.string().required('Key is required'),
  // options: Yup.array().of(
  //   Yup.object().shape({
  //     key: Yup.string().required('Option key is required'),
  //     value: Yup.string().required('Option value is required'),
  //   })
  // ).when('formFieldType', {
  //   is: (val:any) => ['dropdown', 'radio', 'checkbox'].includes(val),
  //   then: Yup.array().min(1, 'At least one option is required for dropdown, radio, or checkbox'),
  //   otherwise: Yup.array().notRequired()
  // }),
  options: Yup.array()
    .of(optionSchema)
    .test(
      'check-options',
      'At least one option is required for dropdown, radio, or checkbox',
      function (value: any) {
        const { formFieldType } = this.parent;
        if (['dropdown', 'radio', 'checkbox','radio'].includes(formFieldType)) {
          return value && value.length > 0;
        }
        return true; // Skip validation for other form_field_types
      }
    ),
});

const ManageDynamicFormFiled = (props: any) => {
  const { openPopup, setOpenPopup, rowData, handleFormSubmit } = props;
  const [formFields, setFormFields] = useState<any>([]);
  const formik = useFormik<any>({
    initialValues: {
      headingLabel: rowData?.headingLabel || '',
      formFieldType: rowData?.formFieldType || '',
      labelText: rowData?.labelText || '',
      postKey: rowData?.postKey || '',
      options: rowData?.options || [{ key: '', value: '' }], // Initial options array
      isRequired: rowData?.isRequired || false,
      status: rowData?.status || true,
    },
    onSubmit: (val: any) => {
      console.log('val', val);
      handleFormSubmit(val)
      handleClose();
    },
    validationSchema,
  });

  const formFiledType = [
    {
      value: 'text',
      label: 'Text',
    },
    {
      value: 'number',
      label: 'Number',
    },
    {
      value: 'date',
      label: 'Date',
    },
    {
      value: 'checkbox',
      label: 'Checkbox',
    },
    {
      value: 'dropdown',
      label: 'Dropdown',
    },
    {
      value: 'radio',
      label: 'Radio',
    },
    {
      value: 'year',
      label: 'Year',
    },
  ];

  const handleClose = () => setOpenPopup(false);

  const handleAddOption = () => {
    formik.setValues({
      ...formik.values,
      options: [...formik.values.options, { key: '', value: '' }],
    });
  };

  const handleDeleteOption = (index: number) => {
    const updatedOptions = [...formik.values.options];
    updatedOptions.splice(index, 1);
    formik.setValues({
      ...formik.values,
      options: updatedOptions,
    });
  };

  const handleSubmit = async () => {

    if (
      formik.values.formFieldType !== 'checkbox' &&
      formik.values.formFieldType !== 'dropdown' &&
      formik.values.formFieldType !== 'radio' 
    ) {
      // Clear options field if the field type is not "Checkbox" or "Dropdown"
      await formik.setValues({
        ...formik.values,
        options: [],
      });
    }
    // Proceed with form submission
    formik.handleSubmit();
  };

  useEffect(() => {
    console.log('rowData', formik?.errors);
  }, [formik?.errors]);

  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        fullWidth
        className="backdrop"
      >
        <DialogTitle> Manage Form Questions</DialogTitle>
        <div className="close-button" onClick={handleClose}>
          <CloseIcon style={{ fontSize: '32px' }} />
        </div>
        <DialogContent style={{ paddingTop: '5px' }}>
          <Box>
            <FormikProvider value={formik}>
              <Grid container spacing={2} sx={{ mt: 1, mb: 4 }}>
              <Grid item xs={12}>
                  <TextField
                    label="Heading Label"
                    variant="outlined"
                    name="headingLabel"
                    value={formik.values.headingLabel}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.headingLabel &&
                      Boolean(formik.errors.headingLabel)
                    }
                  />
                  {formik.touched.headingLabel &&
                  Boolean(formik.errors.headingLabel) ? (
                    <Box color="#db2424">
                      {formik?.errors?.headingLabel as string}
                    </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Label Text"
                    variant="outlined"
                    name="labelText"
                    value={formik.values.labelText}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.labelText &&
                      Boolean(formik.errors.labelText)
                    }
                  />
                  {formik.touched.labelText &&
                  Boolean(formik.errors.labelText) ? (
                    <Box color="#db2424">
                      {formik?.errors?.labelText as string}
                    </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Post Key"
                    variant="outlined"
                    name="postKey"
                    value={formik.values.postKey}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.postKey && Boolean(formik.errors.postKey)
                    }
                  />
                  {formik.touched.postKey && Boolean(formik.errors.postKey) ? (
                    <Box color="#db2424">
                      {formik?.errors?.postKey as string}
                    </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Field Type</InputLabel>
                    <Select
                      value={formik.values.formFieldType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="formFieldType"
                      label="Field Type"
                      error={
                        formik.touched.formFieldType &&
                        Boolean(formik.errors.formFieldType)
                      }
                    >
                      {formFiledType?.map(type => (
                        <MenuItem value={type.value} key={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.formFieldType &&
                  Boolean(formik.errors.formFieldType) ? (
                    <Box color="#db2424">
                      {formik?.errors?.formFieldType as string}
                    </Box>
                  ) : null}
                </Grid>
                {(formik.values.formFieldType === 'dropdown' ||
                  formik.values.formFieldType === 'checkbox' ||
                  formik.values.formFieldType === 'radio') && (
                  <Grid item xs={12}>
                    <Typography variant="h6">Options</Typography>
                    {formik.values.options.map((option: any, index: number) => (
                      // <Box key={index} display="flex" alignItems="center">
                      <Grid container key={index} spacing={2} sx={{ mt: 1 }}>
                        <Grid item>
                          <TextField
                            label="Value"
                            variant="outlined"
                            name={`options.${index}.value`}
                            value={option.value}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Key"
                            variant="outlined"
                            name={`options.${index}.key`}
                            value={option.key}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => handleDeleteOption(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Button
                      variant="outlined"
                      sx={{ marginTop: '10px' }}
                      onClick={handleAddOption}
                    >
                      Add Option
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        as={Checkbox}
                        checked={formik.values.isRequired}
                        onChange={formik.handleChange}
                        name="isRequired"
                        value={formik.values.isRequired}
                      />
                    }
                    label="Is Required Field"
                  />
                </Grid>
              </Grid>
            </FormikProvider>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '15px' }}>
          <Button onClick={handleClose} style={{ color: 'red' }}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageDynamicFormFiled;
