import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  IconButton,
  useMediaQuery,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IOSSwitch } from '../../../../../Component/Table/MMTable';
import ManageDynamicFormFiled from './dynamicForm';

const QuestionsPage: FC<any> = ({ submitRef, formData, handleFormSubmit }) => {
  const [steps, setSteps] = useState(formData?.formQuestion || []);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [openFormFiledPopup, setOpenFormFiledPopup] = useState(false);
  const [rowData, setRowData] = useState<any>('');
  const [showTabs, setShowTabs] = useState(true);
  const isScreenAbove1300 = useMediaQuery('(min-width:1300px)');
  // Function to handle drag start
  const handleDragStart = (event: React.DragEvent, index: any) => {
    console.log('DragStart', index);
    event.dataTransfer.setData('draggedItemIndex', index);
  };

  // Function to handle drag over
  const handleDragOver = (event: React.DragEvent) => {
    console.log('handleDragOver');
    event.preventDefault();
  };

  // Function to handle drop
  const handleDrop = (event: React.DragEvent, index: number) => {
    console.log('handleDrop', index);
    event.preventDefault();
    const draggedItemIndex = Number(
      event.dataTransfer.getData('draggedItemIndex')
    );
    if (draggedItemIndex === index) return;

    const updatedQuestions = [...steps[activeStepIndex].questions];
    const [movedItem] = updatedQuestions.splice(draggedItemIndex, 1);
    updatedQuestions.splice(index, 0, movedItem);

    setSteps((prevSteps: any) =>
      prevSteps.map((step: any, idx: number) =>
        idx === activeStepIndex
          ? { ...step, questions: updatedQuestions }
          : step
      )
    );
  };

  // Function to handle adding a new step
  const handleAddStep = () => {
    setSteps((prevSteps: any) => [
      ...prevSteps,
      {
        id: (prevSteps.length + 1).toString(),
        label: `Step ${prevSteps.length + 1}`,
        questions: [],
      },
    ]);
  };

  // Function to handle deleting a step
  const handleDeleteStep = (id: string) => {
    setSteps((prevSteps: any) => {
      const updatedSteps = prevSteps.filter((step: any) => step.id !== id);

      // Update IDs and labels to be sequential
      const reSequencedSteps = updatedSteps.map((step: any, index: number) => ({
        ...step,
        id: (index + 1).toString(),
        label: `Step ${index + 1}`,
      }));

      // Adjust the active step index if needed
      const newIndex = Math.min(activeStepIndex, reSequencedSteps.length - 1);
      setActiveStepIndex(newIndex);

      return reSequencedSteps;
    });
  };

  useEffect(() => {
    submitRef.current = () => {
      handleFormSubmit({ ...formData, formQuestion: steps });
    };
  }, [steps]);

  useEffect(() => {
    if (!isScreenAbove1300) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [isScreenAbove1300]);

  const toggleTabsVisibility = () => {
    setShowTabs(!showTabs);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" sx={{ justifyContent: 'end', mt: 1, mb: 1 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddStep}
        >
          Add Step
        </Button>
      </Box>
      {steps.length > 0 && (
        <Box sx={{ position: 'relative', display: 'flex' }}>
          {showTabs && (
            <Box
              sx={{
                height: '70vh',
                flexBasis: '20%',
                padding: '10px',
              }}
              className="mm-tab-container"
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={activeStepIndex}
                onChange={(event, newValue) => setActiveStepIndex(newValue)}
                aria-label="Area Study Tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {steps.map((step: any, index: number) => (
                  <Tab
                    key={index}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>{step.label}</span>
                        <IconButton
                          edge="end"
                          color="error"
                          onClick={() => handleDeleteStep(step.id)}
                          sx={{ ml: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                    className={`mm-tab ${
                      index === activeStepIndex ? 'active' : ''
                    }`}
                  />
                ))}
              </Tabs>
            </Box>
          )}
          <Box
            className="mm-tabpanel-container"
            sx={{
              height: '75vh',
              flexBasis: '100%',
              padding: '11.5px',
            }}
          >
            <div role="tabpanel" style={{ width: '100%' }}>
              <Paper elevation={3} className="ad-campaign-form">
                <Box
                  display="flex"
                  sx={{ justifyContent: 'end', mt: 1, mb: 1 }}
                >
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setRowData('');
                      setOpenFormFiledPopup(true);
                    }}
                  >
                    Add New Field
                  </Button>
                </Box>
                <Box className="no-more-tables responsive-tbl">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Label</TableCell>
                        <TableCell>Key</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {steps[activeStepIndex]?.questions.map(
                        (val: any, index: number) => (
                          <TableRow
                            key={index}
                            draggable
                            onDragStart={event => handleDragStart(event, index)}
                            onDragOver={handleDragOver}
                            onDrop={event => handleDrop(event, index)}
                          >
                            <TableCell data-title="Label">{val?.labelText}</TableCell>
                            <TableCell data-title="Key">{val?.postKey}</TableCell>
                            <TableCell data-title="Status">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      sx={{ m: 1 }}
                                      checked={val?.status}
                                      onChange={() => {
                                        const updatedQuestions = steps[
                                          activeStepIndex
                                        ]?.questions.map((question: any) =>
                                          question?.postKey === val?.postKey
                                            ? {
                                                ...question,
                                                status: !question.status,
                                              }
                                            : question
                                        );

                                        setSteps((prevSteps: any) =>
                                          prevSteps.map(
                                            (step: any, idx: number) =>
                                              idx === activeStepIndex
                                                ? {
                                                    ...step,
                                                    questions: updatedQuestions,
                                                  }
                                                : step
                                          )
                                        );
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Edit">
                                <EditIcon
                                  onClick={() => {
                                    setRowData(val);
                                    setOpenFormFiledPopup(true);
                                  }}
                                  color="primary"
                                />
                              </Tooltip>
                              <Tooltip title="Edit">
                                <DeleteIcon
                                  color="error"
                                  sx={{ ml: 1 }}
                                  onClick={() => {
                                    const updatedQuestions = steps[
                                      activeStepIndex
                                    ]?.questions.filter(
                                      (question: any) =>
                                        question?.postKey !== val?.postKey
                                    );
                                    setSteps((prevSteps: any) =>
                                      prevSteps.map(
                                        (step: any, idx: number) =>
                                          idx === activeStepIndex
                                            ? {
                                                ...step,
                                                questions: updatedQuestions,
                                              }
                                            : step
                                      )
                                    );
                                  
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Paper>
            </div>
          </Box>
          <Box
            sx={{
              position: 'fixed',
              bottom: '92px',
              right: '12px',
              zIndex: 999, // Ensure it's above other elements
            }}
          >
            {!isScreenAbove1300 && (
              <Fab
                onClick={toggleTabsVisibility}
                variant="extended"
                size="small"
                color="primary"
              >
                <MenuIcon sx={{ mr: 1 }} />
                Program
                {/* {tabContent[0].label == 'Business & Finance' ? 'Programs' : (tabContent[0].label == 'Campus Type' ? 'Audience' : (tabContent[0].label == '[ISM] Online College Guide' ? 'Media' : 'Rules'))} */}
              </Fab>
            )}
          </Box>
        </Box>
      )}
      {openFormFiledPopup && (
        <ManageDynamicFormFiled
          openPopup={openFormFiledPopup}
          setOpenPopup={setOpenFormFiledPopup}
          rowData={rowData}
          handleFormSubmit={(value: any) => {
            if (rowData) {
              const updatedQuestions = steps[activeStepIndex]?.questions.map(
                (question: any) =>
                  question?.postKey === value?.postKey ? value : question
              );
              setSteps((prevSteps: any) =>
                prevSteps.map((step: any, idx: number) =>
                  idx === activeStepIndex
                    ? { ...step, questions: updatedQuestions }
                    : step
                )
              );
              // Update existing field logic here
            } else {
              setSteps((prevSteps: any) =>
                prevSteps.map((step: any, idx: number) =>
                  idx === activeStepIndex
                    ? { ...step, questions: [...step?.questions, value] }
                    : step
                )
              );
            }
          }}
        />
      )}
    </Box>
  );
};

export default QuestionsPage;
