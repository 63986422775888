import React, { memo, useState, useEffect } from 'react';
import {
    Paper, Box, Typography, Button, Modal, TextField,
    Table,
    TableBody,
    TableCell,
    TableSortLabel,
    TableHead,
    TableRow, FormControl, Grid, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, MenuItem
} from '@mui/material';
import { InputField } from '../CreateUser'
import { awsConfig } from '../../../config/envConfig'
import CloseIcon from '@mui/icons-material/Close';
import { createVendorGroup } from '../../../service/User'
import SnackbarMessage from '../../../Component/Snackbar'
import { getDisplayName } from './Vendors'
import { getAdvByVendor, updateVendorGroup } from '../../../service/Vendor';


import { CircleLoader } from 'react-spinners'
const advHeadCell = [
    {
        id: 'advertiserID',
        label: "Advertiser ID",
        numeric: false,
        key: "advertiserID"
    },
    {
        id: 'advertiserName',
        label: "Advertiser Name",
        numeric: false,
        key: "advertiserName"
    }
]

const createGroup = memo((props: any) => {
    const [groupInfo, setGroupInfo] = useState({
        groupName: '',
        groupDescription: '',
        groupNameError: ''
    })
    const [advList, setAdvList] = useState<any[]>([])
    const [loading, setLoading] = useState(false);
    const fetchAdvByGroupName = async (groupName: string) => {
        setLoading(true)
        const response = await getAdvByVendor(groupName)
        // console.log("response ", response)
        const data = response.result.map((item: any) => {
            return {
                advertiserID: item.mPubID,
                advertiserName: item.values.companyName
            }
        })

        setAdvList(data)
        setLoading(false);
    }
// const [isEdit, setIsEdit]=useState(false)
    useEffect(() => {

        const { isEdit, rowData } = props
        if (isEdit) {
            // setIsEdit(true)
            fetchAdvByGroupName(rowData.GroupName)
            setGroupInfo((prev:any) => (
                {
                    ...prev,
                    groupName: getDisplayName(rowData.GroupName),
                    groupDescription: rowData.Description
                }
            ))

        } else {
            // setIsEdit(false)
            setGroupInfo({
                groupName: '',
                groupDescription: '',
                groupNameError: ''
            })
        }


    }, [props.isEdit])
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [snackBarType, setSnackBarType] = useState('')
    const [snackBarMessage, setSnackBarMessage] = useState('')
    const handleSnackBarClose = () => {

        setSnackBarOpen(false)


    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("handleINput CHange", event)
        const { name, value } = event.target;
        if (name === 'groupName') {
            const error = validateGroupName(value);
            setGroupInfo((prev) =>
            ({
                ...prev,
                groupName: value,
                groupNameError: error
            }))

        } else if (name === 'Description') {
            setGroupInfo((prev) =>
            ({
                ...prev,
                groupDescription: value
            }))
        }
    }


    const handleCloseDialog = () => {
    
        setAdvList([])
        setGroupInfo({
            groupName: '',
            groupDescription: '',
            groupNameError: ''
        })
        
        props.modalCloseHandler()
        
        // setIsEdit(false)
    }


    const closeHandler=(event:any, reason:any)=>{
      if (reason && reason === "backdropClick" && "escapeKeyDown") 
            return;

        handleCloseDialog()
    }
// console.log("IS EDIT::::::::::::::::::::::", props.isEdit)
// console.log("open::::::::::::::::::::::", props.open)
    const handleCreateGroup = async () => {
        const params = {
            GroupName: `Vendor-${groupInfo.groupName}`,
            UserPoolId: awsConfig.Auth.userPoolId,
            Description: groupInfo.groupDescription, // Optional
            Precedence: 1, // Optional
        };

        if (!groupInfo.groupName) {
            setGroupInfo((prev) => (
                {
                    ...prev,
                    groupNameError: "Group name can not be blank"
                }
            ))
            return;

        }
        if (props.isEdit) {
            updateVendorGroup(params).then((res) => {
                setSnackBarOpen(true)
                setSnackBarType('success')
                setSnackBarMessage("Vendor group edited successfully")
                // props.snackBarHandler(true, 'success', "Vendor group created successfully")
                handleCloseDialog()
            }).catch((err) => {

                setSnackBarOpen(true)
                // props.snackBarHandler(true, 'error', err)
                setSnackBarType('error')
                setSnackBarMessage(err)

            })
        } else {
            createVendorGroup(params).then((res) => {
                setSnackBarOpen(true)
                setSnackBarType('success')
                setSnackBarMessage("Vendor group  created successfully")
                // props.snackBarHandler(true, 'success', "Vendor group created successfully")
                props.refreshTable();
                handleCloseDialog()
                // props.refreshTable()
            }).catch((err) => {

                setSnackBarOpen(true)
                // props.snackBarHandler(true, 'error', err)
                setSnackBarType('error')
                setSnackBarMessage(err)

            })
        }

    }
    return (
        <>
            <Dialog open={props.open} onClose={closeHandler} fullWidth maxWidth="lg">
                {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} >

                    <CircleLoader color="#00BFFF" loading={loading ? true : false} size={80} />
                </Box> :
                    <Paper elevation={3} className="card" >
                        <DialogTitle> {props.isEdit ? "Edit Vendor" : "Create Vendor"}</DialogTitle>
                        <div className="close-button" onClick={props.modalCloseHandler}>
                            <CloseIcon style={{ fontSize: '32px' }} />
                        </div>
                        <DialogContent
                            sx={{
                                overflowY: { xs: "auto", md: "hidden" },
                                maxHeight: { xs: "80vh", md: "none" },
                            }}
                        >
                            <Grid container spacing={3.5}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth style={{ marginBottom: "16px" }} >
                                        <InputLabel id="demo-simple-select-label">Group</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={'Vendor'}
                                            label="Group"
                                            name='groupType'
                                            disabled={props.isEdit ? true : false}
                                        >
                                            <MenuItem value={"Vendor"}>Vendor</MenuItem>

                                        </Select>
                                    </FormControl>
                                    <InputField name="groupName" value={groupInfo.groupName} changeHandler={handleInputChange} error={groupInfo.groupNameError} hidden={props.isEdit ? true : false} required={true} label={"Group Name"} />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <ListAdvDetails advList={advList} isEdit={props.isEdit} />
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ padding: "15px" }}>
                            <Button style={{ color: 'red' }} onClick={handleCloseDialog}>CANCEL</Button>
                         {!props.isEdit &&
                            <Button variant="contained" color="primary" onClick={handleCreateGroup}>Create</Button>}
                        </DialogActions>
                    </Paper>
                }


            </Dialog>
            <SnackbarMessage open={snackBarOpen} onClose={handleSnackBarClose} type={snackBarType} message={snackBarMessage} />
        </>
    )

});

export default createGroup;




const validateGroupName = (name: string) => {
    // Length constraint
    if (name.length < 1 || name.length > 128) {
        return 'Group name must be between 1 and 128 characters long.';
    }

    // Pattern constraint
    const pattern = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u;
    if (!pattern.test(name)) {
        return 'Group name contains invalid characters.';
    }

    return ''; // No error
};


interface IadvList {
    advList: any[],
    isEdit:boolean
}
const ListAdvDetails = (props: IadvList) => {
    const { advList, isEdit } = props;
    return (
        <Paper elevation={3} className="card" >
            <Box display="flex" justifyContent="center" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
                <Box display="flex" justifyContent="center"  >
                    <Typography className="card-heading" sx={{ border: "10px" }}> Advertisers</Typography>

                </Box>

            </Box>

            <Box className='no-more-tables responsive-tbl' sx={{ overflow: 'auto', maxHeight: '400px' }}>
                <Table size="small">
                    <TableHead sx={{
                        fontWeight: "bold",
                        backgroundColor: "#EAECF0",
                        color: "#737791"
                    }}>
                        <TableRow>
                            {
                                advHeadCell.map((item) => {
                                    return (

                                        <TableCell>
                                          
                                                {item.label}
                                         
                                        </TableCell>

                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                       {
                            advList.map((listItem) => (
                                <TableRow>
                                    {advHeadCell.map((key) => (
                                        <TableCell data-title={key.label}>{listItem[key.id]}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                       {advList.length===0 && <p style={{ textAlign: 'center', paddingBottom: '10px', marginTop: '5px', fontSize: 'large' , alignItems:'center'}}>
                        No Data Available
                    </p>

                }

            
            </Box>
        </Paper>
    )
}