import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { RootState } from '../../Store/index'; // Update the path to your Redux store
import { Box, Button, Checkbox, Chip, CircularProgress, DialogContentText, FormControl, FormGroup, FormHelperText, FormLabel, Input, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Snackbar, Tooltip, Typography, } from '@mui/material'
import MMTable, { IOSSwitch } from '../../Component/Table/MMTable'
import AddIcon from '@mui/icons-material/Add';
import { publiCampaignHeadCells } from '../../Component/Table/tableInterface';
import './PublisherList.css'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import Search from '../../Component/Search/Search';
import IconButton from '@mui/material/IconButton';
import { fetchCompApiData, filterPublisherSearch, searchPublisherCampaigns } from '../../Services/publisherTabApis';
import { setFields, setDayCapField, resetForm, statusHandler, specificDayToggle, setSelectedRadioValue, setEditFlag, setExcludeMbrandId, setCapSettingsField } from '../../Store/Slices/PublisherCampaignSlice';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { createPublisherCampAsync, setCampData, setCampFlag, setFrontEndFilSearch, setfilterSearchFlag, updatePublisherCampAsync } from '../../Store/Slices/PublisherTabApisData';
import { fetchPublisherCampApiData } from '../../Services/publisherTabApis';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
// import { DeleteIconPop } from '../Advertiser/Action';
import { setBlockScroll, setIsoLoading, setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData';
// import { Link, useNavigate } from 'react-router-dom';
// import AdUnitsIcon from '@mui/icons-material/AdUnits';
import MbrandExclusion from '../Tools/MbrandExclusion';
import { SelectChangeEvent } from '@mui/material/Select';
const CAMPAIGN_TYPE_OPTIONS = [
  {
    label: 'Clicks',
    value: 'Clicks'
  },
  {
    label: 'TCPA',
    value: 'TCPA'
  },
  {
    label: 'Leads',
    value: 'Leads'
  },
  {
    label: 'Email',
    value: 'Email'
  },

]

const PublisherCampaigns = () => {

  const dispatch = useAppDispatch();
  const publiCampaignTableData = useAppSelector((state) => state.publisherCampApi.datacamp);
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);
  const isLoading = useAppSelector((state) => state.publisherCampApi.campLoading);
  // const compApiData = useAppSelector((state) => state.publisherCampApi.compApiDataCamp);
  const currentCursor = useAppSelector((state) => state.publisherCampApi.currentCampCursor);
  const hasMoreData = useAppSelector((state) => state.publisherCampApi.hasCampMoreData);
  const [openPopup, setOpenPopup] = React.useState(false);
  const fullDataPubli = useAppSelector((state) => state.publisherAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  // const [selectedOptions, setSelectedOptions] = useState<number[]>([]); // Specify the type as number[]
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const filterSearchFlag = useAppSelector((state) => state.publisherCampApi.filterSearchFlag);
  const filterSearchPubData = useAppSelector((state) => state.publisherCampApi.filterSearchPubData);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  // const FrontEndSearchInput = useAppSelector((state) => state.advertiserCampApi.FrontEndSearchInput);
  // const frontEndFilSearch = useAppSelector((state) => state.publisherCampApi.frontEndFilSearch);
  const campaignState = useAppSelector((state: RootState) => state.campaign);
  const {verticalFilter} = campaignState
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const verticalOptions: string[] = ["AUTO", "EDU"];
  let updatedFilterData: any


  
  useEffect(() => {
    // console.log('triggred1')
    dispatch(setfilterSearchFlag(false));
    setIsMouseOverDelete(false);
    dispatch(setCampFlag(false));
    setVerticalMouseOverDelete(false);
    if (!searchInput && searchFilterData.length === 0 && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setFrontEndFilSearch(''));
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      fetchInitialCampData();
      dispatch(setNoDataFlag(false));
      // fetchComCampApiData(dispatch);
    } else {
      if ((searchFilterData.length !== 0 && searchInput === '') || (verticalFilter.length !== 0 && searchInput === '')) {
        // console.log('triggred3')
        dispatch(setSearchInput(''));
        const debounceTimer = setTimeout(async () => {
          const payload = {
            "vertical": verticalFilter,
            "mPubIds": searchFilterData
          }
       { (verticalFilter.length > 0 || searchFilterData.length > 0) && filterPublisherSearch(payload, dispatch)}

        }, 1000);

        // Clear the timer on every key press to restart the countdown
        return () => clearTimeout(debounceTimer);
      } else {
        // console.log('triggred4')
        const debounceTimer = setTimeout(() => {
          setDebouncedInputValue(searchInput);
        }, 600);

        // Clear the timer on every key press to restart the countdown
        return () => clearTimeout(debounceTimer);
      }
    }
    // console.log('triggred5')
  }, [dispatch, searchInput, searchFilterData,verticalFilter]);

  useEffect(() => {
    // console.log('triggred6')
    dispatch(setSearchFilterData([]));
    // dispatch(setSearchInput(''));
    fetchPublisherCampApiData(dispatch, null)
    // fetchComCampApiData(dispatch);
    fetchCompApiData(dispatch);
  }, [])
  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      dispatch(setSearchFilterData([]));
      searchPublisherCampaigns(debouncedInputValue, dispatch, 'campaign')
    }
  }, [debouncedInputValue])
  const fetchInitialCampData = () => {
    fetchPublisherCampApiData(dispatch, null).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setCampFlag(true));
      fetchPublisherCampApiData(dispatch, currentCursor);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchPubData.filter(function (item: any) {
        return (
          item.campaign_id?.toString().toLowerCase().includes(userInput) ||
          item.campaign_name?.toLowerCase().includes(userInput) ||
          item.Publisher_ID?.toString().toLowerCase().includes(userInput) ||
          item.Publisher_Name?.toLowerCase().includes(userInput) ||
          item.Status?.toString().toLocaleLowerCase().includes(userInput)
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setCampData(filteredDataa));
    } else {
      dispatch(setCampData(filterSearchPubData));
      // dispatch(appendCampData(filteredData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterSearchInput(value);
    //dispatch(setFrontEndFilSearch(value));
    searchTable(value)
  }

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchInput(event.target.value));
  }



  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }

  const handleChipRemove = (mPubID: number) => {
    updatedFilterData = searchFilterData.filter((id: number) => id !== mPubID);
    dispatch(setSearchFilterData(updatedFilterData));
  };

  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };

  const handleRemoveVertical = (value: string) => {
    const newVerticalFilter = verticalFilter.filter(
      (vertical) => vertical !== value
    );
    handleFieldChange("verticalFilter", newVerticalFilter); // Update the state with the new array
    setVerticalMouseOverDelete(false);
  };

  return (
    <>
      <Paper elevation={3} className="card" >
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
          <Box >
            <Typography className="card-heading" sx={{ border: "10px" }}>Publisher Campaign</Typography>
            <Typography className="card-sub-heading">
              Publisher Campaigns Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={filterSearchFlag ? filterSearchInput : searchInput} searchHandler={filterSearchFlag ? handleFilter : handelSearch} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenPopup(true);
                dispatch(setEditFlag(false));
              }}
              sx={{ backgroundColor: "#0ae" }}
            >
              Create Campaign
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="8px">
        <FormControl style={{ width: '30%', marginBottom: '0.4%', paddingLeft: '1%' }}>
      <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
      <Select
        label="Filter by Vertical"
        multiple
        value={verticalFilter} // Ensure this is an array in your state
        onChange={(e) => handleFieldChange("verticalFilter", e.target.value as string[])} // Update handler to manage multiple selections
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() => handleRemoveVertical(value)} // Call the remove function here
                deleteIcon={
                  <IconButton
                    size="small"
                    onMouseEnter={() => setVerticalMouseOverDelete(true)}
                    onMouseLeave={() => setVerticalMouseOverDelete(false)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            ))}
          </div>
        )}
        style={{ height: '50px' }}
        MenuProps={{
          style: {
            maxHeight: '400px', // Adjust height
            marginTop: '5px',   // Adjust margin to start below the select field
          },
        }}
        disabled={VerticalMouseOverDelete} 
      >
        {verticalOptions.map((vertical) => (
          <MenuItem key={vertical} value={vertical}>
            <Checkbox checked={verticalFilter.includes(vertical)} />
            <ListItemText primary={vertical} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  {/* New Dropdown with Name and Country */}
  {/* <FormControl style={{ width: '30%', marginBottom: '0.4%',paddingLeft: '1%'}}>
    <InputLabel style={{paddingLeft:'19px'}} >Filter by Vertical</InputLabel>
    <Select
     label="Filter by Vertical"
      value={campaignState.verticalFilter} // Assuming you have this value in your state
      onChange={(e) =>  handleFieldChange("verticalFilter", e.target.value)} // Update your handler
      style={{ height: '50px' }}
    > */}
      {/* <MenuItem value="Select">Select Vertical</MenuItem> */}
      {/* <MenuItem value="AutoInsurance">Auto Insurance</MenuItem>
      <MenuItem value="HigherEducation">Higher Education</MenuItem>
    </Select>
  </FormControl> */}

  {/* Existing Publisher Filter Dropdown */}
  <FormControl style={{width: '67%', marginBottom:'0.4%'}}>
    <InputLabel>Filter by Publisher</InputLabel>
    <Select
      multiple
      label="Filter by Publisher"
      value={searchFilterData}
      onChange={(event) => {
        handleFilterSearch();
        dispatch(setSearchFilterData(event.target.value as []));
      }}
      renderValue={(selected) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {fullDataPubli
            .filter((pub: any) => selected.includes(pub.mPubID))
            .sort((a: any, b: any) => a.publisherName.localeCompare(b.publisherName))
            .map((pub: any) => (
              <Chip
                key={pub.SORTID}
                label={`${pub.publisherName}`}
                onDelete={() => handleChipRemove(pub.mPubID)}
                deleteIcon={
                  <IconButton
                    size="small"
                    onClick={() => handleChipRemove(pub.mPubID)}
                    onMouseEnter={() => setIsMouseOverDelete(true)}
                    onMouseLeave={() => setIsMouseOverDelete(false)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            ))}
        </div>
      )}
      MenuProps={{
        style: {
          maxHeight: '400px',
          marginTop: '5px',
        },
      }}
      disabled={isMouseOverDelete}
      style={{ height: '50px' }}
    >
      {fullDataPubli
        .filter((pub: any) => pub.publisherName)
        .sort((a: any, b: any) => a.publisherName.localeCompare(b.publisherName))
        .map((pub: any) => (
          <MenuItem key={pub.mPubID} value={pub.mPubID}>
            <Checkbox checked={searchFilterData.includes(pub.mPubID)} />
            <ListItemText primary={`${pub.publisherName} - ${pub.mPubID}`} />
          </MenuItem>
        ))}
    </Select>
  </FormControl>
</Box>


        <MMTable
          tableData={publiCampaignTableData}
          tableHeadCell={publiCampaignHeadCells}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          isLoadingMore={isLoading}
          fetchInitialData={fetchInitialCampData}
          searchInput={debouncedInputValue}
        />
      </Paper>
      <CreatePubliCampaigns
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialCampData}
      />
    </>
  );

}

export default PublisherCampaigns



export const CreatePubliCampaigns: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const campaignState = useAppSelector((state: RootState) => state.campaign);
  const { openPopup, setOpenPopup, rowData, fetchInitialData, searchInput } = props;
  const fullDataPubli = useAppSelector((state) => state.publisherAccApi.fullData);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [publisherNameError, setPublisherNameError] = useState(false);
  const [campaignNameError, setCampaignNameError] = useState(false);
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);
  const [verticalNameError, setVerticalNameError] = useState(false);
  const [campaignType, setCampaignType] = React.useState(rowData?.campaignClass ? rowData?.campaignClass : CAMPAIGN_TYPE_OPTIONS[0].value);
  const getRowDataBycamId = (camId: string) => {
    return fullDataPubli.find((row: any) => row?.SORTID === camId);
  };
  const RowDataPay = getRowDataBycamId(campaignState.publisherName);

  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };
  const [open, setOpen] = useState(false);
  const [matchingIds, setMatchingIds] = useState<string[]>([]);

  const handleConfirm = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    // clearSelectedFile();
    setOpen(false);
  };
 

const [campaignTypeError, setCampaignTypeError]=useState(false)
//new feature
// const handleCreateCampaign = async () => {
  //   setErrorMessage('');
  //   setSuccessMessage('');
    
  //   if (!campaignState.publisherName.trim() || !campaignState.campaignname.trim() || !campaignState.vertical.trim()) {
  //     setPublisherNameError(!campaignState.publisherName.trim());
  //     setCampaignNameError(!campaignState.campaignname.trim());
  //     setVerticalNameError(!campaignState.vertical.trim())
  //     if (
  //       !state.clicks && 
  //       !state.tcpa && 
  //       !state.leads && 
  //       !state.email && 
  //       !state.linkout
  //     ) {
  //       // return 'Please select at least one campaign type.';
  //       setCampaignTypeError(true)
  //     }

      
  //   } else if (campaignState.campaignname.includes(',')) {
  //     setErrorMessage('Comma is not allowed in Publisher Camp Name');
  //     setSnackbarOpen(true);
  //     return;
  //   } else  if (
  //     !state.clicks && 
  //     !state.tcpa && 
  //     !state.leads && 
  //     !state.email && 
  //     !state.linkout
  //   ) {
  //     // return 'Please select at least one campaign type.';
  //     setCampaignTypeError(true)
  //   }

  //   else {
  //     setCampaignTypeError(false)
  //     setSuccessMessage('');
  //     setErrorMessage('');
  //     setOpenPopup(false);
  //     dispatch(setIsoLoading(true));
  //     setPublisherNameError(false);
  //     setCampaignNameError(false);
  //     setVerticalNameError(false);
  //     const payload: any = {
  //       campaignClass: campaignType,
  //       advertiserStatus: RowDataPay?.status,
  //       campaignname: campaignState.campaignname,
  //       campaignType: 'publisherCampaign',
  //       publisher: RowDataPay,
  //       vertical:campaignState.vertical,
  //       capSettings: {
  //         daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
  //         dayCap: {
  //           monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
  //           tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
  //           wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
  //           thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
  //           friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
  //           saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
  //           sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
  //         },
  //         enableDayCap: campaignState.enableDayCap,
  //         monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
  //         total: 500,
  //         weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
  //       },
  //       excludedBrandIdToggle: campaignState.excludedBrandIDAction,
  //       excludeMbrandID: campaignState.excludeMbrandID,
  //       publisherID: RowDataPay?.mPubID,
  //       mPubID: RowDataPay?.mPubID,
  //       publisherName: campaignState.publisherName,
  //       source: campaignState.description,
  //       status: campaignState.status,
  //       website: "",
  //     }

  //     let requestData
  //     if (rowData) {
  //       requestData = {
  //         ID: rowData.ID,
  //         SORTID: rowData?.SORTID,
  //         advertiserStatus: rowData?.publisher?.status,
  //         createdOn: rowData?.createdOn,
  //         campaignname: campaignState.campaignname,
  //         campaignType: rowData?.campaignType,
  //         publisher: RowDataPay,
  //         vertical:campaignState.vertical,
  //         capSettings: {
  //           daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
  //           dayCap: {
  //             monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
  //             tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
  //             wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
  //             thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
  //             friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
  //             saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
  //             sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
  //           },
  //           enableDayCap: campaignState.enableDayCap,
  //           monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
  //           total: 500,
  //           weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
  //         },
  //         excludedBrandIdToggle: campaignState.excludedBrandIDAction,
  //         excludeMbrandID: ((Object.keys(campaignState.excludeMbrandID).length === 0) ? rowData?.excludeMbrandID : campaignState.excludeMbrandID), //campaignState.excludeMbrandID,
  //         publisherID: rowData?.publisherID,
  //         mPubID: RowDataPay?.mPubID,
  //         publisherName: rowData?.publisher?.SORTID,
  //         source: campaignState.description,
  //         status: campaignState.status,
  //         website: "",
  //         campaignClass: campaignType
  //       };
  //     }
  //     try {
  //       if (campaignState.editFlag) {
  //         try {
  //           await dispatch(updatePublisherCampAsync(requestData)).then(() => setSuccessMessage('Campaign Updated Successfully'));
  //           // setSuccessMessage('Campaign Updated Successfully');
  //         } catch (error) {
  //           console.log("Error in Updating the Campaign", error);
  //         }
  //       } else {
  //         try {
  //           await dispatch(createPublisherCampAsync(payload));
  //           setSuccessMessage('Campaign Created Successfully');
  //         } catch (error) {
  //           console.log("Error in Creating the Campaign", error);
  //         }
  //       }
  //       dispatch(setCampFlag(false));
  //       setOpenPopup(false);
  //       setSnackbarOpen(true);
  //       // !rowData && fetchInitialData();
  //       if (rowData && searchInput) { searchPublisherCampaigns(searchInput, dispatch, 'campaign') }
  //       else if (searchFilterData.length > 0 && rowData) {
  //         const payload = {
  //           "mPubIds": searchFilterData
  //         }

  //         searchFilterData.length > 0 && filterPublisherSearch(payload, dispatch);
  //       }
  //       fetchPublisherCampApiData(dispatch, null)
  //       fetchCompApiData(dispatch);
  //     } catch (error) {
  //       console.error('Error:', error);
  //       setErrorMessage('Failed to create publisher');
  //       setSnackbarOpen(false);
  //     }
  //     dispatch(setIsoLoading(false));
  //     dispatch(resetForm());
  //     dispatch(setExcludeMbrandId({}));

  //   }
  // };


  const handleCreateCampaign = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    if (!campaignState.publisherName.trim() || !campaignState.campaignname.trim() || !campaignState.vertical.trim()) {
      setPublisherNameError(!campaignState.publisherName.trim());
      setCampaignNameError(!campaignState.campaignname.trim());
      setVerticalNameError(!campaignState.vertical.trim())
    } else if (campaignState.campaignname.includes(',')) {
      setErrorMessage('Comma is not allowed in Publisher Camp Name');
      setSnackbarOpen(true);
      return;
    } else {
      setSuccessMessage('');
      setErrorMessage('');
      setOpenPopup(false);
      dispatch(setIsoLoading(true));
      setPublisherNameError(false);
      setCampaignNameError(false);
      setVerticalNameError(false);
      const payload: any = {
        campaignClass: campaignType,
        advertiserStatus: RowDataPay?.status,
        campaignname: campaignState.campaignname,
        campaignType: 'publisherCampaign',
        publisher: RowDataPay,
        vertical:campaignState.vertical,
        capSettings: {
          daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
          dayCap: {
            monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
            tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
            wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
            thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
            friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
            saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
            sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
          },
          enableDayCap: campaignState.enableDayCap,
          monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
          total: 500,
          weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
        },
        excludedBrandIdToggle: campaignState.excludedBrandIDAction,
        excludeMbrandID: campaignState.excludeMbrandID,
        publisherID: RowDataPay?.mPubID,
        mPubID: RowDataPay?.mPubID,
        publisherName: campaignState.publisherName,
        source: campaignState.description,
        status: campaignState.status,
        website: "",
      }

      let requestData
      if (rowData) {
        requestData = {
          ID: rowData.ID,
          SORTID: rowData?.SORTID,
          advertiserStatus: rowData?.publisher?.status,
          createdOn: rowData?.createdOn,
          campaignname: campaignState.campaignname,
          campaignType: rowData?.campaignType,
          publisher: RowDataPay,
          vertical:campaignState.vertical,
          capSettings: {
            daily: (campaignState.capSettings.daily == null) ? 0 : campaignState.capSettings.daily,
            dayCap: {
              monday: (campaignState.capSettings.monday == null) ? 0 : campaignState.capSettings.monday,
              tuesday: (campaignState.capSettings.tuesday == null) ? 0 : campaignState.capSettings.tuesday,
              wednesday: (campaignState.capSettings.wednesday == null) ? 0 : campaignState.capSettings.wednesday,
              thursday: (campaignState.capSettings.thursday == null) ? 0 : campaignState.capSettings.thursday,
              friday: (campaignState.capSettings.friday == null) ? 0 : campaignState.capSettings.friday,
              saturday: (campaignState.capSettings.saturday == null) ? 0 : campaignState.capSettings.saturday,
              sunday: (campaignState.capSettings.sunday == null) ? 0 : campaignState.capSettings.sunday
            },
            enableDayCap: campaignState.enableDayCap,
            monthly: (campaignState.capSettings.monthly == null) ? 0 : campaignState.capSettings.monthly,
            total: 500,
            weekly: (campaignState.capSettings.weekly == null) ? 0 : campaignState.capSettings.weekly,
          },
          excludedBrandIdToggle: campaignState.excludedBrandIDAction,
          excludeMbrandID: ((Object.keys(campaignState.excludeMbrandID).length === 0) ? rowData?.excludeMbrandID : campaignState.excludeMbrandID), //campaignState.excludeMbrandID,
          publisherID: rowData?.publisherID,
          mPubID: RowDataPay?.mPubID,
          publisherName: rowData?.publisher?.SORTID,
          source: campaignState.description,
          status: campaignState.status,
          website: "",
          campaignClass: campaignType
        };
      }
      try {
        if (campaignState.editFlag) {
          try {
            await dispatch(updatePublisherCampAsync(requestData)).then(() => setSuccessMessage('Campaign Updated Successfully'));
            // setSuccessMessage('Campaign Updated Successfully');
          } catch (error) {
            console.log("Error in Updating the Campaign", error);
          }
        } else {
          try {
            await dispatch(createPublisherCampAsync(payload));
            setSuccessMessage('Campaign Created Successfully');
          } catch (error) {
            console.log("Error in Creating the Campaign", error);
          }
        }
        dispatch(setCampFlag(false));
        setOpenPopup(false);
        setSnackbarOpen(true);
        // !rowData && fetchInitialData();
        if (rowData && searchInput) { searchPublisherCampaigns(searchInput, dispatch, 'campaign') }
        else if (searchFilterData.length > 0 && rowData) {
          const payload = {
            "mPubIds": searchFilterData
          }

          searchFilterData.length > 0 && filterPublisherSearch(payload, dispatch);
        }
        fetchPublisherCampApiData(dispatch, null)
        fetchCompApiData(dispatch);
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create publisher');
        setSnackbarOpen(false);
      }
      dispatch(setIsoLoading(false));
      dispatch(resetForm());
      dispatch(setExcludeMbrandId({}));

    }
  };
  const cancelButton = () => {
    setOpenPopup(false);
    dispatch(resetForm());
    // setSelectedFileName('');
    dispatch(setExcludeMbrandId({}))
    setPublisherNameError(false);
    setCampaignNameError(false);
    setVerticalNameError(false);

  };


  // console.log("campaign Type of Edit ", campaignType)


  const handleCampaignType = (event: SelectChangeEvent) => {
    setCampaignType(event.target.value);
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [state, setState] = React.useState({
    clicks: true,
   tcpa: false,
    leads: false,
    email:false,
    linkout:false
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    if (
      event.target.checked || 
      (state.clicks || state.tcpa || state.leads || state.email || state.linkout) // check if any checkbox was previously selected
    ) {
      setCampaignTypeError(false);
    }

    console.log("Checked::::", event.target.checked)
  };

  return (
    <div>
      <Dialog open={openPopup} fullWidth maxWidth="lg">
        <DialogTitle>
          {campaignState.editFlag ? "Update" : "Create"} Campaign
        </DialogTitle>
        <div className="close-button" onClick={cancelButton}>
          <CloseIcon style={{ fontSize: "32px" }} />
        </div>
        <DialogContent
          sx={{
            overflowY: { xs: "auto", md: "hidden" }, // Enable scroll only on small screens
            maxHeight: { xs: "80vh", md: "none" }, // Limit height on small screens, no limit on larger screens
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth style={{ marginBottom: "16px" }}>
              <InputLabel>
                Select Vertical*
                </InputLabel>
                <Select
                disabled={campaignState.editFlag}
                label='Select Vertical*'
                  fullWidth
                  value={campaignState.vertical} // Assuming this is managed in your state
                  onChange={(e: { target: { value: any } }) => {
                   setVerticalNameError(false);
                    handleFieldChange("vertical", e.target.value); // Replace with the appropriate handler
                  }}
                  error={verticalNameError}
                >
                  <MenuItem value="AUTO">AUTO</MenuItem>
                  <MenuItem value="EDU">EDU</MenuItem>
                </Select>
                {verticalNameError && <div style={{ color: '#d32f2f', fontSize: '13px', paddingLeft: '12px' }}>Please select Vertical</div>}
              </FormControl>
              <FormControl fullWidth>
              <InputLabel>
                Select Publisher*
                </InputLabel>
                <Select
                label='Select Publisher*'
                  fullWidth
                  value={campaignState.publisherName}
                  onChange={(e: { target: { value: any } }) => {
                    setPublisherNameError(false);
                    handleFieldChange("publisherName", e.target.value);
                  }}
                  error={publisherNameError}
                  // className={
                  //   campaignState.publisherName == "" ? "custom-selectcam" : ""
                  // }
                >
                  {fullDataPubli
                    .filter((pub: any) => pub.publisherName) // Filter out elements with undefined or null publisherName
                    .sort((a: any, b: any) =>
                      a.publisherName.localeCompare(b.publisherName)
                    )
                    .map((pub: any, index: any) => (
                      <MenuItem key={pub.mPubID} value={pub.SORTID}>
                        {`${pub.publisherName} - ${pub.mPubID}`}
                      </MenuItem>
                    ))}
                </Select>
                {publisherNameError && (
                  <div
                    style={{
                      color: "#d32f2f",
                      fontSize: "13px",
                      paddingLeft: "12px",
                    }}
                  >
                    Please select Publisher
                  </div>
                )}
              </FormControl>

              <TextField
                label="Name*"
                fullWidth
                value={campaignState.campaignname}
                onChange={(e: { target: { value: any } }) => {
                  setCampaignNameError(false);
                  handleFieldChange("campaignname", e.target.value);
                }}
                error={campaignNameError}
                // helperText={
                //   campaignNameError ? "Campaign Name is mandatory" : ""
                // }
                margin="normal"
              />
{campaignNameError && (
                  <div
                    style={{
                      color: "#d32f2f",
                      fontSize: "13px",
                      paddingLeft: "12px",
                    }}
                  > Campaign Name is mandatory</div>
                )}
          

              <FormControlLabel
                sx={{ marginTop: "8px" }}
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                control={
                  <Switch
                    checked={campaignState.status === "Active"}
                    onChange={() => dispatch(statusHandler())}
                  />
                }


              />
        <FormControl fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">
                  Campaign Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={campaignType}
                  label="Campaign Type"
                  onChange={handleCampaignType}
                >
                  {
                    // Filter out elements with undefined or null publisherName
                    CAMPAIGN_TYPE_OPTIONS.map((pub: any, index: any) => (
                      <MenuItem key={pub.value} value={pub.value}>
                        {pub.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

  {/* <FormControl  variant="standard" sx={{backgroundColor:'#EAECF0', gap:'10px'}}>
        <FormLabel component="legend">Campaign Type</FormLabel>
        <FormGroup sx={{flexDirection:'row', gap:'15px', padding:'4px'}}>
          <FormControlLabel
            control={
              <Checkbox checked={state.tcpa} onChange={handleChange} name="tcpa"    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>
            }
            label="TCPA"
          />
          <FormControlLabel
            control={
              <Checkbox checked={state.clicks} onChange={handleChange} name="clicks"    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>
            }
            label="Clicks"
          />
          <FormControlLabel
            control={
              <Checkbox checked={state.leads} onChange={handleChange} name="leads"   sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
            }
            label="Leads"
          />
            <FormControlLabel
            control={
              <Checkbox checked={state.email} onChange={handleChange} name="email" />
            }
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            label="Email"
          />
            <FormControlLabel
            control={
              <Checkbox checked={state.linkout} onChange={handleChange} name="linkout" />
            }
            label="Linkout"
          />
        </FormGroup>
        {campaignTypeError &&
          <FormHelperText error={true}> 'Please select at least one campaign type.</FormHelperText> }
       
      </FormControl> */}
              
            </Grid>

            <Grid item xs={12} md={6}>
              <MbrandExclusion {...props} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: "15px", paddingRight: "20px" }}>
          <Button onClick={cancelButton} style={{ color: "red" }}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCampaign}
          >
            {campaignState.editFlag ? "UPDATE" : "CREATE"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? "success" : "error"}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
      <Dialog open={open} onClose={handleCancel} maxWidth="xs">
        <DialogTitle
          style={{ backgroundColor: "rgb(198, 40, 40)", color: "white" }}
        >
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginTop: "10px", color: "black" }}>
            The following mbrand IDs already exist: {matchingIds.join(", ")}. Do
            you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
