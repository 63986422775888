import React from 'react'
import { AudienceTargetJSONData ,AudienceTargetTabData} from '../../../../Mock/AudienceTargetTabData';
import AudienceTargetJson from '../../../../Mock/AudieneTargeting.json'
import MMTabs from '../../../../Component/Tabs/MMTabs';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks'
import { campaignFormSelector } from '../../../../Store/Slices/CreateAdvCampaignSlice';
const AudienceTargeting = () => {
  const advCampaignFormState=useAppSelector(campaignFormSelector);


  //console.log("advCampaignFormState.excludeAdjustment", advCampaignFormState.value.excludeAdjustment)
  function generateAudienceTargetLists(key: string="1") {
    const jsonData:AudienceTargetJSONData={
      ...advCampaignFormState.value.excludeAdjustment
    }
    if (jsonData.hasOwnProperty(key)) {
      return jsonData[key];
    } 
    
  }
  return (
    <MMTabs  tabContent={AudienceTargetTabData} generateTabPanelContentHandler={generateAudienceTargetLists}/>
  )
}

export default AudienceTargeting