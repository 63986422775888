import { baseURLConfig } from "../config/envConfig";
import axios from 'axios';

export    const postEmail=async (queryParams:any)=>{
    const baseURL=baseURLConfig.emailAPIBaseURL
    const queryString = new URLSearchParams(queryParams).toString();

// Build the complete URL with query params
const urlWithParams = `${baseURL}?${queryString}`;

let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: urlWithParams,
    headers: {}
  };
    
    
  try {
    const response = await axios.request(config);
    console.log('Success:', JSON.stringify(response.data));
    // Handle successful response here (e.g., processing data or further actions)
    return {response:response.data, urlWithParams};
  } catch (error:any) {
    console.log("error", error)
    throw new Error(error.message);
    }
}