import React from 'react'


const SignOutSVGIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7957 1 20V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H12C12.7956 1 13.5587 1.31607 14.1213 1.87868C14.6839 2.44129 15 3.20435 15 4V6C15 6.26522 14.8946 6.51957 14.7071 6.70711C14.5196 6.89464 14.2652 7 14 7C13.7348 7 13.4804 6.89464 13.2929 6.70711C13.1054 6.51957 13 6.26522 13 6V4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H12C12.2652 21 12.5196 20.8946 12.7071 20.7071C12.8946 20.5196 13 20.2652 13 20V18C13 17.7348 13.1054 17.4804 13.2929 17.2929C13.4804 17.1054 13.7348 17 14 17C14.2652 17 14.5196 17.1054 14.7071 17.2929C14.8946 17.4804 15 17.7348 15 18V20C15 20.7957 14.6839 21.5587 14.1213 22.1213C13.5587 22.6839 12.7956 23 12 23ZM18 17C17.8684 17.0008 17.7379 16.9755 17.6161 16.9258C17.4943 16.876 17.3834 16.8027 17.29 16.71C17.1963 16.617 17.1219 16.5064 17.0711 16.3846C17.0203 16.2627 16.9942 16.132 16.9942 16C16.9942 15.868 17.0203 15.7373 17.0711 15.6154C17.1219 15.4936 17.1963 15.383 17.29 15.29L19.59 13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H19.59L17.29 8.71C17.1017 8.5217 16.9959 8.2663 16.9959 8C16.9959 7.7337 17.1017 7.47831 17.29 7.29C17.4783 7.1017 17.7337 6.99591 18 6.99591C18.2663 6.99591 18.5217 7.1017 18.71 7.29L22.71 11.29C22.8037 11.383 22.8781 11.4936 22.9289 11.6154C22.9797 11.7373 23.0058 11.868 23.0058 12C23.0058 12.132 22.9797 12.2627 22.9289 12.3846C22.8781 12.5064 22.8037 12.617 22.71 12.71L18.71 16.71C18.6166 16.8027 18.5057 16.876 18.3839 16.9258C18.2621 16.9755 18.1316 17.0008 18 17Z" fill="#737791"/>
</svg>

  )
}

export default SignOutSVGIcon