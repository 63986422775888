import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckedSvgIcon from '../../../../assets/CheckedSvgIcon';
import { statesTargeting, StateData } from '../../../../Mock/GeoTargetingData';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { anyStateToggle, campaignFormSelector, updateGeoTargeting, updateStateRules } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import { current } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
interface StatesCheckboxButtonsProps {
  statesData: StateData[];
  selectedStates: string[];
  onCheckboxChange: (stateId: string) => void;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

const GeoTargeting = () => {

  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  //useState<string[]>(statesTargeting.map((state) => state.key)); 
  // const [selectallFlag, setSelectAllFlag] = useState<boolean>(true);
  // 
  const location = useLocation();
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit} = tcpaCampSlice

  const dispatch = useAppDispatch()
  const handleCheckboxChange = (stateId: string) => {
    setSelectedStates((prevSelected) => {
      if (prevSelected.includes(stateId)) {
        return prevSelected.filter((id) => id !== stateId);
      } else {
        return [...prevSelected, stateId];
      }
    });
  };
  const campaignFormData = useAppSelector(campaignFormSelector)




  useEffect(() => {
    // if (campaignFormData.isEdit) {
    //   const stateKeys = Object.keys(campaignFormData.value.excludeStates)
    //   // console.log("state Keys", stateKeys);

    //   stateKeys.forEach((key, index) => {
    //     if (campaignFormData.value.excludeStates[key].include) {
    //       setSelectedStates((prev) => {
    //         return [...prev,key]
    //       })
    //     }
    //   })
    // }else{

    //   // console.log
    //   setSelectedStates(statesTargeting.map((state) => state.key))
    // }


    const stateKeys = Object.keys(campaignFormData.value.excludeStates)
      // console.log("state Keys", stateKeys);
      stateKeys.forEach((key, index) => {
        if (campaignFormData.value.excludeStates[key].include) {
          setSelectedStates((prev) => {
            return [...prev,key]
          })
        }
      })
  }, [])

  // console.log("Selected ")
  let geoTargetingForPayload: any = {

  }

  statesTargeting.forEach((item) => {
    geoTargetingForPayload[item.key] = {
      include: false
    }
  });
  const formattedGeoTargetPayload: any = {};
  for (const stateCode in geoTargetingForPayload) {
    if (geoTargetingForPayload.hasOwnProperty(stateCode)) {
      const uppercaseStateCode = stateCode.toUpperCase();
      formattedGeoTargetPayload[uppercaseStateCode] = geoTargetingForPayload[stateCode];
    }
  }

  geoTargetingForPayload = { ...formattedGeoTargetPayload }
  useEffect(() => {
    if (selectedStates.length > 0) {
      selectedStates.forEach((stateCode) => {
        if (geoTargetingForPayload.hasOwnProperty(stateCode.toLocaleUpperCase())) {
          geoTargetingForPayload[stateCode.toLocaleUpperCase()].include = true

        }
      })
    }

    dispatch(updateGeoTargeting(geoTargetingForPayload))

    const stateRules = {
      allState: selectedStates.length < 0 ? false : true
    }
    dispatch(updateStateRules(stateRules))

  }, [selectedStates])


  const handleSelectAll = () => {
    const allStateIds = statesTargeting.map((state) => state.key);
    setSelectedStates(allStateIds);
  };

  const handleDeselectAll = () => {
    setSelectedStates([]);
  };

  const handelSelectAllCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // handleDeselectAll()
      const allStateIds = statesTargeting.map((state) => state.key);
      setSelectedStates(allStateIds);
    } else {
      // handleDeselectAll()
      setSelectedStates([]);
    }
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>

        <Box className="select-action">

          {/* <FormControlLabel control={<Checkbox onChange={handelSelectAllCheckBox} />} label="Select All" /> */}
          <FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} name='status'  onChange={handelSelectAllCheckBox}   checked={selectedStates.length===51?true:false}/>}
          label="Select All"
          // checked={selectedStates.length===50?true:false}
        />
      </FormGroup>

        </Box>

        <Box className="select-action">

<FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
<FormControlLabel
control={<IOSSwitch sx={{ m: 1 }} name='anyState' checked={campaignFormData.value.anyState} onChange={() =>dispatch(anyStateToggle())} />}
label="Any State"
/>
</FormGroup>
</Box>
      </Box>
      <Grid container spacing={0}>


        <CheckboxButton
          statesData={statesTargeting}
          selectedStates={selectedStates}
          onCheckboxChange={handleCheckboxChange}
          onSelectAll={handleSelectAll}
          onDeselectAll={handleDeselectAll}
        />


      </Grid>
    </>

  )
}

export default GeoTargeting



const CheckboxButton: React.FC<StatesCheckboxButtonsProps> = (
  {
    statesData, selectedStates, onCheckboxChange, onDeselectAll, onSelectAll
  }
) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const isStateSelected = (stateId: string) => selectedStates.includes(stateId);


  const renderStateButtons = () => {
    return statesData.map((state) => {
      return (
        <Grid item xs={12} sm={6} md={3} paddingLeft={1}>
          <Button
            onClick={() => onCheckboxChange(state.key)}
            variant='outlined'
            style={{
              borderColor: isStateSelected(state.key) ? '#34C759' : '#C9CBD5',
              color: isStateSelected(state.key) ? '#34C759' : '#697386',
              width: "100%",
              margin: "6px"

            }}
          //   sx={{
          //     // Adjust padding for the value inside OutlinedInput
          //     "& input": {
          //       padding:( "12px 14px 12px 0px" )
          //     },
          // }}
            endIcon={isStateSelected(state.key) && <CheckedSvgIcon />}
          >
            {state.name}
          </Button>
        </Grid>
      )
    })
  }
  return (

    <>
      {
        renderStateButtons()
      }
    </>
  );
}

// export default CheckboxButton;
