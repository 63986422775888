// publisherSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PublisherState {
  enablePublisherEmail: boolean;
  publisherName: string;
  publisherEmail: string;
  companyDescription: string;
  address: string;
  primaryContact: string;
  city: string;
  phNo: string;
  state: string;
  website: string;
  status: string;
  type:string;
  editFlag:boolean;
  vertical:string[];
  // excludeBrandID:any,
}

const initialState: PublisherState = {
  enablePublisherEmail: false,
  publisherName: '',
  publisherEmail: '',
  companyDescription: '',
  address: '',
  primaryContact: '',
  city: '',
  phNo: '',
  state: '',
  website: '',
  status: "Inactive",
  type: "Publisher",
  editFlag: true,
  vertical: []
};


const publisherSlice = createSlice({
  name: 'publisher',
  initialState,

  reducers: {
   
    toggleReportingEmail: (state) => {
      state.enablePublisherEmail = !state.enablePublisherEmail;
      if(state.enablePublisherEmail===false){
        state.publisherEmail='';
      }
    },
        toggleStatus: (state) => {
          state.status = state.status === 'Active' ? 'Inactive' : 'Active';

    },
    resetForm: (state) => {
        Object.assign(state, initialState);
      },
    setField: (state, action: PayloadAction<{ field: keyof PublisherState; value: string }>) => {
        const { field, value } = action.payload;
        (state as any)[field] = value;
      },
      setEditFlag: (state, action: PayloadAction<boolean>) => {
        state.editFlag = action.payload;
      },
      toggleVertical: (state, action: PayloadAction<string>) => {
        const value = action.payload;
        const verticalSet = new Set(state.vertical);
        if (verticalSet.has(value)) {
          verticalSet.delete(value);
        } else {
          verticalSet.add(value);
        }
        state.vertical = Array.from(verticalSet);
      },
  },
});



export const { toggleVertical,toggleReportingEmail, setField ,toggleStatus, resetForm,setEditFlag} = publisherSlice.actions;

export default publisherSlice.reducer;
