import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Info } from './info';
import Ability from './Ability';
import QuestionsPage from './questionsPage';
import TestPage from './testPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateAdvMBrandPortalData } from '../../../../Services/seoApis';

const steps = ['Set Up', 'Program', 'Form Questions'];

const SmallHorizontalActiveBar = styled('div')(({ theme }) => ({
  backgroundColor: '#34C759', // Change to your preferred color
  borderRadius: '100px',
  width: '100%',
  height: 4,
}));

const SmallHorizontalBar = styled('div')(({ theme }) => ({
  backgroundColor: '#C1C9D2;', // Change to your preferred color
  borderRadius: '100px',
  width: '100%',
  height: 4,
}));

const AddEditDetails = () => {
  const submitRef = useRef(() => {});
  const location = useLocation();
  const { advertiserPageData } = location.state;
  const [currentStepperIndex, setCurrentStepperIndex] = useState(0);
  const [formData, setFormData] = useState<any>(advertiserPageData);
  const [isLoading, setIsoLoading] = useState(false);
  const navigate = useNavigate();
  const handleFormSubmit = async (value: any) => {
    setIsoLoading(true);
    console.log('handleFormSubmit', value);
    await updateAdvMBrandPortalData(value);
    setFormData(value);
    handleNext();
    setIsoLoading(false);
  };

  const getCurrentStepperChild = () => {
    switch (currentStepperIndex) {
      case 0:
        return (
          <Info
            submitRef={submitRef}
            formData={formData}
            handleFormSubmit={handleFormSubmit}
          />
        );
        break;
      case 1:
        return (
          <Ability
            submitRef={submitRef}
            formData={formData}
            handleFormSubmit={handleFormSubmit}
          />
        );
        break;
      case 2:
        return (
          <QuestionsPage
            submitRef={submitRef}
            formData={formData}
            handleFormSubmit={handleFormSubmit}
          />
        );
        break;
      // case 3:
      //   return <TestPage submitRef={submitRef} handleNext={handleNext} handleFormSubmit={handleFormSubmit}/>;
      //   break;
      default:
        return null;
    }
  };

  const handleNext = () => {
    currentStepperIndex < 2
      ? setCurrentStepperIndex(currentStepperIndex + 1)
      : navigate('/advertiseHandler');
  };
  const handleBack = () => {
    if (currentStepperIndex > 0) {
      setCurrentStepperIndex(currentStepperIndex - 1);
    }
  };

  useEffect(() => {
    console.log('advertiserPageData', advertiserPageData);
  }, []);
  return (
    <>
      <Box className="card">
        <Box display="flex" mb={2.5}>
          <Box>
            <Typography className="card-heading">
              Edit Advertiser Page details
            </Typography>
            <Typography className="card-sub-heading">
              Fill the details below to edit Advertiser
            </Typography>
          </Box>
        </Box>
        <div className="stepper-container">
          <Stepper
            sx={{ overflow: 'auto', paddingBottom: '16px' }}
            activeStep={currentStepperIndex}
            alternativeLabel
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                className="root-step-test"
                onClick={index => {
                  setCurrentStepperIndex(steps.indexOf(label));
                }}
              >
                <StepLabel
                  StepIconComponent={({ active }) =>
                    active ? (
                      <SmallHorizontalActiveBar />
                    ) : (
                      <SmallHorizontalBar />
                    )
                  }
                  className="step-label-test"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ marginTop: '10px' }}>{getCurrentStepperChild()}</Box>
          <Box
            className="mProgram-btn-container"
            sx={{
              textAlign: 'right',
              paddingRight: '30px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Button
              onClick={handleBack}
              sx={{
                backgroundColor: '#FFF',
                border: '1px solid #0AE',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
                color: '#00AAEE',
                width: '100px',
                height: '45px',
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              size="large"
              onClick={() => {
                submitRef.current();
              }}
              sx={{
                backgroundColor: '#1976d2',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#1976d2 !important',
                },

                '&:hover': { backgroundColor: '#1976d2' },
                border: '1px solid #1976d2',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
                // color: '#ffff',
                // width:'110px',
                height: '45px',
                marginLeft: '10px',
              }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : currentStepperIndex === 2 ? (
                'Finish'
              ) : (
                'Save & Next'
              )}
            </Button>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default AddEditDetails;
