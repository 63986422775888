import { Typography, Box, Paper, Table, TableHead, TableCell, TableRow, TableBody, FormGroup, FormControlLabel } from "@mui/material"
import { useState, useEffect } from "react";
import { IOSSwitch } from "../../../Component/Table/MMTable"
import { extractAdvertiserName } from "../../../Mock/MediaSourceData";
import { useAppDispatch } from "../../../Store/hooks";
import { updateExcludeAdvertiser } from "../../../Store/Slices/PublisherCampaignSlice";

export const ConfigureMsTabContent = (props: any) => {
    // console.log("props.data", props.data)
    const dispatch = useAppDispatch();
    const [rowFormData, setRowFormData] = useState<any[]>(props.data.length > 0 ? props.data : []);
    // here we need to make API Call for advertiser campaigns

    console.log("Rows data", rowFormData)
    const [isSelectAll, setSelectAll] = useState(false);
    const handleStatus = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        //console.log("id status;;;;media source" , id)
        const { name, checked } = event.target;
        setRowFormData(prevData => prevData.map(item => (item.SORTID === id ? { ...item, [name]: checked } : item)));

    }

    const handelSelectAllCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (event.target.checked) {
            setRowFormData(prevData => prevData.map(item => ({ ...item, enable: checked })));
        } else {

            setRowFormData(prevData => prevData.map(item => ({ ...item, enable: checked })));
        }
    }

    const checkSelectAllStatus = () => {
        // const { yourData } = this.state; // Replace with the actual name of your data array
        const allSelected = rowFormData.every(item => item.enable === true);
        const allDeselected = rowFormData.every(item => item.enable === false);
        //console.log("all selected", allSelected)
        if (allSelected) {
            setSelectAll(true)
        } else {
            setSelectAll(false);
        }
        //  else if (allDeselected) {
        //   setSelectAll(false);
        // }

        return allSelected;
    };

    useEffect(() => {
        if (rowFormData.length >= 1) {
            const allSelected = checkSelectAllStatus()
            dispatch(updateExcludeAdvertiser({
                data: rowFormData,
                allSelected: allSelected
            }))

            setSelectAll(allSelected)

        }

    }, [rowFormData])



 
    return (
        <Paper>
            {/* <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
                <Box >
                    <Typography className="card-heading" sx={{ border: "10px" }}>Advertiser Campaigns</Typography>
    
                </Box>

            </Box> */}
            <Box className='select-all-container' px={3} >
                <Box className="header-message">
                    <h3 style={{ fontWeight: '600' }}> {props.data.length > 0 ?extractAdvertiserName(props.data[0]?.advName) : ""}</h3>
                </Box>
                <FormGroup className='select-all-toggle-container'>
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} name='status' checked={isSelectAll} onChange={handelSelectAllCheckBox} />}
                        label="Select All"
                        className='select-all-toggle-container-label'
                        sx={{ flexDirection: "row-reverse" }}
                    />
                </FormGroup>

            </Box>
            <Box className='no-more-tables responsive-tbl'>

                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Campaign Name</TableCell>
                            <TableCell >Status</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rowFormData.map((item: any, index: number) => {
                                return (
                                    <TableRow key={index} >
                                        <TableCell data-title='Pub Campaign Name'>{item.title}</TableCell>

                                        <TableCell data-title='Status'>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<IOSSwitch sx={{ m: 1 }} checked={item.enable} name='enable' onChange={
                                                        (e) => {
                                                            handleStatus(e, item.SORTID)
                                                        }
                                                    } />}
                                                    label=""
                                                />
                                            </FormGroup>
                                        </TableCell>



                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            </Box>
        </Paper>
    )

}



const ConfigureMsTabContentWrapper = (props: any) => {
  
    return (
        <>
     
            {
             props.data?.length >= 1 ? <ConfigureMsTabContent data={props.data} /> :
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', minHeight:'75vh'}} className="message-box">
                    <Typography style={{ 'textAlign': 'center' }}>No campaign exists.</Typography>
                </Box>
            }
        </>
    )
}


export default ConfigureMsTabContentWrapper

