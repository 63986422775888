
import BidPrice from "../View/Advertiser/Stepper Form/Step components/BidPrice";
import GeoTargeting from "../View/Advertiser/Stepper Form/Step components/GeoTargeting";
import ZipRules from "../View/Advertiser/Stepper Form/Step components/ZipRules";
import TcpaGeoTargeting from "../View/TCPAcampaign/addTcpaEditDetails/tcpaGeoTarg/TcpaGeoTargeting";
import TcpaZipRules from "../View/TCPAcampaign/addTcpaEditDetails/tcpaGeoTarg/TcpaZipRules";
import SchedulingTable from "./Poc/CellSelectionTable";

export interface StateData {
    id: number;
    name: string;
    key: string
}

export const statesTargeting: StateData[] = [
    {
        id: 1,
        name: "Alabama (AL)",
        key: "AL"
    },
    {
        id: 2,
        name: "Alaska (AK)",
        key: 'AK'
    },
    {
        id: 3,
        name: "Arizona (AZ)",
        key: "AZ"
    },
    {
        id: 4,
        name: "Arkansas (Ar)",
        key: "AR"
    },
    {
        key: "CA",
        id: 5,
        name: "California (CA)"
    },
    {
        key: "CO",
        id: 7,
        name: "Colorado (CO)"
    },
    {
        key: "CT",
        id: 9,
        name: "Connecticut (CT)"
    },
    {
        key: "DE",
        id: 10,
        name: "Delaware (DE)"
    },
    {
        key: "DC",
        id: 11,
        name: "District of Columbia (DC)"
    },
    {
        key: "FL",
        id: 12,
        name: "Florida (FL)"
    },
    {
        key: "GA",
        id: 13,
        name: "Georgia (GA)"
    },
    {
        key: "HI",
        id: 14,
        name: "Hawaii (HI)"
    },
    {
        key: "ID",
        id: 15,
        name: "Idaho (ID)"
    },
    {
        key: "IL",
        id: 16,
        name: "Illinois (IL)"
    }, {
        key: "IA",
        id: 17,
        name: "Iowa (IA)"
    },
    {
        key: "KS",
        id: 18,
        name: "Kansas (KS)"
    },
    {
        key: "KY",
        id: 19,
        name: "Kentucky (KY)"
    },
    {
        key: "LA",
        id: 20,
        name: "Louisiana (LA)"
    },
    {
        key: "ME",
        id: 21,
        name: "Maine (ME)"
    },
    {
        key: "MD",
        id: 22,
        name: "Maryland (MD)"
    },
    {
        key: "IN",
        id: 53,
        name: "Indiana (IN)"
    },
    {
        key: "MA",
        id: 23,
        name: "Massachusetts (MA)"
    },
    {
        key: "MI",
        id: 24,
        name: "Michigan (MI)"
    },
    {
        key: "MN",
        id: 25,
        name: "Minnesota (MN)"
    }, {

        key: "MS",
        id: 26,
        name: "Mississippi (MS)"
    }, {
        key: "MO",
        id: 27,
        name: "Missouri (MO)"
    }, {
        key: "MT",
        id: 28,
        name: "Montana (MT)"
    }, {
        key: "NE",
        id: 29,
        name: "Nebraska (NE)"
    }, {
        key: "NV",
        id: 30,
        name: "Nevada (NV)"
    }, {
        key: "NH",
        id: 31,
        name: "New Hampshire (NH)"
    },

    {
        key: "NJ",
        id: 32,
        name: "New Jersey (NJ)"
    },
    {
        key: "NM",
        id: 33,
        name: "New Mexico (NM)"
    },
    {
        key: "NY",
        id: 34,
        name: "New York (NY)"
    },
    {
        key: "NC",
        id: 35,
        name: "North Carolina (NC)"
    },
    {
        key: "ND",
        id: 36,
        name: "North Dakota (ND)"
    },
    {
        key: "OH",
        id: 37,
        name: "Ohio (OH)"
    },
    {
        key: "OK",
        id: 38,
        name: "Oklahoma (OK)"
    },
    {
        key: "OR",
        id: 39,
        name: "Oregon (OR)"
    },
    {
        key: "PA",
        id: 40,
        name: "Pennsylvania (PA)"
    },
    {
        key: "RI",
        id: 41,
        name: "Rhode Island (RI)"
    },
    {
        key: "SC",
        id: 42,
        name: "South Carolina (SC)"
    },
    {
        key: "SD",
        id: 43,
        name: "South Dakota (SD)"
    },
    {
        key: "TN",
        id: 44,
        name: "Tennessee (TN)"
    },
    {
        key: "TX",
        id: 45,
        name: "Texas (TX)"
    },
    {
        key: "UT",
        id: 46,
        name: "Utah (UT)"
    },
    {
        key: "VT",
        id: 47,
        name: "Vermont (VT)"
    },

    {
        key: "VA",
        id: 48,
        name: "Virginia (VA)"
    },
    {
        key: "WA",
        id: 49,
        name: "Washington (WA)"
    },
    {
        key: "WV",
        id: 50,
        name: "West Virginia (WV)"
    }, {
        key: "WI",
        id: 51,
        name: "Wisconsin (WI)"
    },
    {
        key: "WY",
        id: 52,
        name: "Wyoming (WY)"
    },

]

export const stateRulesData=[
    {
        "label":"State Rules",
        content:GeoTargeting,
        "value":"1"

    },
    {
        "label":"Zip Rules",
        content:ZipRules,
        "value":"2"

    },
]


export const tcpaStateRulesData=[
    {
        "label":"State Rules",
        content: TcpaGeoTargeting,
        "value":"1"

    },
    {
        "label":"Zip Rules",
        content:TcpaZipRules,
        "value":"2"

    },
]

export const schedulingComponentData=[
    {
        "label":"Scheduling",
        content:SchedulingTable,
        "value":"1"

    },
    {
        "label":"Bid Price",
        content:BidPrice,
        "value":"2"

    },
]

// export const zipStates = {
//     "00601-00999": "PR",
//     "01000-02899": "MA",
//     "02900-02999": "RI",
//     "03000-03899": "NH",
//     "03900-04999": "ME",
//     "05000-05999": "VT",
//     "06000-06999": "CT",
//     "07000-08999": "NJ",
//     "10000-14999": "NY",
//     "15000-19699": "PA",
//     "19700-19999": "DE",
//     "20000-20599": "DC",
//     "20600-24699": "VA",
//     "24700-26899": "WV",
//     "26900-29999": "NC",
//     "30000-31999": "GA",
//     "32000-34999": "FL",
//     "35000-36999": "AL",
//     "37000-38599": "TN",
//     "38600-39799": "MS",
//     "39800-39999": "GA",
//     "40000-42999": "KY",
//     "43000-45999": "OH",
//     "46000-47999": "IN",
//     "48000-49999": "MI",
//     "50000-52999": "IA",
//     "53000-54999": "WI",
//     "55000-56999": "MN",
//     "57000-57999": "SD",
//     "58000-58999": "ND",
//     "59000-59999": "MT",
//     "60000-62999": "IL",
//     "63000-65999": "MO",
//     "66000-67999": "KS",
//     "68000-71599": "NE",
//     "71600-72999": "AR",
//     "73000-74999": "OK",
//     "75000-79999": "TX",
//     "80000-81999": "CO",
//     "82000-83199": "WY",
//     "83200-83999": "ID",
//     "84000-84999": "UT",
//     "85000-86999": "AZ",
//     "87000-88499": "NM",
//     "88500-89999": "NV",
//     "90000-96699": "CA",
//     "96700-96899": "HI",
//     "97000-97999": "OR",
//     "98000-99499": "WA",
//     "99500-99999": "AK"
// };

interface ZipRange {
    range: string;
    state: string;
  }

  const zipStates: ZipRange[] = [
    { range: "00601-00999", state: "PR" },
    { range: "01000-02899", state: "MA" },
    { range: "02900-02999", state: "RI" },
    { range: "03000-03899", state: "NH" },
    { range: "03900-04999", state: "ME" },
    { range: "05000-05999", state: "VT" },
    { range: "06000-06999", state: "CT" },
    { range: "07000-08999", state: "NJ" },
    { range: "10000-14999", state: "NY" },
    { range: "15000-19699", state: "PA" },
    { range: "19700-19999", state: "DE" },
    { range: "20000-20599", state: "DC" },
    { range: "20600-24699", state: "VA" },
    { range: "24700-26899", state: "WV" },
    { range: "26900-29999", state: "NC" },
    { range: "30000-31999", state: "GA" },
    { range: "32000-34999", state: "FL" },
    { range: "35000-36999", state: "AL" },
    { range: "37000-38599", state: "TN" },
    { range: "38600-39799", state: "MS" },
    { range: "39800-39999", state: "GA" },
    { range: "40000-42999", state: "KY" },
    { range: "43000-45999", state: "OH" },
    { range: "46000-47999", state: "IN" },
    { range: "48000-49999", state: "MI" },
    { range: "50000-52999", state: "IA" },
    { range: "53000-54999", state: "WI" },
    { range: "55000-56999", state: "MN" },
    { range: "57000-57999", state: "SD" },
    { range: "58000-58999", state: "ND" },
    { range: "59000-59999", state: "MT" },
    { range: "60000-62999", state: "IL" },
    { range: "63000-65999", state: "MO" },
    { range: "66000-67999", state: "KS" },
    { range: "68000-71599", state: "NE" },
    { range: "71600-72999", state: "AR" },
    { range: "73000-74999", state: "OK" },
    { range: "75000-79999", state: "TX" },
    { range: "80000-81999", state: "CO" },
    { range: "82000-83199", state: "WY" },
    { range: "83200-83999", state: "ID" },
    { range: "84000-84999", state: "UT" },
    { range: "85000-86999", state: "AZ" },
    { range: "87000-88499", state: "NM" },
    { range: "88500-89999", state: "NV" },
    { range: "90000-96699", state: "CA" },
    { range: "96700-96899", state: "HI" },
    { range: "97000-97999", state: "OR" },
    { range: "98000-99499", state: "WA" },
    { range: "99500-99999", state: "AK" }
  ];

export function getStateByZip(zipCode: string): string {
    const zip = parseInt(zipCode, 10);
    
    for (const entry of zipStates) {
      const [start, end] = entry.range.split('-').map(Number);
      if (zip >= start && zip <= end) {
        return entry.state;
      }
    }
    return "";
  }