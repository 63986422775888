import { ThunkDispatch } from 'redux-thunk'; // Import the ThunkDispatch type
import {  AnyAction } from '@reduxjs/toolkit';
import {setLoading, setCurrentCursor, setHasMoreData, appendData,setFullData,setApiFullData, storeLimitCampaignsData, appendLimitCampaignsData, setNoDataFlag, setIsoLoading, setFilterSearchAdvData, } from '../Store/Slices/advertiserTabApisData';
import axios from 'axios';
import{setCampLoading,setCurrentCampCursor,setHasCampMoreData,setBlockScroll,appendCampData,setFullCampData,setApiFullCampData,setFetchFlagAdvCamp } from '../Store/Slices/advertiserTabApisData';
import{ baseURLConfig }from '../config/envConfig';
import { setfilterSearchFlag } from '../Store/Slices/PublisherTabApisData';
import { fetchToken, generateHeader } from '../service/Auth';
import { getCampaignStatus } from '../Utils/utils';
import { getDisplayName } from '../View/User Accounts/Vendors/Vendors';

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;

const baseURL=baseURLConfig.baseURl;
const advertiAccApiUrl = `${baseURL}/advertisers?ID=advertiser&limit=25`;
const API_BASE_URL = `${baseURL}/advertisers`;
const advertiserCampApiUrl = `${baseURL}/advertisers/campaign?ID=campaign&limit=25`;



export const fetchAdvertAccApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null,
  location : any
) => {
  const baseURL=baseURLConfig.baseURl;
  try {
    dispatch(appendData([]));
    const headers=await generateHeader();
    dispatch(setLoading(true));
    let url = advertiAccApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url,{headers});
    const jsonData = await response.json();
    const cursorEncode = encodeURIComponent(jsonData.result.meta.cursor);
    const filteredData = jsonData.result.data.map((item:any) => {

      const mappedItem: any = {
        Status: item.status,
        Adv_ID: item.advertiserID,
        Advertiser_Name: item.advertiserName,
        Brand_Name: item.brand,
        vendorName: getDisplayName(item.vendorName),
      }

      if (location.pathname === '/budget-allocation' || location.pathname === '/caps-filtering') {
        mappedItem.Created_on = item?.createdOn;
      }else{
        mappedItem.vertical = item?.vertical;
      }
        // Created_on: item.createdOn,
        return mappedItem;
    });

    // const filteredData = jsonData.result.data.map((item: any) => {
    //   // Create the mapped object
    //   const mappedItem: any = {
    //     publisherID: item?.mPubID,
    //     Publisher_Name: item?.publisherName,
    //     Created_on: item?.createdOn,
    //   };
    
    //   // Conditionally add the website key
    //   if (location.pathname !== '/cpc-threshold') {
    //     mappedItem.website = item?.website;
    //   }
    
    //   return mappedItem;
    // });
    //  dispatch(setFullData(jsonData.result.data))
    // dispatch(appendFullData(jsonData.result.data))
    
    dispatch(appendData(filteredData));
    dispatch(setCurrentCursor(cursorEncode));
    dispatch(setHasMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAdvertiser = async (payload:any) => {
  
  try {
    const headers=await generateHeader();
    const response = await axios.post(API_BASE_URL, payload, {headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAdvertiser = async ( payload:any) => {
  try {
    const headers=await generateHeader();
    const response = await axios.put(API_BASE_URL, payload,{headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAdvertiser = async (payload:any) => {
  try {
    const headers=await generateHeader();
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url:API_BASE_URL,
      headers:headers,
       data:payload.data
    };
    const response=await axios.request(config)
    // const response = await axios.delete(API_BASE_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//for serch functionality fetching complete data withour limit and cursor.
export const fetchCompApiData =  async ( dispatch: DispatchType) => {
  try {
    const headers=await generateHeader();
   const response = await fetch(`${baseURL}/advertisers?ID=advertiser`,{headers});
  const jsonData = await response.json();
  const compApiData = jsonData.result.data.map((item:any) => ({
     Status: item.status, 
     Adv_ID: item.advertiserID,
      Advertiser_Name: item.advertiserName,
      Brand_Name: item.brand,
      vendorName:getDisplayName(item.vendorName),
      // Created_on: item.createdOn,
      
  }));
  dispatch(setApiFullData(compApiData));
  dispatch(setFullData(jsonData.result.data));
  } catch (error) {
    throw new Error('Failed to fetch Api data');
  }
};

export const searchAdvAccount=async(inputValue:string, dispatch: DispatchType,ID:string,location:any)=>{
  dispatch(setIsoLoading(true));
  const headers=await generateHeader();
  const encodedValue: string = encodeURIComponent(inputValue);
const response=await axios.get(`${baseURL}/search?ID=${ID}&key=${encodedValue}`, {headers});
const filteredData = response.data.data.map((item:any) => {

  const mappedItem: any = {
    Status: item.status,
    Adv_ID: item.mPubID,
    Advertiser_Name: item.GroupName,
    Brand_Name: item.brand,
    vendorName: getDisplayName(item.vendorName),
  }

  if (location.pathname === '/budget-allocation' || location.pathname === '/caps-filtering') {
    mappedItem.Created_on = item?.createdOn;
  }else{
    mappedItem.vertical = item?.vertical;
  }
    // Created_on: item.createdOn,
    return mappedItem;
});

if(encodedValue && filteredData.length==0){
  dispatch(setNoDataFlag(true));
}else{
  dispatch(setNoDataFlag(false));
  dispatch(setBlockScroll(true));
}
dispatch(setIsoLoading(false));
dispatch(appendData(filteredData));
}

export const filterByVerticalSearch = async (palyoad: Object, dispatch: DispatchType) => {
  dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  // const encodedValue: string = encodeURIComponent(inputValue);
  const headers = await generateHeader();
  const response = await axios.post(`${baseURL}/filterByVertical?ID=advertiser`, palyoad, { headers });
  console.log("response======byvertical", response)
  const compApiData = response.data.map((item: any) => ({
    Status: item.status,
    Adv_ID: item.mPubID,
    Advertiser_Name: item.GroupName,
    Brand_Name: item.brand,
    vendorName: getDisplayName(item.vendorName),
    vertical: item.vertical,
    // Created_on: item.createdOn,

  }));
  dispatch(appendData(compApiData));
  dispatch(setFilterSearchAdvData(compApiData));
  if (palyoad && compApiData.length === 0) {
    dispatch(setNoDataFlag(true));
  } else {
    dispatch(setNoDataFlag(false));
    dispatch(setBlockScroll(true));
  }
  dispatch(setIsoLoading(false));
}

////////////////adv camp

export const fetchAdvertiserCampApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null
) => {
  try {
    const headers=await generateHeader();
    dispatch(setCampLoading(true));
    let url = advertiserCampApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url,{headers});
    const jsonData = await response.json();
    const filteredData = jsonData.result.data.map((item:any) => {

     const pauseStatus=getCampaignStatus(item.values.configTimeOfDay)
      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit  || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.advertiser?.mPubID,
        Advertiser_Name: item.advertiser?.GroupName,   
        vertical: item?.advertiser?.vertical,   
        // Status: item.status,
        error:item.values?.programCSVConfig?item.values.programCSVConfig.csvDataError:false,
        isOFF:pauseStatus==='Active'?false:true,
       

      }
    });
    dispatch(appendCampData(filteredData));
    dispatch(appendLimitCampaignsData(jsonData.result.data))
    // dispatch(storeLimitCampaignsData(jsonData.result.data))
    dispatch(setCurrentCampCursor(jsonData.result.meta.cursor));
    dispatch(setHasCampMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setCampLoading(false));
  }
};

export const deleteAdvertiserCamp = async (payload:any) => {
  try {
    const headers=await generateHeader();
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url:`${API_BASE_URL}/campaign?SORTID=${payload}`,
      headers:headers,
      //  data:payload.data
    };
    const response=await axios.request(config)
    return response.data;
  } catch (error) {
    throw error;
  }
};

//For status toggle switch not for edit icon.
export const updateStatusAdvCamp = async (SORTID:any,ID:any , requestData:any ) => {
  try {
    const headers=await generateHeader();
    const response = await axios.put(`${baseURL}/update/status?ID=${ID}&SORTID=${SORTID}`,requestData, {headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};
//  

//for serch functionality fetching complete data withour limit and cursor.
export const fetchAdvComCampApiData =  async ( dispatch: DispatchType) => {
  try {
    const headers=await generateHeader();
   const response = await fetch(`${baseURL}/advertisers/campaign?ID=campaign`,{headers});
  const jsonData = await response.json();
  const compApiData = jsonData.result.data.map((item:any) => {
    const pauseStatus=getCampaignStatus(item.values.configTimeOfDay)
    return {
      Status: item.status,
      budgetFlag: item?.budgetFilteringLimit || false,
      campaign_id: item?.SORTID,
      capFlag: item?.clickCapFilteringLimit  || false,
      campaign_name: item?.campaignname,
      Adv_ID: item.advertiser?.mPubID,
      Advertiser_Name: item.advertiser?.GroupName,
      vertical: item?.advertiser?.vertical,
      // Brand_Name: item?.advertiser?.brand ? item.advertiser.brand: item.values.brand,
      // Status: item.status,
      error:item.values?.programCSVConfig?item.values.programCSVConfig.csvDataError:false,
      isOFF:pauseStatus==='Active'?false:true,

    }
  });
  dispatch(setApiFullCampData(compApiData));
  dispatch(setFullCampData(jsonData.result.data));
  dispatch(setFetchFlagAdvCamp(true));
  } catch (error) {
    throw new Error('Failed to fetch Api data');
  }
};


export const fetchSearchResult = async (inputValue: string, dispatch: DispatchType, ID: string) => {
  try {
    dispatch(setIsoLoading(true));
    const headers=await generateHeader();
    const encodedValue: string = encodeURIComponent(inputValue);
    const response = await axios.get(`${baseURL}/search?ID=${ID}&key=${encodedValue}&campaignType=advertiserCampaign`, {headers});
    const filteredData = response.data.data.map((item: any) => {
      const advertiserName = item?.publisherName;
      const formattedAdvertiserName = advertiserName ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '') : '';
      const pauseStatus=getCampaignStatus(item.values.configTimeOfDay)
      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit  || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.publisherID,
        Advertiser_Name: item?.advertiser.GroupName ? item?.advertiser?.GroupName : item?.advertiser?.advertiserName,
        vertical: item?.advertiser?.vertical,
        // Brand_Name: item.values?.brand,
        // Status: item.status,
      error:item.values?.programCSVConfig?item.values.programCSVConfig.csvDataError:false,
      isOFF:pauseStatus==='Active'?false:true,

      };
    });

    if (encodedValue && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }

    dispatch(setIsoLoading(false));
    dispatch(appendCampData(filteredData));
    dispatch(appendLimitCampaignsData(response.data.data));
  } catch (error) {
    console.error("Error fetching search results:", error);
    dispatch(setIsoLoading(false)); // Set loading flag to false in case of an error
    // You can dispatch additional actions or handle the error as needed
  }
};


export const filterAdvertiserSearch =  async(palyoad:Object, dispatch: DispatchType)=> {
  try {
    dispatch(setfilterSearchFlag(true));
    dispatch(setIsoLoading(true));
    const headers=await generateHeader();
    const response=await axios.post(`${baseURL}/filter?campaignType=advertiserCampaign`,palyoad, {headers});

    const filteredData = response.data.map((item: any) => {
      const advertiserName = item?.publisherName;
      const formattedAdvertiserName = advertiserName ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '') : '';
      const pauseStatus=getCampaignStatus(item.values.configTimeOfDay)
      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit  || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.publisherID,
        Advertiser_Name: item?.advertiser.GroupName ? item?.advertiser?.GroupName : item?.advertiser?.advertiserName,
        vertical: item?.advertiser?.vertical,  
        // Brand_Name: item.values?.brand,
        // Status: item.status,
      error:item.values?.programCSVConfig?item.values.programCSVConfig.csvDataError:false,
      isOFF:pauseStatus==='Active'?false:true,
      };
    });

    if (palyoad && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }

    dispatch(setIsoLoading(false));
    dispatch(setFilterSearchAdvData(filteredData));
    dispatch(appendCampData(filteredData));
    dispatch(appendLimitCampaignsData(response.data));
  } catch (error) {
    dispatch(setfilterSearchFlag(false));
    console.error("Error fetching search results:", error);
    dispatch(setIsoLoading(false)); // Set loading flag to false in case of an error
    // You can dispatch additional actions or handle the error as needed
  }
};


type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): DebouncedFunction<T> {
  let timeoutId: NodeJS.Timeout;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;

    clearTimeout(timeoutId);
    
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}


