import React, { useState, useMemo, useCallback } from 'react';
import { Table, Tooltip, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Button, createTheme, ThemeProvider, TableContainer, Paper, Box, IconButton } from '@mui/material';
import { IOSSwitch } from '../../../Component/Table/MMTable';
interface Column {
  id: string;
  label: string;
  sortable: boolean;
}

interface DynamicTableProps {
  columns: Column[];
  data: any[];
  //   fetchMoreData: () => void;
  //   hasMoreData: boolean;
  //    onDelete: (rowId: string) => void;
}
const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          backgroundColor: "#EAECF0",
          color: "#737791"
        },

      },
    },
  },
});
const VendorAccountTable: React.FC<DynamicTableProps> = React.memo(({ columns, data }) => {
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [selectedRow, setSelectedRow] = useState<any>(null);

  // Sorting function based on current column
  const sortedData = useMemo(() => {
    if (!sortConfig) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  // Handle sorting toggle
  const handleSort = useCallback(
    (columnId: string) => {
      setSortConfig((prevSortConfig) => {
        if (prevSortConfig?.key === columnId) {
          return {
            key: columnId,
            direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
          };
        } else {
          return { key: columnId, direction: 'asc' };
        }
      });
    },
    []
  );



  return (
    <>
      <ThemeProvider theme={theme} >

        {/* <InfiniteScroll dataLength={data.length} next={fetchMoreData} hasMore={hasMoreData} loader={<CircleLoader />}> */}
        <TableContainer component={Paper}>
          <Box className='no-more-tables responsive-tbl'>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.sortable ? (
                        <TableSortLabel
                          active={sortConfig?.key === column.id}
                          direction={sortConfig?.key === column.id ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => {
                      if (column.id === 'status') {
                        return <TableCell><IOSSwitch checked={row[column.id] === 'Active'} /></TableCell>
                      } else {
                        return <TableCell key={column.id} data-title={column.label}>{row[column.id]}</TableCell>
                      }



                    })}
                  </TableRow>
                ))}


              </TableBody>
            </Table>
          </Box>
        </TableContainer>
        {(sortedData.length === 0) && <p style={{ textAlign: 'center', paddingBottom: '10px', marginTop: '14px', fontSize: 'large' }}>No Result Found</p>}
      </ThemeProvider>
    </>
  );
});

export default VendorAccountTable;
