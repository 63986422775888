import * as Yup from 'yup';

const idsValidation = /^\d{5}\D*$/


export const tcpaWebsiteInfoValidation = Yup.lazy(values => {
    return Yup.object().shape({
        mBrand: Yup.string().required("Please select advertiser"),
        advertiserName: Yup.string().required('Please select advertiser'),
        headContent: Yup.string().required('Please add head content'),
        postUrl: Yup.string().required('Please add post URL'),
        tcpaText: Yup.string().required('Please add TCPA text'),
        defaultProgram: Yup.string().required('Please add default program'),
        defaultProgramValue: Yup.string().required('Please add default program value'),
        shortDescription: Yup.string().required('Please add short description'),
        description: Yup.string().required('Please add description'),
        bid: Yup.number().required('Please add bid price'),
        // tcpaCampName: Yup.string().required('Please enter TCPA campaign name'),
        // advertiserDisclosure: values.advertiserDisToggle === true ? Yup.string().required('Please enter advertiser disclosure text') : Yup.string().nullable(),
        //  footerContent: values.footerContentToggle === true ? Yup.string().required('Please enter some footer contents') : Yup.string().nullable(),
        //    footerLinksToggle: Yup.boolean(),
        //  footerLinks: values.footerLinksToggle === true ?
        //     Yup.array().of(
        //         Yup.object().shape({
        //             key: Yup.string().required('Key is required'),
        //             value: Yup.string().required('Value is required')
        //         })
        //     ).required('footerLinks is required') :
        //     Yup.array().nullable()
    });
});