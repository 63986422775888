import React from 'react'
import { ISvgIcons } from './AdvertiserSVGIcon'
// fill="#737791"
const UserSvgIcon :React.FC<ISvgIcons> = ({iconColor}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1120_1239)">
    <path d="M20.4853 3.51469C18.2188 1.24828 15.2053 0 12 0C8.79473 0 5.78119 1.24823 3.51469 3.51469C1.24828 5.78119 0 8.79473 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78119 22.7517 8.79473 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7517 18.2188 24 15.2053 24 12C24 8.79473 22.7518 5.78119 20.4853 3.51469ZM5.20805 20.1233C5.60433 16.7138 8.53566 14.0816 12 14.0816C13.8263 14.0816 15.5437 14.7931 16.8354 16.0847C17.9267 17.1762 18.615 18.6013 18.7921 20.1231C16.9519 21.6643 14.5825 22.5938 12 22.5938C9.41747 22.5938 7.04827 21.6645 5.20805 20.1233ZM12 12.6331C9.99042 12.6331 8.35528 10.998 8.35528 8.98847C8.35528 6.9787 9.99047 5.34375 12 5.34375C14.0095 5.34375 15.6447 6.9787 15.6447 8.98847C15.6447 10.998 14.0096 12.6332 12 12.6332V12.6331ZM20.0025 18.9344C19.6411 17.4794 18.8899 16.1504 17.8297 15.0905C16.9655 14.2262 15.943 13.5778 14.8279 13.1715C16.1684 12.2624 17.051 10.7265 17.051 8.98847C17.051 6.20344 14.785 3.9375 12 3.9375C9.21497 3.9375 6.94903 6.20344 6.94903 8.98847C6.94903 10.7274 7.83234 12.2639 9.17395 13.1728C8.148 13.5467 7.19864 14.1248 6.3818 14.8857C5.19891 15.9873 4.37752 17.3919 3.99666 18.9333C2.38369 17.0738 1.40625 14.649 1.40625 12C1.40625 6.15858 6.15858 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15858 22.5938 12C22.5938 14.6495 21.6159 17.075 20.0025 18.9344Z" fill={iconColor} />
    </g>
    <defs>
    <clipPath id="clip0_1120_1239">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default UserSvgIcon