import { generateHeader } from "../Auth";
import{ baseURLConfig }from '../../config/envConfig';
import axios from 'axios';
import AWS from 'aws-sdk';
export const getAdvByVendor=async(vendorName:string)=>{
  
  try{
    const baseURL = baseURLConfig.baseURl
    const headers=await generateHeader();
    const queryParams={
        vendorName:vendorName
    }
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    const finalURL=baseURL+`/getAdvertiserByVendor?${queryParamsObject}`
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:finalURL,
        headers: headers
      };

      const response = await axios.request(config)
  // console.log("response ", response)
  return response.data;
  }catch(error){
    return error
  }
   

}

export const getCampaignsByAdvertiser=async(mPubID:string)=>{
  
  try{
    const baseURL = baseURLConfig.baseURl
    const headers=await generateHeader();
    const queryParams={
      // 
      mPubID:mPubID
    }
    // Sandeep  https://3xkv7zjm2i.execute-api.us-east-1.amazonaws.com/staging/v3/getAdvAndCampByVendor?vendorName=Vendor-All-Star
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    // const finalURL=baseURL+`/getAdvAndCampByVendor?${queryParamsObject}`
    const finalURL=baseURL+`/getCampaignByAdvID?${queryParamsObject}`
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:finalURL,
        headers: headers
      };

      const response = await axios.request(config)
  // console.log("response ", response)

  return response.data;
  }catch(error){
    return error
  }
   

}


export const getCampaignsByVendor=async(vendorName:string)=>{
  
  try{
    const baseURL = baseURLConfig.baseURl
    const headers=await generateHeader();
    const queryParams={
      vendorName:vendorName
    }
    // Sandeep  https://3xkv7zjm2i.execute-api.us-east-1.amazonaws.com/staging/v3/getAdvAndCampByVendor?vendorName=Vendor-All-Star
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    const finalURL=baseURL+`/getAdvAndCampByVendor?${queryParamsObject}`
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:finalURL,
        headers: headers
      };

      const response = await axios.request(config)
  // console.log("response ", response)

  return response.data;
  }catch(error){
    return error
  }
   

}


export const updateVendorGroup = async (params:any) => {
  try {
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    const result = await cognitoIdentityServiceProvider.updateGroup(params).promise();
    console.log('Group updated successfully:', result);
    return result;
  } catch (error) {
    console.error('Error updating group:', error);
    return error;
  }
};


export const fetchVendorsData=async ()=>{
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: baseURL + `/vendorsData`,
    headers
  };
  const res=await axios.request(config)
  return res.data
}



export const deleteVendor=async (groupName:string)=>{
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: baseURL + `/vendorsData`,
    headers,
    data:{
      groupName:groupName
    }
  };
  try{
    const res=await axios.request(config)
  return res.data
  }catch(error){
    throw error;
  }
  
}