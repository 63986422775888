import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CampaignState {
  campaignname: string;
  publisherName:any;
  description: string;
  status: string;
   capSettings: {
    daily: number;
    weekly: number;
    monthly: number;
    monday:number;
    tuesday:number;
   wednesday:number;
   thursday:number;
   friday:number;
   saturday:number;
   sunday:number;
   };
   enableDayCap: boolean;
   excludedBrandIDAction: string;
  editFlag:boolean;
  excludeMbrandID:{};
  excludeAdvertiser:{
    [id:string]:{
      title:string;
      bid:string;
      enable:boolean;
      campaigns:any[]
      Adv_ID:string
    }
  } | null;
  vertical:string;
  verticalFilter: string[]; 
}

const initialState: CampaignState = {
  campaignname: '',
  publisherName: '',
  description: '',
  status: 'Inactive',
  enableDayCap: false,
  capSettings: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  },
  excludedBrandIDAction: 'false',
  editFlag: true,
  excludeMbrandID: {},
  excludeAdvertiser: null,
  vertical: '',
  verticalFilter: []
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setFields: (state, action) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
    },
    setCapSettingsField: (state, action) => {
      const { field, value } = action.payload;
      (state as any).capSettings[field] = value;
    },
    setDayCapField: (state, action) => {
      const { field, value } = action.payload;
      (state as any).dayCap[field] = value;
    },
    // setInitialValues: (state, action) => {
    //   // Directly spread the response data into the state
    //   return { ...state, ...action.payload };
    // },
    resetForm: (state) => {
      Object.assign(state, initialState);
    },
    statusHandler: (state) => {
      // state.status = !state.status;
      state.status = state.status === 'Active' ? 'Inactive' : 'Active';
    },

    specificDayToggle: (pro) => {
      pro.enableDayCap = !pro.enableDayCap;  
      if(pro.enableDayCap === false){
        pro.capSettings.monday=0;
        pro.capSettings.tuesday=0;
        pro.capSettings.wednesday=0;
        pro.capSettings.thursday=0;
        pro.capSettings.friday=0;
        pro.capSettings.saturday=0;
        pro.capSettings.sunday=0;
      }
    },
    setSelectedRadioValue: (state, action: PayloadAction<string>) => {
      state.excludedBrandIDAction = action.payload;
    },
    setEditFlag: (state, action: PayloadAction<boolean>) => {
      state.editFlag = action.payload;
    },
    setExcludeMbrandId: (state, action: PayloadAction<any>) => {
      state.excludeMbrandID = action.payload;
    },

    storeInitialExcludeAdvertiser:(state, action:PayloadAction<any>)=>{
  state.excludeAdvertiser=action.payload;
    },
    
    updateExcludeAdvertiser:(state, action:PayloadAction<any>)=>{

      const advID = action.payload.data[0].Adv_ID
      if(state.excludeAdvertiser){
        state.excludeAdvertiser[advID].campaigns=action.payload.data;
        if (action.payload.allSelected) {
          state.excludeAdvertiser[advID].enable = true;
        } else {
          state.excludeAdvertiser[advID].enable = false;
        }
      }
     



        }
  },
});

export const { setFields ,setCapSettingsField,setDayCapField,resetForm, statusHandler,specificDayToggle, setSelectedRadioValue,setEditFlag,setExcludeMbrandId, storeInitialExcludeAdvertiser,  updateExcludeAdvertiser} = campaignSlice.actions;

export default campaignSlice.reducer;
