import React from 'react';
import { mmRoutes } from '../routes';
import { Route, Routes } from 'react-router-dom';
import ResponsiveDrawerDemo from '../Component/MMDrawer'

const AdminDashboard: React.FC = () => {
  return (
    <>
    {/* <h1>HI</h1> */}
      {mmRoutes.map((route:any, index: any) => (
      <Routes>
        <Route key={index} path={route.path} element={
          <ResponsiveDrawerDemo>
            <route.element />
          </ResponsiveDrawerDemo>
        } /></Routes>
     
      ))}
    </>
  );
};

export default AdminDashboard;
