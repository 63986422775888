import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Grid,
    Input,
    Tooltip,
    TextField,
    Button,
    DialogActions,
    CircularProgress,
} from '@mui/material';
import { FC, useState, useEffect, } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../../../Store/hooks';
import { setProgramInfoField } from '../../../../../Store/Slices/CmsProgramContentDataSlice';
import {
    useFormik,
    FormikProvider,
    FieldArray,
    ErrorMessage,
    getIn,
} from 'formik';
import * as Yup from 'yup';
import { uploadSEOToS3 } from '../../../../../service/S3 Upload';
import { getBannerData, updateBannerData } from '../../../../../Services/seoApis';
import { CircleLoader } from 'react-spinners';
import { setApiLoading, setSnackbarOpen, setSuccessMessage } from '../../../../../Store/Slices/SEOHandlerApisSlice';


const bannerValidation = Yup.object().shape({
    bannerTitle_1: Yup.string().required('Banner Title 1 is required'),
    bannerTitle_2: Yup.string().required('Banner Title 1 is required'),
    bannerTitle_3: Yup.string().required('Banner Title 1 is required'),
    uploadImage: Yup.string().required('Banner Title 1 is required'),
})

const AddEditImgContent: FC<any> = ({ openPopup, setDialogOpen,selectedValue }) => {
    const handleClose = () => setDialogOpen(false);
    const programContentState = useAppSelector(state => state.programContentApi);
    const dispatch = useAppDispatch();
    const [uploadImage, setUploadImage] = useState<string>('');
    const [dataFlag, setDataFlag] = useState<boolean>(true);
 const apiLoading = useAppSelector((state) => state.seoHandlerApi.apiLoading);

    interface bannerDialogFields {
        bannerTitle_1: string,
        bannerTitle_2: string,
        bannerTitle_3: string,
        uploadImage: string;
        websiteDomainName: string;
        areaStudy: any;
        mProgram: any;
    }

    const formik = useFormik<bannerDialogFields>({
        initialValues: {
            bannerTitle_1: '',
            bannerTitle_2: '',
            bannerTitle_3: '',
            uploadImage: '',
            websiteDomainName: selectedValue?.websiteDomainName,
            areaStudy: selectedValue?.areaStudy,
            mProgram: selectedValue?.mProgram,
        },
        onSubmit: values => {
            // console.log('values', values);
            dispatch(setApiLoading(true));

            const updateData = async () => {
                try {
                  const getData = await updateBannerData(values);
                  setDialogOpen(false);
                  dispatch(setSuccessMessage('Updated Banner Setting'));
                  dispatch(setSnackbarOpen(true));
                } catch (error) {
                  console.error('Error fetching data:', error);
                  setDialogOpen(false);
                dispatch(setApiLoading(false));
                }
                dispatch(setApiLoading(false));
              };
              updateData();
            // console.log('bannerfields', programContentState)
            // formik.resetForm();
            //   setFooterKeyValues([{ key: '', value: '' }]);
            //   setUploadImage('');
            //   setEditorState(EditorState.createEmpty());
            //   setFooterContentEditorState(EditorState.createEmpty());
        },
        // validationSchema: bannerValidation,
    });


    const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setImageAlert(false);
        const fileInput = event.target;
        const file = fileInput.files && fileInput.files[0];

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/webp') {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const image = new Image();
                    image.src = reader.result as string;

                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;

                        // if (width <= 500 && height <= 250) {
                        const objectURL = uploadSEOToS3(
                            reader.result,
                            `${new Date().getTime()}`,
                            file
                        );
                        objectURL.then((res: any) => {
                            formik.setFieldValue('uploadImage', res);
                            dispatch(setProgramInfoField({ field: 'uploadImage', value: res }))
                            // console.log('file details....', res);
                            setUploadImage(res as any);
                        });
                        // }

                        // else {
                        //   event.target.value = '';

                        // //   setImageAlert(true);
                        //   fileInput.value = '';
                        // }
                    };
                };

                reader.readAsDataURL(file);
            } else {
                // Display an error alert
                alert('Please select a jpeg or png image file');
                // Reset the file input to clear the selected file
                fileInput.value = '';
            }
        }
    };


    useEffect(() => {

        const getData = async () => {
          try {
            const getData = await getBannerData(
              `?websiteDomainName=${selectedValue?.websiteDomainName}&areaStudy=${selectedValue?.areaStudy}&mProgram=${selectedValue?.mProgram}`
            );
            if (getData?.length) {
              console.log('getData', getData[0]);
              formik.setValues(getData[0]);
              setDataFlag(false);
            //   setChips(getData[0]?.seoKeywords)
            }
            else{
                setDataFlag(false);
              }
          } catch (error) {
            console.error('Error fetching data:', error);
            setDataFlag(false);
          }
        };
        getData();
      }, []);

    return (<>
        <Dialog
            open={openPopup}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: '1' } }}
        >
            {dataFlag ? (
      <Box className="loading-spinner-container" sx={{
        minHeight: "500px",
        minWidth:'500px',
      }}>
        <CircleLoader color="#00BFFF" loading={dataFlag ? true : false} size={60} />
      </Box>
    ) : 
          ( <> <DialogTitle>Banner Setting</DialogTitle>
            <div className="close-button" onClick={handleClose}>
                <CloseIcon style={{ fontSize: '32px' }} />
            </div>

            <Box display="flex" flexDirection="column" alignItems="center">
                <form className="seo-form" id="seoForm" onSubmit={() => { }}>
                    <DialogContent>
                        <Box>
                            <Grid container spacing={4} alignItems="flex-start">
                                <Grid item xs={12}>
                                    <TextField
                                        name='bannerTitle_1'
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        value={formik.values.bannerTitle_1}
                                        label="Banner Title 1" fullWidth
                                        error={formik.touched.bannerTitle_1 && Boolean(formik.errors.bannerTitle_1)}
                                        helperText={formik.touched.bannerTitle_1 && formik.errors.bannerTitle_1}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='bannerTitle_2'
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        value={formik.values.bannerTitle_2}
                                        label="Banner Title 2" fullWidth
                                        error={formik.touched.bannerTitle_2 && Boolean(formik.errors.bannerTitle_2)}
                                        helperText={formik.touched.bannerTitle_2 && formik.errors.bannerTitle_2}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='bannerTitle_3'
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        value={formik.values.bannerTitle_3}
                                        label="Banner Title 3" fullWidth
                                        error={formik.touched.bannerTitle_3 && Boolean(formik.errors.bannerTitle_3)}
                                        helperText={formik.touched.bannerTitle_3 && formik.errors.bannerTitle_3}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        type="file"
                                        id="image"
                                        inputProps={{ accept: 'image/*' }}
                                        style={{ display: 'none' }}
                                        onChange={handleUploadImage}
                                        onBlur={formik.handleBlur}
                                        name='uploadImage'
                                    />
                                    <Box display="flex" flexDirection="column" gap={2}>
                                        <Box display="flex" gap={3}>
                                            <Tooltip title={'image' || ''} arrow>

                                                <TextField
                                                    fullWidth
                                                    name='uploadImage'
                                                    value={formik.values.uploadImage}
                                                    // onChange={formik.handleChange}
                                                    label="Website banner Image *"
                                                    variant="outlined"
                                                    error={formik.touched.uploadImage && Boolean(formik.errors.uploadImage)}
                                                // helperText="Dimension (500*250)"
                                                />
                                            </Tooltip>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                sx={{
                                                    backgroundColor: '#1976d2',
                                                    '&.MuiButtonBase-root:hover': {
                                                        bgcolor: '#1976d2 !important',
                                                    },

                                                    '&:hover': { backgroundColor: '#1976d2' },
                                                    border: '1px solid #1976d2',
                                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                                    borderRadius: '8px',
                                                    // color: '#ffff',
                                                    height: '45px',
                                                    margin: '5px',
                                                }}
                                                onClick={() => {
                                                    // Trigger the hidden file input
                                                    const fileInput = document.getElementById(
                                                        'image'
                                                    ) as HTMLInputElement;
                                                    fileInput.click();
                                                }}
                                            >
                                                Upload
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12}>
                                        <Input
                                            type="file"
                                            id="image"
                                            inputProps={{ accept: 'image/*' }}
                                            style={{ display: 'none' }}
                                        // onChange={handleTwitterFileChange}
                                        />
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <Box display="flex" gap={3}>
                                                <Tooltip title={'image' || ''} arrow>
                                                    <TextField
                                                        fullWidth
                                                        //   value={image}
                                                        label="Website Icons *"
                                                        variant="outlined"
                                                        helperText="Dimension (500*250)"
                                                    />
                                                </Tooltip>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    sx={{
                                                        backgroundColor: '#1976d2',
                                                        color: '#fff',
                                                        '&.MuiButtonBase-root:hover': {
                                                            bgcolor: '#1976d2 !important',
                                                        },
                                                        '&:hover': { backgroundColor: '#1976d2' },
                                                        border: '1px solid #1976d2',
                                                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                                        borderRadius: '8px',
                                                        whiteSpace: 'nowrap',
                                                        width: '140px',
                                                        boxSizing: 'content-box',
                                                    }}
                                                    onClick={() => {
                                                        // Trigger the hidden file input
                                                        const fileInput = document.getElementById(
                                                            'image'
                                                        ) as HTMLInputElement;
                                                        fileInput.click();
                                                    }}
                                                >
                                                    Upload Image
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid> */}
                            </Grid>
                        </Box>
                    </DialogContent>
                </form>
            </Box>
            <DialogActions sx={{ p: 3 }}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    gap="16px"
                >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => formik.handleSubmit()}>
                    {
          apiLoading ? <CircularProgress color="inherit" size={24} /> : 'UPDATE'
        }
                        </Button>
                </Box>
            </DialogActions> </>)
     }
        </Dialog>
    </>);
};

export default AddEditImgContent;
