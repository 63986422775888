import React, { useEffect, useMemo } from 'react'
import PublisherStagingJSON from '../../Mock/PublishersStaging.json'
import { Box, Paper, Typography } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import { mProgramActivationHeadCell } from '../../Component/Table/tableInterface'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { setData, setFlag } from '../../Store/Slices/PublisherTabApisData'
import { setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData'
import Search from '../../Component/Search/Search';

// import { mpartnerActivationHeadCells } from '../../Component/Table/tableInterface';
import { fetchMpartnerApiData, fetchMpartnerCompApiData } from '../../Services/mPartnerActivation'
import { useLocation } from 'react-router-dom'

const MpartnerActivation = () => {

  // // const publisherTableData={

  // // }
  // const publisherTableData: any[] = PublisherStagingJSON.map((item, index) => {
  //   // publisherTableData[]
  //   const myObj = {
  //     publisherID: item.mPubID,
  //     publisherName: item.GroupName,
  //     createdOn: item.createdOn,
  //     website: item.values.website,

  //     // value:item.values

  //   }
  //   return myObj
  // })
  // //console.log("publisherTable", publisherTableData)

  // return (
  //   <Paper>
  //     <MMTable tableData={publisherTableData} tableHeadCell={mProgramActivationHeadCell} editComponentNavigation="/mPartners-config" />
  //   </Paper>
  // )

  const dispatch = useAppDispatch();
  const location = useLocation();
  const publisherTableData = useAppSelector((state) => state.publisherAccApi.data);
  const compApiData = useAppSelector((state) => state.publisherAccApi.compApiData);
  const isLoading = useAppSelector((state) => state.publisherAccApi.loading);
  const currentCursor = useAppSelector((state) => state.publisherAccApi.currentCursor);
  const hasMoreData = useAppSelector((state) => state.publisherAccApi.hasMoreData);
  // const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);

  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setSearchFilterData([]));
    if (!searchInput) {
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      fetchInitialData();
      // fetchCompApiData(dispatch);
    }
  }, [dispatch,searchInput]);

  useEffect(() =>{
    dispatch(setSearchInput(''));
    fetchMpartnerCompApiData(dispatch,location);
  },[])


  const fetchInitialData = () => {
    fetchMpartnerApiData(dispatch, null,location).then(() => {
      dispatch(setScrollNext(true));
     });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchMpartnerApiData(dispatch, currentCursor,location);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = compApiData.filter(function (item:any) {
          return (
          item.publisherID?.toString().toLowerCase().includes(userInput) ||
          item.publisherName?.toLowerCase().includes(userInput) ||        
          item.website?.toString().toLocaleLowerCase().includes(userInput) ||
          item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if(userInput && filteredDataa.length==0){
        dispatch(setNoDataFlag(true));
      }else{dispatch(setNoDataFlag(false));}
      dispatch(setData(filteredDataa));
    } else {
      dispatch(setData(publisherTableData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  // const handleSearchInput = useMemo(()=> (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const { value } = event.target
  //     dispatch(setSearchInput(value));
  //     searchTable(value)
  //   }, [searchTable])

    //New search functionality with debounce technique
  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch(setSearchInput(value));
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchInput, searchTable]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchInput) {
        searchTable(searchInput);
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, [searchInput]);
  //--------------end search

  return (
    <React.Fragment>
        <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
          <Typography className="card-heading">MPartners </Typography>
        <Typography className="card-sub-heading">
        MPartners Summary
        </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
        <Search value={searchInput} searchHandler={handleSearchInput} />
          {/* <Button variant="contained" startIcon={<AddIcon />}
                      onClick={() => {setOpenPopup(true); dispatch(setEditFlag(false))}}>
                      Create Publisher
                    </Button> */}
            </Box>
          </Box>
          <MMTable tableData={publisherTableData} tableHeadCell={mProgramActivationHeadCell} isLoadingMore={isLoading} 
               fetchMoreData={() => fetchMoreData()} hasMoreData={hasMoreData}   fetchInitialData={fetchInitialData}
               editComponentNavigation="/mPartners-config"
              />
        </Paper>
        {/* <CreatePublisher
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialData}
      /> */}
    </React.Fragment>
  )
}

export default MpartnerActivation