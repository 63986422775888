import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
// import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
//   // autoFocus: true,
// };

import { createTheme, ThemeProvider } from '@mui/material/styles';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    blueC: Palette['primary'];
  }

  interface PaletteOptions {
    blueC?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an blueC option
declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    blueC: true;
  }
}
const theme = createTheme({
  palette: {
    blueC:{
      main:"#0Ae"
    }
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps1:Partial<MenuProps> = {
  // getContentAnchorEl: null,
  variant: 'menu',
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



interface IMultipleSelectProps{
  dropdownData:{label:string, value:string}[];
  dropdownLabel:string;
  dropDownValue:any,
  setDropDownValue:any,
  

}
export default function MultipleSelectCheckmarks(props:IMultipleSelectProps) {
  const {dropdownData, dropdownLabel,dropDownValue,setDropDownValue}=props
  const [personName, setPersonName] = React.useState<string[]>(dropDownValue);



  const isAllSelected =
  dropdownData.length > 0 && dropDownValue.length === dropdownData.length;

const handleChange1 = (event: SelectChangeEvent<string[]>) => {
  const value = event.target.value;
  if (value[value.length - 1] === "all") {
    setPersonName(personName.length === dropdownData.length ? [] : dropdownData.map((name) => name.value));
    setDropDownValue(dropDownValue.length === dropdownData.length ? [] : dropdownData.map((name) => name.value))
    return;
  }
  setPersonName(typeof value === 'string' ? value.split(',').map(String) : value);
  setDropDownValue(typeof value === 'string' ? value.split(',').map(String) : value)
};

  return (
   <ThemeProvider  theme={theme}>
    <Box>
      <FormControl  fullWidth color={'blueC'}>
        <InputLabel id="demo-multiple-checkbox-label">{dropdownLabel}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={dropDownValue}
          onChange={handleChange1}
          
          input={<OutlinedInput label={dropdownLabel}/>}
          renderValue={(selected:any) =>
            isAllSelected
              ? "All"
              : (selected as string[]).map(
                  (selectedValue) =>
                    dropdownData.find((name) => name.value === selectedValue)?.label ||
                    ''
                ).join(', ')
          }
          MenuProps={MenuProps1}
        >
          {/* "Select All" option */}
          <MenuItem value={'all'} >
            <Checkbox checked={isAllSelected}    style ={{
                      color: "#0Ae",
                    }}/>
            <ListItemText primary="Select All" />
          </MenuItem>

          {/* Other options */}
          {dropdownData.map((name) => (
            <MenuItem key={name.value} value={name.value}>
              <Checkbox checked={dropDownValue.includes(name.value)} style ={{
                      color: "#0Ae",
                    }}/>
              <ListItemText primary={name.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
    </ThemeProvider>
  );
}

