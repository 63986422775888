import React, { useState } from 'react'
import { setData as setAdvAccData, setIsoLoading, setAdvCampData, setSearchInput, updateAdvertiserAsync, statusUpdateAdvCampAsync, setCampFlag } from '../../Store/Slices/advertiserTabApisData';
import { fetchAdvComCampApiData, fetchAdvertiserCampApiData, fetchCompApiData, fetchSearchResult, filterAdvertiserSearch } from '../../Services/advertiserTabApis';
import { fetchCompApiData as fetchPubApiData, fetchPublisherCampApiData } from '../../Services/publisherTabApis';
import { setCampData, setData as setPubAccData, updatePublisherAsync, updatePublisherCampAsync } from '../../Store/Slices/PublisherTabApisData';
import axios from 'axios';
import { Location } from 'react-router-dom';
import { useAppSelector } from '../../Store/hooks';
import { setTcpaData, statusUpdateTcpaCampAsync } from '../../Store/Slices/tcpaCampSlice';
import { fetchTcpaCampaignData } from '../../Services/tcpaTabApis';
let userSortid=localStorage.getItem("userSortid")



export const StatusUpdateToggle = async (
  row: any,
  setSuccessMessage: (message: string) => void,
  setErrorMessage: (message: string) => void,
  setSnackbarOpen: (isOpen: boolean) => void,
  fetchInitialData: () => void,
  tableData: any[],
  dispatch: any,
  fullAdvAccData: any,
  fullPubAccData: any,
  fullPubCampData: any,
  advCampaignsLimitData: any,
  tcpaFullData:any,
  location: Location<any>,
  searchInput:string,
  searchFilterData:any[]
) => {

  let rowData;
  let statuss;
  let sortId;
  let id;
  let type;
  let tcpaCampId;
  // const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  dispatch(setIsoLoading(true));
  const updatedRow = { ...row, Status: row.Status === 'Active' ? 'Inactive' : 'Active' };
console.log('updatedRow---1',updatedRow)
  if (row.Adv_ID && location.pathname === "/advertiser-accounts") {
    const updatedTableData = tableData.map((data: any) =>
      data.Adv_ID === updatedRow.Adv_ID ? updatedRow : data);
    dispatch(setAdvAccData(updatedTableData));
    const { Status, Adv_ID } = updatedRow;
    statuss = Status;
    const getRowDataById = (advId: string) => {
      return fullAdvAccData.find((row: any) => row.advertiserID === advId);
    };
    rowData = getRowDataById(Adv_ID);
  // console.log("sdfsfsdfsdf",updatedTableData)
    
  }

  else if (row.Publisher_ID && location.pathname === "/publisher-accounts") {
    const updatedTableData = tableData.map((data: any) =>
      data.Publisher_ID === updatedRow.Publisher_ID ? updatedRow : data);
    dispatch(setPubAccData(updatedTableData));
    const { Status, Publisher_ID } = updatedRow;
    statuss = Status;
    const getRowDataById = (pubId: string) => {
      return fullPubAccData.find((row: any) => row.mPubID === pubId);
    };
    rowData = getRowDataById(Publisher_ID);
  }

  else if (row.campaign_id && (location.pathname === "/publisher-campaigns" || location.pathname === "/publisherCampaigns")) {
    const updatedTableData = tableData.map((data: any) =>
      data.campaign_id === updatedRow.campaign_id ? updatedRow : data);
    dispatch(setCampData(updatedTableData));
    const { Status, campaign_id } = updatedRow;

    statuss = Status;
    const getRowDataById = (advId: string) => {
      return fullPubCampData.find((row: any) => row.SORTID === advId);
    };
    rowData = getRowDataById(campaign_id);
  }
  else if (row.tcpaCampaign_id && location.pathname === "/TCPA-campaigns") {
    const updatedTableData = tableData.map((data: any) =>
      data.tcpaCampaign_id === updatedRow.tcpaCampaign_id ? updatedRow : data);
    dispatch(setTcpaData(updatedTableData));
    const { Status, tcpaCampaign_id } = updatedRow;
    statuss = Status;
    const getRowDataById = (TcpaId: string) => {
      return tcpaFullData.find((row: any) => row.tcpaCampaignId === TcpaId);
    };
    rowData = getRowDataById(tcpaCampaign_id);
  }
  else {
    const updatedTableData = tableData.map((data: any) =>
      data.campaign_id === updatedRow.campaign_id ? updatedRow : data);
    dispatch(setAdvCampData(updatedTableData));
    const { Status, campaign_id } = updatedRow;
    statuss = Status;
    const getRowDataById = (camId: string) => {
      return advCampaignsLimitData.find((row: any) => row.SORTID === camId);
    };
    rowData = getRowDataById(campaign_id);
    //  advCampId = rowData?.SORTID;
    //  console.log('advCampId',rowData)
  }

  let requestData
  let encodedSortId = encodeURIComponent(rowData?.SORTID);
  // console.log('encodedSortId',encodedSortId);
  if (rowData) {
    id = rowData?.ID;
    sortId = encodedSortId;
    requestData = {
      status: statuss,
    }
    type= rowData.campaignType;
    tcpaCampId= rowData.tcpaCampaignId;
  }


  try {
    if(rowData.tcpaCampaignId && location.pathname === '/TCPA-campaigns'){
    const res=  await dispatch(statusUpdateTcpaCampAsync({ tcpaCampId: tcpaCampId, ID: type, requestData }));
     res && setSuccessMessage('Status Updated Successfully!');
     res &&   fetchTcpaCampaignData(dispatch, null);
     setSnackbarOpen(true);
    }else{
      const res= await dispatch(statusUpdateAdvCampAsync({ SORTID: sortId, ID: id, requestData }));
     res && setSuccessMessage('Status Updated Successfully!');
  
      if (rowData && location.pathname === "/advertiser-accounts") {
        fetchCompApiData(dispatch);
      } else if (rowData && location.pathname === "/publisher-accounts") {
        fetchPubApiData(dispatch);
      }
      else if (rowData && (location.pathname === "/publisher-campaigns" || location.pathname === "/publisherCampaigns")) {
        // fetchComCampApiData(dispatch);
        fetchPublisherCampApiData(dispatch, null)
      }
      else { 
        if(location.pathname === '/advertiserCampaigns' && userSortid){
  
            // Extract the last 5 characters dynamically
           let  lastFiveCharacters = userSortid.slice(-5);
        // console.log('lastFiveCharacters===',lastFiveCharacters)
        //     console.log("Last 5 characters:", lastFiveCharacters);
            const payload = {
              "mPubIds": [lastFiveCharacters]
            }
            lastFiveCharacters.length >0 && filterAdvertiserSearch(payload,dispatch);
  
        }else{
  
        //FOr Adv campaigns page
  
        // fetchAdvComCampApiData(dispatch);
        dispatch(setCampFlag(false));
      if(searchFilterData.length > 0){  
        const payload = {
          "mPubIds": searchFilterData}
  
        searchFilterData.length>0 && filterAdvertiserSearch(payload,dispatch);
      }
        if (searchInput){
          fetchSearchResult(searchInput, dispatch, "campaign")
          // if (debouncedInputValue !== '' && debouncedInputValue !== null) {
          //   fetchSearchResult(debouncedInputValue, dispatch, "campaign")
          //   }
        }else{
         
          if(searchFilterData.length === 0){
            fetchAdvertiserCampApiData(dispatch,null);
            dispatch(setIsoLoading(false));}
        }
        // dispatch(setSearchInput(''));
        //  fetchAdvertiserCampApiData(dispatch,null);
        }
   
        
      }
  
      setSnackbarOpen(true);
      // fetchInitialData();
    }
   
  } catch (error) {
    console.error('Error:', error);
    setErrorMessage('Failed to update status');
    setSnackbarOpen(false);
  }
  if (location.pathname !== "/advertiser-campaigns"){
    dispatch(setIsoLoading(false));
  }
  // dispatch(setIsoLoading(false));
  // dispatch(setSearchInput(''));
};