import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SideBarList from './side nav/SidebarList';
import { Avatar, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Fab from '@mui/material/Fab';
const drawerWidth = 300;
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactNode
}
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  marginTop: '0px',
  padding: theme.spacing(1.5),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: -drawerWidth, // Adjusted to 0 when the drawer is closed
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: -drawerWidth,
    marginLeft: 0
  }),
}));



interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
export default function ResponsiveDrawerDemo(props: Props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    const handleResize = () => {
      const isScreenSmall = window.innerWidth >= 1150;
      setOpen(isScreenSmall);
    };
    // handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const drawer = (
    <div>
      <Box className='close-side-bar' >
        <IconButton onClick={handleDrawerClose} >
          {open && <CloseIcon />}
        </IconButton>
      </Box>
      <Toolbar className='mm-toolbar' >
        <img src='/images/MM-Logo-1.svg' alt='mm-logo' onClick={handleDrawerClose} />

      </Toolbar>
      <SideBarList />

    </div>
  );
  return (


    <Box sx={{ display: 'flex' }} className="Sidebar-container">
      <CssBaseline />
      {/* <Box className='sidebar-icon-btn-container'>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          sx={{ display: open ? 'none' : 'block' }}
        >
          <MenuIcon />
        </IconButton>
      </Box> */}

      <Fab size="small" color="primary" className='fab-menu-icon' aria-label="menu" onClick={handleDrawerOpen} sx={{ display: open ? 'none' : 'block' , backgroundColor:"#0ae"}}>
      <MenuIcon />
</Fab>
   {/* <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >

        {drawer}
      </Drawer>


      <Main open={open} className='mm-main'>

        {props.children}
      </Main>


    </Box>


  );
}
