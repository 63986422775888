import AudienceTargetingTabContent, { GradYearSection } from "../View/Advertiser/Stepper Form/Step components/AudienceTargetingTabContent";

export interface ITabData{
    label:string;
    content:React.FC<any>;
    value:string;
    categories?:string;
  }

export  interface AudienceTargetData {

    text: string;
    value: string;
    id: string;
    status: boolean;
    bidPrice: string;
    categories:string;
    
}

export interface AudienceTargetJSONData{
    [key:string]:Array<AudienceTargetData>
}

export const AudienceTargetTabData:ITabData[]=[
{
    label:"Campus Type",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:""
},
{
    label:"Highest Level of Education",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:"highestEducation"
},
{
    label:"Degree of Interest",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:"degreeInterest"
},
{
    label:"Degree Start Time frame",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:"startTimeframe"
},
{
    label:"Miliary Affiliation",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:"militaryAffiliation"
},
{
    label:"Media Type",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:"mediaType"
},
{
    label:"Inventory Type",
    content: AudienceTargetingTabContent,
    value:"1",
    categories:"inventoryType"
},
{
    label:"Grad Year",
    content: GradYearSection,
    value:"1",
    categories:"inventoryType"
},


]