import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarMessageProps {
  open: boolean;
  type: 'success' | 'error'| any;
  message: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
};

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({ open, type, message, onClose }) => {
  const getColor = () => {
    return type === 'success' ? 'success' : 'error';
  };


  return (


    <Snackbar
      open={open}
      autoHideDuration={2000}
      message="Note archived"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={getColor()}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarMessage;
