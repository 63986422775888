import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { createTcpaCampAsync, setSnackbarMessage, setSnackbarOpen, setSnackbarType, setTcpaEditJson } from '../../../../Store/Slices/tcpaCampSlice';
import TcpaSchedulingTable from './TcpaSchedulingTable';
import { CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_UPDATE_SUCCESS } from '../../../../Mock/MessageContstant';
import { ErrorMessage } from 'formik';
 

const TcpaScheduling: FC<any> = ({ submitRef, handleNext }) => {
  const dispatch: any = useAppDispatch();
  const location = useLocation();
  const scheduling = useAppSelector((state) => state.tcpaCampSlice.scheduling);
  const [responseValue, setResponseValue] = useState<null | any>(null)
  const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const {tcpaEditFlag,tcpaCampaignIdOnEdit} = tcpaCampSlice

  useEffect(() => {
    if (location.pathname === '/createTcpaCamp' || location.pathname === '/editTcpaCamp') {
      submitRef.current = () => {
        handleSubmit();
      }
    }
  }, [scheduling]);

  const handleSubmit = async () => {
    let requestPayload;
    requestPayload = {
        ...responseValue,
        configTimeOfDay: tcpaCampSlice.scheduling,
        campaignType: "tcpaCampaign",
         tcpaCampaignId: tcpaEditFlag? tcpaCampaignIdOnEdit: tcpaCampId,
    }

    try{
      const response = await dispatch(createTcpaCampAsync({payload: requestPayload, method: 'put' }));
      if(response){
        dispatch(setSnackbarOpen(true))
        dispatch(setSnackbarType("success"))
        dispatch(setSnackbarMessage(tcpaEditFlag ? CAMPAIGN_UPDATE_SUCCESS : CAMPAIGN_CREATE_SUCCESS ))
        handleNext()
    }
    } catch(err){
      console.error(err);
      dispatch(setSnackbarOpen(true))
      dispatch(setSnackbarType("error"))
      dispatch(setSnackbarMessage(ErrorMessage))
    }
  }

  return (

    <>
      {/* <SchedulingTable /> */}
      <TcpaSchedulingTable />
    </>

  )
}

export default TcpaScheduling