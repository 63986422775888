import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import MMTable from '../../../Component/Table/MMTable';
import {
  brandNameDummy,
  mBrandsHeadCell,
} from '../../../Component/Table/tableInterface';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { setData, setFlag } from '../../../Store/Slices/PublisherTabApisData';
import {
  setNoDataFlag,
  setScrollNext,
  setSearchFilterData,
} from '../../../Store/Slices/advertiserTabApisData';
import Search from '../../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import MuiAlert from '@mui/material/Alert';

import {
  createMbrandAsync,
  resetMBrandForm,
  setMBrandData,
  setMBrandEditFlag,
  setMBrandField,
  setMBrandTableData,
  setSearchMbrandInput,
  storeBrandLogo1ImageURL,
  storeBrandLogo2ImageURL,
  updateMbrandAsync,
} from '../../../Store/Slices/ExternalApiSlice';
import { RootState } from '../../../Store';
import { DeleteIconPop } from '../../Advertiser/Action';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { fetchMBrandApiData } from '../../../Services/externalApis';
import CloseIcon from '@mui/icons-material/Close';
import {
  uploadPrimaryLogoToS3,
  uploadSecondaryLogoToS3,
} from '../../../service/S3 Upload';
import { advertiserPageMock } from './advertiserPageMock';
import { useNavigate } from 'react-router-dom';
import { generateHeader } from '../../../service/Auth';
import { baseURLConfig } from '../../../config/envConfig';
import axios from 'axios';
import { getAdvMBrandData } from '../../../Services/seoApis';

const AdvertisePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const publisherTableData = useAppSelector((state) => state.publisherAccApi.data);
  const mBrandTableData = useAppSelector(
    state => state.mBrandHandlerApi.mBrandTableData
  );
  const mBrandData = useAppSelector(state => state.mBrandHandlerApi.data);

  const searchMbrandInput = useAppSelector(
    state => state.mBrandHandlerApi.searchMbrandInput
  );

  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setSearchFilterData([]));
    if (!searchMbrandInput) {
      // If searchInput is empty, fetch the initial data
      fetchMBrandApiData(dispatch);
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      // fetchInitialData();
      // fetchCompApiData(dispatch);
    }
  }, [dispatch, searchMbrandInput]);

  useEffect(() => {
    dispatch(setSearchMbrandInput(''));
    // fetchMBrandApiData(dispatch);
  }, []);

  const fetchInitialData = () => {
    fetchMBrandApiData(dispatch);
  };

  // const fetchMoreData = () => {
  //   if (!isLoading && hasMoreData) {
  //     dispatch(setFlag(true));
  //     fetchMpartnerApiData(dispatch, currentCursor);
  //   }
  // };

  //  //console.log("apiiieeiiiiiinow",compApiData)
  function searchTable(input: string) {
    // console.log('fire=====')
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = mBrandData.filter(function (item: any) {
        return (
          item.mBrandID?.toString().toLowerCase().includes(userInput) ||
          item.mBrandName?.toLowerCase().includes(userInput) ||
          // item.website?.toString().toLocaleLowerCase().includes(userInput) ||
          item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else {
        dispatch(setNoDataFlag(false));
      }
      dispatch(setMBrandTableData(filteredDataa));
    } else {
      dispatch(setMBrandTableData(mBrandTableData));
      dispatch(setNoDataFlag(false));
    }
    // console.log('=====', mBrandData, mBrandTableData)
  }

  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      dispatch(setSearchMbrandInput(value));
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchMbrandInput, searchMbrandInput, searchTable]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchMbrandInput) {
        searchTable(searchMbrandInput);
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, [searchMbrandInput]);

  const getAdvertiserPageData = async (val: any) => {
    // dispatch(setIsoLoading(true));
    // dispatch(setIsoLoading(false));
    const response = await getAdvMBrandData(val?.mBrandID);

     navigate('/advertisePageHandler', {
      state: { advertiserPageData: response?response:{...advertiserPageMock,mBrandId:val?.mBrandID.toString(),mBrandName:val?.mBrandName} },
    });
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Advertiser Portal</Typography>
            <Typography className="card-sub-heading">Advertiser Page Setup</Typography>
          </Box>
          <Box
            display="flex"
            gap="16px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: '16px', md: '0' },
              alignItems: { xs: 'flex-start', md: 'center' },
            }}
          >
            <Search
              value={searchMbrandInput}
              searchHandler={handleSearchInput}
            />
          </Box>
        </Box>
        <MMTable
          tableData={mBrandTableData}
          tableHeadCell={mBrandsHeadCell}
          hasEdit
          onEdit={getAdvertiserPageData}
          // isLoadingMore={isLoading}
          //      fetchMoreData={() => fetchMoreData()} hasMoreData={hasMoreData}   fetchInitialData={fetchInitialData}
        />
      </Paper>
    </React.Fragment>
  );
};

export default AdvertisePage;
