import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Fab,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Checkbox,
  IconButton,
  FormHelperText,
  Typography,
  Switch,
} from '@mui/material';
import React, {
  FC,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
// import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuIcon from '@mui/icons-material/Menu';
import isEqual from 'lodash/isEqual';
import ManageMProgramMapping from './ManageMProgramMapping';
import { IOSSwitch } from '../../../../../Component/Table/MMTable';
import MultiMapping from './multiMapping';
import {
  Formik,
  Field,
  Form,
  FieldArray,
  useFormik,
  FormikProvider,
} from 'formik';
import * as Yup from 'yup';
import { advertiserPageMock, program } from '../../advertiserPageMock';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const validationSchema = Yup.object({
  programContent: Yup.array().of(
    Yup.object().shape({
      areaStudy: Yup.array().of(
        Yup.object().shape({
          mProgram: Yup.array().of(
            Yup.object().shape({
              description: Yup.string().required('Description is required'),
              status: Yup.boolean(),
              value: Yup.string().required('Value is required'),
            })
          ),
        })
      ),
    })
  ),
});

const Ability: FC<any> = ({ submitRef, formData, handleFormSubmit }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeAreaStudyTab, setActiveAreaStudyTab] = useState(0);
  const isScreenAbove1300 = useMediaQuery('(min-width:1300px)');
  const [showTabs, setShowTabs] = useState(true);

  const formik = useFormik<any>({
    initialValues: formData,
    onSubmit: handleFormSubmit,
    validationSchema,
  });

  const handleTabChange = async (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    formik.values.programContent[activeTab]?.areaStudy[
      activeAreaStudyTab
    ]?.mProgram.forEach((_: any, mProgramIndex: any) => {
      formik.setFieldTouched(
        `programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].description`,
        true
      );
      formik.setFieldTouched(
        `programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].value`,
        true
      );
    });
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      setActiveTab(newIndex);
      setActiveAreaStudyTab(0); // Reset area study tab when changing the main type tab
    }
  };

  const handleAreaStudyTabChange = async (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    formik.values.programContent[activeTab]?.areaStudy[
      activeAreaStudyTab
    ]?.mProgram.forEach((_: any, mProgramIndex: any) => {
      formik.setFieldTouched(
        `programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].description`,
        true
      );
      formik.setFieldTouched(
        `programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].value`,
        true
      );
    });
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      setActiveAreaStudyTab(newIndex);
    }
  };

  const handleAddMProgram = (arrayHelpers: any) => {
    arrayHelpers.push({ description: '', status: false, value: '' });
  };

  useEffect(() => {
    if (!isScreenAbove1300) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [isScreenAbove1300]);

  const toggleTabsVisibility = () => {
    setShowTabs(!showTabs);
  };

  useEffect(() => {
    submitRef.current = () => {
      formik?.handleSubmit();
    };
  }, []);

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="programContent"
        render={arrayHelpers => (
          <>
            <Box sx={{ width: '100%' }}>
              <Tabs
                onChange={handleTabChange}
                value={activeTab}
                aria-label="Tabs where each tab needs to be selected manually"
                className="tabButtons"
                variant="scrollable"
                scrollButtons="auto"
              >
                {formik.values?.programContent?.map(
                  (item: any, index: number) => {
                    return (
                      <Tab
                        value={index}
                        className={`tabButton ${
                          index === activeTab ? 'active' : ''
                        } `}
                        label={item?.type}
                      />
                    );
                  }
                )}
              </Tabs>
            </Box>
            <Box sx={{ position: 'relative', display: 'flex', backgroundColor:'white' }}>
              {showTabs && (
                <Box
                  sx={{
                    height: '70vh',
                    flexBasis: '20%',
                    padding: '10px',
                  }}
                  className="mm-tab-container"
                >
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={activeAreaStudyTab}
                    onChange={handleAreaStudyTabChange}
                    aria-label="Area Study Tabs"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                  >
                    {formik.values.programContent[activeTab]?.areaStudy.map(
                      (study: any, index: number) => (
                        <Tab
                          key={index}
                          label={study.areaStudyLabel}
                          className={`mm-tab ${
                            index === activeAreaStudyTab ? 'active' : ''
                          } `}
                        />
                      )
                    )}
                  </Tabs>
                </Box>
              )}
              <Box
                className="mm-tabpanel-container"
                sx={{
                  height: '75vh',
                  flexBasis: '100%',
                  padding: '11.5px',
                }}
              >
                <div role="tabpanel" style={{ width: '100%' }}>
                  <Paper>
                    <Box className="no-more-tables responsive-tbl">
                      <Table size="small" className="programTable">
                        <TableHead>
                          <TableRow>
                            <TableCell>Program Name</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <FieldArray
                            name={`programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram`}
                            render={mProgramHelpers => (
                              <>
                                {formik.values.programContent[
                                  activeTab
                                ].areaStudy[activeAreaStudyTab].mProgram.map(
                                  (mProgram: any, mProgramIndex: number) => (
                                    <TableRow key={mProgramIndex}>
                                      <TableCell data-title="Program Name">
                                        <FormControl
                                          error={
                                            (
                                              formik.touched
                                                .programContent as any
                                            )?.[activeTab]?.areaStudy?.[
                                              activeAreaStudyTab
                                            ]?.mProgram?.[mProgramIndex]
                                              ?.description &&
                                            Boolean(
                                              (
                                                formik.errors
                                                  .programContent as any
                                              )?.[activeTab]?.areaStudy?.[
                                                activeAreaStudyTab
                                              ]?.mProgram?.[mProgramIndex]
                                                ?.description
                                            )
                                          }
                                          fullWidth
                                        >
                                          <TextField
                                            name={`programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].description`}
                                            value={mProgram.description}
                                            label="Description"
                                            variant="outlined"
                                            size="small"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                              (
                                                formik.touched
                                                  .programContent as any
                                              )?.[activeTab]?.areaStudy?.[
                                                activeAreaStudyTab
                                              ]?.mProgram?.[mProgramIndex]
                                                ?.description &&
                                              Boolean(
                                                (
                                                  formik.errors
                                                    .programContent as any
                                                )?.[activeTab]?.areaStudy?.[
                                                  activeAreaStudyTab
                                                ]?.mProgram?.[mProgramIndex]
                                                  ?.description
                                              )
                                            }
                                          />
                                          {(
                                            formik.touched.programContent as any
                                          )?.[activeTab]?.areaStudy?.[
                                            activeAreaStudyTab
                                          ]?.mProgram?.[mProgramIndex]
                                            ?.description &&
                                            Boolean(
                                              (
                                                formik.errors
                                                  .programContent as any
                                              )?.[activeTab]?.areaStudy?.[
                                                activeAreaStudyTab
                                              ]?.mProgram?.[mProgramIndex]
                                                ?.description
                                            ) && (
                                              <Box
                                                color="#db2424"
                                                sx={{
                                                  // fontWeight: 400,
                                                  fontSize: '10px',
                                                  // pt: 0.5,
                                                  top: '100%',
                                                  position: 'absolute',
                                                }}
                                              >
                                                {
                                                  (
                                                    formik.errors
                                                      .programContent as any
                                                  )?.[activeTab]?.areaStudy?.[
                                                    activeAreaStudyTab
                                                  ]?.mProgram?.[mProgramIndex]
                                                    ?.description
                                                }
                                              </Box>
                                            )}
                                        </FormControl>
                                      </TableCell>

                                      <TableCell data-title="Value">
                                        <FormControl
                                          error={
                                            (
                                              formik.touched
                                                .programContent as any
                                            )?.[activeTab]?.areaStudy?.[
                                              activeAreaStudyTab
                                            ]?.mProgram?.[mProgramIndex]
                                              ?.value &&
                                            Boolean(
                                              (
                                                formik.errors
                                                  .programContent as any
                                              )?.[activeTab]?.areaStudy?.[
                                                activeAreaStudyTab
                                              ]?.mProgram?.[mProgramIndex]
                                                ?.value
                                            )
                                          }
                                          fullWidth
                                        >
                                          <TextField
                                            name={`programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].value`}
                                            value={mProgram.value}
                                            label="Value"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                            size="small"
                                            error={
                                              (
                                                formik.touched
                                                  .programContent as any
                                              )?.[activeTab]?.areaStudy?.[
                                                activeAreaStudyTab
                                              ]?.mProgram?.[mProgramIndex]
                                                ?.value &&
                                              Boolean(
                                                (
                                                  formik.errors
                                                    .programContent as any
                                                )?.[activeTab]?.areaStudy?.[
                                                  activeAreaStudyTab
                                                ]?.mProgram?.[mProgramIndex]
                                                  ?.value
                                              )
                                            }
                                            // fullWidth
                                          />
                                          {(
                                            formik.touched.programContent as any
                                          )?.[activeTab]?.areaStudy?.[
                                            activeAreaStudyTab
                                          ]?.mProgram?.[mProgramIndex]?.value &&
                                            Boolean(
                                              (
                                                formik.errors
                                                  .programContent as any
                                              )?.[activeTab]?.areaStudy?.[
                                                activeAreaStudyTab
                                              ]?.mProgram?.[mProgramIndex]
                                                ?.value
                                            ) && (
                                              <Box
                                                color="#db2424"
                                                sx={{
                                                  // fontWeight: 400,
                                                  fontSize: '10px',
                                                  // pt: 0.5,
                                                  position: 'absolute',
                                                  top: '100%',
                                                }}
                                              >
                                                {
                                                  (
                                                    formik.errors
                                                      .programContent as any
                                                  )?.[activeTab]?.areaStudy?.[
                                                    activeAreaStudyTab
                                                  ]?.mProgram?.[mProgramIndex]
                                                    ?.value
                                                }
                                              </Box>
                                            )}
                                        </FormControl>
                                      </TableCell>
                                      <TableCell data-title="Status">
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={mProgram.status}
                                              onChange={formik.handleChange}
                                              name={`programContent[${activeTab}].areaStudy[${activeAreaStudyTab}].mProgram[${mProgramIndex}].status`}
                                            />
                                          }
                                          label=""
                                        />
                                      </TableCell>
                                      <TableCell
                                        sx={{ textAlign: 'center' }}
                                        data-title=""
                                      >
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            mProgramHelpers.remove(
                                              mProgramIndex
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                                <TableRow>
                                  <TableCell
                                    colSpan={4}
                                    sx={{ textAlign: 'center' }}
                                  >
                                    <IconButton
                                      color="primary"
                                      aria-label="add to shopping cart"
                                      sx={{
                                        borderRadius: '50%',
                                        backgroundColor: '#d3e3fd',
                                      }}
                                      onClick={() =>
                                        handleAddMProgram(mProgramHelpers)
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          />
                        </TableBody>
                      </Table>
                    </Box>
                  </Paper>
                </div>
              </Box>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: '92px',
                  right: '12px',
                  zIndex: 999, // Ensure it's above other elements
                }}
              >
                {!isScreenAbove1300 && (
                  <Fab
                    onClick={toggleTabsVisibility}
                    variant="extended"
                    size="small"
                    color="primary"
                  >
                    <MenuIcon sx={{ mr: 1 }} />
                    Program
                    {/* {tabContent[0].label == 'Business & Finance' ? 'Programs' : (tabContent[0].label == 'Campus Type' ? 'Audience' : (tabContent[0].label == '[ISM] Online College Guide' ? 'Media' : 'Rules'))} */}
                  </Fab>
                )}
              </Box>
            </Box>
          </>
        )}
      />
    </FormikProvider>
  );
};

export default Ability;
