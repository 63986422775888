import { GridColDef, } from '@mui/x-data-grid';
export const columns: GridColDef[] = [
  { field: 'ID', headerName: 'ID', width: 70, headerClassName: 'mm-app-grid-theme-header', hideable: false, filterable: false },

  { field: 'Date/Time', headerClassName: 'mm-app-grid-theme-header', headerName: 'Date/Time', width: 200, filterable: false, hideable: false, align: "center", headerAlign: 'center' },

  { field: 'publisherName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Publisher Name', width: 270, align: "center", headerAlign: 'center' },
  { field: 'campaignName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Campaign Name', width: 350, align: "center", headerAlign: 'center' },
  { field: 'Ad_Requests', headerClassName: 'mm-app-grid-theme-header', headerName: 'Ad Requests', type: 'number', width: 150, align: "center", headerAlign: 'center' },
  { field: 'CLICKS', headerClassName: 'mm-app-grid-theme-header', headerName: 'Clicks', type: 'number', width: 110, align: "center", headerAlign: 'center' },
  { field: 'CTR', headerClassName: 'mm-app-grid-theme-header', headerName: 'CTR', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Avg position', headerClassName: 'mm-app-grid-theme-header', headerName: 'Avg Position', type: 'number', width: 140, align: "center", headerAlign: 'center' },
  { field: 'CPC', headerClassName: 'mm-app-grid-theme-header', headerName: 'CPC', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Total Commission', headerClassName: 'mm-app-grid-theme-header', headerName: 'Total Commission', width: 170, align: "center", headerAlign: 'center' },
];

export const advColumns: GridColDef[] = [
  { field: 'ID', headerName: 'ID', width: 70, headerClassName: 'mm-app-grid-theme-header', hideable: false, filterable: false },

  { field: 'Date/Time', headerClassName: 'mm-app-grid-theme-header', headerName: 'Date/Time', width: 200, filterable: false, hideable: false, align: "center", headerAlign: 'center' },

  { field: 'advertiserName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Advertiser Name', width: 270, align: "center", headerAlign: 'center' },
  { field: 'campaignName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Campaign Name', width: 350, align: "center", headerAlign: 'center' },
  { field: 'Ad_Requests', headerClassName: 'mm-app-grid-theme-header', headerName: 'Ad Requests', type: 'number', width: 150, align: "center", headerAlign: 'center' },
  { field: 'CLICKS', headerClassName: 'mm-app-grid-theme-header', headerName: 'Clicks', type: 'number', width: 110, align: "center", headerAlign: 'center' },
  { field: 'CTR', headerClassName: 'mm-app-grid-theme-header', headerName: 'CTR', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Avg position', headerClassName: 'mm-app-grid-theme-header', headerName: 'Avg Position', type: 'number', width: 140, align: "center", headerAlign: 'center' },
  { field: 'RPC', headerClassName: 'mm-app-grid-theme-header', headerName: 'RPC', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Total Spent', headerClassName: 'mm-app-grid-theme-header', headerName: 'Total Spend', width: 170, align: "center", headerAlign: 'center' },
];

export const intradayColumns: GridColDef[] = [
  { field: 'ID', headerName: 'ID', width: 70, headerClassName: 'mm-app-grid-theme-header', hideable: false, filterable: false },

  { field: 'Date/Time', headerClassName: 'mm-app-grid-theme-header', headerName: 'Date/Time', width: 200, filterable: false, hideable: false, align: "center", headerAlign: 'center' },

  { field: 'publisherName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Publisher Name', width: 270, align: "center", headerAlign: 'center' },
  { field: 'campaignName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Campaign Name', width: 350, align: "center", headerAlign: 'center' },
  // { field: 'Ad_Requests', headerClassName: 'mm-app-grid-theme-header', headerName: 'Ad Requests', type: 'number', width: 150, align: "center", headerAlign: 'center' },
  { field: 'CLICKS', headerClassName: 'mm-app-grid-theme-header', headerName: 'Clicks', type: 'number', width: 110, align: "center", headerAlign: 'center' },
  // { field: 'CTR', headerClassName: 'mm-app-grid-theme-header', headerName: 'CTR', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Avg position', headerClassName: 'mm-app-grid-theme-header', headerName: 'Avg Position', type: 'number', width: 140, align: "center", headerAlign: 'center' },
  { field: 'CPC', headerClassName: 'mm-app-grid-theme-header', headerName: 'CPC', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Total Commission', headerClassName: 'mm-app-grid-theme-header', headerName: 'Total Commission', width: 170, align: "center", headerAlign: 'center' },
];

export const intradayAdvColumns: GridColDef[] = [
  { field: 'ID', headerName: 'ID', width: 70, headerClassName: 'mm-app-grid-theme-header', hideable: false, filterable: false },

  { field: 'Date/Time', headerClassName: 'mm-app-grid-theme-header', headerName: 'Date/Time', width: 200, filterable: false, hideable: false, align: "center", headerAlign: 'center' },

  { field: 'advertiserName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Advertiser Name', width: 270, align: "center", headerAlign: 'center' },
  { field: 'campaignName', headerClassName: 'mm-app-grid-theme-header', headerName: 'Campaign Name', width: 350, align: "center", headerAlign: 'center' },
  // { field: 'Ad_Requests', headerClassName: 'mm-app-grid-theme-header', headerName: 'Ad Requests', type: 'number', width: 150, align: "center", headerAlign: 'center' },
  { field: 'CLICKS', headerClassName: 'mm-app-grid-theme-header', headerName: 'Clicks', type: 'number', width: 110, align: "center", headerAlign: 'center' },
  // { field: 'CTR', headerClassName: 'mm-app-grid-theme-header', headerName: 'CTR', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Avg position', headerClassName: 'mm-app-grid-theme-header', headerName: 'Avg Position', type: 'number', width: 140, align: "center", headerAlign: 'center' },
  { field: 'RPC', headerClassName: 'mm-app-grid-theme-header', headerName: 'RPC', width: 140, align: "center", headerAlign: 'center', },
  { field: 'Total Spent', headerClassName: 'mm-app-grid-theme-header', headerName: 'Total Spend', width: 170, align: "center", headerAlign: 'center' },
];

export const advSummary = [
  {
    key: "totalRequests",
    label: "Ad Requests",
    value: ""
  },
  {
    key: "totalClicks",
    label: "CLICKS",
    value: ""
  },
  {
    key: "finalAverageCTR",
    label: "CTR",
    value: ""
  },
  {
    key: "finalAverageCPC",
    label: "RPC",
    value: "$"
  },
  {
    key: "finalCommission",
    label: "Total Spend",
    value: "$"
  }
]

export const pubSummary = [
  {
    key: "totalRequests",
    label: "Ad Requests",
    value: "",

  },
  {
    key: "totalClicks",
    label: "CLICKS",
    value: ""
  },
  {
    key: "finalAverageCTR",
    label: "CTR",
    value: ""
  },
  {
    key: "finalAverageCPC",
    label: "CPC",
    value: "$"
  },
  {
    key: "finalCommission",
    label: "Total Commission",
    value: "$"
  }
]



export const overallPubGroupItem = [
  {
    showInColumn: "campaignName",
   summaryType: "custom",
   displayFormat: "Total",
   name: "Total",

 },
  {
     showInColumn: "Ad_Requests",
    summaryType: "custom",
    displayFormat: "{0}",
    name: "requestSummary",

  },
  {
     showInColumn: "CLICKS",
    summaryType: "custom",
    displayFormat: "{0}",

    name: "clickSummary"
  },
  {
     showInColumn: "CTR",
    summaryType: "custom",
    displayFormat: "{0}",

    name: "CTR"
  },
 
  {
     showInColumn: "CPC",
    summaryType: "custom",
    displayFormat:"$ {0}",
    name: "CPC"
  },
  {
     showInColumn: "Total Commission",
    summaryType: "custom",
    displayFormat: "$ {0}",
    name: "commission"
  }


]

export const IntradayPubGroupItem = [
  {
    showInColumn: "campaignName",
   summaryType: "custom",
   displayFormat: "Total",
   name: "Total",

 },
  {
    showInColumn: "CLICKS",
    summaryType: "custom",
    displayFormat: "{0}",

    name: "clickSummary"
  },
  // {
  //    showInColumn: "Ad_Requests",
  //   summaryType: "custom",
  //   displayFormat: "Sum: {0}",
  //   name: "requestSummary",

  // },
  {
    showInColumn: "CPC",
    summaryType: "custom",
    displayFormat:"$ {0}",
    name: "CPC"
  },
  {
    showInColumn:"Total Commission",
    // column: "Total Commission",
    summaryType: "custom",
    displayFormat: "$ {0}",
    name: "commission",
    // format:"currency"
  }


]


export const overallAdvGroupItem = [
  {
    showInColumn: "campaignName",
   summaryType: "custom",
   displayFormat: "Total",
   name: "Total",

 },
  {
     showInColumn: "Ad_Requests",
    summaryType: "custom",
    displayFormat: "{0}",
    name: "requestSummary",

  },
  {
     showInColumn: "CLICKS",
    summaryType: "custom",
    displayFormat: "{0}",

    name: "clickSummary"
  },
  // {
  //    showInColumn: "CTR",
  //   summaryType: "custom",
  //   displayFormat: "Avg CTR {0}",

  //   name: "CTR"
  // },
 
  {
     showInColumn: "RPC",
    summaryType: "custom",
    displayFormat:"$ {0}",
    name: "RPC"
  },
  {
     showInColumn: "Total Spent",
    summaryType: "custom",
    displayFormat: "$ {0}",
    name: "spent"
  }


]

export const IntradayAdvGroupItem = [
  {
    showInColumn: "campaignName",
   summaryType: "custom",
   displayFormat: "Total",
   name: "Total",

 },
  {
    showInColumn: "CLICKS",
    summaryType: "custom",
    displayFormat: "{0}",

    name: "clickSummary"
  },
  // {
  //    showInColumn: "Ad_Requests",
  //   summaryType: "custom",
  //   displayFormat: "Sum: {0}",
  //   name: "requestSummary",

  // },
  {
    showInColumn: "RPC",
    summaryType: "custom",
    displayFormat:"$ {0}",
    name: "RPC"
  },
  {
    showInColumn:"Total Spent",
    // column: "Total Commission",
    summaryType: "custom",
    displayFormat: "$ {0}",
    name: "spent",
    // format:"currency"
  }


]
