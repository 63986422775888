import { ThunkDispatch } from 'redux-thunk'; // Import the ThunkDispatch type
import {  AnyAction } from '@reduxjs/toolkit';
import { fetchToken, generateHeader } from '../service/Auth';
import { baseURLConfig } from '../config/envConfig';
import { setRawData, setRawReportStatus } from '../Store/Slices/RawDataReportSlice';

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;

const baseURL=baseURLConfig.baseURl;

const url='https://837mtp2kgh.execute-api.us-east-1.amazonaws.com/production/v3';
const advUrl ='https://xprc1rdtae.execute-api.us-east-1.amazonaws.com/production/v3'


export const generateRawDataReport =  async ( dispatch: DispatchType, payload: any, dataObj: any) => {
    try {
      const headers=await generateHeader();
      let response: Response | undefined
     dataObj.mPubId ? (response = await fetch(`${url}/pub-raw-report?${payload}`)) : (response = await fetch(`${advUrl}/advertiser/raw/report?${payload}`));
      
    const jsonData = await response.json();
    
    } catch (error) {
    //   throw new Error('Failed to fetch Api data');
    }
  };
  

  export const fetchRawReportTableData =  async ( dispatch: DispatchType, payload:any, dataObj: any) => {
    try {
      const headers=await generateHeader();
      let response: Response | undefined

      dataObj.mPubId ? response = await fetch(`${url}/report/info?userName=${payload}`): response = await fetch(`${advUrl}/advertiser/raw/report/info?userName=${payload}`);
    const jsonData = await response.json();

    const dataToMap = Array.isArray(jsonData) ? jsonData : [jsonData];

    const compApiData = dataToMap.map((item:any) => ({
        reportName: item.reportName,
        reportType: item.reportType,
        rawStatus: item.status,
        lastExecution: item.executedTimestamp,
        download: item.reportUrl,

    }));
     dispatch(setRawData(compApiData));
     dispatch(setRawReportStatus(dataToMap[0]?.status))
     return compApiData;
    } catch (error) {
      throw new Error('Failed to fetch Api data');
    }
  };