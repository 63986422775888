import React, {useEffect, useState}from 'react'
import { Typography,TableSortLabel, Box, Paper, Table, TableHead, TableCell, TableRow, TableBody, CircularProgress } from "@mui/material"
// import { fetchMBrandMediaSourceDataAction } from '../../Store/Actions'
import { useAppDispatch } from '../../Store/hooks'
import { getBrands } from '../../service/Publisher'
import { IOSSwitch } from '../../Component/Table/MMTable'
import { setExcludeMbrandId } from '../../Store/Slices/PublisherCampaignSlice'


export interface BrandData {
  mBrandName: string;
  mBrandId: number;
  primaryLogo: string;
  createdOn: string;
  secondaryLogo: string;
}

const MbrandExclusion = (props:any) => {
  const dispatch=useAppDispatch()
  const [brandsInfo, setBrandsInfo]=useState<Record<string, { exclude: boolean }> | any>({})
  const [brands, setBrands] = useState<BrandData[]>([]);
  const [loading, setLoading]= useState(false)
useEffect(()=>{
  const fetchBrands = async () => {
    try {
      setLoading(true)
      const response = await getBrandsData();
      setBrands(response.data.values);
       setLoading(false)
    } catch (error) {
      setLoading(true)
      console.error('Error fetching brands:', error);
    }
  };

  fetchBrands();
},[])

useEffect(()=>{
  const brandInfo=generateExcludeMBrand(props.rowData, brands)
  setBrandsInfo(brandInfo);
},[brands, props.rowData])


useEffect(()=>{
 
   dispatch(setExcludeMbrandId(brandsInfo))
},[ brandsInfo])

const getBrandsData=async()=>{
  const response=await getBrands()
  return response
  }


  const handleBrandExclusionChange = (brandId:string) => {
    setBrandsInfo((prevList:any) => ({
      ...prevList,
      [brandId]: {
        ...prevList[brandId],
        exclude: !prevList[brandId].exclude,
      },
    }));
  };

  return (
    <Paper elevation={3} className="card" >
    <Box display="flex" justifyContent="center" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
      <Box textAlign="center">
        <Typography className="card-heading" sx={{ border: "10px" }}> Exclude Brands</Typography>
        <Typography className="card-sub-heading">
        Configure mBrand status 
        </Typography>
      </Box>

    </Box>
    {
      loading?   
      <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center',  minHeight:'50px' }} >
        <CircularProgress color="inherit" size={24} />
        <Typography className="card-sub-heading" sx={{paddingLeft:'20px'}}>
          We are fetching brands, please wait
        </Typography>
      </Box> :
        <Box className='no-more-tables responsive-tbl ' sx={{overflow:'auto', maxHeight:'400px'}}>
        <BrandTable 
        brands={brands}
        brandsInfo={brandsInfo}
        handleBrandExclusionChange={handleBrandExclusionChange}
        />
        </Box>
     } 
  </Paper>

  )
}

export default MbrandExclusion



const generateExcludeMBrand = (rowData: any, brands: BrandData[] = []) => {
  let result: Record<string, { exclude: boolean }> = {};

  if (brands.length >= 1) {
      // console.log("ROW DATA ", rowData);

      if (rowData) {
          // Check if excludeMbrandID exists and is an object
          if (rowData["excludeMbrandID"] && typeof rowData["excludeMbrandID"] === 'object') {
              const excludeBrandId = rowData["excludeMbrandID"];
              result = { ...excludeBrandId };
              // console.log('excludeBrandId=rowData["excludeMbrandID"]', excludeBrandId);

              Object.keys(excludeBrandId).forEach(id => {
                  result[id] = { exclude: excludeBrandId[id].exclude };
              });

              brands.forEach(brand => {
                  const brandId = brand.mBrandId.toString();
                  if (!result[brandId]) {
                      result[brandId] = { exclude: false };
                  }
              });

              // Remove IDs from result that are not present in brands array
              Object.keys(result).forEach(id => {
                  if (!brands.some(brand => brand.mBrandId.toString() === id)) {
                      delete result[id];
                  }
              });
          } else {
              // If excludeMbrandID doesn't exist or is not an object, create a new schema
              brands.forEach(brand => {
                  result[brand.mBrandId.toString()] = { exclude: false };
              });
          }
      } else {
          // If rowData is falsy, create a new schema
          brands.forEach(brand => {
              result[brand.mBrandId.toString()] = { exclude: false };
          });
      }

      return result;
  }

  return result;
};



export interface BrandRowProps {
  brand: BrandData;
  checked: boolean;
  onToggle: (brandId: string) => void;
}


const BrandRow: React.FC<BrandRowProps> = React.memo(({ brand, checked, onToggle }) => {
  return (
    <TableRow key={brand?.mBrandId}>
      <TableCell data-title="Brand ID">{brand?.mBrandId}</TableCell>
      <TableCell  data-title="Brand Name">{brand?.mBrandName}</TableCell>
      <TableCell  data-title="Status">
        <IOSSwitch
          checked={checked}
          onChange={() => onToggle(brand?.mBrandId.toString())}
        />
      </TableCell>
    </TableRow>
  );
});


const BrandTable: React.FC<{ brands: BrandData[]; brandsInfo: any; handleBrandExclusionChange: (id: string) => void }> = ({
  brands,
  brandsInfo,
  handleBrandExclusionChange,
}) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<'mBrandId' | 'mBrandName' | 'status' >('mBrandName');

  const handleRequestSort = (property: 'mBrandId' | 'mBrandName' | 'status' ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedBrands = React.useMemo(() => {
    return brands.sort((a, b) => {
      if (orderBy === 'status') {
        const aStatus = brandsInfo[a.mBrandId]?.exclude || false;
        const bStatus = brandsInfo[b.mBrandId]?.exclude || false;
        if (aStatus < bStatus) {
          return order === 'asc' ? -1 : 1;
        }
        if (aStatus > bStatus) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      } else if(orderBy==='mBrandName') {
        if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) { // Case-insensitive comparison
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      }else{
        if (a[orderBy] < b[orderBy]) {
                  return order === 'asc' ? -1 : 1;
                }
                if (a[orderBy] > b[orderBy]) {
                  return order === 'asc' ? 1 : -1;
                }
                return 0;
      }
    });
  }, [brands, order, orderBy, brandsInfo]);
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sortDirection={orderBy === 'mBrandId' ? order : false} sx={{minWidth:'120px'}}>
            <TableSortLabel
              active={orderBy === 'mBrandId'}
              direction={orderBy === 'mBrandId' ? order : 'asc'}
              onClick={() => handleRequestSort('mBrandId')}
            >
              Brand ID
            </TableSortLabel>
          </TableCell>
          <TableCell sortDirection={orderBy === 'mBrandName' ? order : false}>
            <TableSortLabel
              active={orderBy === 'mBrandName'}
              direction={orderBy === 'mBrandName' ? order : 'asc'}
              onClick={() => handleRequestSort('mBrandName')}
            >
              Brand Name
            </TableSortLabel>
          </TableCell>
          <TableCell sortDirection={orderBy === 'status' ? order : false}>
            <TableSortLabel
              active={orderBy === 'status'}
              direction={orderBy === 'status' ? order : 'asc'}
              onClick={() => handleRequestSort('status')}
            >
              Status
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedBrands.map((brand) => (
          <BrandRow
            key={brand.mBrandId}
            brand={brand}
            checked={brandsInfo[brand.mBrandId]?.exclude || false}
            onToggle={handleBrandExclusionChange}
          />
        ))}
      </TableBody>
    </Table>
  );
};