import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { RootState } from "..";
import mProgramJson from '../../Mock/Mprogram.json';
import AudienceTargetingJson from '../../Mock/AudieneTargeting.json'
// import PublisherCampaignsJson from '../../Mock/publisherCampaigns.json'
import { statesTargeting } from "../../Mock/GeoTargetingData";
import { ICampaignData, ICreateCampaignForm, IGeoTargeting } from "../sliceInteface";
import { fetchAllAdvertiserData } from "../../service/Advertiser";
import { fetchAllPublisherCampaignsData, fetchAllPublishersData } from "../../service/Publisher";
import { addProgramValue, mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps } from "../../Utils/utils";
import { scheduling_jsonData } from "../../Mock/SchedulingData";
// import { generateMediaSourceDataForUI } from "../../Mock/MediaSourceData";
// const excludePublisher: ICampaignData = {
//   // Your data object goes here...
// };
interface TransformedObject {
  [key: string]: {
    publisherId: string;
    bid: number;
    title: string;
    enable: boolean;
  };
}


const removeDuplicateNewCampaigns=(publisherCampaigns: any[], existingExcludePublisher: any)=>{
  const excludePublisher: ICampaignData = {
    // Your data object goes here...
    ...existingExcludePublisher
  };

  publisherCampaigns.forEach((campaigns: any) => {
  if( excludePublisher.hasOwnProperty(campaigns?.publisherID)){
    const newCampaigns=excludePublisher[campaigns?.publisherID].newCampaigns
    let filteredByPublisherCampsData = publisherCampaigns.filter((pc) => {
      return pc.publisherID === campaigns.publisherID
    });
    const campaignIdsFromArray2 = filteredByPublisherCampsData.map(item => item.campaignID);
    const filteredArray1 = newCampaigns.filter(item => campaignIdsFromArray2.includes(item.SORTID));
    // console.log("NEW CAMP IN REMOVE FUNC,", newCampaigns)
    // console.log("CAMPAIGN by PUB IN REMOVE FUNC,", filteredByPublisherCampsData)
    // console.log("update after remove CAMP IN REMOVE FUNC,",filteredArray1)

    const newEXLPublisher={
      ...excludePublisher[campaigns?.publisherID]
    }
    newEXLPublisher.newCampaigns=filteredArray1;
    excludePublisher[campaigns.publisherID] = newEXLPublisher
  } 

  })

  // if(publisherCampaigns.length>1){
  
  // }

   // Extract unique publisher IDs from advertiserCampaigns
 const publisherIdsToCheck = [...new Set(publisherCampaigns.map(campaign => campaign.publisherID))];
//  console.log("publisherIdsToCheck",publisherIdsToCheck )
 // Extract publisher IDs from excludePublisher
 const existingPublisherIds = Object.keys(excludePublisher);
// console.log("existing pub id on expub",existingPublisherIds )
 // Find the IDs that do not exist in the excludePublisher
//  const nonExistentIds = publisherIdsToCheck.filter(id => !existingPublisherIds.includes(id));
 const missingIds = existingPublisherIds.filter(id => !publisherIdsToCheck.includes(id));
 // Output the non-existent IDs
//  console.log("Non-existent publisher IDs:",missingIds);

  missingIds.forEach((pubID)=>{
    const newEXLPublisher={
      ...excludePublisher[pubID]
    }
    newEXLPublisher.newCampaigns=[]
    newEXLPublisher.campaigns={}
    excludePublisher[pubID] = newEXLPublisher
  })


  return excludePublisher;
}
export const generateExcludePublisherData00 = (publisherCampaigns: any[], existingExcludePublisher: any) => {
  const excludePublisher: ICampaignData = {
    // Your data object goes here...
    ...existingExcludePublisher
  };
  if (publisherCampaigns.length > 1) {
    publisherCampaigns.forEach((campaigns: any) => {
      let filteredByPublisherCampsData = publisherCampaigns.filter((pc) => {
        return pc.publisherID === campaigns.publisherID
      })
      if (!excludePublisher.hasOwnProperty(campaigns?.publisherID)) {
        excludePublisher[campaigns?.publisherID] = {
          title: campaigns?.publisherName + " - " + campaigns?.publisherID,
          campaigns: {},
          bid: 0,
          enable: true,
          newCampaigns: [],
          publisherID: campaigns?.publisherID

        }

        filteredByPublisherCampsData.forEach((campaigns) => {

          excludePublisher[campaigns.publisherID].campaigns[campaigns.SORTID] = {
            title: campaigns?.campaignname + "-" + campaigns?.SORTID,
            bid: 0,
            enable: false,
            SORTID: campaigns.SORTID
            // publisherID: campaigns?.publisherID
          }

          excludePublisher[campaigns.publisherID].newCampaigns.push({
            title: campaigns?.campaignname + "-" + campaigns?.SORTID,
            bid: 0,
            enable: false,
            SORTID: campaigns.SORTID,
            publisherId: campaigns.publisherID
          })
        })

      } else {
        // console.log("Worked  new campaigns", excludePublisher[campaigns?.publisherID])
        // console.log("Campaigns based on each pub", filteredByPublisherCampsData)

        filteredByPublisherCampsData.forEach((campaignData) => {
        // console.log('campaign data on ms generate ep in 00 ', campaignData)
          const newPublisherData = {
            ...excludePublisher[campaignData.publisherID]
          }

          // console.log("excludePub data", newPublisherData)
          if (!newPublisherData.campaigns.hasOwnProperty(campaigns?.SORTID)) {
            const newPublisherCampaigns = {
              ...newPublisherData.campaigns
            }
            newPublisherCampaigns[campaigns?.SORTID] = {
              title: campaigns?.campaignname + "-" + campaigns?.SORTID,
              bid: 0,
              enable: false,
              SORTID: campaigns.SORTID,
              //  publisherID: campaigns?.publisherID
            }

            // const newCampaignsArray = [...newPublisherData.newCampaigns]
             newPublisherData.campaigns= newPublisherCampaigns
            // console.log("new camp array", newCampaignsArray)
            excludePublisher[campaignData.publisherID] = newPublisherData
          }


          const newCampaignsArray = [...excludePublisher[campaignData.publisherID].newCampaigns]

          const foundItem = newCampaignsArray.find(item => item.SORTID === campaigns?.SORTID);
          if (!foundItem) {

            const newObj = {
              title: campaigns?.campaignname + "-" + campaigns?.SORTID,
              bid: 0,   ///changed on 19 dec for media source 0
              enable: false,
              SORTID: campaigns.SORTID,
              publisherId: campaigns.publisherID
            }

            newCampaignsArray.push(newObj);
            newPublisherData.newCampaigns = newCampaignsArray
         
             
                }
                else{
    

                const updatedData = newCampaignsArray.map(item => {
                  if (item.SORTID === foundItem.SORTID) {
                      // Return a new object with the updated title
                      return { ...item, title: campaigns?.campaignname + "-" + campaigns?.SORTID };
                  }
                  return item;
              });
        
            newPublisherData.newCampaigns = updatedData
           excludePublisher[campaignData.publisherID] = newPublisherData
          }

         
          
        })


      }

      // console.log("publisherCampaigns single ", campaigns)


    })
  }

  return excludePublisher
}

const generateExcludePublisherData = (publisherCampaigns: any[]) => {
  const excludePublisher: ICampaignData = {
    // Your data object goes here...
  };
  //   const publisherCampaigns = publisherCampaign.filter((cam) => {
  //   return cam.publisherName.indexOf("PUB") != -1
  // })

  // console.log("publisherCampaigns", publisherCampaigns)
  if (publisherCampaigns.length > 1) {
    publisherCampaigns.forEach((campaigns: any) => {


      excludePublisher[campaigns?.publisherID] = {
        title: campaigns?.publisherName + " - " + campaigns?.publisherID,
        campaigns: {},
        bid: 0,
        enable: true,
        newCampaigns: [],
        publisherID: campaigns?.publisherID

      }
      let filteredByPublisherCampsData = publisherCampaigns.filter((pc) => {
        return pc.publisherID === campaigns.publisherID
      })
      filteredByPublisherCampsData.forEach((campaigns) => {

        excludePublisher[campaigns.publisherID].campaigns[campaigns.SORTID] = {
          title: campaigns?.campaignname + "-" + campaigns?.SORTID,
          bid: 6,
          enable: false,
          SORTID: campaigns.SORTID
          // publisherID: campaigns?.publisherID
        }

        excludePublisher[campaigns.publisherID].newCampaigns.push({
          title: campaigns?.campaignname + "-" + campaigns?.SORTID,
          bid: 0,
          enable: false,
          SORTID: campaigns.SORTID,
          publisherId: campaigns.publisherID
        })
      })

      // console.log("publisherCampaigns single ", campaigns)


    })
  }

  return excludePublisher
}

const geoTargetingForPayload: IGeoTargeting = {

}
statesTargeting.forEach((item) => {
  geoTargetingForPayload[item.key] = {
    include: true
  }
});


// Define an async thunk to fetch user data
export const fetchAdvDataAsync = createAsyncThunk(
  'data/fetchAllAdvertiserData',
  async ({ path, queryParamsObject }: { path: string, queryParamsObject: any }) => {
    const response = await fetchAllAdvertiserData(path, queryParamsObject)
    return response.data;
  }
);


export const fetchAllPublisherCampaignsDataAsync = createAsyncThunk(
  'data/fetchAllPublisherCampaignData',
  async ({ path, queryParamsObject }: { path: string, queryParamsObject: any }) => {
    const response = await fetchAllPublisherCampaignsData(path, queryParamsObject)
    return response.data;
  }
);


export const fetchAllPublisherDataAsync = createAsyncThunk(
  'data/fetchAllPublisherData',
  async ({ path, queryParamsObject }: { path: string, queryParamsObject: any }) => {
    const response = await fetchAllPublishersData(path, queryParamsObject)
    return response.data;
  }
);

const addNewCampaigns = (excludePublishers: any) => {
  // const newExcludePublisher:any = { ...excludePublishers };
  // const keys = Object.keys(newExcludePublisher);
  // console.log("keys of exe pub", keys);

  const newExcludePublisher: any = {};
  for (const key in excludePublishers) {
    if (excludePublishers.hasOwnProperty(key)) {
      newExcludePublisher[key] = { ...excludePublishers[key] };
    }
  }
  const keys = Object.keys(newExcludePublisher);
  keys.forEach((key) => {
    if (newExcludePublisher.hasOwnProperty(key)) {
      if (!newExcludePublisher[key].hasOwnProperty("newCampaigns")) {
        // console.log("add newCampaignsSS", newExcludePublisher[key].campaigns );

        const campaigns = newExcludePublisher[key].campaigns;
        // console.log(campaigns);
        const newCampaigns: any[] = [];
        Object.keys(campaigns).forEach((campaignKey) => {
          // console.log(campaigns[campaignKey]);
          const newCampaignsObject = {
            ...campaigns[campaignKey],
            publisherId: key, SORTID: campaignKey
          }
          // console.log("add newCampaignsSS", newCampaignsObject);
          // newCampaigns.push({ ...campaigns[campaignKey], publisherId: key, SORTID:campaignKey });
          newCampaigns.push(newCampaignsObject)
        });
        newExcludePublisher[key].newCampaigns = newCampaigns;
      }
    }
  });

  return newExcludePublisher;
};




const INITIAL_STATE: ICreateCampaignForm = {
  selectedAdvertiser: [],
  advertiserProp: [],
  isEdit: false,
  isClone: false,
  campaignName: "",
  activeStep: 0,
  advertiserStatus: "Active",
  campaignType: "",
  status: "Inactive",
  publisherName: "", //Same as adv name
  
  campaignNameError: '',
  campaignCSVData:[],
  lastUploadedCSVFileData:null,
  errorTrackURLmProgramID:[],
  campaignProgramCSVFileName:'',
  csvDataError:false,
  csvRadioSelected:'remove',
  isProgramCsvUploaded:false,
  adCopy: {
    adContent: {
      bulletInput1: "",
      bulletInput2: "",
      bulletInput3: "",
      previewList: [],
      trackingUrlError: '',
      startYearError: '',
      endYearError: '',
    },
    adDescription: {
      advertiserName: "",
      brandName: "",
      displayUrl: "",
      imageUrl: '',
      headContent: '',
      advertiserNameError: '',
      displayURLError: "",
      headContentError: ""

    },

  },
  value: {
    bidPrice: {
      price: "0",
      overall: "3000",
      unlimited: false,
      "capSettings": {
        "monthly": 0,
        "dayCap": {
          "sunday": 0,
          "saturday": 0,
          "tuesday": 0,
          "wednesday": 0,
          "thursday": 0,
          "friday": 0,
          "monday": 0
        },
        "weekly": 0,
        "daily": 0,
        "enableDayCap": false
      },
      "configTimeOfDay": {
        "Monday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        },
        "Thursday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        },
        "Friday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        },
        "Sunday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        },
        "Wednesday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        },
        "Tuesday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        },
        "Saturday": {
          "4AM": {
            "include": true
          },
          "5AM": {
            "include": true
          },
          "6AM": {
            "include": true
          },
          "7AM": {
            "include": true
          },
          "8AM": {
            "include": true
          },
          "9AM": {
            "include": true
          },
          "12PM": {
            "include": true
          },
          "11PM": {
            "include": true
          },
          "10PM": {
            "include": true
          },
          "1PM": {
            "include": true
          },
          "2PM": {
            "include": true
          },
          "3PM": {
            "include": true
          },
          "4PM": {
            "include": true
          },
          "5PM": {
            "include": true
          },
          "6PM": {
            "include": true
          },
          "7PM": {
            "include": true
          },
          "8PM": {
            "include": true
          },
          "9PM": {
            "include": true
          },
          "12AM": {
            "include": true
          },
          "11AM": {
            "include": true
          },
          "10AM": {
            "include": true
          },
          "1AM": {
            "include": true
          },
          "2AM": {
            "include": true
          },
          "3AM": {
            "include": true
          }
        }
      },
      "startDate": "",
      "timeOfDay": true
    },
    mPrograms: mProgramJson,
    excludeAdjustment: AudienceTargetingJson,
    excludePublishers: null,
    excludeStates: geoTargetingForPayload,
    scheduling: scheduling_jsonData,
    displayUrl: "",
    imageUrl: "",
    advertiserName: "",
    brandName: "",
    headContent: "",
    source1: "",
    source2: "",
    source: "",
    trackingUrl: "",
    stateRules: {
      allState: true
    },
    gradYear: {
      startYear: 2005,
      endYear: 2024,
      unknown: true,
    },
    status: true,
    zipRules: {
      files: null,
      filterType: "include",
      uploadType: "addupdateZip",
      zipCode: null
    },
    anyState:false,

  },
  requestPayload: {
    "campaignType": "",
    "campaignname": "",
    "values": {
      "advertiserName": "",
      "headline": "",
      "source": "",
      "displayUrl": "",
      "imageUrl": "",
      "status": true,
      "publisherName": "",
      "brand": "",
      "source1": "",
      "source2": ""
    },
    "status": true,
    "publisherID": "",
    "publisherName": ""
  },
  advertiserList: [],
  advertisers: [],
  pageLoading: false,
  getError: null,
  publisherLists: [],
  publisherCampaignslists: [],
  mediaSourceTabLoaderPublisher: true,
  mediaSourceTabLoaderPublisherCampaigns: true,
  mediaSourcePublisherLists: [],
  selectedPopUpAdv:0

}


export const createAdvCampaignSlice = createSlice({
  name: "test",
  initialState: INITIAL_STATE,
  reducers: {
    storeCampaignName: (state, action) => {
      state.campaignName = action.payload
    },
    storeAdvStatus: (state, action) => {
      if (action.payload) {
        state.advertiserStatus = 'Active'
        state.status = 'Active'
      } else {
        state.advertiserStatus = 'Inactive'
        state.status = 'Inactive'
      }
    },
    storeCampaignDisplayUrl: (state, action) => {
      state.adCopy.adDescription.displayUrl = action.payload
    },
    storeCampaignHeadContent: (state, action) => {
      state.adCopy.adDescription.headContent = action.payload
    },
    storeCampaignAdImageURL: (state, action) => {
      state.adCopy.adDescription.imageUrl = action.payload
    },
    storeCampaignAdImageFile: (state, action) => {
      state.adCopy.adDescription.imageUrl = action.payload
    },
    storeAdvertiserName: (state, action) => {
      const brandName: any = current(state).advertiserList.filter((item: any) => item.advName === action.payload);
      // console.log("Brand name", brandName)
      state.adCopy.adDescription.brandName = brandName[0].brand
      state.adCopy.adDescription.advertiserName = brandName[0].advName
      //  Add brand name here from advertiser list 
    },

    storeSelectedAdvertiser: (state, action) => {
      const advertiserProp: any = current(state).advertisers.filter((item: any) => item.advertiserID === action.payload);

      const advertiserList: any = current(state).advertiserList.filter((item: any) => item.advertiserID === action.payload);
      //  console.log("advertiserList::::", advertiserList,advertiserProp)
      state.adCopy.adDescription.advertiserName = advertiserList[0].advertiserID
      state.adCopy.adDescription.brandName = advertiserList[0].brandName
      state.adCopy.adDescription.imageUrl = advertiserProp[0].primaryLogo

      state.selectedAdvertiser = advertiserList
      state.advertiserProp = advertiserProp
    },

    storeSelectedAdvertiserForEditOperation: (state, action) => {
      // console.log("advertiserList:::: for Edit",current(state).advertisers)

      // console.log("Payload for Edit",action.payload)

      if (current(state).advertisers.length > 0) {
        const advertiserList: any = current(state).advertisers.filter((item: any) => item.SORTID === action.payload);
        // console.log("advertiserList::::", advertiserList)
        state.adCopy.adDescription.advertiserName = advertiserList[0].advertiserID
        if (advertiserList[0].brand) {
          state.adCopy.adDescription.brandName = advertiserList[0].brand
        } else {
          const brandname = current(state).value.brandName
          state.adCopy.adDescription.brandName = brandname
        }


      }
      // state.selectedAdvertiser = advertiserList
    },
    storeBulletInput1Value: (state, action) => {
      state.adCopy.adContent.bulletInput1 = action.payload

    },
    storeBulletInput2Value: (state, action) => {
      state.adCopy.adContent.bulletInput2 = action.payload

    },
    storeBulletInput3Value: (state, action) => {
      state.adCopy.adContent.bulletInput3 = action.payload

    },
    storeTrackingUrlValue: (state, action) => {
      state.value.trackingUrl = action.payload;
    },
    storePreviewList: (state, action) => {
      //   const {bulletInput1, bulletInput2, bulletInput3}=state.adCopy.adContent
      //  const previewLists=[bulletInput1, bulletInput2, bulletInput3].filter((value)=>value.trim()!== "");
      //  console.log("previewLists", previewLists)
      state.adCopy.adContent.previewList = action.payload
    },
    increaseActiveStep: (state, action) => {
      const prevActiveStep = state.activeStep;
      // console.log("prev Active step on incree", prevActiveStep)
      state.activeStep = prevActiveStep + 1
    },
    decreaseActiveStep: (state, action) => {
      const prevActiveStep = state.activeStep;
      // console.log(" prev Active step on decree", prevActiveStep)
      state.activeStep = prevActiveStep - 1
    },
    updateStepsBasedOnClick: (state, action) => {
      state.activeStep = action.payload;
    },
    resetActiveStep: (state, action) => {
      state.activeStep = 0
    },

    storeCampaignNameError: (state, action) => {
      state.campaignNameError = action.payload
    },
    storeAdvertiserNameError: (state, action) => {
      state.adCopy.adDescription.advertiserNameError = action.payload
    },
    storeHeadContentError: (state, action) => {
      state.adCopy.adDescription.headContentError = action.payload
    },
    storeDisplayURLError: (state, action) => {
      state.adCopy.adDescription.displayURLError = action.payload
    },
    storeTrackingURLError: (state, action) => {
      state.adCopy.adContent.trackingUrlError = action.payload;
    },
    storeStartYearError: (state, action) => {
      state.adCopy.adContent.startYearError = action.payload;
    },
    storeEndYearError: (state, action) => {
      state.adCopy.adContent.endYearError = action.payload;
    },
    cleanTrackingURLError: (state, action) => {
      state.adCopy.adContent.trackingUrlError = '';
    },
    cleanCampaignNameError: (state, action) => {
      state.campaignNameError = ''
    },
    cleanAdvertiserNameError: (state, action) => {
      state.adCopy.adDescription.advertiserNameError = ''
    },
    cleanHeadContentError: (state, action) => {
      state.adCopy.adDescription.headContentError = ''
    },
    cleanDisplayURLError: (state, action) => {
      state.adCopy.adDescription.displayURLError = ""
    },
    updateMPrograms: (state, action) => {
      const { payload } = action;
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          state.value.mPrograms[key] = payload[key];
        }
      }
      // console.log("action.payload", action.payload)
      // const mPrograms=state.value.mPrograms;

      // replaceKeyValues(mPrograms, action.payload)
      // console.log("mProgramin slice",mPrograms )
    },
    updateAudienceTargeting: (state, action) => {
      const categoryId = action.payload[0].categoryId;
      state.value.excludeAdjustment[categoryId] = action.payload
    },
    updateGradYear: (state, action) => {
      const { type, value } = action.payload;

      if (type === 'startYear') {
        state.value.gradYear.startYear = value
      }
      if (type === 'endYear') {
        state.value.gradYear.endYear = value
      }

      if (type === 'unknown') {
        state.value.gradYear.unknown = value
      }
    },
    updateExcludePublisher: (state, action,) => {
      //  cons
      const publisherID = action.payload.data[0].publisherId


      const campaignObject: any = {

      }
      const transformedObject: TransformedObject = {};
      action.payload.data.forEach((item: any, index: number) => {
        const key = item.SORTID ? item.SORTID : item.title.split(' - ')[1].trim(); // You may adjust the key generation logic as needed
        transformedObject[key] = {
          publisherId: item.publisherId,
          bid: item.bid,
          title: item.title.split(' - ')[0], // Extracting the title part after the hyphen
          enable: item.enable
        };
      });
      // console.log("campaings for  excludePublisher",transformedObject)
      state.value.excludePublishers[publisherID].newCampaigns = action.payload.data
      state.value.excludePublishers[publisherID].campaigns = transformedObject
      if (action.payload.allSelected) {
        state.value.excludePublishers[publisherID].enable = true;
      } else {
        state.value.excludePublishers[publisherID].enable = false;
      }
    },
    updateGeoTargeting: (state, action) => {
      state.value.excludeStates = action.payload
    },
    updateScheduling: (state, action) => {
      state.value.scheduling = action.payload
    },
    storeBidOverallPrice: (state, action) => {
      const { type, value } = action.payload;
      if (type === 'bidPrice') {
        state.value.bidPrice.price = value
      } else {
        state.value.bidPrice.overall = value
      }

    },
    resetState: (state, action) => {
      // state=INITIAL_STATE
      return INITIAL_STATE
    },

    storeCampaignCSVData:(state, action)=>{
        state.campaignCSVData=action.payload
        state.lastUploadedCSVFileData=[]
    },
    storeErrorTrackURLmProgramId:(state, action)=>{
      state.errorTrackURLmProgramID=action.payload;
         },

         storeProgramCSVFileName:(state, action)=>{
          state.campaignProgramCSVFileName=action.payload;
          state.isProgramCsvUploaded=true
             },
    updateStateForCloneAction: (state, action) => {
      //  const newState=state;
      // console.log("action.payload", action.payload);
      // console.log("current state ", current(state))
      const { payload } = action;
      state.activeStep = 0
      state.isClone = true;
      state.campaignName = payload.campaignname;
      state.ID = payload.ID;
      state.publisherName = payload.publisherName;
      state.SORTID = payload.SORTID;
      state.GroupName = payload.GroupName;
      state.type = payload.type
      state.mPubID = payload.mPubID ? payload.mPubID : payload.publisherID
      state.advertiserStatus = payload.advertiserStatus;
      state.status = payload.status;
      state.createdOn = payload.createdOn
      state.updatedOn = payload.updatedOn;
      state.campaignType = payload.campaignType
      // state.advertiser=payload.advertiser
      state.status = payload.status
      // state.adCopy.adDescription.advertiserName = ''
      // state.adCopy.adDescription.brandName =''
      state.adCopy.adDescription.displayUrl = payload.values.displayUrl
      state.adCopy.adDescription.imageUrl = payload.values.imageUrl
      state.adCopy.adDescription.headContent = payload.values.headline
      state.adCopy.adContent.bulletInput1 = payload.values.source
      state.adCopy.adContent.bulletInput2 = payload.values.source1
      state.adCopy.adContent.bulletInput3 = payload.values.source2
      state.value.trackingUrl = payload.values.trackingUrl

      state.selectedAdvertiser.push(payload.advertiser)
      state.advertiserProp.push(payload.advertiser)
      // console.log("value of data ", payload.values)
      if (payload.values.mProgram) {
        const mProgram = addProgramValue(payload.values.mProgram)
        state.value.mPrograms = mProgram
      };
      if (payload.values.excludeAdjustment) {
        const excludeAdjustment = mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps(payload.values.excludeAdjustment, AudienceTargetingJson)
        state.value.excludeAdjustment = excludeAdjustment
        // console.log("EXCLUDE ADJUSTMENT", excludeAdjustment)
      };
      if (payload.values.excludeStates) {
        state.value.excludeStates = payload.values.excludeStates
      };
      if (payload.values.excludePublishers) {
        const updatedPublishers = addNewCampaigns(payload.values.excludePublishers)
        //  console.log("Updated Publishers", updatedPublishers) 
        state.value.excludePublishers = updatedPublishers
      };
      if (payload.values.bidPrice) {
        state.value.bidPrice = { ...payload.values.bidPrice }
        state.value.scheduling = payload.values.bidPrice.configTimeOfDay
      } else {
        state.value.bidPrice = null
      }
      if (payload.values.configTimeOfDay) {
        state.value.scheduling = payload.values.configTimeOfDay
      }

      if (payload.values.zipRules) {
        state.value.zipRules = payload.values.zipRules
      }
      if (payload.values.gradYear) {
        state.value.gradYear = payload.values.gradYear
      }
      state.value.stateRules = payload.values.stateRules;
      state.value.imageUrl = payload.values.imageUrl
      state.adCopy.adDescription.imageUrl = payload.values.imageUrl
      // state.value.headline=payload.values.headline
      state.adCopy.adDescription.brandName = payload.values.brand
      // console.log("Payload brand edit", payload.values.brand)
      state.value.brandName = payload.values.brand
      if(payload.values.programCSVConfig){
        state.campaignCSVData=payload.values.programCSVConfig.programCSVData;
        state.campaignProgramCSVFileName=payload.values.programCSVConfig.csvFileName;
        state.csvDataError=payload.values.programCSVConfig.csvDataError
        state.lastUploadedCSVFileData=payload.values.programCSVConfig.programCSVData

      }
    },

    // updateStateForTCPAEditAction: (state, action) => {
    //   const { payload } = action;
    //   state.value.anyState = payload.anyState
    //   // console.log("value of data ", payload.values)
    //   if (payload.excludeStates) {
    //     state.value.excludeStates = payload.excludeStates
    //   };

    //   if (payload.zipRules) {
    //     state.value.zipRules = payload.zipRules
    //   }
      
    //   state.value.stateRules = payload.stateRules;

    // },


    updateStateForEditAction: (state, action) => {
      //  const newState=state;
      // console.log("action.payload", action.payload);
      // console.log("current state ", current(state))
      const { payload } = action;
      state.activeStep = 0
      state.isEdit = true;
      state.campaignName = payload.campaignname;
      state.ID = payload.ID;
      state.publisherName = payload.publisherName;
      state.SORTID = payload.SORTID;
      state.GroupName = payload.GroupName;
      state.type = payload.type
      state.mPubID = payload.mPubID ? payload.mPubID : payload.publisherID
      state.advertiserStatus = payload.advertiserStatus;
      state.status = payload.status;
      state.createdOn = payload.createdOn
      state.updatedOn = payload.updatedOn;
      state.campaignType = payload.campaignType
      // state.advertiser=payload.advertiser
      state.status = payload.status
      // state.adCopy.adDescription.advertiserName = ''
      // state.adCopy.adDescription.brandName =''
      state.adCopy.adDescription.displayUrl = payload.values.displayUrl
      state.adCopy.adDescription.imageUrl = payload.values.imageUrl
      state.adCopy.adDescription.headContent = payload.values.headline
      state.adCopy.adContent.bulletInput1 = payload.values.source
      state.adCopy.adContent.bulletInput2 = payload.values.source1
      state.adCopy.adContent.bulletInput3 = payload.values.source2
      state.value.trackingUrl = payload.values.trackingUrl
      state.selectedAdvertiser.push(payload.advertiser)
      state.advertiserProp.push(payload.advertiser)
      state.value.anyState = payload.values.anyState
      // console.log("value of data ", payload.values)
      if (payload.values.mProgram) {
        const mProgram = addProgramValue(payload.values.mProgram)
        state.value.mPrograms = mProgram
      };
      if (payload.values.excludeAdjustment) {
        const excludeAdjustment = mapResponseForAudienceTargetingDataForEditOrCreateAdvCampaignsComponentProps(payload.values.excludeAdjustment, AudienceTargetingJson)
        state.value.excludeAdjustment = excludeAdjustment
        // console.log("EXCLUDE ADJUSTMENT", excludeAdjustment)
      };
      if (payload.values.excludeStates) {
        state.value.excludeStates = payload.values.excludeStates
      };
      if (payload.values.excludePublishers) {
        const updatedPublishers = addNewCampaigns(payload.values.excludePublishers)
        //  console.log("Updated Publishers", updatedPublishers) 
        state.value.excludePublishers = updatedPublishers
      };
      if (payload.values.bidPrice) {
        state.value.bidPrice = { ...payload.values.bidPrice }
        state.value.scheduling = payload.values.bidPrice.configTimeOfDay
      } else {
        state.value.bidPrice = null
      }
      if (payload.values.configTimeOfDay) {
        state.value.scheduling = payload.values.configTimeOfDay
      }

      if (payload.values.zipRules) {
        state.value.zipRules = payload.values.zipRules
      }
      if (payload.values.gradYear) {
        state.value.gradYear = payload.values.gradYear
      }

      if(payload.values.programCSVConfig){
        state.campaignCSVData=payload.values.programCSVConfig.programCSVData;
        state.campaignProgramCSVFileName=payload.values.programCSVConfig.csvFileName;
        state.csvDataError=payload.values.programCSVConfig.csvDataError
        const mProgram = addProgramValue(payload.values.mProgram)
        // console.log("mProgram", mProgram)
        // console.log("payload.values.mProgram",payload.values.mProgram)
        state.lastUploadedCSVFileData=mProgram
      }
      
      state.value.stateRules = payload.values.stateRules;
      state.value.imageUrl = payload.values.imageUrl
      state.adCopy.adDescription.imageUrl = payload.values.imageUrl
      // state.value.headline=payload.values.headline
      state.adCopy.adDescription.brandName = payload.values.brand
      // console.log("Payload brand edit", payload.values.brand)
      state.value.brandName = payload.values.brand

    },
    updateStateRules: (state, action) => {
      state.value.stateRules = action.payload
    },
    updateZipRules: (state, action) => {
      state.value.zipRules = action.payload
    },
    updateZipRulesFilterType: (state, action) => {
      state.value.zipRules.filterType = action.payload
    },
    anyStateToggle: (state:any) => {
      state.value.anyState = !state.value.anyState;  
    },

    programCsvRadioToggle:(state:any, action)=>{
  state.csvRadioSelected=action.payload
    },
     setSelectedPopUpAdv: (state, action) => {
      state.selectedPopUpAdv = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvDataAsync.pending, (state) => {
        state.pageLoading = true;
      })
      .addCase(fetchAdvDataAsync.fulfilled, (state, action) => {
        state.pageLoading = false
        state.advertisers = action.payload.result.data;
        state.advertiserList = action.payload.result.data.map((item: any, index: number) => {
          return {
            advName: item.advertiserName,
            brandName: item.brand,
            advertiserID: item.advertiserID,
            SORTID: item.SORTID,
            vertical: item.vertical
          }
        })
      })
      .addCase(fetchAdvDataAsync.rejected, (state, action) => {
        // state.loading = 'failed';
        // state.error = action.error.message || 'An error occurred';
        state.pageLoading = false;
        state.getError = action.error.message || 'An error occurred'
        // state.advertiserList = action.payload;
      })

      .addCase(fetchAllPublisherCampaignsDataAsync.pending, (state) => {
        // state.pageLoading = true;
        // console.log("TRack state PublisherCampaignsDataAsync")
        state.mediaSourceTabLoaderPublisherCampaigns = true
      })
      .addCase(fetchAllPublisherCampaignsDataAsync.fulfilled, (state, action) => {
        const currentState = current(state);
        state.publisherCampaignslists = action.payload.result.data;
        // console.log("current exlclude ",currentState.value.excludePublishers)

        if (!currentState.value.excludePublishers) {

          state.value.excludePublishers = generateExcludePublisherData(action.payload.result.data)
        }

        if (currentState.isEdit) {
          const testMediaSourceData = generateExcludePublisherData00(action.payload.result.data, currentState.value.excludePublishers);
          //  console.log("testMediaSourceData", testMediaSourceData)
          const removedDeletedCamps=removeDuplicateNewCampaigns(action.payload.result.data, testMediaSourceData)
          // console.log("removedDeletedCamps", removedDeletedCamps)
          state.value.excludePublishers = removedDeletedCamps
        }
        if (currentState.isClone) {
          const testMediaSourceData = generateExcludePublisherData00(action.payload.result.data, currentState.value.excludePublishers);
          //  console.log("testMediaSourceData", testMediaSourceData)
          const removedDeletedCamps=removeDuplicateNewCampaigns(action.payload.result.data, testMediaSourceData)
          // console.log("removedDeletedCamps", removedDeletedCamps)
          state.value.excludePublishers = removedDeletedCamps
        }
        
        state.mediaSourceTabLoaderPublisherCampaigns = false

      })
      .addCase(fetchAllPublisherCampaignsDataAsync.rejected, (state, action) => {
        // state.loading = 'failed';
        // state.error = action.error.message || 'An error occurred';
        state.mediaSourceTabLoaderPublisherCampaigns = true

        state.getError = action.error.message || 'An error occurred'
        // state.advertiserList = action.payload;
      })


      .addCase(fetchAllPublisherDataAsync.pending, (state) => {
        // state.pageLoading = true;
        // console.log("TRack state  PublisherDataAsync ")
        state.mediaSourceTabLoaderPublisher = true
      })
      .addCase(fetchAllPublisherDataAsync.fulfilled, (state, action) => {

        state.publisherLists = action.payload.result.data;

        state.mediaSourceTabLoaderPublisher = false

      })
      .addCase(fetchAllPublisherDataAsync.rejected, (state, action) => {

        state.mediaSourceTabLoaderPublisher = true
        state.getError = action.error.message || 'An error occurred'
        // state.advertiserList = action.payload;
      });



  },
})


export const {setSelectedPopUpAdv, updateGeoTargeting, storeCampaignName, storeErrorTrackURLmProgramId, updateScheduling, storeCampaignDisplayUrl, storeCampaignHeadContent, storeCampaignAdImageURL, storeCampaignAdImageFile, storeSelectedAdvertiser, storeAdvertiserName, storeBulletInput1Value, storeBulletInput2Value, storeBulletInput3Value, storePreviewList, increaseActiveStep, decreaseActiveStep, resetActiveStep, cleanAdvertiserNameError, cleanCampaignNameError, cleanDisplayURLError, cleanHeadContentError, storeAdvertiserNameError, storeCampaignNameError, storeDisplayURLError, storeHeadContentError, updateMPrograms, updateAudienceTargeting, updateExcludePublisher, storeTrackingUrlValue, storeTrackingURLError, resetState, updateStateForEditAction,updateStateForCloneAction, storeSelectedAdvertiserForEditOperation, updateStateRules, updateZipRules, updateZipRulesFilterType, storeAdvStatus, storeBidOverallPrice, updateGradYear, updateStepsBasedOnClick,anyStateToggle, storeCampaignCSVData, storeProgramCSVFileName,storeStartYearError,storeEndYearError, programCsvRadioToggle } =
  createAdvCampaignSlice.actions;
export const campaignFormSelector = (state: RootState) => state.CreateAdvCampaignReducer;
export default createAdvCampaignSlice.reducer;