import { ROLE } from '../../routes';
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";

 const PrivateRoutes = ({
  
  roles,
  
}: {
  
  roles: Array<ROLE>;
}) => {


  
  let location = useLocation();
  let localStorageItem=localStorage.getItem("userId")

//  const userHasRequiredRole = userRole && roles.map(role => role.toLowerCase()).includes(userRole.toLowerCase())
if (!localStorageItem) {
  return <Navigate to="/login" state={{ from: location }} />;
}

else {return localStorageItem ? (
  <Outlet />
) : (
  <Navigate to={"/login"} replace={true} />
);}
};


export default PrivateRoutes;