import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { RootState } from '../../Store';
import { FormHelperText, Modal, Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Paper, Typography } from '@mui/material';
import { handelChangePasswordModalOpen, updateCreateNewPassword, handelPasswordError, handelConfirmPasswordError, clearNewPasswordModalValue, handelUserSliceReset } from '../../Store/Slices/Auth/userSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Auth } from 'aws-amplify';
import SnackbarMessage from '../Snackbar';
import { updateUserService } from '../../service/User';
// import { LoadingButton } from '@mui/lab';

const style1 = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px',
  bgcolor: '#E1E4E9',
  // border: '3px solid #00AAE3',
  boxShadow: 24,
  p: 4,
}
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

interface IcreatePassword {
  resetHandler: () => void;
}
const CreatePassword: React.FC<IcreatePassword> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.userAuthData);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const handleClickShowConfirmPassword = () => setShowPasswordConfirm((show) => !show);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handelnewPasswordInput = (e: any) => {
    const { name, value } = e.target
    if (name === 'newPassword') {
      if (!passwordRegex.test(value)) {
        dispatch(handelPasswordError("Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"))
      } else {
        dispatch(handelPasswordError(""))
      }
      dispatch(updateCreateNewPassword({
        type: "newPassword",
        value: value
      }))

    }
  }

  const handleConfirmPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const { newPassword } = userState.createNewPassword
    if (newPassword === value) {
      dispatch(updateCreateNewPassword({
        type: "confirmPassword",
        value: value
      }))
      dispatch(handelConfirmPasswordError(""))
    } else {
      dispatch(handelConfirmPasswordError("Password not matched"))
    }

  }

  const updateUser=async(user:any)=>{
    // console.log("user", user)

    const userAttributes=user.challengeParam.userAttributes;
    // console.log("userAttributes", userAttributes)
    const requestPayload={
      "Username": user.username
      ,
      "UserAttributes": [
          {
            Name: "email_verified",
            Value: "true",
          },
          {
            Name: "email",
            Value: userAttributes.email,
          },
          {
            Name: "custom:first_name",
            Value: userAttributes["custom:first_name"]
          },
          {
            Name: "custom:last_name",
            Value: userAttributes["custom:last_name"]?userAttributes["custom:last_name"]:"",
          },
          {
            Name: "custom:group",
            Value: userAttributes["custom:group"]?userAttributes["custom:group"]:"",
          },
          // {
          //   Name: "custom:groupType",
          //   Value: userAttributes["custom:groupType"]
          // },
          {
            Name: "name",
            Value: userAttributes["name"]?userAttributes["name"]:"",
          },
      ]
    }

   
    
    // console.log("Req payload", requestPayload)
    
    // 
    return await updateUserService(requestPayload)
  }
  const handelCompletePassword = () => {
    const user = userState.user;
    const email = user.challengeParam.userAttributes.email
    const { newPassword, confirmPassword } = userState.createNewPassword

    if ((userState.confirmPasswordError.length > 1) || (userState.createPasswordError.length > 1)) {
      return
    }


    if (newPassword && confirmPassword){
      if (newPassword === confirmPassword) {
        Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword, // the new password
          { name: email } // TO DO - NAME, has to be set to user's
        ).then((user) => {
          // console.log("user ", user)
          updateUser(user).then((res)=>{
            // console.log("res", res)
            setSnackbarOpen(true)
            setSnackbarType('success')
            setSnackbarMessage("Updated !!")
            props.resetHandler();
            dispatch(handelUserSliceReset(""))
            dispatch(handelChangePasswordModalOpen(false))
            dispatch(clearNewPasswordModalValue("clear"))
          })
          // props.resetHandler();
          // setSnackbarOpen(true)
          // setSnackbarType('success')
          // setSnackbarMessage("Updated !!")
          // dispatch(handelChangePasswordModalOpen(false))
  
        }).catch((e) => {
          console.log("e0", e);
          setSnackbarOpen(true)
          setSnackbarType('error')
          setSnackbarMessage(e.message)
  
        })
      } else {
        dispatch(handelConfirmPasswordError("Password not matched"))
      }
    }else{
      if(newPassword && !confirmPassword){
        dispatch(handelConfirmPasswordError("Please enter confirm password"))
        if (!passwordRegex.test(newPassword)) {
          dispatch(handelPasswordError("Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"))
        }
      }

      if(!newPassword && confirmPassword){
        // dispatch(handelConfirmPasswordError("Please enter confirm password"))
        dispatch(handelPasswordError("Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"))
      }
      dispatch(handelConfirmPasswordError("Please enter confirm password"))
      dispatch(handelPasswordError("Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"))
    }
   
  }

  return (
    <>
      <Modal
         open={userState.openChangePasswordModal}
        //  open={true}
        onClose={() => {
          dispatch(handelChangePasswordModalOpen(false));
          props.resetHandler();
          dispatch(clearNewPasswordModalValue("clear"))
          dispatch(handelPasswordError(""))
          dispatch(handelConfirmPasswordError(""))
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Paper elevation={3} className="createPassword-p" sx={style1}>
          <Box sx={{marginBottom:"15px"}}>
            <Typography className="card-heading">
              Create new password
            </Typography>
          
          </Box>
          <Box  className='password-form'>
            <FormControl sx={{ width: "100%", marginBottom:"21px"}} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password*</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                className='password-text-input'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Change Password"
                // onChange={(event)=>{dispatch(handelNewPassword(event.target.value))}}
                onChange={handelnewPasswordInput}
                // error={passwordError.length>0}
                value={userState.createNewPassword.newPassword}
                error={userState.createPasswordError.length > 1}
                name='newPassword'
              />
              {
                userState.createPasswordError.length > 1 ? <FormHelperText sx={{ color: "red" }}>{userState.createPasswordError}</FormHelperText>: <FormHelperText>"Required"</FormHelperText>
              }
             
            </FormControl>
            <FormControl sx={{  width: "100%", marginTop: userState.createPasswordError.length > 1 ? '28px' : '0px' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password*</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPasswordConfirm ? 'text' : 'password'}
                className='password-text-input'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                // onChange={(event)=>{dispatch(handelConfirmPassword(event.target.value))}}
                error={userState.confirmPasswordError.length > 1}
                name='confirmPassword'
                onChange={handleConfirmPasswordInput}
              />
              {/* {
                userState.confirmPasswordError.length > 1 && <FormHelperText sx={{ color: "red" }}>{userState.confirmPasswordError}</FormHelperText>
              } */}
              {
                userState.confirmPasswordError.length > 1 ? <FormHelperText sx={{ color: "red" }}>{userState.confirmPasswordError}</FormHelperText>: <FormHelperText>"Required"</FormHelperText>
              }
              {/* <FormHelperText>"Required"</FormHelperText> */}
            </FormControl>
            <Box className='btn_container' sx={{ display: "flex", justifyContent: "flex-end" }} >
              {/* <LoadingButton 
        //   onClick={handelChangePassword} loadingPosition="center" 
        //   loading={loading}
          >Change password</LoadingButton> */}

              <Button className="forgot-password__button-base" onClick={handelCompletePassword} >Continue</Button>

            </Box>
          </Box>
        </Paper>
      </Modal>
      <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false); }} type={snackbarType} message={snackbarMessage} />
    </>



  )
}

export default CreatePassword