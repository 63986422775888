import MediaSourceTabContent from "../View/Advertiser/Stepper Form/Step components/MediaSourceTabContent";
import ConfigureMsTabContentWrapper, { ConfigureMsTabContent, } from "../View/Publisher/Configure Media Source/ConfigureMsTabContent";
import { IProgramUIData } from "./ProgramTargetingData";
import stagingPublisherList from './stagingPublisherList.json';
const demoObj:IProgramUIData={
    label:"",
     content:MediaSourceTabContent,
    //  content: MediaSourceTabContentWrapper
    value:""
}


const configureMediaSource:IProgramUIData={
  label:"",
   content:ConfigureMsTabContentWrapper,
  //  content: MediaSourceTabContentWrapper
  value:""
}

let mediaSourceData:IProgramUIData[] | []=[]


export const generateMediaSourceData=()=>{
 mediaSourceData =stagingPublisherList.map((publisher)=>{
           const object={...demoObj};
           return {
            ...object,
             label:publisher.GroupName,
             value:publisher.mPubID
           }
 })

 return mediaSourceData
}



export const generateMediaSourceDataForUI=(publisherList:any[] | any)=>{
  const mediaSourceData :IProgramUIData[] | []=publisherList.map((publisher:any)=>{
            const object={...demoObj};
            return {
             ...object,
              label:publisher.publisherName?publisher.publisherName:publisher?.values?.companyName,
              value:publisher.mPubID
            }
  })
 
  return mediaSourceData
 }
 
 
 
 export const generateMediaSourceCampaigns=(publisherList:any[] | any)=>{
  const mediaSourceData :IProgramUIData[] | []=publisherList.map((publisher:any)=>{
            const object={...configureMediaSource};
            return {
             ...object,
              label:publisher.advertiserName
              ?publisher.advertiserName:publisher?.values?.companyName,
              value:publisher.mPubID
            }
  })
 
  return mediaSourceData
 }
 


 export const removeDuplicateCampaigns=(advertiserCampaigns: any[], existingExcludeAdvertiser: any)=>{
  const excludePublisher: any = {
    // Your data object goes here...
    ...existingExcludeAdvertiser
  };

  advertiserCampaigns.forEach((campaigns: any) => {
  if( excludePublisher.hasOwnProperty(campaigns?.publisherID)){
    const newCampaigns=excludePublisher[campaigns?.publisherID].campaigns
    let filteredByAdvertiserCampsData = advertiserCampaigns.filter((pc) => {
      return pc.publisherID === campaigns.publisherID
    });
    const campaignIdsFromArray2 = filteredByAdvertiserCampsData.map(item => item.campaignID);
    const filteredArray1 = newCampaigns.filter((item:any) => campaignIdsFromArray2.includes(item.SORTID));
    //  console.log("NEW CAMP IN REMOVE FUNC,", newCampaigns)
    // console.log("CAMPAIGN by PUB IN REMOVE FUNC,", filteredByAdvertiserCampsData)
    //  console.log("update after remove CAMP IN REMOVE FUNC,",filteredArray1)

    const newEXLPublisher={
      ...excludePublisher[campaigns?.publisherID]
    }
    newEXLPublisher.campaigns=filteredArray1;
    excludePublisher[campaigns.publisherID] = newEXLPublisher
  } 

  })
 // Extract unique publisher IDs from advertiserCampaigns
 const publisherIdsToCheck = [...new Set(advertiserCampaigns.map(campaign => campaign.publisherID))];
 console.log("publisherIdsToCheck",publisherIdsToCheck )
 // Extract publisher IDs from excludePublisher
 const existingPublisherIds = Object.keys(excludePublisher);
console.log("existing pub id on expub",existingPublisherIds )
 // Find the IDs that do not exist in the excludePublisher
//  const nonExistentIds = publisherIdsToCheck.filter(id => !existingPublisherIds.includes(id));
 const missingIds = existingPublisherIds.filter(id => !publisherIdsToCheck.includes(id));
 // Output the non-existent IDs
 console.log("Non-existent publisher IDs:",missingIds);

  missingIds.forEach((pubID)=>{
    const newEXLPublisher={
      ...excludePublisher[pubID]
    }
    newEXLPublisher.campaigns=[]
    excludePublisher[pubID] = newEXLPublisher
  })


  return excludePublisher;
 }

 export const removeDeletedCampaigns=(advertiserCampaigns: any[], existingExcludeAdvertiser: any)=>{
  const excludePublisher: any = {
    // Your data object goes here...
    ...existingExcludeAdvertiser
  };


  const pubKeys=Object.keys(excludePublisher);
  console.log("pub keys", pubKeys)
  // advertiserCampaigns.forEach((campaigns: any) => {
  // if( excludePublisher.hasOwnProperty(campaigns?.publisherID)){
  //   const newCampaigns=excludePublisher[campaigns?.publisherID].campaigns
  //   let filteredByAdvertiserCampsData = advertiserCampaigns.filter((pc) => {
  //     return pc.publisherID === campaigns.publisherID
  //   });
  //   const campaignIdsFromArray2 = filteredByAdvertiserCampsData.map(item => item.campaignID);
  //   const filteredArray1 = newCampaigns.filter((item:any) => campaignIdsFromArray2.includes(item.SORTID));
  //    console.log("NEW CAMP IN REMOVE FUNC,", newCampaigns)
  //   // console.log("CAMPAIGN by PUB IN REMOVE FUNC,", filteredByAdvertiserCampsData)
  //    console.log("update after remove CAMP IN REMOVE FUNC,",filteredArray1)

  //   const newEXLPublisher={
  //     ...excludePublisher[campaigns?.publisherID]
  //   }
  //   newEXLPublisher.campaigns=filteredArray1;
  //   excludePublisher[campaigns.publisherID] = newEXLPublisher
  // } 

  // })

  return excludePublisher;
 }


 export function extractAdvertiserName(inputString:string) {
  const prefix = 'ADV-';
  const suffixPattern = /-ADM-(\d+)$/;

  // Remove the prefix
  let withoutPrefix = inputString.startsWith(prefix) ? inputString.slice(prefix.length) : inputString;

  // Extract the suffix number using the pattern
  const match = withoutPrefix.match(suffixPattern);
  let numberPart = '';
  if (match) {
    numberPart = `-${match[1]}`;
    withoutPrefix = withoutPrefix.replace(suffixPattern, '');
  }

  // Combine the advertiser name and the number part
  const advertiserName = withoutPrefix + numberPart;

  return advertiserName;
}
