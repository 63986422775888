// import { Auth } from 'aws-amplify'
// import { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
// import { NoUserRoutes, ROLE, mmRoutes, publisherRoutes } from './routes';
import PrivateRoutes from './Component/Auth/PrivateRoutes';
import { redirect, Route, Routes } from "react-router-dom";
import { Login1 } from "./Component/Auth/Login1";
import { ForgotPassword } from "./Component/Auth/Reset Password/ResetPassword";
import ResponsiveDrawerDemo from './Component/MMDrawer';
import theme from './MM_Theme';
import './App.scss';
import './App.css';
import { NoUserRoutes, ROLE, advertiserRoutes, mmRoutes, publisherRoutes } from './routes';
import pubCamp from './Mock/publisherCampaigns.json'
import { generateExcludePublisherData00 } from './Store/Slices/CreateAdvCampaignSlice';
function App() {
  let userRole = localStorage.getItem("userRole")
//   // let userRole = ''
//   const data = generateExcludePublisherData00(pubCamp,ep)
// console.log("data", data)
  return (
    <ThemeProvider theme={theme}>

      <div className="App">
        <Routes>
          <Route path="/login" element={<Login1 />} />
          <Route path="/reset-password" element={<ForgotPassword />} />

          {(userRole === 'admin' || userRole === 'Super-Admin') && (<Route element={<PrivateRoutes roles={[ROLE.admin]} />}>
            {mmRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={
                <ResponsiveDrawerDemo>
                  <route.element />
                </ResponsiveDrawerDemo>
              } />
            })}
          </Route>)}
          {userRole == 'Publisher' && (<Route element={<PrivateRoutes roles={[ROLE.publisher]} />}>
            {publisherRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={
                <ResponsiveDrawerDemo>
                  <route.element />
                </ResponsiveDrawerDemo>
              } />
            })}
          </Route>)}
          {userRole === 'Vendor' && (<Route element={<PrivateRoutes roles={[ROLE.advertiser]} />}>
            {advertiserRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={
                <ResponsiveDrawerDemo>
                  <route.element />
                </ResponsiveDrawerDemo>
              } />
            })}
          </Route>)}

          {!userRole && (<Route element={<PrivateRoutes roles={[]} />}>
            {NoUserRoutes.map((route:any, index:number) => {
              return <Route key={index} path={route.path} element={

                <route.element />


              } />
            })}
          </Route>)} 

          {/* <Route
        path="user-dashboard"
        element={
          <PrivateRoute roles={[ROLE.User]}>
            <UserDashboard />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>

    </ThemeProvider>
  );
};
export default App;


