import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RootState } from '../../Store/index'; // Update the path to your Redux store
import './PublisherList.css'
import { Button, Checkbox, FormGroup, Paper, Snackbar, TableCell, Tooltip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import MMTable from '../../Component/Table/MMTable';
import { publiAccountHeadCells } from '../../Component/Table/tableInterface';
import Search from '../../Component/Search/Search';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { toggleReportingEmail, setField, toggleStatus, resetForm, setEditFlag, toggleVertical } from '../../Store/Slices/CreatePublisherSlice';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from "../../Store/hooks"
import { setData, setLoading, setCurrentCursor, setHasMoreData, appendData, setFlag, setFullData, updatePublisherAsync, createPublisherAsync } from '../../Store/Slices/PublisherTabApisData';
import { fetchCompApiData, fetchPublisherApiData, searchPublisherAccount } from '../../Services/publisherTabApis';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteIconPop } from '../Advertiser/Action';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { setBlockScroll, setIsoLoading, setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData';

const PublisherAccount = () => {
  return (
    <PublisherDashboard />
  )
}

export default PublisherAccount

const PublisherDashboard = () => {
  const dispatch = useAppDispatch();
  const publisherTableData = useAppSelector((state) => state.publisherAccApi.data);
  const compApiData = useAppSelector((state) => state.publisherAccApi.compApiData);
  const isLoading = useAppSelector((state) => state.publisherAccApi.loading);
  const currentCursor = useAppSelector((state) => state.publisherAccApi.currentCursor);
  const hasMoreData = useAppSelector((state) => state.publisherAccApi.hasMoreData);
  const [openPopup, setOpenPopup] = React.useState(false);
  // const [searchInput, setSearchInput] = useState<string>('');
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  useEffect(() => {
    dispatch(setFlag(false));

    if (!searchInput) {
      dispatch(setBlockScroll(false));
      dispatch(setSearchInput(''));
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      setDebouncedInputValue('');
      fetchInitialData();
    } else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput]);

  useEffect(() => {
    dispatch(setSearchFilterData([]));
    // dispatch(setSearchInput(''));
    fetchCompApiData(dispatch);
  }, [])

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      // searchAdvAccount(debouncedInputValue, dispatch, 'advertiser')
      searchPublisherAccount(debouncedInputValue, dispatch, 'publisher')
    }
  }, [debouncedInputValue])

  const fetchInitialData = () => {
    fetchPublisherApiData(dispatch, null).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData) {
      dispatch(setFlag(true));
      fetchPublisherApiData(dispatch, currentCursor);
    }
  };

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("event", event.target.value)
    // setSearchInput(event.target.value)
    dispatch(setSearchInput(event.target.value));
  }

  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' }}}>
          <Box>
            <Typography className="card-heading">Publisher List</Typography>
            <Typography className="card-sub-heading">
              Publisher Summary
            </Typography>
          </Box>
          <Box  display="flex" gap="16px" sx={{flexDirection: { xs: 'column', sm: 'row' }, mt:{xs:'16px', md:'0'}, alignItems:{xs:'flex-start', md:'center'} }}>
            <Search value={searchInput} searchHandler={handelSearch} />
            <Button variant="contained" 
              startIcon={<AddIcon />}
              onClick={() => { setOpenPopup(true); dispatch(setEditFlag(false)) }}
              sx={{backgroundColor:"#0ae"}}
              >
              Create Publisher
            </Button>
          </Box>
        </Box>
        <MMTable tableData={publisherTableData} tableHeadCell={publiAccountHeadCells} isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()} hasMoreData={hasMoreData} fetchInitialData={fetchInitialData}
        />
      </Paper>
      <CreatePublisher
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialData}
      />
    </React.Fragment>
  )
}


export const CreatePublisher: React.FC<any> = (props) => {

  const { openPopup, setOpenPopup, advDialHeader, setAdvDialHeader, fetchInitialData, rowData } = props;
  const [nameCharacterCount, setNameCharacterCount] = useState(0);
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);
  const [addressCharacterCount, setAddressCharacterCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [publisherNameError, setPublisherNameError] = useState(false);
  const [publisherEmailError, setPublisherEmailError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const fullData = useAppSelector((state) => state.publisherAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);

  const [emailTouched, setEmailTouched] = useState(false);


  const dispatch = useAppDispatch();
  const publisherState = useSelector((state: RootState) => state.publisher);

  const toggleReportingEmailHandler = () => {
    dispatch(toggleReportingEmail());

  };


  const handleFieldChange = useCallback((field: keyof typeof publisherState, value: string) => {
    let newValue = value;

    switch (field) {
      case 'publisherName':
        newValue = value.slice(0, 100); // Limit input to 100 characters
        setNameCharacterCount(newValue.length);
        break;
      case 'companyDescription':
        newValue = value.slice(0, 400); // Limit input to 200 characters
        setDescriptionCharacterCount(newValue.length);
        break;
      case 'address':
        newValue = value.slice(0, 300); // Limit input to 150 characters
        setAddressCharacterCount(newValue.length);
        break;
      // ... (other cases)
      default:
        break;
    }
    dispatch(setField({ field, value: newValue }));
  }, [dispatch]);

  const isPublisherNameExists = fullData.some(
    (data: any) => data.publisherName.trim() === publisherState.publisherName.trim()
  );

  const handleCreatePublisher = async () => {
    setSuccessMessage('');
    setSuccessMessage('');
    if (!publisherState.publisherName.trim() || ((publisherState.enablePublisherEmail == true) && (!publisherState.publisherEmail.trim()))) {
      setPublisherNameError(!publisherState.publisherName.trim());
      if (publisherState.enablePublisherEmail == true) {
        setPublisherEmailError(!publisherState.publisherEmail.trim());
      }
    } else if (publisherState.publisherName.includes(',')) {
      setErrorMessage('Comma is not allowed in Publisher Name');
      setSnackbarOpen(true);
      return;
    } else {
      if (isPublisherNameExists && publisherState.editFlag == false) {
        setErrorMessage('Publisher with this name already exists');
        setSnackbarOpen(true);
        return;
      }

      setOpenPopup(false);
      dispatch(setIsoLoading(true));
      setPublisherNameError(false);
      setPublisherEmailError(false);

      const payload = {
        GroupName: publisherState.publisherName,
        status: publisherState.status,
        type: 'Publisher',
        values: {
          address: publisherState.address,
          // city: publisherState.city,
          // companyDescription: publisherState.companyDescription,
          companyName: publisherState.publisherName,
          enablePublisherEmail: publisherState.enablePublisherEmail,
          // phNo: publisherState.phNo,
          // primaryContact: publisherState.primaryContact,
          publisherEmail: publisherState.publisherEmail,
          // state: publisherState.state,
          // website: publisherState.website,
          // vertical:publisherState.vertical,
        },
      };

      let requestData
      if (rowData) {
        requestData = {
          GroupName: publisherState.publisherName,
          ID: rowData.ID,
          SORTID: rowData.SORTID,
          createdOn: rowData.createdOn,
          mPubID: rowData.mPubID,
          status: publisherState.status,
          type: 'Publisher',
          values: {
            excludeMpartners: rowData.values.excludeMpartners ? rowData.values.excludeMpartners : {},
            address: publisherState.address,
            // city: publisherState.city,
            // companyDescription: publisherState.companyDescription,
            companyName: publisherState.publisherName,
            enablePublisherEmail: publisherState.enablePublisherEmail,
            // phNo: publisherState.phNo,
            // primaryContact: publisherState.primaryContact,
            publisherEmail: publisherState.publisherEmail,
            // state: publisherState.state,
            // website: publisherState.website,
            // vertical:publisherState.vertical,
          },
        };
      }
      try {

        if (publisherState.editFlag) {
          try {
            // For editing an existing record, send a PUT request with the ID
            await dispatch(updatePublisherAsync(requestData));
            setSuccessMessage('Publisher Updated Successfully');
          } catch (error) {
            console.error('Error updating Publisher:', error);
          }

        } else {
          try {
            // For creating a new record, send a POST request
            await dispatch(createPublisherAsync(payload));
            setSuccessMessage('Publisher Created Successfully');
          } catch (error) {
            console.error('Error Creating Publisher:', error);
          }
        }

        dispatch(setFlag(false));
        setSnackbarOpen(true);
        // fetchInitialData();
        fetchCompApiData(dispatch);
        if (searchInput !== '' && searchInput !== null) {
          searchPublisherAccount(searchInput, dispatch, 'publisher')
          }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create publisher');
        setSnackbarOpen(false);
      }
      dispatch(resetForm());
      // //console.log(publisherState);
      setAddressCharacterCount(0);
      setDescriptionCharacterCount(0);
      setNameCharacterCount(0);
      dispatch(setIsoLoading(false));
      // dispatch(setSearchInput(''));
    }
  };

  const cancelButton = () => {
    setOpenPopup(false);
    dispatch(resetForm());
    setPublisherNameError(false);
    setPublisherEmailError(false);
    setAddressCharacterCount(0);
    setDescriptionCharacterCount(0);
    setNameCharacterCount(0);
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const isValidEmail = (email: any) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCheckboxChange = (value: string) => {
    dispatch(toggleVertical(value)); // Dispatch the Redux action
  };

  return (
    <div>
      <Dialog open={openPopup} BackdropProps={{ style: advDialHeader ? { opacity: '0.2' } : { opacity: '1' } }}>
        <DialogTitle>{publisherState.editFlag ? "Update" : 'Create'} Publisher</DialogTitle>
        <div className="close-button" onClick={cancelButton}><CloseIcon style={{ fontSize: '32px' }} /></div>
        <DialogContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <TextField label="Publisher Name*" fullWidth
              disabled={publisherState.editFlag}
                value={publisherState.publisherName}
                onChange={(e) => {
                  setPublisherNameError(false);
                  handleFieldChange('publisherName', e.target.value)
                }}
                error={publisherNameError}
                helperText={publisherNameError ? 'Publisher Name is mandatory' : ''} />
              <div style={{ textAlign: 'right', marginTop: '4px', color: nameCharacterCount > 100 ? 'red' : 'inherit' }}>
                {nameCharacterCount}/100
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <TextField label="Reporting Email*" fullWidth
                value={publisherState.publisherEmail}
                onChange={(e) => {
                  setPublisherEmailError(false);
                  handleFieldChange('publisherEmail', e.target.value);
                  setEmailTouched(true);
                }}
                disabled={!publisherState.enablePublisherEmail}
                error={publisherEmailError}
                helperText={
                  publisherEmailError
                    ? 'mail is mandatory'
                    : publisherState.enablePublisherEmail &&
                      emailTouched &&
                      publisherState.publisherEmail.trim() !== '' &&
                      !isValidEmail(publisherState.publisherEmail)
                      ? (
                        <span style={{ color: 'red' }}>Invalid Email Format</span>
                      ) : ''
                }
              />
            </Grid>

            <Grid item xs={12} lg={5}>
              <FormControlLabel
                control={<Switch checked={publisherState.enablePublisherEmail} onChange={toggleReportingEmailHandler} />}
                style={{ marginTop: "10px" }}
                label={publisherState.enablePublisherEmail ? 'Enable Email' : 'Disable Email'}
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <TextField label="Publisher Address" fullWidth variant="outlined" rows={4} multiline
                value={publisherState.address}
                onChange={(e) => handleFieldChange('address', e.target.value)} />
              <div style={{ textAlign: 'right', marginTop: '4px', color: addressCharacterCount > 300 ? 'red' : 'inherit' }}>
                {addressCharacterCount}/300
              </div>
            </Grid>
            <Grid item xs={12} lg={5}>
            <FormGroup>
          <label style={{fontWeight:500}}>Verticals</label>
          <FormControlLabel
            control={
              <Checkbox
                checked={publisherState.vertical.includes("AUTO")}
                onChange={() => handleCheckboxChange("AUTO")}
              />
            }
            label="AUTO"
          />
           <FormControlLabel
            control={
              <Checkbox
                checked={publisherState.vertical.includes("EDU")}
                onChange={() => handleCheckboxChange("EDU")}
              />
            }
            label="EDU"
          />
        </FormGroup>
            </Grid>
            
            {/* <Grid item xs={12} lg={6}>
              <TextField label="Contact Person Name" fullWidth
                value={publisherState.primaryContact}
                onChange={(e) => handleFieldChange('primaryContact', e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField label="City" fullWidth
                value={publisherState.city}
                onChange={(e) => handleFieldChange('city', e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField label="Phone Number" fullWidth
                value={publisherState.phNo}
                onChange={(e) => handleFieldChange('phNo', e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField label="State" fullWidth
                value={publisherState.state}
                onChange={(e) => handleFieldChange('state', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Website" fullWidth
                value={publisherState.website}
                onChange={(e) => handleFieldChange('website', e.target.value)} />
            </Grid> */}
            <Grid item xs={4}>
              <FormControlLabel
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                control={<Switch checked={publisherState.status === 'Active'} onChange={() => dispatch(toggleStatus())} />}
              />
             
            </Grid>
          </Grid>
        </DialogContent >
        <DialogActions style={{ paddingBottom: "15px", paddingRight: "20px" }}>
          <Button onClick={cancelButton} style={{ color: 'red' }}>CANCEL</Button>
          <Button variant="contained" color="primary" onClick={handleCreatePublisher}
            disabled={
              !publisherState.enablePublisherEmail
                ? false
                : !isValidEmail(publisherState.publisherEmail) // Disable if email is invalid
            }
          >{publisherState.editFlag ? "UPDATE" : 'CREATE'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export const PubActionIcons = (props: any) => {
  const { PubID, fetchInitialData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.publisherAccApi.fullData);
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const publisherState = useAppSelector((state: RootState) => state.publisher);


  const getRowDataById = (pubId: string) => {
    return fullData.find((row: any) => row.mPubID === pubId);
  };
  const rowData = getRowDataById(PubID);

  const editFetch = () => {
    if (publisherState.editFlag && rowData) {
      const fieldsToUpdate = [
        'address',
        // 'city',
        // 'companyDescription',
        // 'enablePublisherEmail',
        // 'phNo',
        // 'primaryContact',
        'publisherEmail',
        // 'state',
        // 'website',
      ];

      fieldsToUpdate.forEach((field: any) => {
        dispatch(setField({ field, value: rowData.values?.[field] || '' }));
      });

      dispatch(setField({ field: 'enablePublisherEmail', value: rowData.values?.enablePublisherEmail || false }));
      dispatch(setField({ field: 'publisherName', value: rowData.publisherName || '' }));
      dispatch(setField({ field: 'status', value: rowData.status || '' }));
    }
  };

  const handleEditButtonClick = () => {
    editFetch();
    setOpenPopup(true);
  };

  const fetchData = () => {
    fetchPublisherApiData(dispatch, null);
    fetchCompApiData(dispatch);
  };

  let delPayload: { ID: any; mPubID: any; }
  delPayload = {
    ID: rowData?.ID,
    mPubID: rowData?.mPubID,
  }


  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
      <CreatePublisher openPopup={openPopup} setOpenPopup={setOpenPopup}
        rowData={rowData} fetchInitialData={fetchInitialData}
      />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData}
      />
    </div>
  )
}


