import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface rawData {
    reportName?: string,
    reportType?: string,
    rawStatus?: string,
    lastExecution?: string,
    download?: string,
}


interface PublisherState {
    data: rawData[];
    loading: boolean;
    rawStatus:string;

}

const initialState: PublisherState = {
    data: [],
    loading: false,
    rawStatus: ''
};


const rawDataReportSlice = createSlice({
  name: 'rawDataReport',
  initialState,

  reducers: {
    setRawData: (state, action: PayloadAction<rawData[]>) => {
        state.data = action.payload;
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
        state.loading = action.payload;
      },
      setRawReportStatus: (state, action ) => {
        state.rawStatus = action.payload;
      },
  },
});



export const {setRawData, setLoading,setRawReportStatus } = rawDataReportSlice.actions;

export default rawDataReportSlice.reducer;
