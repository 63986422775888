import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, createTheme } from "@mui/material";
import { useAppDispatch } from "../../Store/hooks";
import { useState } from "react";
import { removeUserService } from "../../service/User";
import SnackbarMessage from '../../Component/Snackbar';

export const DeleteUser = (props: any) => {

    // Theme configuration
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center', // Center-align text in cells
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            backgroundColor: "#EAECF0",
            color: "#737791",
             text:'center', // Center-align text in header cells
          },
  
        },
      },
    },
  });
  
    const { modalCloseHandler, setDeletePopup, data, isDeleteModalOpen,   refreshHandler} = props
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
  

     const handleConfirm =  () => {
      const reqPayload={
        userName:data.userName
      }

      
      // console.log("reqPayloadreqPayloadreqPayload==",reqPayload,data)
      removeUserService(JSON.stringify(reqPayload)).then((res)=>{
        console.log("res",res);
        setSnackbarOpen(true)
        setSnackbarType("success")
        setSnackbarMessage(`${data.userName}- User deleted successfully`)
        modalCloseHandler()
        refreshHandler();
      }).catch((err)=>{
        console.log("res");
        setSnackbarOpen(true)
        setSnackbarType("error")
        setSnackbarMessage(`Some error occurred`)
      })
     }
  
    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };
  
    return (<>
      <div>
        <Dialog open={isDeleteModalOpen} >
          <DialogTitle className="dialogTitle">ALERT</DialogTitle>
          <DialogContent >
         <p>Are you sure you want to delete this - {data.userName}</p>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={()=> modalCloseHandler()} color="primary">
              CANCEL
            </Button>
            <Button onClick={handleConfirm} color="error" variant="contained">
              CONFIRM
            </Button>
          </DialogActions>
        </Dialog>
        {/* {successMessage && (
          <div className='delMessage'>
            {successMessage}
          </div>
        )} */}
        <Box>
      <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }} type={snackbarType} message={snackbarMessage}/>
      </Box>
      </div>
      </>)
  }