import React, { useEffect, useState } from 'react'
import { Paper, Box, Typography } from '@mui/material'
import { getAdvByVendor, getCampaignsByAdvertiser, getCampaignsByVendor } from '../../../service/Vendor'
import CircleLoader from 'react-spinners/CircleLoader';
import VendorAccountTable from './VendorAccountTable';
import Search from '../../../Component/Search/Search';
interface Column{
    id:string;
    label:string;
    sortable:boolean
  }
  const columns: Column[] = [
    { id: 'status', label: 'Status', sortable: true },
    { id: 'SORTID', label: 'Campaign ID', sortable: true },
    { id: 'campaignname', label: 'Campaign Name', sortable: true },
    { id: 'mPubID', label: 'Advertiser ID', sortable: true }, 
    { id: 'publisherName', label: 'Advertiser Name', sortable: true },
  ];
const VendorAdCampaign = () => {
    const [advData, setData] = useState< any[]>([])
    const [loading, setLoading] = useState(false)
    const fetchData = async () => {
      try {
        setLoading(true)
        let vendorName = localStorage.getItem("userSortid");
        if (vendorName) {

           const res: any = await getCampaignsByVendor(vendorName)
          // console.log("res", res);
          setData(res?.advertiserCampaignData.map((item:any, index:number)=>{
             return {
              publisherName:item.advertiser.GroupName,
              mPubID:item.mPubID,
              campaignname:item.campaignname,
              SORTID:item.SORTID,
            //   createdOn:item.createdOn,
            //   updatedOn:item.updatedOn,
              status:item.status
  
             }
          }))
          setLoading(false)
        }
      } catch (error) {
  
      }
    }
    useEffect(() => {
      fetchData()
  
  
    }, [])
  
    const [searchValue, setSearchValue]=useState('')
    const handelSearch=(event:React.ChangeEvent<HTMLInputElement>)=>{
      setSearchValue(event.target.value)
    }
    
    // useEffect(() => {
    //   console.log(searchValue)
    
    
    // }, [searchValue])
    return (
      <Paper elevation={3} className="card">
        <Box className="user_Container">
          <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Box>
  
              <Typography className="card-heading">Campaigns List</Typography>
              <Typography className="card-sub-heading">
                Campaigns Summary
              </Typography>
  
            </Box>
            <Box display="flex" gap="16px" alignItems="center">
            <Search value={searchValue} searchHandler={handelSearch} />
           
          </Box>
          </Box>
          <Box className='user_body' >
            {loading ? <Box className="loading-spinner-container" sx={{
              minHeight: "700px"
            }}>
  
              <CircleLoader color="#00BFFF" loading={loading ? true : false} size={60} />
            </Box> :<VendorAccountTable data={advData}  columns={columns} />
            }  </Box>
        </Box>
      </Paper>
    )
}

export default VendorAdCampaign