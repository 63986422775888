import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

interface deleteAlertProps{
open:boolean;
closeHandler:()=>void;
submitHandler:()=>void;
alertText:string;
successMessage:string;
errorMessage:any;
isSnackbarOpen:boolean;
};
export default function DeleteVendorDialog(props:deleteAlertProps) {

const {open, closeHandler, submitHandler}=props;

  return (
    <React.Fragment>
     
      <Dialog
        open={open}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"  className="dialogTitle">
          Alert
        </DialogTitle>
        <DialogContent>
          
          <p>
          Are you sure you want to delete {`${props.alertText}`}?
          </p>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={closeHandler}>Cancel</Button>
          <Button onClick={submitHandler} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.isSnackbarOpen}
        autoHideDuration={3000}
        onClose={closeHandler}
        >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={closeHandler}
          severity={props.successMessage ? 'success' : 'error'}
        >
          {props.successMessage|| props.errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}
