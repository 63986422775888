import React from 'react'
import { ISvgIcons } from './AdvertiserSVGIcon'

const PublisherSVGIcon:React.FC<ISvgIcons>  = ({iconColor}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1120_1241)">
<path d="M20.4999 20.0003C20.4177 20 20.3359 19.9865 20.2579 19.9603L0.507947 13.2103C0.369212 13.1625 0.24744 13.0752 0.15756 12.9592C0.06768 12.8433 0.0135988 12.7036 0.00194678 12.5573C-0.00891369 12.4111 0.0231519 12.2648 0.094208 12.1366C0.165264 12.0083 0.272219 11.9036 0.401947 11.8353L22.9019 0.0852764C23.0248 0.0216744 23.1627 -0.00682878 23.3007 0.00290127C23.4386 0.0126313 23.5712 0.0602178 23.6839 0.140428C23.7966 0.220637 23.8849 0.330363 23.9393 0.45754C23.9936 0.584716 24.0119 0.724416 23.9919 0.861277L21.2419 19.3613C21.2153 19.539 21.1258 19.7013 20.9896 19.8186C20.8534 19.9359 20.6797 20.0003 20.4999 20.0003ZM2.64595 12.3553L19.8899 18.2493L22.2909 2.09728L2.64595 12.3553Z" fill={iconColor}/>
<path d="M9.50001 23C9.4014 23.0004 9.3037 22.9813 9.21252 22.9437C9.12135 22.9062 9.03851 22.851 8.96879 22.7812C8.89907 22.7115 8.84384 22.6287 8.80629 22.5375C8.76874 22.4463 8.74961 22.3486 8.75001 22.25V15.5C8.75001 15.311 8.82201 15.127 8.95101 14.988L22.701 0.238026C22.8368 0.0924216 23.0249 0.00672401 23.2238 -0.000214833C23.4228 -0.00715368 23.6164 0.0652346 23.762 0.201026C23.9076 0.336816 23.9933 0.524886 24.0002 0.723863C24.0072 0.922839 23.9348 1.11642 23.799 1.26203L10.25 15.795V19.963L12.753 16.556C12.8742 16.4049 13.0491 16.3065 13.2412 16.2814C13.4333 16.2562 13.6276 16.3062 13.7837 16.421C13.9397 16.5357 14.0454 16.7063 14.0786 16.8972C14.1119 17.088 14.0701 17.2843 13.962 17.445L10.105 22.695C10.0349 22.7895 9.94368 22.8662 9.83864 22.9192C9.7336 22.9722 9.61764 22.9998 9.50001 23Z" fill={iconColor}/>
</g>
<defs>
<clipPath id="clip0_1120_1241">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default PublisherSVGIcon
