// import { Box, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import BasicDialog from '../../Component/Dialog/BasicDialog';
import { Box, Button, TextField, Tooltip, IconButton } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { cleanUp, setCsvData, setCsvFileName, setUploadRadioValue } from '../../Store/Slices/tcpaCampSlice';
import { createTcpaCamp } from '../../Services/tcpaTabApis';
import SnackbarMessage from '../../Component/Snackbar';
import { defaultTCPACSVConfig } from '../../Mock/CSV file data/tcpaProgramAudience';
interface IUploadTCPAContent {
  rowData: any,
  editHandler: (rowData: any) => void,
  reload: () => void
}

interface customHeader {
  Status: string,
  categoryID: string,
  categoryName: string,
  parameterId: string,
  "parameter name": string,
  "advertiser_key": string,
  "parameterType": string
}

const tcpa_file_column = ["Status", "parameterType", "categoryID", "categoryName", "parameterId", "parameter name", "advertiser_key",]

const UploadTCPAContent = (props: IUploadTCPAContent) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  //   const [radioOption,setRadioOption]=useState('add')

  const dispatch = useAppDispatch();
  const tcpaTableData = useAppSelector((state) => state.tcpaCampSlice);
  const { radioValue } = tcpaTableData.csvContent;
  const handleExpo = () => {
    setIsDialogOpen(true);

    props.editHandler(props.rowData.tcpaCampaign_id)
  }

  const handleCancel = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(cleanUp())
      setIsDialogOpen(false);
    }
  };
  const handleRadioChange = (value: string) => {
    dispatch(setUploadRadioValue(value))
  };
  // console.log("tcpaTableData.csvFileData", tcpaTableData.tcpaEditJsonData)

  const handleSave = async () => {
    setIsLoading(true);
    try {
      let payload;
      if (radioValue === 'remove') {
        payload = {
          ...tcpaTableData.tcpaEditJsonData,
          csvContent: {
            fileName: '',
            fileData: [],
            radioValue: 'remove'
          }
        }
      } else {
        const mProgramData: any[] = [];
        const audienceTargetingData: any[] = [
         
        ];

        // Process the parsed data
        tcpaTableData.csvContent.fileData.forEach((row: any) => {
          if (row.parameterType === 'mProgram') {
            // Find or create areaStudy entry
            let areaStudy = mProgramData.find(item => item.areaStudyId === row.categoryID);

            if (!areaStudy) {
              // Create a new areaStudy entry if not found
              areaStudy = {
                areaStudyId: row.categoryID,
                areaStudyLabel: row.categoryName,
                enable: false,
                mProgram: []
              };
              mProgramData.push(areaStudy);
            }

            // Add the program to the area's mProgram list
            areaStudy.mProgram.push({
              mProgramLabel: row['parameter name'],
              mProgramId: row.parameterId,
              postValue: row['advertiser_key'],
              enable: row.Status === 'on',
              isError: (row.Status === 'on' && row['advertiser_key'] === '') ? true : false
            });

            // Update enable field if any of the programs are enabled
            if (row.Status === 'on') {
              areaStudy.enable = true;
            }
          } else {
            // Process audience targeting data
            let audience = audienceTargetingData.find(item => item.title === row.categoryName);
            // console.log("audience", audience)
            if (!audience) {
              audience = {
                title: row.categoryName,
                key: row.categoryName?.replace(/\s+/g, ""),
                values: []
              };
              audienceTargetingData.push(audience);
            }

            audience.values.push({
              [`${row.categoryName.replace(/\s+/g, "")}Id`]: row.parameterId,
              postValue: row['advertiser_key'],
              label: row['parameter name'],
              enable: row.Status === 'on',
              isError: (row.Status === 'on' && row['advertiser_key'] === '') ? true : false
            });
          }
        });
        audienceTargetingData.push( {
          "title": "Grad Year",
          "key": "gradYear",
          "content": "TcpaGradYearSection"
      })
        payload = {
          ...tcpaTableData.tcpaEditJsonData,
          "tcpaCampaignId": tcpaTableData.tcpaEditJsonData.tcpaCampaignId,
          "campaignType": tcpaTableData.tcpaEditJsonData.campaignType,
          gradYear: tcpaTableData.tcpaEditJsonData.gradYear,
          programTargeting: mProgramData,
          audienceTargeting: audienceTargetingData,
          csvContent: {
            ...tcpaTableData.csvContent,
            radioValue: 'remove'
          }
        }


      }

      const response = await createTcpaCamp(payload, "put");
      //  console.log("response", response)
      dispatch(cleanUp())
      setSnackbarType('success')
      setSnackbarOpen(true)
      setSnackbarMessage("Updated!")
      setIsLoading(false);
      props.reload()
      setIsDialogOpen(false);
      //  window.location.reload();
    } catch (error: any) {
      dispatch(cleanUp())
      setSnackbarType('error')
      setSnackbarOpen(true)
      setSnackbarMessage(error.message)
      setIsLoading(false);
      props.reload()
      setIsDialogOpen(false);
    }

  }
  const [snackbarType, setSnackbarType] = useState('success')
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleClose = () => {
    dispatch(cleanUp())
    setIsDialogOpen(false);
  }

  return (
    <React.Fragment>


      <Tooltip title={"upload File"}>
        <ImportExportIcon color={"primary"} onClick={handleExpo} />
      </Tooltip>

      <BasicDialog
        isLoading={isLoading}
        open={isDialogOpen}
        cancelHandler={handleCancel}
        radioValue={radioValue}
        radioChangeHandler={handleRadioChange}
        submitHandler={handleSave}
        dialogTitle={"Upload TCPA Content CSV"}
        children={UploadCSV}
        closeHandler={handleClose}
      />

      <SnackbarMessage
        type={snackbarType}
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </React.Fragment>
  )
}

export default UploadTCPAContent

const UploadCSV = () => {
  const [selectedFile, setSelectedFile] = useState<null | any>(null)
  const tcpaTableData = useAppSelector((state) => state.tcpaCampSlice);
  const dispatch = useAppDispatch();
  // console.log("tcpaTable Data", tcpaTableData)
  const { fileName, radioValue } = tcpaTableData.csvContent
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file.name)

      dispatch(setCsvFileName(file.name))
      Papa.parse(file, {
        complete: (result: any) => {
          dispatch(setCsvData(result.data))
        },
        header: true,
        skipEmptyLines: true

      })
    }
  }
  const mapProgramData = (programData: any) => {
    return programData.flatMap((programItem: any) =>
      programItem.mProgram.map((item: any) => ({
        "parameterId": item.mProgramId,
        "parameter name": item.mProgramLabel,
        "advertiser_key": item.postValue,
        "Status": item.enable ? "on" : "off",
        "parameterType": "mProgram",
        "categoryID": programItem.areaStudyId,
        "categoryName": programItem.areaStudyLabel
      }))
    );
  };

  // Helper function to map audience data
  const mapAudienceData = (audienceTargeting: any) => {
    return audienceTargeting.flatMap((audItem: any, audIndex: number) =>
      audItem.values.map((item: any, itemIndex: number) => ({
        "parameterId": itemIndex + 1,
        "parameter name": item.label,
        "advertiser_key": item.postValue,
        "Status": item.enable ? "on" : "off",
        "parameterType": "Audience Targeting",
        "categoryID": audIndex + 1,
        "categoryName": audItem.title
      }))
    );
  };




  const handleDownload = () => {
    const programData = tcpaTableData.tcpaEditJsonData.programTargeting
    const audienceTargeting = tcpaTableData.tcpaEditJsonData.audienceTargeting
    const tcpaCampaign_id = tcpaTableData.tcpaEditJsonData.tcpaCampaignId
    // Helper function to filter out "mProgram" from defaultTCPACSVConfig
    const filterDefaultAudienceData = () => {
      return defaultTCPACSVConfig.filter(item => item['parameterType'] !== 'mProgram');
    };

    // Helper function to filter for "mProgram" in defaultTCPACSVConfig
    const filterDefaultProgramData = () => {
      return defaultTCPACSVConfig.filter(item => item['parameterType'] === 'mProgram');
    };


    // console.log("DEFAULt PRogram data ", filterDefaultProgramData)
    // console.log("DEFAU tcpa audience data ", filterDefaultAudienceData)
    let programFileData = []
    let audienceFileData = []
    let file_data: any[];
    if (programData && audienceTargeting) {
      const programFileData = mapProgramData(programData);
      const ad = audienceTargeting.filter((item: any) => {
        return item.key !== 'gradYear'
      })
      const audienceFileData = mapAudienceData(ad);

      file_data = [...programFileData, ...audienceFileData];
    } else if (programData && !audienceTargeting) {
      const programFileData = mapProgramData(programData);
      console.log("DEFAUlt program file data ", programFileData)
      const defaultAudienceData = filterDefaultAudienceData();
      file_data = [...programFileData, ...defaultAudienceData];
    } else if (!programData && audienceTargeting) {
      const ad = audienceTargeting.filter((item: any) => {
        return item.key !== 'gradYear'
      })
      const audienceFileData = mapAudienceData(ad);
      const defaultProgramData = filterDefaultProgramData();
      console.log("DEFAULt PRogram data ", defaultProgramData)

      file_data = [...audienceFileData, ...defaultProgramData];
    } else {
      file_data = defaultTCPACSVConfig;
    }
    var csv
    csv = Papa.unparse({
      fields: tcpa_file_column.map((item) => item),
      data: file_data
    })

    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${tcpaCampaign_id}-tcpa-program-audience-config.csv`;
    link.click();
  }
  return (
    <>
      <Box className='upload-container' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "20px" }}>
        <Box className='upload-btn-container'   >
          <label htmlFor="file-input">
            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              disabled={(radioValue === 'remove')}
            >

              <AttachFileIcon style={{ marginTop: "19px", fontSize: '30px' }} />
            </IconButton>
          </label>
          <input
            type="file"
            accept=".csv"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <TextField
            // style={{ width: "70%" }}
            id="standard-helperText"
            label={selectedFile ? "" : "Upload CSV"}
            helperText="Select  files"
            variant="standard"
            value={fileName}
            disabled={(radioValue === 'remove')}
          />
        </Box>
        <Button
          title={fileName ? "Download last uploaded file" : "Sample file"}
          onClick={handleDownload}
          disabled={(radioValue === 'remove')}

        >
          <DownloadIcon style={{ marginTop: "13px", fontSize: '40px' }} />

        </Button>
      </Box>
    </>
  )
}