import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Fab,
  useMediaQuery,
  Snackbar,
} from '@mui/material';
import React, {
  FC,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
// import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import AddEditSEOContent from './SEOContent';
import AddEditWebContent from './WebContent';
import AddEditImgContent from './BannerContent';
import AddEditFAQContent from './FAQContent';
import MenuIcon from '@mui/icons-material/Menu';
import isEqual from 'lodash/isEqual';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { setTempData } from '../../../../Store/Slices/SEOWebsiteInfoApiSlice';
import { updateWebSiteContent } from '../../../../Services/seoApis';
import MuiAlert from '@mui/material/Alert';
import { setApiLoading, setErrorMessage, setSnackbarOpen, setSuccessMessage } from '../../../../Store/Slices/SEOHandlerApisSlice';
import { Navigate, useNavigate } from 'react-router-dom';

const ProgramContent: FC<any> = ({ submitRef, handleNext }) => {
  const websiteInfoFormData = useAppSelector(state => state.websiteInfoApi);

  const [tabContent, setTabContent] = useState(
    websiteInfoFormData?.programContent
  );

  const [showTabs, setShowTabs] = useState(true);
  const [seoDialog, setSeoDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState(false);
  const [imgDialog, setImgDialog] = useState(false);
  const [faqDialog, setFaqDialog] = useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState<any>('');
  const [subProgram, setSubProgram] = useState<any>(tabContent[0]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log('newValue',newValue)
    setValue(newValue);
    setSubProgram(tabContent[newValue]);
  };

  const toggleTabsVisibility = () => {
    setShowTabs(!showTabs);
  };

  // Using useMediaQuery to detect screen width
  const isScreenAbove1300 = useMediaQuery('(min-width:1300px)');

  useEffect(() => {
    submitRef.current = () => {
      handleSubmit();
    };
  }, [tabContent]);

  const handleSubmit = () => {
    dispatch(setApiLoading(true));
    dispatch(
      setTempData({ ...websiteInfoFormData, programContent: tabContent })
    );
    const updateContent = async () => {
      try {
        await updateWebSiteContent({
          ...websiteInfoFormData,
          programContent: tabContent,
        });
        dispatch(setSuccessMessage('Updated Program Content'));
        dispatch(setSnackbarOpen(true));
        navigate('/cms-website');
        // handleNext();
      } catch (error) {
        console.error('Error fetching data:', error);
     dispatch(setApiLoading(false));
      }
    dispatch(setApiLoading(false));
    };

    updateContent();
  };

  useEffect(() => {
    // Automatically close the tabs if the screen width is below 1300px
    if (!isScreenAbove1300) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [isScreenAbove1300]);

  function a11yProps(index: number, name: string) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      name: name,
    };
  }

  
  const setUpDatedValue = (updateValue: any) => {
    const updatedTabContent = tabContent.map((item: any, index: number) =>
      item.label === updateValue.label ? updateValue : item
    );
    // console.log('updateValue', updatedTabContent);
    setTabContent(updatedTabContent);
    setSubProgram(updatedTabContent[value]);
  };

  const TabPanel = ({ program, upDatedValue }: any) => {
    const [programContent, setProgramContent] = useState(program);
    const [isSelectAll, setSelectAll] = useState(
      program?.mProgram?.every((item: any) => item.enable === true)
    );
console.log('program.mProgram.every((item: any)',program?.mProgram?.every((item: any) => item.enable === true));


    const handelSelectAllCheckBox = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { checked } = event.target;
      const updatedSubPrograms = programContent.mProgram.map((item: any) => ({
        ...item,
        enable: checked,
      }));
      setProgramContent((prevData: any) => ({
        ...prevData,
        mProgram: updatedSubPrograms,
      }));
    };

    useEffect(() => {
      // console.log('programContent',programContent)
      setSelectAll(
        programContent.mProgram.every((item: any) => item.enable === true)
      );
      !isEqual(programContent, program) && upDatedValue(programContent);
    }, [programContent]);

    const handleStatus = (
      event: React.ChangeEvent<HTMLInputElement>,
      id: string
    ) => {
      const { name, checked } = event.target;

      const updatedSubPrograms = programContent.mProgram.map((item: any) =>
        item.id === id ? { ...item, enable: checked } : item
      );
      setProgramContent((prevData: any) => ({
        ...prevData,
        mProgram: updatedSubPrograms,
      }));
    };
    const handleAreaStudyStatus = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { checked } = event.target;
      setProgramContent((prevData: any) => ({
        ...prevData,
        enable: checked,
      }));
    };
    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, [])

  console.log('tabContent?.label',tabContent?.label)


    return (
      <div role="tabpanel" style={{ width: '100%' }}>
        <Paper>
          <Grid container justifyContent="space-between">
            <Grid item>
              <FormGroup sx={{ m: 1, minWidth: '100%', p: 1 }}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      name="status"
                      checked={isSelectAll}
                      onChange={handelSelectAllCheckBox}
                    />
                  }
                  label="Select All"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup sx={{ m: 1, minWidth: '100%', p: 1 }}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      name="status"
                      checked={programContent?.enable}
                       disabled={programContent?.areaStudy === websiteInfoFormData.areaStudy}
                      onChange={handleAreaStudyStatus}
                    />
                  }
                  label="Status"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Box className="no-more-tables responsive-tbl">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Program Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {programContent?.mProgram?.map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell data-title="Program Name">
                        {item.label}
                      </TableCell>
                      <TableCell data-title="">
                        {' '}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                name={'enable'}
                                disabled={item.value === websiteInfoFormData.mProgram}
                                 checked={item.enable}
                                // checked={item.enable}

                                onChange={event => {
                                  handleStatus(event, item.id);
                                }}
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell data-title="" sx={{ textAlign: 'right' }}>
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid #0AE',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            color: '#00AAEE',
                            marginRight: '8px',
                          }}
                          onClick={() => {
                            setSelectedValue({
                              websiteDomainName:
                                websiteInfoFormData?.websiteDomainName,
                              areaStudy: item?.areaStudy,
                              mProgram: item?.id,
                            });
                            setSeoDialog(true);
                          }}
                        >
                          SEO
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid #0AE',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            color: '#00AAEE',
                            marginRight: '8px',
                          }}
                          onClick={() => {
                            setSelectedValue({
                              websiteDomainName:
                                websiteInfoFormData?.websiteDomainName,
                              areaStudy: item?.areaStudy,
                              mProgram: item?.id,
                            });
                            setContentDialog(true);
                          }}
                        //  disabled={!item.enable}
                        >
                          Content
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid #0AE',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            color: '#00AAEE',
                            marginRight: '8px',
                          }}
                          onClick={() => {
                            setSelectedValue({
                              websiteDomainName:
                                websiteInfoFormData?.websiteDomainName,
                              areaStudy: item?.areaStudy,
                              mProgram: item?.id,
                            });
                            setImgDialog(true);
                          }}
                        //  disabled={!item.enable}
                        >
                          Banner
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid #0AE',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            color: '#00AAEE',
                            marginRight: '8px',
                          }}
                          onClick={() => {
                            setSelectedValue({
                              websiteDomainName:
                                websiteInfoFormData?.websiteDomainName,
                              areaStudy: item?.areaStudy,
                              mProgram: item?.id,
                            });
                            setFaqDialog(true);
                          }}
                        //  disabled={!item.enable}
                        >
                          FAQ
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </div>
    );
  };

  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      {showTabs && (
        <Box
          sx={{
            height: '70vh',
            flexBasis: '20%',
            padding: '10px',
          }}
          className="mm-tab-container"
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {tabContent.map((item: any, index: any) => {
              return (
                <Tab
                  label={item.label}
                  {...a11yProps(index, item.label)}
                  className={`mm-tab ${index === value ? 'active' : ''} `}
                />
              );
            })}
          </Tabs>
        </Box>
      )}

      <Box
        className="mm-tabpanel-container"
        sx={{
          height: '75vh',
          flexBasis: '100%',
          padding: '11.5px',
        }}
      >
        <TabPanel program={subProgram} upDatedValue={setUpDatedValue} />
      </Box>
      {seoDialog && (
        <AddEditSEOContent
          openPopup={seoDialog}
          selectedValue={selectedValue}
          setDialogOpen={(data: any) => {
            setSelectedValue('');
            setSeoDialog(data);
          }}
        />
      )}
      {contentDialog && (
        <AddEditWebContent
          openPopup={contentDialog}
          selectedValue={selectedValue}
          setDialogOpen={(data: any) => {
            setSelectedValue('');
            setContentDialog(data);
          }}
        />
      )}
      {imgDialog && (
        <AddEditImgContent
          openPopup={imgDialog}
          selectedValue={selectedValue}
          setDialogOpen={(data: any) => {
            setSelectedValue('');
            setImgDialog(data);
          }}
        />
      )}
      {faqDialog && (
        <AddEditFAQContent
          openPopup={faqDialog}
          selectedValue={selectedValue}
          setDialogOpen={(data: any) => {
            setSelectedValue('');
            setFaqDialog(data);
          }}
        />
      )}
      <Box
        sx={{
          position: 'fixed',
          bottom: '92px',
          right: '12px',
          zIndex: 999, // Ensure it's above other elements
        }}
      >
        {!isScreenAbove1300 && (
          <Fab
            onClick={toggleTabsVisibility}
            variant="extended"
            size="small"
            color="primary"
          >
            <MenuIcon sx={{ mr: 1 }} />
            Program
            {/* {tabContent[0].label == 'Business & Finance' ? 'Programs' : (tabContent[0].label == 'Campus Type' ? 'Audience' : (tabContent[0].label == '[ISM] Online College Guide' ? 'Media' : 'Rules'))} */}
          </Fab>
        )}
      </Box>
    </Box>
  );
};

export default ProgramContent;
