import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState,useEffect, useMemo } from 'react'
import MMTable from '../../../Component/Table/MMTable';

import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { seoHandlerHeadCells,advRows, seoRows } from '../../../Component/Table/tableInterface';

import { fetchAreaMprogramnew, fetchDegreeInterestNew, fetchSeoApiData } from '../../../Services/seoApis';
import MuiAlert from '@mui/material/Alert';
import { setSeoTableData, setSnackbarOpen } from '../../../Store/Slices/SEOHandlerApisSlice';
import Search from '../../../Component/Search/Search';
import { setNoDataFlag } from '../../../Store/Slices/advertiserTabApisData';


const SeoAccount = () => {
  return (
    <SEODashboard />
  )
}

export default SeoAccount


const SEODashboard = () => {
  const dispatch = useAppDispatch();
  const seoApiData = useAppSelector((state) => state.seoHandlerApi.seoApiData);
  const seoTableData = useAppSelector((state) => state.seoHandlerApi.seoTableData);
  const seoFormTableData = useAppSelector((state) => state.seoHandlerApi.seoFormTableData);
  const successMessage = useAppSelector((state) => state.seoHandlerApi.successMessage);
  const errorMessage = useAppSelector((state) => state.seoHandlerApi.errorMessage);
  const snackbarOpen = useAppSelector((state) => state.seoHandlerApi.snackbarOpen);
  const [searchInput, setSearchInput] = useState('');
 const [reload, setReload]=useState(false)
  useEffect(() => {
    fetchInitialData();
    // fetchSeoApiData(dispatch);
    fetchAreaMprogramnew(dispatch);
    fetchDegreeInterestNew(dispatch);
  }, [reload])


  const fetchInitialData = () => {
    fetchSeoApiData(dispatch)
    // .then(() => {
    //  dispatch(setScrollNext(true));
    // });
  };

  const handleCloseSnackbar = () => {
    dispatch(setSnackbarOpen(false));
  }

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = seoFormTableData.filter(function (item: any) {
        return (
          item.website?.toString().toLowerCase().includes(userInput) ||
          item.seoTitle?.toLowerCase().includes(userInput) ||
          item.seoDescription?.toString().toLocaleLowerCase().includes(userInput) ||
          item.seoKeywords?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setSeoTableData(filteredDataa));
    } else {
      dispatch(setSeoTableData(seoTableData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleSearchInput = useMemo(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchInput(value);
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => searchTable(value), 300);
    };
  }, [dispatch, setSearchInput, searchTable]);

  
  return (
    <React.Fragment>
      <Paper elevation={3} className='card'>
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className='card-heading'>CMS Website's</Typography>
            <Typography className="card-sub-heading">
              CMS Website Configuration
            </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
          <Search value={searchInput} searchHandler={handleSearchInput} />

          {/* <Link to='/createseoconfig' style={{ textDecoration: "none" }} state={{
              drawerHeader: "Create Advertiser Campaigns"
            }}>
            <Button variant="contained" startIcon={<AddIcon />}>
              Create SEO 
            </Button>
            </Link> */}
          </Box>
        </Box>
        <MMTable 
        // tableData={seoTableData} 
        tableData={seoTableData} 
        tableHeadCell={seoHandlerHeadCells} 
        fetchInitialData={fetchInitialData}
        reload={(value:boolean)=>{setReload(value)}}
        />
      </Paper>
      {
        (successMessage || errorMessage) &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={successMessage ? 'success' : 'error'}
          >
            {successMessage || errorMessage}
          </MuiAlert>
        </Snackbar>

      }
   
    </React.Fragment>
  )
}

