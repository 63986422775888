import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, } from '@mui/material'
interface IDialog {
    open: boolean;
    children: (props: any) => JSX.Element;
    // openHandler: () => void;
    closeHandler: () => void;
    dialogTitle: string;
    submitHandler:()=>void;
    cancelHandler:(event:any, reason:string)=>void;
    radioValue:string;
    radioChangeHandler:(checked:string)=>void
    isLoading:boolean
   
  }
const BasicDialog = (props:IDialog) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.cancelHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { opacity: '1' } }}
      // onBackdropClick={handleBackdropClick}
      >
        <DialogTitle id="alert-dialog-title">
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Box sx={{paddingLeft:'20px'}}>
            <RadioGroup row name="option" value={props.radioValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.radioChangeHandler(e.target.value)} >
              <FormControlLabel value="add" control={<Radio />} label="Include  file" />
              <FormControlLabel value="remove" control={<Radio />} label="Remove file" />
            </RadioGroup>
          </Box>
          {<props.children  {...props} />}

        </DialogContent>
        <DialogActions>

          <Button color='error' onClick={props.closeHandler}>
            CANCEL
          </Button>
          <Button sx={{
            backgroundColor: "#0ae",

            "&.MuiButtonBase-root:hover": {
              bgcolor: "#1976d2 !important"
            },
            "&:hover": { backgroundColor: "#1976d2" },
            "&:disabled": { backgroundColor: "#ffff" },
            border: "1px solid #0ae",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            borderRadius: "8px",
            color: "#ffff",
            height: "45px"
          }}
            // disabled={advCampaignData.campaignCSVData?.length <= 0}
            onClick={props.submitHandler}
          >
            {props.isLoading ? <CircularProgress color="inherit" size={24} /> : "SAVE"}
          </Button>
        </DialogActions>

      </Dialog>


    </React.Fragment>
  )
}

export default BasicDialog