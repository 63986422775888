import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import { createEvents, formatEvents } from '../../Utils/utils'
import { useAppDispatch , useAppSelector} from '../../Store/hooks'
import { campaignFormSelector, updateScheduling } from '../../Store/Slices/CreateAdvCampaignSlice'
import {
    EventApi,
    DateSelectArg,
    EventClickArg,
    EventContentArg,
    formatDate,
  } from '@fullcalendar/core'
import { Box } from '@mui/material'
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

let eventGuid = 0;
const jsonData1 = {
    "Friday": {
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12AM": {
            "include": true
        },
    },
    "Sunday": {
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": false
        }
    }
}
export function createEventId() {
    return String(eventGuid++)
}

// const generateEvents = (jsonData:any) => {
//     const events = [];
  
//     for (const day in jsonData) {
//       for (const time in jsonData[day]) {
//         if (jsonData[day][time].include) {
//           const [hour, minute] = time.split('AM')[0].split(':');
//           const start = new Date();
//         //   start.setUTCHours(Number(hour));
//         //   start.setUTCMinutes(Number(minute));
//         //   start.setSeconds(0);
//         //   const end = new Date(start);
//         //   end.setUTCMinutes(end.getUTCMinutes() + 60); // Event duration: 1 hour
  
//         //   events.push({
//         //     title: 'Campaign Display time',
//         //     start: start.toISOString(),
//         //     end: end.toISOString(),
//         //     allDay: false
//         //   });
//         const date = new Date(); // Use current date

//         // Set the time using parsed hours and minutes
//         date.setHours(parseInt(hour, 10));
//         date.setMinutes(parseInt(minute, 10));
//         date.setSeconds(0);

//         const eventEnd = new Date(date);
//         eventEnd.setHours(eventEnd.getHours() + 1); // Event duration: 1 hour

//         events.push({
//           title: 'Campaign Display time',
//           start: date.toISOString(),
//           end: eventEnd.toISOString(),
//           allDay: false
//         });
//         }
//       }
//     }
// }


const CalenderPage = () => {
    const campaignFormData=useAppSelector(campaignFormSelector)
    // const test_event=generateEvents(jsonData1)
    const INITIAL_EVENTS = createEvents(campaignFormData.value.scheduling)
    // console.log("CAMPAIGN form REDUX", campaignFormData)
    // console.log("Test _event", test_event)
      console.log(INITIAL_EVENTS)
    const [currentEvent, setCurrentEvent] = useState([])
    const handleDateSelect = (selectInfo: DateSelectArg) => {
        // let title = prompt('Please enter a new title for your event');
        let title = "Campaign on";
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection
        // console.log('calender API', calendarApi);
        // console.log('Select info', selectInfo);
        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay,
            });

            console.log("calender API ", calendarApi)
        }
    };
    const findDay = (str: string) => {
        const date = new Date(str);

        // Extract the day and time
        const day = date.toLocaleDateString("en-US", { weekday: "long" });
        const time = date.toLocaleTimeString("en-US");

        // console.log("Day:", day);
        // console.log("Time:", time)
        return day;
    }
    // function renderEventContent(eventInfo: any) {
    //     const day = findDay(eventInfo.event.start)
    //     return (
    //         <div style={{
    //             display: 'flex',
    //             // flexDirection: "column",
    //             gap: "10px"
    //         }}
    //         className='event-content-message'>

    //             <i>{eventInfo.event.title}</i>
    //             <i>{day}</i>
    //             <b>{eventInfo.timeText}</b>

    //         </div>
    //     );
    // }

    const handleEventClick = (clickInfo: EventClickArg) => {
        // console.log("Events clickINfo", clickInfo.event)
        if ( window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
          clickInfo.event.remove()
        }
      }
    function renderEventContent(eventContent: EventContentArg) {
        return (
          <Box>
            <b>{eventContent.timeText}</b>
            <i>{eventContent.event.title}</i>
          </Box>
        )
      }


    // const handleEventClick = (clickInfo: any) => {

    //     clickInfo.event.remove();

    // };

    const handleEvents = (events: any) => {
        // console.log("events Handel", events)
        setCurrentEvent(events)
    };

    const dispatch = useAppDispatch()
    // console.log("current events", currentEvent)


    useEffect(() => {
        // console.log("CUrrent Events ", currentEvent)
        const convertedEvents = formatEvents(currentEvent)
        // console.log("convertedEEEEEEEEEv", convertedEvents)
        dispatch(updateScheduling(convertedEvents))
    }, [currentEvent])

    return (
        <>
            <FullCalendar
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                selectable={true}
                select={handleDateSelect}
                selectMirror={true}
                eventContent={renderEventContent}
                editable={true}
                initialEvents={INITIAL_EVENTS}
                // eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents}
                headerToolbar={false}
                allDaySlot={false}
                // slotDuration={'1:00:00'}
                // dayHeaders={true}
                // dayHeaderFormat={{ weekday: 'long' }}
                dayHeaderContent={({ date }) => {
                    // Display only the day of the week in the row header
                    return date.toLocaleDateString(undefined, { weekday: 'long' });
                  }}
                  slotLabelContent={(arg) => {
                    // Display only the time in the column header
                    console.log("slot label content is arg", arg)
                    // return time.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                    return arg.text
                  }}
                views={{
                    customTimeGrid: {
                      type: 'timeGridWeek',
                      duration: { days: 7 }, // Display a week view
                      slotDuration: '60:00', // Set the slot duration to 1 hour
                    //   slotDuration:"01:00:00", // Set the slot duration to one hour
                      slotLabelInterval:'60:00', // Set the slot label interval to one hour
                      slotMinTime:"00:00:00", // Set the minimum time to start at midnight
                      slotMaxTime:"24:00:00",
                        slotLabelFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                      },
                    //   slotLabelInterval:"01:00:00"
                      // Display time in columns
                     // Display the day of the week in rows
                    //  columnHeaderFormat: { weekday: 'long' }
                    
                    },
                  }}
        
            // views={views}
            />
        </>
    )
}

export default CalenderPage