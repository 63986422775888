import { Box, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './CreateAdvCampaign.scss'
import StepperForm from './Stepper'
import AdCopy from './Step components/AdCopy';
import ProgramTargeting from './Step components/ProgramTargeting';
import AudienceTargeting from './Step components/AudienceTargeting';
import MediaSource from './Step components/MediaSource';
import GeoTargeting from './Step components/GeoTargeting';
import Scheduling from './Step components/Scheduling';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { campaignFormSelector, cleanCampaignNameError, resetState, setSelectedPopUpAdv, storeCampaignName, storeSelectedAdvertiser } from '../../../Store/Slices/CreateAdvCampaignSlice';
import SnackbarMessage from '../../../Component/Snackbar';
import StateRules from './Step components/StateRules';
import { useLocation, useNavigate } from 'react-router-dom';


const steps = ["Ad Copy", "Program Targeting", "Audience Targeting", "Media Source", "Geo Targeting", "Scheduling"];

export function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <AdCopy />
    case 1:
      return <ProgramTargeting />
    case 2:
      return (
        <AudienceTargeting />

      );
    case 3:
      return (
        <MediaSource />

      );
    case 4:
      // return <GeoTargeting />
      return <StateRules />
    case 5:
      return <Scheduling />
    default:
      return <Scheduling />;
  }
}


//  This is the wrapper component or Parent component 
const CreateAdvCampaigns = () => {

  const advCampaignData = useAppSelector(campaignFormSelector);
  const dispatch = useAppDispatch();
  const { campaignNameError, isEdit, isClone, activeStep,selectedPopUpAdv  } = advCampaignData

const navigate=useNavigate();
const location=useLocation()

// console.log('advCampaignData',advCampaignData.GroupName)
  // const handelCampaignName = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(event.target.value)
  //   dispatch(storeCampaignName(event.target.value))
  //   if (event.target.value.length > 4) {
  //     dispatch(cleanCampaignNameError(""))
  //   }


  // }
  const [editedCampaignName, setEditedCampaignName] = useState(isClone ? `Clone of ${advCampaignData.campaignName}` : advCampaignData.campaignName);
  dispatch(storeCampaignName(editedCampaignName));
  const handelCampaignName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setEditedCampaignName(inputValue);

    // Dispatch actions or handle other logic as needed
    dispatch(storeCampaignName(inputValue));

    if (inputValue.length > 4) {
      dispatch(cleanCampaignNameError(''));
    }
  };

  useEffect(() => {
    // console.log("track, create parent page", advCampaignData,isEdit)
    if(selectedPopUpAdv > 0){
      dispatch(storeSelectedAdvertiser(selectedPopUpAdv))
    }
    
    if( location.pathname==='/editAdvertiserCampaign'){
        if(!isEdit && !isClone){
           navigate('/advertiser-campaigns')
        }

        dispatch(setSelectedPopUpAdv(0));
        // else if(!isClone){
        //   navigate('/advertiser-campaigns')
        // }
    }
    return () => {
      dispatch(resetState("test"));
    };

  }, [dispatch])


  const title = (isEdit ? "Edit Campaign" : (isClone? "Clone Campaign":'Create Campaign'))
  const description = (isEdit ? "Fill the details below to edit campaign" : (isClone? "Fill the details below to clone campaign":" Fill the details below to create campaign"))
  const returnContent = () => {
    return (



      <React.Fragment>
        <Box className="card">
          <Box display="flex" mb={2.5}>
            <Box>
              <Typography className="card-heading">{title}</Typography>
              <Typography className="card-sub-heading">
                {description}
              </Typography>
            </Box>

          </Box>
          <Box display="flex" alignItems="center" mb={4}>
            <TextField id="outlined-basic" label="Campaign Name" variant="outlined" sx={{ width: "100%" }} required onChange={handelCampaignName} error={campaignNameError.length > 0} helperText={campaignNameError} disabled={isEdit || (activeStep>0 && isClone) || (activeStep>0 && !isEdit)} value={editedCampaignName} />
          </Box>
          <StepperForm steps={steps} getContent={getStepContent} isEdit={isEdit} isClone={isClone} />
        </Box>

      </React.Fragment>

    )
  }

  return (
    <>

      {returnContent()}
    </>




  )
}

export default CreateAdvCampaigns

