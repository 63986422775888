import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, OutlinedInput, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import { AudienceTargetData } from '../../../../Mock/AudienceTargetTabData';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector, storeEndYearError, storeStartYearError, updateAudienceTargeting, updateGradYear } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { START_YEAR_FIELD_ERROR } from '../../../../Mock/MessageContstant';


interface AudienceTargetingTabContentProps {
    data: AudienceTargetData[] | any[];
}
const AudienceTargetingTabContent: React.FC<AudienceTargetingTabContentProps> = ({ data }) => {
    // console.log(" audience target contentvalue ", data)
    const [rowFormData, setRowFormData] = useState<AudienceTargetData[] | any[]>(data)
    const [isSelectAll, setSelectAll] = useState(false);
    const dispatch = useAppDispatch()
    const handelBidPrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        const { name, value } = event.target;
        setRowFormData(prevData => prevData?.map(item => (item.id === id ? { ...item, [name]: value } : item)));
    }

    const handleStatus = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        // //console.log("event" , event.target.name)
        const { name, checked } = event.target;
        //console.log("id", id)
        setRowFormData(prevData => prevData?.map(item => (item.id === id ? { ...item, [name]: checked } : item)));

    }
    const checkSelectAllStatus = () => {
        // const { yourData } = this.state; // Replace with the actual name of your data array
        const allSelected = rowFormData.every(item => item.status === true);
        const allDeselected = rowFormData.every(item => item.status === false);

        if (allSelected) {
            setSelectAll(true)
        } else {
            setSelectAll(false);
        }
    };

    useEffect(() => {
        checkSelectAllStatus()
        dispatch(updateAudienceTargeting(rowFormData))
    }, [rowFormData])



    const handelSelectAllCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(event.target.checked)
        const { checked } = event.target;
        if (event.target.checked) {
            setRowFormData(prevData => prevData?.map(item => ({ ...item, status: checked })));
        } else {

            setRowFormData(prevData => prevData?.map(item => ({ ...item, status: checked })));
        }
    }

    function formatLabel(label: string) {
        // Add a space before each uppercase letter and convert the string to lowercase
        let formattedLabel = label.replace(/([A-Z])/g, ' $1');
        // Capitalize the first letter of the entire string
        return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
      }


    return (
        <Paper>
            <Box className='select-all-container'>
                <Box className="select-action">

                    {/* <FormControlLabel control={<Checkbox onChange={handelSelectAllCheckBox}  checked={isSelectAll}/>} label="Select All" /> */}
                    <FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} name='status' checked={isSelectAll} onChange={handelSelectAllCheckBox} />}
                            label="Select All"
                        />
                    </FormGroup>

                </Box>
            </Box>
            <Box className='no-more-tables responsive-tbl'>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{formatLabel(rowFormData[0]?.categories)}</TableCell>
                        <TableCell sx={{ paddingLeft: "30px" }} >Bid Adjustment %</TableCell>
                        <TableCell >Status</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rowFormData?.map((item: AudienceTargetData, index: number) => {
                            return (
                                <TableRow key={index} >
                                    <TableCell data-title={formatLabel(item.categories)}>{item.text}</TableCell>
                                    <TableCell data-title='Bid Adjustment %'>
                                        <FormControl sx={{ width: { xs: '100%', md: '30%' }, my: 1 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount">Adjustment %</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-amount"
                                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                label="Adjustment %"
                                                value={item.bidPrice}
                                                // name={}
                                                name='bidPrice'
                                                onChange={(event) => {
                                                    handelBidPrice(event, item.id)
                                                }}
                                                sx={{
                                                    // Adjust padding for the value inside OutlinedInput
                                                    "& input": {
                                                        padding: ("12px 14px 12px 0px")
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell data-title='Status'>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} checked={item.status} name='status' onChange={(event) => {
                                                    handleStatus(event, item.id)
                                                }} />}
                                                label=""
                                            />
                                        </FormGroup>
                                    </TableCell>



                                </TableRow>
                            )
                        })
                    }


                </TableBody>
            </Table>
            </Box>
        </Paper>
    )
}

export default AudienceTargetingTabContent



export const GradYearSection = () => {
    const dispatch = useAppDispatch()
    const advCampaignFormState = useAppSelector(campaignFormSelector);
    const campaignFormData = useAppSelector(campaignFormSelector)
    const handleInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateGradYear({ type: name, value: value }))
        
        if( name==='startYear' && value >= '1900' && value <= '2050'){
            dispatch(storeStartYearError(''))
        } if(name==='endYear' && value <= '2050' && value>='1900'){
            dispatch(storeEndYearError(''))
        }
       

    }
    //console.log("advCampaignFormState", advCampaignFormState.value)
    return (
        <Paper>
            <Box paddingBottom={5}>
                <FormGroup sx={{ m: 1, minWidth: "100%", p: 1 }}>
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} checked={advCampaignFormState.value.gradYear.unknown} name='unknown' onChange={(event) => {
                            dispatch(updateGradYear({ type: 'unknown', value: event.target.checked }))
                        }} />}
                        label="Unknown"
                    />
                </FormGroup>
                <TextField
                    id="outlined-number"
                    label="Start year"
                    name='startYear'
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={campaignFormData.adCopy.adContent.startYearError.length > 1}
                    helperText={campaignFormData.adCopy.adContent.startYearError.length > 1 ? campaignFormData.adCopy.adContent.startYearError : ""}
                    value={advCampaignFormState.value.gradYear.startYear}
                    onChange={handleInputField}
                    sx={{ mr: 2 , ml: 1.8}} 
                />
            
                <TextField
                    id="outlined-number"
                    label="End year"
                    type="number"
                    name='endYear'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={campaignFormData.adCopy.adContent.endYearError.length > 1}
                    helperText={campaignFormData.adCopy.adContent.endYearError.length > 1 ? campaignFormData.adCopy.adContent.endYearError : ""}
                    value={advCampaignFormState.value.gradYear.endYear}
                    onChange={handleInputField}
                />
            </Box>
        </Paper>

    )
}