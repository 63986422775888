import { scheduling_jsonData } from '../../../../Mock/SchedulingData';
// ../../Store/hooks
// Your input data
// import { scheduling_jsonData } from '../SchedulingData';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks'
import { campaignFormSelector, updateScheduling } from '../../../../Store/Slices/CreateAdvCampaignSlice'
// Your input data
import _debounce from 'lodash/debounce';
import { updateTcpaScheduling } from '../../../../Store/Slices/tcpaCampSlice';
// Define types
interface TimeSlot {
    include: boolean;
}

interface DaySchedule {
    [timeSlot: string]: TimeSlot;
}

interface SchedulingData {
    [day: string]: DaySchedule;
}


// Function to create an initial state based on input data
const DeselectAllCell = (): SchedulingData => {
    const days = Object.keys(scheduling_jsonData);
    //   const timeSlots = generateTimeSlots();
    const timeSlots = Object.keys(scheduling_jsonData["Monday"]);

    const initialState: SchedulingData = {};
    days.forEach(day => {
        initialState[day] = {};
        timeSlots.forEach(slot => {
            initialState[day][slot] = { include: false };
        });
    });

    return initialState;
};

const selectAllCell = (): SchedulingData => {
    const days = Object.keys(scheduling_jsonData);
    //   const timeSlots = generateTimeSlots();
    const timeSlots = Object.keys(scheduling_jsonData["Monday"]);

    const initialState: SchedulingData = {};
    days.forEach(day => {
        initialState[day] = {};
        timeSlots.forEach(slot => {
            initialState[day][slot] = { include: true };
        });
    });

    return initialState;
};

function checkIncludeValues(data: SchedulingData): string {
    const allIncludeTrue = Object.values(data).every((hours) =>
        Object.values(hours).every((time) => time.include)
    );

    return allIncludeTrue ? "DESELECT ALL" : "SELECT ALL";
}

// Main component
const TcpaSchedulingTable: React.FC = () => {
    const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
    const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit,excludeStates} = tcpaCampSlice

    const [tableState, setTableState] = useState<SchedulingData>(tcpaCampSlice.scheduling);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const startCell = useRef<{ day: string; timeSlot: string } | null>(null);
   
    const [btnTextName, setBtnTextName] = useState<string>("");
    
   
    const customDaySort = (a: string, b: string) => {
        const order = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        return order.indexOf(a) - order.indexOf(b);
    };
    const customSlotsSort = (a: string, b: string) => {
        const slotsOrder = ["12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM",
        "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"];
        return slotsOrder.indexOf(a) - slotsOrder.indexOf(b);
    };
    const days = Object.keys(tableState).sort(customDaySort);
    const timeSlots = Object.keys(tableState["Monday"]).sort(customSlotsSort);
    // console.log("timeslots", timeSlots)
    const dispatch = useAppDispatch();
    // console.log("IS MOUSE DOWN", isMouseDown)
    const handleCellClick = (day: string, timeSlot: string): void => {
        // if (isMouseDown) {
        //   // If mouse is down, treat it as dragging
        //   handleCellDragOver({},day, timeSlot);
        // } else {
        // //   If mouse is up, treat it as a simple click
        //   const newState = {
        //     ...tableState,
        //     [day]: {
        //       ...tableState[day],
        //       [timeSlot]: {
        //         ...tableState[day][timeSlot],
        //         include: !tableState[day][timeSlot].include,
        //       },
        //     },
        //   };

        //   // Use delayedSetTableState to update state after a delay
        //   delayedSetTableState(newState);
        // }

        const newState = {
            ...tableState,
            [day]: {
                ...tableState[day],
                [timeSlot]: {
                    ...tableState[day][timeSlot],
                    include: !tableState[day][timeSlot].include,
                },
            },
        };

        // Use delayedSetTableState to update state after a delay
        delayedSetTableState(newState);
    };
   
   
useEffect(()=>{
   const btnName=checkIncludeValues(tableState);
        // console.log("Btn name ", btnName);
        setBtnTextName(btnName);
},[])

    useEffect(() => {
        // console.log("Table State for SCHEDULING", tableState)
        dispatch(updateTcpaScheduling(tableState))
      
    }, [tableState])



    const delayedSetTableState = useCallback(
        _debounce((newState: SchedulingData) => {
            setTableState(newState);
        }, 500), // Adjust the delay time as needed
        []
    );


    const handelDeselectAll = () => {
        const deselectAll = DeselectAllCell();
        setTableState(deselectAll);
        setBtnTextName("SELECT ALL")
    }

    const handelSelectAll = () => {
        const selectAll = selectAllCell();
        setTableState(selectAll);
        setBtnTextName("DESELECT ALL")
    }

    // Function to handle cell drag start
    const handleCellDragStart = (day: string, timeSlot: string): void => {
        setIsMouseDown(true);
        startCell.current = { day, timeSlot };
    };

    // Function to handle cell drag over
    const handleCellDragOver = (event: any, day: string, timeSlot: string): void => {
        // console.log("CELL DRAG over::::::::::::::::::::::::::::::::::::: start cell", startCell)
        // console.log("TABLE STATE drag ovet", JSON.parse(JSON.stringify(tableState)))
        // console.log("TABLE STATE drag timeSlot", timeSlot)

        if (isMouseDown && startCell.current) {
            const startDay = startCell.current.day;
            const startTimeSlot = startCell.current.timeSlot;
            const newState: any = JSON.parse(JSON.stringify(tableState))
            // console.log("new state", newState);
            const daysInRange = days.slice(
                Math.min(days.indexOf(startDay), days.indexOf(day)),
                Math.max(days.indexOf(startDay), days.indexOf(day)) + 1
            );
            const timeSlotsInRange = timeSlots.slice(
                Math.min(timeSlots.indexOf(startTimeSlot), timeSlots.indexOf(timeSlot)),
                Math.max(timeSlots.indexOf(startTimeSlot), timeSlots.indexOf(timeSlot)) + 1
            );


            // console.log("day in  range", daysInRange)
            // console.log("time in  range", timeSlotsInRange)


            daysInRange.forEach(d => {
                timeSlotsInRange.forEach(slot => {
                    newState[d][slot].include = !tableState[d][slot].include
                });
            });

            //   return newState;
            // console.log('new state in DRAG OVER', newState)
            // setTableState(newState);
            delayedSetTableState(newState)
            // handleMouseUp()
            // setTimeout(()=>{
            //     setTableState(newState);
            //     handleMouseUp()

            // },300)
            // setTableState(newState);
            // setTimeout(()=>{
            //   setTableState(prevState => {
            //     const newState:any = JSON.parse(JSON.stringify(prevState))
            //     // console.log("new state", newState);
            //     const daysInRange = days.slice(
            //       Math.min(days.indexOf(startDay), days.indexOf(day)),
            //       Math.max(days.indexOf(startDay), days.indexOf(day)) + 1
            //     );
            //     const timeSlotsInRange = timeSlots.slice(
            //       Math.min(timeSlots.indexOf(startTimeSlot), timeSlots.indexOf(timeSlot)),
            //       Math.max(timeSlots.indexOf(startTimeSlot), timeSlots.indexOf(timeSlot)) + 1
            //     );


            //      console.log("day in  range", daysInRange) 
            //     //  console.log("time in  range", timeSlotsInRange)


            //     daysInRange.forEach(d => {
            //       timeSlotsInRange.forEach(slot => {
            //         newState[d][slot].include =!tableState[d][slot].include
            //       });
            //     });

            //     return newState;
            //   });

            // },500)
        }
    };

    // Function to handle mouse up
    const handleMouseUp = (): void => {
        setIsMouseDown(false);
        startCell.current = null;
    };
    // console.log("table data", tableState)
    //   console.log("time solts ", timeSlots)
    // tableState.hasOwnProperty(tableState[day][timeSlot])
    return (
        <div onMouseUp={handleMouseUp}>
            <Button onClick={() => btnTextName === "DESELECT ALL" ? handelDeselectAll() : handelSelectAll()}
                sx={{
                    backgroundColor: "#00AAEE",

                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "#00AAEE !important"
                    },
                    "&:hover": { backgroundColor: "#00AAEE" },
                    border: "1px solid #0AE",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    borderRadius: "8px",
                    color: "#ffff",
                    height: "45px"
                }}
            >{btnTextName === "DESELECT ALL" ? "DESELECT ALL" : "SELECT ALL"}</Button>
            <TableContainer component={Paper}  style={{ height: '68vh' }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow >
                            <TableCell></TableCell>
                            {days.map(day => (
                                <TableCell key={day}>{day}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timeSlots.map(timeSlot => (
                            <TableRow key={timeSlot}>
                                <TableCell>{timeSlot}</TableCell>
                                {days.map(day => {
                                    let bgColor = 'white';
                                    if (tableState[day][timeSlot]) {
                                        bgColor = tableState[day][timeSlot]?.include ? 'lightblue' : 'white'
                                    }
                                    return (
                                        <TableCell
                                            key={`${day}-${timeSlot}`}
                                            onMouseDown={() => handleCellDragStart(day, timeSlot)}
                                            onMouseEnter={(e) => handleCellDragOver(e, day, timeSlot)}
                                            onClick={() => handleCellClick(day, timeSlot)}
                                            style={{
                                                whiteSpace:'nowrap',
                                                cursor: 'pointer',
                                                backgroundColor: bgColor,
                                                userSelect: 'none'
                                            }}




                                        >
                                            {/* Render your text here based on selected state */}
                                            {
                                                tableState[day][timeSlot] &&
                                                (tableState[day][timeSlot]?.include && 'Campaign On')}
                                        </TableCell>

                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <div>{renderSelectedText()}</div> */}
        </div>
    );
};

export default TcpaSchedulingTable;
