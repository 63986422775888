import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface programContentState {

    bannerTitle_1: string,
    bannerTitle_2: string,
    bannerTitle_3: string,
    uploadImage: string,
    faqToggle: boolean,
    faqs:{ key: any; value: any }[];
    // template: string;
    // primaryColor: string;
    // secondaryColor: string;
    // areaStudy: string;
    // mProgram: string;
    // degreeInterest: string;
    // widgetButton:string;
    // bannerRequestToggle: boolean;
    // bannerRequestInfo:string;
    // onlineSchoolsMatching: boolean;
    // requestInformation: boolean;
    // footerContent: string;
    // footerContentToggle: boolean;
    // footerLinks: [];
    // footerLinksToggle: boolean;
    // copyright: string;
    // copyrightToggle: boolean;
    // advertiserDisclosure: string;
    // advertiserDisToggle: boolean;
    // advertiserMessage: string;
    // advertiserLink: string;
    // // pubID: string;
    // // pubCampaignID: string;
    // // defaultURL: [];
    // uploadImage: string;
    // successMessage:string;
    // errorMessage:string;
    // snackbarOpen:boolean;
    // currentStepperIndex:number;
}


const initialProgramContentState: programContentState = {
    bannerTitle_1: "",
    bannerTitle_2: "",
    bannerTitle_3: "",
    uploadImage: "",
    faqToggle:false,
    faqs: [{ key: '', value: '' }]
}


const PeogramContentApiSlice = createSlice({
    name: 'seoWebsiteList',
    initialState: initialProgramContentState,
    reducers: {

        // setTemplateData: (state, action: PayloadAction<any>) => {
        //     state.template = action.payload;
        // },
        setFaqs: (state, action: PayloadAction<any>) => {
            // console.log('state.footerLinks',state.footerLinks)
            // console.log('action.payload',action.payload)
            state.faqs = action.payload;
        },
        setProgramInfoField: (state, action: PayloadAction<{ field: keyof programContentState, value: string | boolean }>) => {
            const { field, value } = action.payload;
            (state as any)[field] = value;
        },
        // setCurrentStepperIndex:(state, action: PayloadAction<any>) => {
        //     state.currentStepperIndex = action.payload;
        // },

        setToggleField: (state: programContentState, action: PayloadAction<{ field: keyof programContentState }>) => {
            const { field } = action.payload;
            // Toggle the boolean value of the specified field
            (state as any)[field] = !(state as any)[field];
        },
        // setSuccessMessage: (state, action: PayloadAction<any>) => {
        //     state.successMessage = action.payload;
        // },
        // setErrorMessage: (state, action: PayloadAction<any>) => {
        //     state.errorMessage = action.payload;
        // },
        // setSnackbarOpen: (state, action: PayloadAction<any>) => {
        //     state.snackbarOpen = action.payload;
        // }
    }
})


export const { setProgramInfoField, setToggleField,setFaqs } = PeogramContentApiSlice.actions;
export const programContentApi = PeogramContentApiSlice.reducer;

