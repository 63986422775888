import { Box, Paper, Typography, Button, FormControl, Modal } from '@mui/material'
import React, { useState , useEffect} from 'react'
import Search from '../../../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import { UserData, UserTable } from '../../../User Accounts/PublisherUser';
import { fetchUser } from '../../../../service/User';
import CreateUser from '../../../User Accounts/CreateUser';
import UpdateUser from '../../../User Accounts/UpdateUser';
import { DeleteUser } from '../../../User Accounts/DeleteUser';
import SnackbarMessage from '../../../../Component/Snackbar';
import { useLocation } from 'react-router-dom';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  // bgcolor: 'background.paper',
  bgcolor:"#ffffff",
  border: '2px solid #0AE;',
  boxShadow: 24,
  p: 2,
};
const PublisherUsers = () => {
  let location = useLocation();
  const [searchValue, setSearchValue]=useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[] | any>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const [advData, setAdvData]=useState([]);
  // const [pubData, setPubData]=useState([])
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditModalData, setIsEditModalData]=useState<UserData | any>({})
  const [isDeleteModalData, setIsDeleteModalData]=useState<UserData | any>({})
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  let userRole=localStorage.getItem("userRole")

  const handelSearch=()=>{

  };

  const handleSnackBarData = (type:string, isOpen:boolean, message:string) => {
    // Use the setter function returned by useState to update the state
    setSnackbarOpen(isOpen);
    setSnackbarMessage(message)
    setSnackbarType(type);
  };
  
const userGroup=localStorage.getItem("userSortid")??'';
console.log("user group")
  const fetchData = async () => {
    setLoading(true)
  //   const idToken=await fetchToken()
  //  console.log("track1", idToken)
    Promise.all([fetchUser()]).then(([userRes])=>{
      const fd=userRes.result.filter((item:any)=>item.cognitoGroup===userGroup);
console.log("fd",fd)
      setData(fd)
      // setPubData(advRes.result.data)
      // setAdvData(pubRes.result.data)
      setLoading(false)
      // setLoading(false)
    }).catch((error)=>{
      setLoading(true)
    })
  }
  useEffect(() => {
  fetchData()
  // console.log("ReFRESH  console useEFFECT", isRefresh)
  }, [])

console.log("data", data);
const handleClose = () => {
  setIsModalOpen(false)
}

const handleIsEditModalClose = () => {
  setIsEditModalOpen(false)
}

const handleIsEditModalOpen = () => {
  setIsEditModalOpen(true)
}

const handleIsEditModalData = (data:any) => {
  setIsEditModalData(data)
}

const handleIsDeleteModalOpen = () => {
  setIsDeleteModalOpen(true)
}

const handleIsDeleteModalClose = () => {
  setIsDeleteModalOpen(false)
}
const handleIsDeleteModalData = (data:any) => {
  setIsDeleteModalData(data)
}
const handleRefresh=()=>{
  console.log("ReFRESH CLICK")
  // setIsRefresh(isRefresh);
  fetchData() 
}
console.log('kjhfhdskfk===',userRole)
  return (
    <Paper elevation={3} className="card">
       <Box display="flex" justifyContent="space-between" p={3}>
        { ((location.pathname === '/vendor_user' || '/') && userRole === 'Vendor')? <Box>
            <Typography className="card-heading">Vendor User</Typography>
            <Typography className="card-sub-heading">
            Vendor User Summary
            </Typography>
          </Box>:
          <Box>
          <Typography className="card-heading">Publisher User</Typography>
          <Typography className="card-sub-heading">
            Publisher User Summary
          </Typography>
        </Box>}
          <Box display="flex" gap="16px" alignItems="center">
            <Search value={searchValue} searchHandler={handelSearch} />
            {  ((location.pathname !== '/vendor_user') && userRole !== 'Vendor') && 
            <Button
              variant="contained"
              startIcon={<AddIcon />}
             sx={{backgroundColor:"#0ae"}}  
              onClick={() => { setIsModalOpen(true) }}
            >
              Create User
            </Button>}
          </Box>
        </Box>
        <Box display="flex" gap="16px">
        <FormControl style={{ width: '98.3%' , marginBottom:'0.4%',paddingLeft:'1.3%'}}>
  </FormControl>
</Box>
{loading && (
          <div className="loading-border">
            <div className="loading-bar" />
          </div>
        )}
<UserTable data={data} modalCloseHandler={handleIsEditModalClose} modalOpenHandler={handleIsEditModalOpen} deleteModalOpenHandler={handleIsDeleteModalOpen} setEditData={handleIsEditModalData} setDeleteData={handleIsDeleteModalData}/>
<Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <CreateUser   modalCloseHandler={handleClose}  refreshHandler={handleRefresh} userList={data} userRole="Publisher" userGroupName={userGroup} snackBarDataHandler={handleSnackBarData}/>
        </Box>
      
      </Modal>
      <Modal
        open={isEditModalOpen}
        onClose={handleIsEditModalClose}
        aria-labelledby="modal-update-user"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {/* <CreateUser   modalCloseHandler={handleClose} /> */}
        <UpdateUser  data={isEditModalData}   modalCloseHandler={handleIsEditModalClose} refreshHandler={handleRefresh} snackBarDataHandler={handleSnackBarData}/>
        </Box>
     
      </Modal>

        <DeleteUser  data={isDeleteModalData} isDeleteModalOpen={isDeleteModalOpen}  modalCloseHandler={handleIsDeleteModalClose} 
        refreshHandler={handleRefresh} 
        />
         <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }} type={snackbarType} message={snackbarMessage} />
      </Paper>
  )
}

export default PublisherUsers