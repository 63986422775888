import { createAsyncThunk, createSlice ,current} from "@reduxjs/toolkit";
import { generateOtpService } from "../../../service/Auth";



interface IforgetPasswordslice{
userName:string,
user:any,
activeStep:number,
createNewPassword:{
    newPassword:string,
    confirmPassword:string
   },
   formErrors: {
    userNameError: string,
    passwordError:string
  },
   OTP:string,
   getOTPLoading:boolean
}
const INITIAL_STATE:IforgetPasswordslice={
    userName:'',
    user:null,
    activeStep:0,
    createNewPassword:{
        newPassword:'',
        confirmPassword:''

    },
    OTP:'',
    formErrors: {
        userNameError: "",
        passwordError: ""
      },
      getOTPLoading:false
}

export const getOTP = createAsyncThunk("MM/ResetUserPassword", async (userInfo:string) => {
    // console.log("user otp ", userInfo)
    let response = await generateOtpService(userInfo)
    return response;
    //det som returneras här, kommer att bli vår action.payload
  });
  
const forgetPasswordSlice=createSlice({
    name: "Forget password store",
    initialState:INITIAL_STATE,
    reducers:{
       storeUserName:(state, action)=>{
            state.userName=action.payload
       },
       handelUserNameError:(state, action)=>{
          state.formErrors.userNameError=action.payload
       },
       handelPasswordError:(state,action)=>{
        state.formErrors.passwordError=action.payload
       },
       decreaseActiveStep:(state,action)=>{
        state.activeStep=state.activeStep-1
       },
       increaseActiveStep:(state, action)=>{
        state.activeStep=state.activeStep+1
       },
       handelResetStep:()=>{
        return INITIAL_STATE
       }
       
    },
    extraReducers: (builder)=>{
        builder.addCase(getOTP.fulfilled, (state, action) => {
            //If we have to totally replace the existing array:
            // state.todos = action.payload;
      
            //if we want to add the json to an existing array
      
            let updateUser = { ...state.user };
            updateUser = action.payload
            state.user = updateUser;
            state.getOTPLoading=false
            state.activeStep=state.activeStep+1
      
            // state.loading = false;
            // state.activeStep++
          }).addCase(getOTP.pending, (state) => {
            // state.loading = true;
            // console.log("PENDING CASE")
            state.getOTPLoading=true
          }, ).addCase(getOTP.rejected, (state, action) => {
            // console.log("error in redux thunk for get OTP", action)
            // state.error = action.error
            // state.loading = false;
            state.getOTPLoading=true
          })
        
       
      }
});

export default forgetPasswordSlice.reducer;
export const {storeUserName, handelUserNameError,handelPasswordError, decreaseActiveStep, increaseActiveStep , handelResetStep}=forgetPasswordSlice.actions
