import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  InputAdornment,
  Input,
  Button,
  Paper,
  TextareaAutosize,
  FormGroup,
  IconButton,
  CardMedia,
  Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import {
  // setFooterLinks,
  setTempData,
  // setToggleField,
  // setWebsiteInfoField,
} from '../../../../Store/Slices/SEOWebsiteInfoApiSlice';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import CancelIcon from '@mui/icons-material/Cancel';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useFormik, FormikProvider, FieldArray, getIn } from 'formik';
import { headerFooterInfoValidation } from '../../validation';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { updateWebSiteContent } from '../../../../Services/seoApis';
import { setApiLoading, setSnackbarOpen, setSuccessMessage } from '../../../../Store/Slices/SEOHandlerApisSlice';

interface headerFooterInfoFieldValues {
  footerContent: string;
  footerContentToggle: boolean;
  footerLinksToggle: boolean;
  footerLinks: { key: string; value: string }[];
}

const HeaderFooterContent: FC<any> = ({ submitRef, handleNext }) => {
  const websiteInfoFormData = useAppSelector(state => state.websiteInfoApi);
  const dispatch = useAppDispatch();
  const [footerContentEditorState, setFooterContentEditorState] = useState(() =>
    EditorState.createEmpty()
  );


  const formik = useFormik<headerFooterInfoFieldValues>({
    initialValues: {
      footerContent: websiteInfoFormData?.footerContent,
      footerContentToggle: websiteInfoFormData?.footerContentToggle,
      footerLinksToggle: websiteInfoFormData?.footerLinksToggle,
      footerLinks: websiteInfoFormData?.footerLinks,
    },
    onSubmit: values => {
      // console.log('values', values);
      dispatch(setApiLoading(true));
      dispatch(setTempData({ ...websiteInfoFormData, ...values }));
      const updateContent = async () => {
        try {
          await updateWebSiteContent({ ...websiteInfoFormData, ...values });
          dispatch(setSuccessMessage('Updated Header & Footer'));
          dispatch(setSnackbarOpen(true));
          handleNext();
        } catch (error) {
          console.error('Error fetching data:', error);
          dispatch(setApiLoading(false));
        }
        dispatch(setApiLoading(false));
      };

      updateContent();
    },
    validationSchema: headerFooterInfoValidation,
  });

  const { handleSubmit } = formik;

  // Function to load the editor initial value
  const loadInitialValue = (initialContent: string) => {
    if (initialContent) {
      const contentBlock = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setFooterContentEditorState(editorStateFromHTML);
    }
  };
  useEffect(() => {
    submitRef.current = () => {
      handleSubmit();
    };
    loadInitialValue(websiteInfoFormData?.footerContent);
  }, []);

  useEffect(() => {
    const rawContentState = convertToRaw(footerContentEditorState.getCurrentContent());
    rawContentState?.blocks.forEach(value => {
      if (value.text) {
        formik.setFieldValue(
          'footerContent',
          draftToHtml(rawContentState)
        );
      }
    });
  }, [footerContentEditorState]);

  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, [])
  return (
    <Paper elevation={3} className="ad-campaign-form">
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={formik.values.footerContentToggle}
                  onChange={() => {
                    formik.setFieldValue(
                      'footerContentToggle',
                      !formik.values.footerContentToggle
                    );
                    // dispatch(setToggleField({ field: 'footerContentToggle' }));
                  }}
                  sx={{ ml: 2 }}
                />
              }
              sx={{ ml: 1 }}
              label={
                <Typography sx={{ fontWeight: '500' }}>
                  Footer Content
                </Typography>
              }
              labelPlacement="start"
            />
          </Grid>
          {formik.values.footerContentToggle && (
            <Grid item xs={12}>
              <Editor
                editorState={footerContentEditorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={editorState => {
                  setFooterContentEditorState(editorState);

                }}
                onChange={() => formik.setFieldTouched('footerContent', false)}
                //   onBlur={() => setFieldformik.touched('footerContent', false)}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'emoji',
                    'remove',
                    'history',
                  ],
                }}
              />
              {formik.values.footerContentToggle &&
                formik.errors.footerContent &&
                formik.touched.footerContent ? (
                <Box color="#db2424">{formik?.errors?.footerContent}</Box>
              ) : null}
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <IOSSwitch
                  name="footerLinksToggle"
                  checked={formik.values.footerLinksToggle}
                  onChange={() => {
                    formik.setFieldValue(
                      'footerLinksToggle',
                      !formik.values.footerLinksToggle
                    );
                    //  dispatch(setToggleField({ field: 'footerLinksToggle' }));
                  }}
                  sx={{ ml: 2 }}
                />
              }
              sx={{ ml: 1 }}
              label={
                <Typography sx={{ fontWeight: '500' }}>Footer Links</Typography>
              }
              labelPlacement="start" // Changed from "start" to "end"
            />
          </Grid>
          {formik.values.footerLinksToggle && (
            <Grid item xs={12}>
              <FieldArray
                name="footerLinks"
                render={arrayHelpers => (
                  <>
                    {formik.values.footerLinks.map((url: any, index: any) => {
                      const touchedKey = getIn(
                        formik.touched,
                        `footerLinks.${index}.key`
                      );
                      const errorKey = getIn(
                        formik.errors,
                        `footerLinks.${index}.key`
                      );
                      const touchedValue = getIn(
                        formik.touched,
                        `footerLinks.${index}.value`
                      );
                      const errorValue = getIn(
                        formik.errors,
                        `footerLinks.${index}.value`
                      );

                      return (
                        <div key={index}>
                          <InputLabel
                            style={{
                              fontWeight: '500',
                              marginBottom: '10px',
                              marginLeft: '10px',
                            }}
                          >
                            Link {index + 1}
                          </InputLabel>

                          <div
                            style={{
                              position: 'relative',
                              marginBottom: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '20px',
                            }}
                          >
                            <div
                              style={{
                                position: 'relative',
                                flex: 1,
                                marginBottom: '10px',
                                border: '2px solid #ccc',
                                borderRadius: '5px',
                                padding: '3% 2%',
                              }}
                            >
                              <TextField
                                label="Key"
                                variant="outlined"
                                name={`footerLinks[${index}].key`}
                                value={formik.values.footerLinks[index].key}
                                onChange={formik.handleChange}
                                fullWidth
                                error={touchedKey && Boolean(errorKey)}
                                helperText={
                                  touchedKey && errorKey ? errorKey : ''
                                }
                              />
                              <TextField
                                label="Value"
                                variant="outlined"
                                name={`footerLinks[${index}].value`}
                                value={formik.values.footerLinks[index].value}
                                onChange={formik.handleChange}
                                fullWidth
                                style={{ marginTop: '3%' }}
                                error={touchedValue && Boolean(errorValue)}
                                helperText={
                                  touchedValue && errorValue ? errorValue : ''
                                }
                              />
                              {/* <Box color="#db2424">
                                <ErrorMessage
                                  name={`footerLinks.${index}.value`}
                                />
                              </Box> */}
                            </div>
                            {index > 0 && (
                              <IconButton
                                aria-label="remove"
                                onClick={() => arrayHelpers.remove(index)}
                                style={{
                                  position: 'absolute',
                                  top: '-10px',
                                  right: '-10px',
                                  backgroundColor: 'white',
                                  borderRadius: '50%',
                                }}
                              >
                                <CancelIcon
                                  sx={{ color: '#f45d5d', fontSize: 40 }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {formik.values.footerLinks.length < 4 && (
                      <span
                        onClick={() =>
                          arrayHelpers.push({ key: '', value: '' })
                        }
                        style={{
                          textDecoration: 'none',
                          color: '#18c0e2',
                          fontSize: '20px',
                          fontWeight: '500',
                          cursor: 'pointer',
                        }}
                      >
                        + Add More Link
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </Paper>
  );
};

export default HeaderFooterContent;
