import AdvertiserSVGIcon from '../assets/AdvertiserSVGIcon';
import ExternalAPIsIcon from '../assets/External APIs';
import ReportingSVGIcon from '../assets/ReportingSVGIcon';
import ToolIconSVGIcon from '../assets/ToolIconSVGIcon';
import PublisherSVGIcon from '../assets/PublisherSVGIcon';
import UserSvgIcon from '../assets/UserSvgIcon';
import SEOIcon from '../assets/seoSVGIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WebhookIcon from '@mui/icons-material/Webhook';
export const sideNavMenus=[
    {
        id:"USER",
        roles: ['admin', "Super-Admin"],
        menuName:"User Accounts",
        menuIcon:UserSvgIcon,
        // link:"user",
        subMenu:[
            {
                menuIcon:"User Accounts",
                menuName:"Users",
                link:"user"
            },
            {
                menuIcon:"",
                menuName:"Vendors",
                link:"vendors"
            }
           
        ]
    },
    {   id:"PUB",
        menuName:"Publisher",
        menuIcon:PublisherSVGIcon,
        roles: [ 'admin', "Super-Admin"],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Publisher Accounts",
                link:"publisher-accounts"
            },
            {
                menuIcon:"",
                menuName:"Publisher Campaign",
                link:"publisher-campaigns"
            }
        ]
    },
    {
        id:"ADV",
        menuName:"Advertiser",
        menuIcon:AdvertiserSVGIcon,
        roles: ['admin', "Super-Admin"],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Advertiser Accounts",
                link:"advertiser-accounts"
            },
            {
                menuIcon:"",
                menuName:"Click Campaigns",
                link:"advertiser-campaigns"
            },
            {
                menuIcon:"",
                menuName:"Lead Campaigns",
                link:"lead-campaigns"
            },
            {
                menuIcon:'',
                menuName:"TCPA Campaigns",
                link:"TCPA-campaigns"
            }
        ]
    },
    ///FOR USER PUBLISHER
    {
        id:"User-Pub",
        menuName:"Publisher",
        menuIcon:PublisherSVGIcon ,
        roles: ['Publisher'],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Publisher Campaign",
                link:"publisherCampaigns"
            }
        ]
    },

    {
        id:"pub-user",
        menuName:"Users List",
        menuIcon:UserSvgIcon,
        roles: ['Publisher'],
        link:"publisher_user"
        // subMenu:[
        //     {
        //         menuIcon:"",
        //         menuName:"Publisher Campaign",
        //         link:"publisherCampaigns"
        //     }
        // ]
    },
 
    {
        id:"DASHBOARD",
        menuName:"Dashboard",
        menuIcon:DashboardIcon ,
        roles: ['Publisher'],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Intraday",
                link:"intraday"
            },
            {
                menuIcon:"",
                menuName:"Overview",
                link:"overall"
            },
            {
                menuIcon:"",
                menuName:"Click To Leads",
                link:"click-to-leads"
            },
            {
                menuIcon:"",
                menuName:"Raw report",
                link:"raw-report"
            },
            
        ]
    },
    {
        id:"TOOLS",
        menuName:"Tools",
        menuIcon:WebhookIcon ,
        roles: ['Publisher'],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Test API",
                link:"testApi"
            },
            
        ]
    },


    ////////////////////// Admin////////////////////////////////
    {
        id:"EX-API",
        menuName:"API Settings",
        menuIcon:ExternalAPIsIcon ,
        roles: ['admin', "Super-Admin"],
        subMenu:[
            {
                menuIcon:"",
                menuName:"mPartner Activation",
                link:"mPartner-activation"
            },
            {
                menuIcon:"",
                menuName:"Publisher Margins",
                link:"mPub-margins"
            },
            
            // {
            //     menuIcon:"",
            //     menuName:"mPartner Margins",
            //     link:"mPartner-margins"
            // },
           
            
        ]
    }, 
    {
        id:"CMS",
        menuName:"CMS",
        roles: ['admin', "Super-Admin"],
        menuIcon:SEOIcon ,
        subMenu:[
            {
                menuIcon:"",
                menuName:"Websites",
                link:"cms-website"
            },
            {
                menuIcon:"",
                menuName:"Advertiser Portal",
                link:"advertiseHandler"
            }
            
        ]
    },
    {
        id:"TOOLS",
        menuName:"Tools",
        roles: ['admin', "Super-Admin"],
        menuIcon:ToolIconSVGIcon,
        subMenu:[
            {
                menuIcon:"",
                menuName:"Brand Mapping",
                link:"brand-mapping"
            },
            {
                menuIcon:"",
                menuName:"Budget Allocation",
                link:"budget-allocation"
            },  
              {
                menuIcon:"",
                menuName:"Caps Filtering",
                link:"caps-filtering"
            },
            {
                menuIcon:"",
                menuName:"CPC Threshold",
                link:"cpc-threshold"
            },
            {
                menuIcon:"",
                menuName:"Test API",
                link:"adminTestAPI"
            },
            {
                menuIcon:"",
                menuName:"Test TCPA API",
                link:"admin-test-tcpa-API"
            },
            {
                menuIcon:"",
                menuName:"Test Email API",
                link:"emailAPI"
            },
           

        ]
    },
     ///FOR USER ADVERTISER or VENDOR
     {
        id:"vendor-user",
        menuName:"Users List",
        menuIcon:UserSvgIcon,
        roles: ['Vendor'],
        link:"vendor_user"
    },
 
     {
        id:"vendor",
        menuName:"Advertiser",
        menuIcon:AdvertiserSVGIcon ,
        roles: ['Vendor'],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Advertiser",
                link:"vendor-ad"
            },
            {
                menuIcon:"",
                menuName:"Advertiser Campaign",
                link:"vendor-ad-campaigns"
            },
           
            
        ]
    },

   
    {
        id:"DASHBOARD",
        menuName:"Dashboard",
        menuIcon:DashboardIcon ,
        roles: ['Vendor'],
        subMenu:[
            {
                menuIcon:"",
                menuName:"Intraday",
                link:"adv-intraday"
            },
            {
                menuIcon:"",
                menuName:"Overview",
                link:"adv-overall"
            },
            {
                menuIcon:"",
                menuName:"Click To Leads",
                link:"adv-click-to-leads"
            },
            // {
            //     menuIcon:"",
            //     menuName:"Raw report",
            //     link:"adv-raw-report"
            // },
            
        ]
    },
    //////////////////////
]



 // {
    //     id:"REPORT",
    //     menuName:"Reporting",
    //     menuIcon:ReportingSVGIcon,
    //     subMenu:[
    //         {
    //             menuIcon:"",
    //             menuName:"Admin Report",
    //             link:"admin-report"
    //         },
    //         {
    //             menuIcon:"",
    //             menuName:"Publisher Report",
    //             link:'publisher-report'
    //         },
    //         {
    //             menuIcon:"",
    //             menuName:"Advertiser Report",
    //             link:'advertiser-report'
    //         },
    //         {
    //             menuIcon:"",
    //             menuName:"Raw Data Report",
    //             link:"raw-data-report"
    //         }
            
    //     ]
    // },
    // {
    //     id:"TOOLS",
    //     menuName:"Tools",
    //     menuIcon:ToolIconSVGIcon,
    //     subMenu:[
    //         {
    //             menuIcon:"",
    //             menuName:"Margin Distributer",
    //             link:"margin-distributer"
    //         },
    //         {
    //             menuIcon:"",
    //             menuName:"Weighted Adjustment",
    //             link:"weighted-adjustment"
    //         },
          
            
    //     ]
    // },
   


       ///FOR USER PUBLISHER
    // {
    //     id:"EX-API",
    //     menuName:"External APIs",
    //     menuIcon:ExternalAPIsIcon ,
    //     roles: ['Publisher'],
    //     subMenu:[
    //         {
    //             menuIcon:"",
    //             menuName:"mPartner Activation",
    //             link:"usermPartnerActivation"
    //         },
    //     ]
    // },


     // subMenu:[]
        // subMenu:[
        //    {
        //     menuName:"Publisher User Account",
        //     link:"publisher-user"
        //    },
        //    {
        //     menuName:"Advertiser User Account",
        //     link:"Advertiser-user"
        //    }


        // ]
