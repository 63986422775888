import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
interface ISearch{
    value:string, 
    searchHandler:(event:React.ChangeEvent<HTMLInputElement>)=>void
}
const Search = (props:ISearch) => {
   
  return (
    <FormControl  size="small" className='search-input' variant="outlined">
    <OutlinedInput
      id="outlined-adornment-search"
      startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
      aria-describedby="outlined-search-helper-text"
      inputProps={{
        'aria-label': 'Search',
        'placeholder': "Search"
      }}
      onChange={props.searchHandler}
      value={props.value}
    />

  </FormControl>
  )
}

export default Search