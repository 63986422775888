// import React, {  useState } from "react";
import 'devextreme/dist/css/dx.light.css';
import DataGrid, {
  Column,
  Export,
  
  GroupPanel,
  Grouping,
  DataGridTypes,
  Summary,
  TotalItem,
  GroupItem,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
// import { employees } from "./data.ts";
// import { intraDayRows, columns} from "../../Mock/GridConstants.ts";
// import { calculateMetrics } from "../../utils/index.ts";

// import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import { ColumnResizeMode } from 'devextreme/common/grids';


const resizingModes: ColumnResizeMode[] = ['nextColumn', 'widget'];
// const columnResizingModeLabel = { 'aria-label': 'Column Resizing Mode' };

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "Media Mather.xlsx"
      );
    });
  });
};

// const totalSummary=calculateMetrics(intraDayRows)


interface dxGrid{
rows:any[],
columns:any[]
summaryItem:any[]
}
const  DxGrid :React.FC<dxGrid>= ({rows,columns,summaryItem}) => {
  // console.log("rows dxx grid ",rows)
  let totalClicks=0;
  let totalClicksForCTR=0;
  let totalAdvClicks=0;
  let totalCommission=0.0;
  let totalSpent=0.0;
  let finalCommission=0.0;
  let finalSpent=0.0;
  let totalRequests=0;
    const calculateCustomColumns=(options: DataGridTypes.CustomSummaryInfo)=>{
       if(options.name==='CPC'){
      // options.totalValue=totalSummary.finalAverageCPC
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      
      if (options.summaryProcess === 'calculate') {
        if (options.value){
          const clicks = options.value.CLICKS;
          const commission = parseFloat(options.value["Total Commission"].replace("$ ", ""));
            totalClicks+=parseInt(clicks);
            totalCommission+=commission
        }
      }
      if (options.summaryProcess === 'finalize') {
        if (totalClicks > 0) {
          
          options.totalValue = Number.isNaN( totalCommission / totalClicks)?0.00:(totalCommission / totalClicks).toFixed(2);
          totalCommission=0.0
          totalClicks=0
        }
      }
       }else if (options.name==='RPC'){
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        
        if (options.summaryProcess === 'calculate') {
          if (options.value){
            const clicks = options.value.CLICKS;
            const spent = parseFloat(options.value["Total Spent"].replace("$ ", ""));
            totalAdvClicks+=parseInt(clicks);
              totalSpent+=spent
          }
        }
        if (options.summaryProcess === 'finalize') {
          if (totalAdvClicks > 0) {
            options.totalValue = (totalSpent / totalAdvClicks).toFixed(2);
            totalSpent=0.0;
            totalAdvClicks=0;
            
          }
        }
       }
       else if(options.name==='CTR'){
            //  options.totalValue=totalSummary.finalAverageCTR
            // let totalClicks=0;
            
            if (options.summaryProcess === 'start') {
              options.totalValue = 0;
              totalClicksForCTR=0
              totalRequests=0.0
            }
      
            if (options.summaryProcess === 'calculate') {
              if (options.value){
                // options.totalValue +=options.value
                totalClicksForCTR+=parseInt(options.value.CLICKS);
                totalRequests+= parseInt(options.value.Ad_Requests)
        
              //   const avgCTR= (totalClicks / totalRequests)*100;
              //  options.totalValue=avgCTR.toFixed(2) + " " + "%"
              }
            }


            if (options.summaryProcess === 'finalize') {
                const avgCTR= Number.isNaN( totalClicksForCTR/totalRequests)?0.00:  ( totalClicksForCTR/totalRequests )*100;
                console.log("Avg ctr", avgCTR)
                console.log("CLCIKS", totalClicksForCTR)
                console.log("Requests", totalRequests)
               options.totalValue=avgCTR.toFixed(2) +  " %"
               totalClicksForCTR=0
               totalRequests=0
              
            }
       }else if(options.name==='commission'){
      
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      
      }

      if (options.summaryProcess === 'calculate') {
        if (options.value){
          const commissionValue=parseFloat(options.value["Total Commission"].replace("$ ", ""))
          finalCommission+= commissionValue
        }
      }

      if (options.summaryProcess === 'finalize') {
        options.totalValue=finalCommission.toFixed(2)   
        finalCommission=0.0
      }
       }else if(options.name==='spent'){
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        
        }
  
        if (options.summaryProcess === 'calculate') {
          if (options.value){
            const spent=parseFloat(options.value["Total Spent"].replace("$ ", ""))
            finalSpent+= spent
          }
        }
  
        if (options.summaryProcess === 'finalize') {
          options.totalValue=finalSpent.toFixed(2)  
          finalSpent=0.0 
        }
       }else if(options.name==="clickSummary"){
        // console.log("Option",+ " " +"name  "+ options.name+"value"+"  " +options.value)
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        if (options.value){
          options.totalValue +=parseInt(options.value.CLICKS)
        }
       
       }else if(options.name==="requestSummary"){
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }

        if (options.summaryProcess === 'calculate') {
          if (options.value){
            const requestValue=parseInt(options.value.Ad_Requests)
             options.totalValue +=requestValue
  
          }
        }
       
      




        // options.totalValue +=options.value
       }
          }
        
    return(
  <DataGrid
    id="gridContainer"
    dataSource={rows}
    keyExpr="ID"
    width="100%"
    showBorders={true}
    onExporting={onExporting}
    allowColumnResizing={true}
    columnResizingMode={resizingModes[0]}
    columnMinWidth={50}
    columnAutoWidth={true}
    showRowLines={true}
  >
 <GroupPanel visible={true} />
<Grouping autoExpandAll={true} /> 
    {
        columns.map((column, index)=>{
            if( column.field=== "Date/Time"){
                return  <Column dataField={column.field} name={column.headerName} key={index} groupIndex={0} />
            }else{
                return  <Column dataField={column.field} name={column.headerName} key={index}  alignment="center" />
            }
            
        })
    }
    <Export enabled={true} />
    <Summary calculateCustomSummary={calculateCustomColumns} >
      {
        summaryItem.map((item)=>{
 return (
  <GroupItem
  column={item.column}
  summaryType={item.summaryType}
  displayFormat={item.displayFormat}
  alignByColumn={true}
  name={item.name}
  showInColumn={item.showInColumn}
  showInGroupFooter={true}
  valueFormat={item.format}
/>
 )
        })
      }
       </Summary>
  </DataGrid>
)};

export default DxGrid;
