import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup, Select, MenuItem, Button, Input, FormControl, Box, IconButton, TextField, InputLabel } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../../Store/hooks';
import { campaignFormSelector, updateZipRules, updateZipRulesFilterType } from '../../../../Store/Slices/CreateAdvCampaignSlice';
// import Papa from 'papaparse';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { updateTcpaZipRules, updateTcpaZipRulesFilterType, ZipData } from '../../../../Store/Slices/tcpaCampSlice';

const TcpaZipRules: React.FC = () => {
  const dispatch = useAppDispatch()
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit,excludeStates,zipRules} = tcpaCampSlice
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedUploadType, setSelectedUploadType] = useState(zipRules?.filterType);
  const [zipData, setZipData] = useState<ZipData>({});
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | any>("")
  

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  useEffect(()=>{
if(tcpaEditFlag){
 if( zipRules.zipCode !== null ){
  setSelectedOption("add")
  setSelectedFileName("Last uploaded file.csv")
  console.log("zipRules.zipCode ", zipRules.zipCode )
  // setBtnText("DOWNLOAD")
 }else{
  setSelectedOption('remove')
 }
 
}else{
  setSelectedOption('remove')
}
  },[])


  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    setSelectedFileName(file?.name);
    //console.log("file", file);
    if (file) {
      setCsvFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const csvData = e.target.result as string;

          // Split the CSV data by newline to get individual zip codes
          const zipCodes = csvData.split('\n').map((zip) => zip.trim());

          const newZipData: ZipData = {};
          zipCodes.forEach((zipCode: string) => {
            if (zipCode) {
              newZipData[zipCode] = { [selectedUploadType]: selectedOption === 'add' };
            }
          });
  
          // Dispatch the action to update the store immediately
          const updatedzipRule = {
            ...zipRules,
            zipCode: newZipData
          };
          dispatch(updateTcpaZipRules(updatedzipRule));
          //console.log("updated zip Rules", updatedzipRule);
        }
      };
      reader.readAsText(file);
    }
  };

  
//   const handleIncludeExcludeChange = (zipCode: string, include: boolean) => {
//     setZipData((prevZipData) => ({
//       ...prevZipData,
//       [zipCode]: { include },
//     }));
//   };

  const handleUploadType = (event: any) => {
    setSelectedUploadType(event?.target.value)
    dispatch(updateTcpaZipRulesFilterType(event.target.value))
  }
  useEffect(() => {
    if (selectedOption === 'remove') {
      const removeZipRules = {
        ...zipRules,
        zipCode: null
      }
      dispatch(updateTcpaZipRules(removeZipRules))
    }
  }, [selectedOption])

  const generateCsvContent = () => {
    const data = {
      '113388': { exclude: true },
      '122221': { exclude: true },
      '750053': { exclude: true },
    };
    let csvContent = ""
    const { zipCode } = zipRules

    if (zipCode) {
      //console.log("Create data from file")
      csvContent = Object.keys(zipCode).join('\n');
    } else {
      csvContent = Object.keys(data).join('\n');
    }

    return csvContent;
  };

  const handleDownloadCsv = () => {
    const csvContent = generateCsvContent();
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'zip_codes.csv';
    link.click();
  }


//   const updateStoreZipRules=()=>{
//     const updatedzipRule={
//       ...zipRules,
//       zipCode:zipData
//     }
// dispatch(updateZipRules(updatedzipRule))
//     //console.log("updated zip Rules", updatedzipRule)
//   }
  return (
    <div className='zip-rules-container'>
      <RadioGroup row name="option" value={selectedOption} onChange={handleOptionChange}>
        <FormControlLabel value="add" control={<Radio />} label="Include Zip Code" />
        <FormControlLabel value="remove" control={<Radio />} label="Remove Zip Code" />
      </RadioGroup>

      <FormControl >
      <InputLabel style={{display:'none'}} id="demo-select-small-label">Filter Type</InputLabel>
        <Select style={{display:'none'}} value={selectedUploadType} disabled={selectedOption === 'remove'} onChange={handleUploadType}   label="Filter Type">
          <MenuItem value="include">Include</MenuItem>
          {/* <MenuItem value="exclude">Exclude</MenuItem> */}
        </Select>
      </FormControl>

      {/* <Input type="file"  onChange={handleFileUpload}  disabled={selectedOption === 'remove'}/> */}

      <Box className="zip-upload-container">
        <Box>
          <label htmlFor="file-input">
            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              disabled={selectedOption === 'remove'}  >

              <AttachFileIcon style={{ marginTop: "19px", fontSize: '30px' }} />
            </IconButton>
          </label>
          <input
            type="file"
            accept=".csv"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <TextField
            // style={{ width: "70%" }}
            id="standard-helperText"
            label="Upload Zip code list"
            helperText="Select .csv files"
            variant="standard"
            value={selectedFileName}
            disabled={selectedOption === 'remove'}
          />
        </Box>


                <Box className="download-upload-btn-container">
      {/* <Button variant="contained" color="primary" onClick={updateStoreZipRules} disabled={selectedOption === 'remove'}>
       Upload File
      </Button> */}

        <Box className="download-upload-btn-container">
          {/* <Button variant="contained" color="primary" onClick={updateStoreZipRules} disabled={selectedOption === 'remove'}>
            Upload File
          </Button> */}
          <Button sx={{
             backgroundColor:"#1976d2",

             "&.MuiButtonBase-root:hover": {
               bgcolor: "#1976d2 !important"
             },
             "&:hover": { backgroundColor:"#1976d2" },
          border: "1px solid #1976d2",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          borderRadius: "8px",
          color: "#ffff",
          height: "45px"
        }}
         onClick={handleDownloadCsv} >
            {(zipRules.zipCode !== null)? "DOWNLOAD" : "TEMPLATE"}

          </Button>

        </Box>
      </Box>

</Box>


    </div>
  );
};

export default TcpaZipRules;
