import { TableContainer } from '@mui/material'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircleLoader } from 'react-spinners'
import { useAppSelector } from '../../Store/hooks'

interface infinite {
    datalength:number,
    fetchMore:any,
    hasMore:boolean,
    LoadingMore:boolean
}

const ScrollComp = (props:infinite) => {
    const {datalength,fetchMore,hasMore,LoadingMore} = props
    const scrollNext = useAppSelector((state) => state.advertiserAccApi.scrollNext);
    const blockScroll = useAppSelector((state) => state.advertiserCampApi.blockScroll);
  return (
    <TableContainer>
   {(scrollNext && !blockScroll) &&  <InfiniteScroll
      dataLength={datalength}
      next={fetchMore}
      hasMore={hasMore}
      loader={null}
      children={undefined}
      />}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <CircleLoader color="#00BFFF" loading={LoadingMore? true: false} size={60} />
        </div>
      </TableContainer>
      )
}

export default ScrollComp
