import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore, } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sideNavMenus } from '../../Mock/SidenavConstant';
import SignOutSVGIcon from '../../assets/SignOutSVGIcon';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../Store/hooks';
import { handelLogout,setUserType } from '../../Store/Slices/Auth/userSlice';
import {Auth} from 'aws-amplify';
export default function SideBarList() {
  const location = useLocation()
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const dispatch=useAppDispatch()
  const navigate = useNavigate()
  const handelMenu = (id: number, item:any) => {
    setActiveItem(activeItem === id ? null : id);
    // if(item.id==='USER'){
    //  navigate("/user")
    // }
    if(item.id==="pub-user"){
      navigate("/publisher_user")
     }
     if(item.id==="vendor-user"){
      navigate("/vendor_user")
     }
   
  }
  let userRole:any =localStorage.getItem("userRole")
  const filteredMenus = sideNavMenus.filter((menu) => {
    // Check if the user's role is included in the roles array of the menu item
    return menu.roles?.includes(userRole);
  });
useEffect(()=>{
filteredMenus.forEach((menu,index )=>{
  if(menu.subMenu){
    menu.subMenu.forEach((subMenu, i)=>{
      if(subMenu.link===location.pathname.replace("/","")){
         setActiveItem(index)
      }
    })
  }
 
})

if(location.pathname==='/'){
  setActiveItem(1);
}

},[])

  const handelSignOut=()=>{
    dispatch(setUserType(''));
 Auth.signOut().then(()=>{
  // console.log("logout successful", )
  dispatch(handelLogout("logout successfull"))
  navigate("/login")
  window.location.reload();
 }).catch((err)=>{
 console.log("err", err)
 })
  }
  // console.log("activeItem ", activeItem)
  const renderSidebarList = () => {
    return filteredMenus.map((items, index) => {
      return (
        <>
          <ListItemButton onClick={() => handelMenu(index, items)} key={index} className={(index === activeItem  || items.link===location.pathname.replace("/", '')) ? 'side-nav-active' : ''}>
            <ListItemIcon>
              {<items.menuIcon iconColor={index === activeItem ? "#ffffff" : "#737791"} />}
            </ListItemIcon>
            <ListItemText primary={items.menuName} />
          
          {
            items.subMenu ?
            (index === activeItem) ? <ExpandLess /> : <ExpandMore />:""
          }  
    
          </ListItemButton>
          {items.subMenu &&
          <Collapse in={index === activeItem ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                items.subMenu.map((subMenu,index) => {
                  return (
                    <Link to={`/${subMenu.link}`} state={{ drawerHeader: subMenu.menuName }} style={{ all: "unset" }} key={index}>
                      <ListItemButton sx={{ pl: 4 }} >

                        <ListItemText primary={subMenu.menuName} className={subMenu.link === location.pathname.replace("/", '') ? 'active-side-nav-submenu' : 'side-nav-submenu'} />
                      </ListItemButton>
                    </Link>
                  )
                })
              }

            </List>
          </Collapse>
    }
        </>
      )
    })
  }


  return (
    <Box px={2}>
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
       
        {renderSidebarList()}
        <ListItemButton  onClick={handelSignOut}>
          <ListItemIcon >
            {<SignOutSVGIcon />}
          </ListItemIcon>
          <ListItemText primary={"Sign out"} />
        </ListItemButton>
      </List>
    </Box>
  );
}



