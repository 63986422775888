export const  CAMPAIGN_NAME_FIELD_ERROR='Please add Campaign Name';
export const ADVERTISER_NAME_NAME_ERROR='Please Select Advertiser';
export const DISPLAY_URL_FIELD_ERROR='Please add Display URL';
export const HEAD_CONTENT_FIELD_ERROR="Please add Head Content";
export const TRACKING_URL_FIELD_ERROR='Please add  valid Tracking URL';
export const CAMPAIGN_CREATE_SUCCESS='Congratulations! Your Campaign Has Been Successfully Created'
export const CAMPAIGN_UPDATE_SUCCESS='Congratulations! Your Campaign Has Been Successfully Updated'
export const USER_CREATE_SUCCESS='Congratulations! User Has Been Successfully Created'
export const USER_UPDATE_SUCCESS='Congratulations! User Has Been Successfully updated'
export const START_YEAR_FIELD_ERROR='Start year must be b/w 1900 to 2050';
export const END_YEAR_FIELD_ERROR='End year must be b/w 1900 to 2050';
export const END_YEAR_BLANK_ERROR='Please add end year';
export const START_YEAR_BLANK_ERROR='Please add start year';



