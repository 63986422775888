import { createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#1976D2',
  //   },
  //   secondary: {
  //     main: '#FFC107',
  //   },
  // },
  typography: {
    fontFamily: 'Poppins',
  },
});

export default theme;
