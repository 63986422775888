
import { Box, Button, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { CircleLoader } from 'react-spinners';
import { areaStudyDropdown, mPrograms, degreeInterest, highestEducation, startTimeframe, campusType, militaryAffiliation, mediaTypeOptions, inventoryTypeOptions } from '../../../Mock/Tools data/testApi'
import BasicTextField from '../../../Component/Basic TextField/BasicInput';
import AreaCard from '../../../Component/Card';
import BasicSelect from '../../../Component/DropDowns/BasicSelect';
import { buildUrl, fetchAllPublisherCampaignsData, fetchListing, fetchAllPublishersData, getUserIp, fetchTCPAData } from '../../../service/Publisher';
import { getMBrandsDetails } from '../../../service/Advertiser';
//  import { IOSSwitch } from '../../Component/Table/MMTable';
import { IOSSwitch } from '../../../Component/Table/MMTable';
import { fetchTCPA_LISTING } from '../../../Services/tcpaTabApis';
import { baseURLConfig } from '../../../config/envConfig';
import { generateRandomId } from '../../../Utils/utils';
// import { buildUrl, fetchAllPublisherCampaignsData, fetchListing } from '../../../../service/Publisher';
declare global {
  interface Window {
    myCallbackFunction: (token: string) => void;
  }
} 

const args1 = {
  path: "publishers",
  queryParamsObject: {
    ID: "publisher"
  }
}
const baseURL=baseURLConfig.TCPA_BASE_URL


const AdminTCPA_API = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [listingLoading, setListingLoading] = useState(false);
  const defaultAreaStudyValue: string = areaStudyDropdown[0]?.value || '';
  const defaultProgramValue = mPrograms[defaultAreaStudyValue]?.[0]?.value || '';

  const [selectedAreaStudy, setSelectedAreaStudy] = useState<string>(defaultAreaStudyValue);
  const [selectedProgram, setSelectedProgram] = useState<string>(defaultProgramValue);
  const [programOptions, setProgramOptions] = useState<any[]>(mPrograms[selectedAreaStudy])
  const [selectedCampusType, setSelectedCampusType] = useState<string>(campusType[0].value);
  const [selectedPublisher, setSelectedPublisher] = useState<string>('')
  const [publisherOptions, setPublisherOptions] = useState<any[]>([])

  const [selectedCampaign, setSelectedCampaigns] = useState<string>('')
  const [campaignOptions, setCampaignOptions] = useState<any[]>([])

  const [selectedDOI, setSelectedDOI] = useState<string>(degreeInterest[2].value)
  const [selectedHE, setSelectedHE] = useState<string>(highestEducation[0].value)
  const [selectedSTF, setSelectedSTF] = useState<string>(startTimeframe[0].value)
  const [gradYear, setGradYear] = useState<string>("2008")

  const [selectedMbrand, setSelectedMbrand] = useState<string>('')
  const [brandOptions, setBrandOptions] = useState<any[]>([])
  const [selectedMilitary, setSelectedMilitary] = useState<string>(militaryAffiliation[0].value)
  const [sub1, setSub1] = useState<string>("Test-1")
  const [sub2, setSub2] = useState<string>("Test-2")
  const [sub3, setSub3] = useState<string>("Test-3")
  const [zipCode, setzipCode] = useState<string>("33303")

  const [isBrandMapping, setIsBrandMapping] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [adMax, setAdMax] = useState('100')
  const [schoolToExclude, setSchoolToExclude] = useState('')
  const [enableFilterByCPC, setEnableFilterByCPC] = useState(false);


  const [listingUrl, setListingUrl] = useState('')
  const [listingData, setListingData] = useState<any>()

  const listResultRef = useRef<null | HTMLDivElement>(null)
  const targetRef = useRef<null | HTMLDivElement>(null)

  const [isOpenOthers, setIsOpenOthers] = useState(false)
  const [mediaType, setMediaType] = useState(mediaTypeOptions[0].value);
  const [inventoryType, setInventoryType] = useState(inventoryTypeOptions[0].value);
  const [HSGPA, setHSGPA] = useState('')
  const [state, setState] = useState('')
  const [age, setAge] = useState('')
  const [country, setCountry] = useState('')
  const [hasRN, setHasRN] = useState('')
  const [gender, setGender] = useState('')
  const [certificate, setCertificate] = useState('')
  const [userIP, setUserIP] = useState('')
  const [DOB, setDOB] = useState('')
  const [isCitizen, setIsCitizen] = useState('')
  const [userAgent, setUserAgent] = useState(navigator.userAgent)
  const [deviceType, setDeviceType] = useState('')
  const [webInitiatingURL, setWebInitiatingURL] = useState('')
  const [webLandingURL, setWebLandingURL] = useState('')
  const [leadToken, setLeadToken]=useState('')
  const [trustFormURL, setTrustFormURL]=useState('')
  // useEffect(() => {
  //   if (isOpenOthers && targetRef.current) {
  //     setTimeout(() => {
  //       targetRef?.current?.scrollIntoView({ behavior: 'smooth' });
  //     }, 100);
  //   }
  //   if (listingData && listResultRef.current && !isOpenOthers) {
  //     setTimeout(() => {
  //       listResultRef?.current?.scrollIntoView({ behavior: 'smooth' });
  //     }, 100);
  //   }

  // }, [isOpenOthers, listingData]);

  const handleOthersSwitch = () => {
    setIsOpenOthers(!isOpenOthers);

  };

  const handleSub = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'sub1':
        setSub1(value);
        break;
      case 'sub2':
        setSub2(value);
        break;
      case 'sub3':
        setSub3(value);
        break;
      case 'adMax':
        setAdMax(value);

        break;
      case 'excludeSchool':
        setSchoolToExclude(value);
        break;
      case 'isCitizen':
        setIsCitizen(value);
        break;
      case 'userAgent':
        setUserAgent(value);
        break;
      case 'DOB':
        setDOB(value);
        break;
      case 'userIP':
        setUserIP(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'hasRN':
        setHasRN(value);
        break;
      case 'certificate':
        setCertificate(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'HSGPA':
        setHSGPA(value);
        break;

      case 'age':
        setAge(value);
        break;






      default:
        setSub1(value)
        break

    }
  }
  // const [doiOptions, setDOIOptions]=useState<any[]>([])

  const handleMediaType = (value: string) => {
    setMediaType(value)
  }

  const handleInventoryType = (value: string) => {
    setInventoryType(value)
  }

  const handleCategoryChange = (value: string) => {
    setSelectedProgram(mPrograms[value]?.[0]?.value);
    setSelectedAreaStudy(value);
    setProgramOptions(mPrograms[value])


  };
  const handleIsBrandMapping = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBrandMapping(event?.target.checked)
  }

  const handleIsRedirect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRedirect(event?.target.checked)
  }

  const handleEnableFilterByCPC = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableFilterByCPC(event?.target.checked)
  }
  const handlePublisherChange = async (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedPublisher(value);
    setPageLoading(true);
    const campaignData = await fetchCampaigns(value)
    // console.log("campaign data, ", campaignData)
    setCampaignOptions(campaignData);

    setSelectedCampaigns(campaignData[0].value);
    setPageLoading(false);
  };

  const handleProgramChange = (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedProgram(value);
    setPageLoading(false);
  };

  const handleMbrandChange = (value: string) => {
    setSelectedMbrand(value);
  };
  const handleDOIChange = (value: string) => {
    setSelectedDOI(value);
  };
  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setzipCode(event.target.value);
  };
  const handleMilitaryChange = (value: string) => {
    setSelectedMilitary(value);
  };
  const handleSTFChange = (value: string) => {
    setSelectedSTF(value);
  };

  const handleCampusTypeChange = (value: string) => {
    setSelectedCampusType(value);
  };

  const handleGradYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGradYear(event.target.value)
  }
  const handleHighestEducationChange = (value: string) => {
    setSelectedHE(value);
  };

  const handleCampaignChange = (value: string) => {
    setSelectedCampaigns(value)
  };

  const fetchData = async (pubID: string) => {
    const arg2 = {
      path: "publishers/campaign",
      queryParamsObject: {
        publisherID: pubID
      }
    }
    const response = await fetchAllPublisherCampaignsData(arg2.path, arg2.queryParamsObject)
    return response.data
  }


  const initializeData = async () => {
    const data=await getUserIp();
    setUserIP(data?.ip|| '');
    try {
      setPageLoading(true); // Set loading state to true before fetching data

      const [pubLists, brandLists] = await Promise.all([
        fetchAllPublishersData(args1.path, args1.queryParamsObject),
        getMBrandsDetails(),
      ]);
      
      // console.log("pubLists", pubLists.data.result.data)
      const pubDatas = pubLists.data.result.data.map((pub: any) => ({
        label: ` ${pub.publisherName}`,
        value: `${pub.mPubID}`
      }));

      // console.log("PUBDATAS::::::::::::::::::::::::::::", pubDatas)
      pubDatas.sort((a:any, b:any) => {
        const labelA = a.label.toUpperCase(); // Ignore upper and lowercase
        const labelB = b.label.toUpperCase(); // Ignore upper and lowercase
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
    
        // labels must be equal
        return 0;
    });
      setPublisherOptions(pubDatas);

      setSelectedPublisher(pubDatas[0].value);
      const campaignData = await fetchCampaigns(pubDatas[0].value)
      // const brandLists = await getMBrandsDetails()
      // console.log("brandLists", brandLists);
      const brandMenus = brandLists.data.values.map((brand: any) => (
        {
          label: ` ${brand.mBrandName}`,
          value: `${brand.mBrandId}`
        }
      ))

      setCampaignOptions(campaignData);
      setBrandOptions(brandMenus)
      setSelectedCampaigns(campaignData[0].value);
      setPageLoading(false); // Set loading state to false after fetching data
    } catch (error) {
      console.error('Error fetching data:', error);
      setPageLoading(false); // Set loading state to false if an error occurs
    }
  };
//   console.log("CAMPAIGN options", campaignOptions)

  const fetchCampaigns = async (lastFiveCharacters: string) => {
    const publisherResponse = await fetchData(lastFiveCharacters);
    const campaignData = publisherResponse.map((campaign: any) => ({
      label: campaign.campaignname + ` ${campaign.SORTID}`,
      value: `${campaign.SORTID}`
    })).sort((a:any, b:any) => a.label.localeCompare(b.label));

    return campaignData
  } 
  
  useEffect(() => {
 
    const field: string = 'xxTrustedFormCertUrl';
    const provideReferrer: boolean = false;

    // Create the script element
    const tf: HTMLScriptElement = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' +
      encodeURIComponent(provideReferrer.toString()) +
      '&field=' +
      encodeURIComponent(field) +
      '&l=' +
      new Date().getTime() +
      Math.random();

    // Append the script to the DOM
    const s: HTMLScriptElement | null =
      document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(tf, s);
    }

    // Set the URL to state when the script is loaded
    tf.onload = () => {
      setTrustFormURL(tf.src);
    };
  }, []);
  
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'LeadiDscript_campaign';
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      '//create.lidstatic.com/campaign/d50a3557-9854-7b8f-792c-527750401f5f.js?snippet_version=2&f=reset&callback=myCallbackFunction';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Callback function to handle the token
  window.myCallbackFunction = function (token) {
    setLeadToken(token);
  };

  useEffect(() => {
    initializeData()
  }, [])

  const handleSubmit = async () => {
    setListingLoading(true)
    try {
      let queryParams: any = {
        degreeInterest: selectedDOI,
        areaStudy: selectedAreaStudy,
        mProgram: selectedProgram,
        highestEducation: selectedHE,
        gradYear: gradYear,
        campusType: selectedCampusType,
        militaryAffiliation: selectedMilitary,
        startTimeframe: selectedSTF,
        sub1: sub1,
        sub2: sub2,
        sub3: sub3,
        zipCode: zipCode,
        mPubID: selectedPublisher,
        mPubCampaignID: selectedCampaign,
       // adsMax: adMax
      };


      if (selectedMbrand) {
        queryParams.mBrandID = selectedMbrand
      }

      // if (schoolToExclude) {
      //   queryParams.excludeAdvertiser = schoolToExclude
      // }


      if (enableFilterByCPC) {
        queryParams.enableFilterByCPC = 1
      }
      if (isBrandMapping) {
        queryParams.enableFilterByBrand = 1
      }


      if(isOpenOthers){
    

      queryParams.state=state;
      queryParams.age=age;
      queryParams.gender=gender;
      queryParams.hasRN=hasRN;
      queryParams.inventoryType=inventoryType;
      queryParams.mediaType=mediaType;
      queryParams.isCitizen=isCitizen?1:0;
      queryParams.dateBirth=DOB;
      queryParams.hsGPA=HSGPA;
      queryParams.userIP=userIP;
      queryParams.useragent=userAgent;
      queryParams.webInitiatingURL=webInitiatingURL;
      queryParams.webLandingURL=webLandingURL;
      queryParams.deviceType=deviceType;
     
      }
      const newQP = {
        ...queryParams,
        redirect: isRedirect?1:0,
        leadIdToken:leadToken,
       trustedFormUrl:trustFormURL,
        mSessionID:generateRandomId(), 
      }


      console.log("query params", newQP)

      if(!isRedirect){
      const response = await fetchTCPA_LISTING(newQP)
      // console.log("response", response)
      setListingData(response)
      }else{
        setListingData('')
      }
      // console.log("new qp", newQP)

      const listingAPIURL = buildUrl(baseURL,newQP)

      setListingLoading(false)
      setListingUrl(listingAPIURL)
      // listResultRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (err) {
      setListingLoading(true)

    }

  }


  const handleReset = () => {
    setSelectedAreaStudy(defaultAreaStudyValue);
    setSelectedProgram(defaultProgramValue);
    setProgramOptions(mPrograms[defaultAreaStudyValue])
    setSelectedDOI(degreeInterest[0].value);
    setSelectedHE(highestEducation[0].value);
    setSelectedSTF(startTimeframe[0].value);
    setSelectedCampusType(campusType[0].value);
    setSelectedMilitary(militaryAffiliation[0].value);
    setSub1('Test-1');
    setSub2('Test-2');
    setSub3('Test-3');
    setzipCode('33303');
    setGradYear('2008');
    setListingUrl('')
    setSelectedMbrand('')
    setIsBrandMapping(false)
    setEnableFilterByCPC(false)
    setIsOpenOthers(false);
    setMediaType(mediaTypeOptions[0].value)
    setInventoryType(inventoryTypeOptions[0].value)
    setHSGPA('')
    setHSGPA('')
    setAge('')
    setCountry('')
    setHasRN('')
    setGender('')
    setCertificate('')
    setDOB('')
    setIsCitizen('')
    setDeviceType('')
    setWebInitiatingURL('')
    setWebLandingURL('')
    setListingUrl('')

  };

 
  return (
    <>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Test TCPA API</Typography>
            <Typography className="card-sub-heading">
              Test your campaigns with multiple options
            </Typography>
          </Box>
        </Box>
        {pageLoading ? <Box className="loading-spinner-container" sx={{
          minHeight: "500px"
        }}>

          <CircleLoader color="#00BFFF" loading={pageLoading ? true : false} size={60} />
        </Box> :
          <Grid container spacing={2} sx={{ margin: '3px', width: '99%', padding: "5px" }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} sm={6} md={3} >
                  <BasicSelect value={selectedPublisher} menuOptions={publisherOptions} setValue={handlePublisherChange} selectLabel='Publisher' isDisable={false} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} md={3} >
                  <BasicSelect value={selectedCampaign} menuOptions={campaignOptions} setValue={handleCampaignChange} selectLabel='Publisher Campaign' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4} >
                  <BasicTextField name="zipCode" label="Zip Code" value={zipCode} changeHandler={handleZipChange} />
                </Grid>

              </Grid>
            </Grid>

            {/* 2nd row */}
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedDOI} menuOptions={degreeInterest} setValue={handleDOIChange} selectLabel='Degree of Interest' />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedAreaStudy} menuOptions={areaStudyDropdown} setValue={handleCategoryChange} selectLabel='Area study' />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedProgram} menuOptions={programOptions} setValue={handleProgramChange} selectLabel='M programs' />
                </Grid>


              </Grid>
            </Grid>
            {/* 3rd row */}
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedHE} menuOptions={highestEducation} setValue={handleHighestEducationChange} selectLabel='Highest Education' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="gradYear" label="Grad Year" value={gradYear} changeHandler={handleGradYear} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedSTF} menuOptions={startTimeframe} setValue={handleSTFChange} selectLabel='Start Time Frame' />
                </Grid>

              </Grid>
            </Grid>

            {/* 4th row */}
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedMilitary} menuOptions={militaryAffiliation} setValue={handleMilitaryChange} selectLabel='Military' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedCampusType} menuOptions={campusType} setValue={handleCampusTypeChange} selectLabel='Campus Type' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicSelect value={selectedMbrand} menuOptions={brandOptions} setValue={handleMbrandChange} selectLabel='MBrandID' />
                </Grid>

              </Grid>
            </Grid>

            {/* 5th row */}

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  {/* <BasicSelect value={selectedHE} menuOptions={highestEducation} setValue={handleHighestEducationChange} selectLabel='Highest Education' /> */}
                  <BasicTextField name="sub1" label="sub1" value={sub1} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="sub2" label="sub2" value={sub2} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  {/* <BasicSelect value={selectedSTF} menuOptions={startTimeframe} setValue={handleSTFChange} selectLabel='Start Time Frame'/> */}
                  <BasicTextField name="sub3" label="sub3" value={sub3} changeHandler={handleSub} />
                </Grid>

              </Grid>
            </Grid>
            {/* 6th row */}
            {/* <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="adMax" label="Ads Max" value={adMax} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="excludeSchool" label="School to Exclude" value={schoolToExclude} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                <Grid container >
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <FormGroup>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={isBrandMapping}
                        //  value={iso}
                        onChange={handleIsBrandMapping} />}
                      label="Mapping Brand Name"
                    />
                  </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={isRedirect}
                        //  value={iso}
                        onChange={handleIsRedirect} />}
                      label="Redirect"
                    />
                  </FormGroup>
                  </Grid>
                  <Grid item xs={12} >
                  <FormGroup>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={enableFilterByCPC}
                        //  value={iso}
                        onChange={handleEnableFilterByCPC} />}
                      label=" Enable Filter by CPC/Brand"
                    />
                  </FormGroup>
                  </Grid>
                  </Grid>

                </Grid>

              </Grid>
            </Grid> */}


            {/* 7th row */}



            {/* 8th row */}
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }}
                    checked={isOpenOthers}
                    //  value={iso}
                    onChange={handleOthersSwitch} />}
                  label="Others"
                />
              </FormGroup>
            </Grid>

          </Grid>
        }


        {
          isOpenOthers && <Grid container spacing={2} sx={{ margin: '3px', width: '99%', padding: "5px 5px 20px 5px" }} ref={targetRef}>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                  <BasicTextField name="state" label="State" value={state} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                  <BasicSelect value={mediaType} setValue={handleMediaType} menuOptions={mediaTypeOptions}
                    selectLabel="Media Type" />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="HSGPA" label="High school GPA" value={HSGPA} changeHandler={handleSub} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="age" label="Age" value={age} changeHandler={handleSub} />

                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                  <BasicTextField name="country" label="country" value={country} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                  <BasicTextField name="hasRN" label="hasRN" value={hasRN} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="certificate" label="Teaching certificate" value={certificate} changeHandler={handleSub} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="gender" label="Gender" value={gender} changeHandler={handleSub} />

                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                <BasicTextField name="isCitizen" label="isCitizen" value={isCitizen} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                  <BasicSelect value={inventoryType} setValue={handleInventoryType} menuOptions={inventoryTypeOptions}
                    selectLabel="Inventory Type" />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="DOB" label="Date of Birth" value={DOB} changeHandler={handleSub} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="userIP" label="userIP" value={userIP} changeHandler={handleSub} />

                </Grid>
              </Grid>


            </Grid>

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>

                <BasicTextField name="webInitiatingURL" label="webInitiatingURL" value={webInitiatingURL} changeHandler={(event:any)=>{setWebInitiatingURL(event.target?.value)}} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="webLandingURL" label="webLandingURL" value={webLandingURL} changeHandler={(event:any)=>{setWebLandingURL(event.target?.value)}} />
                  </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="deviceType" label="Device Type" value={deviceType} changeHandler={(event:any)=>{setDeviceType(event.target?.value)}} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicTextField name="userAgent" label="User agent" value={userAgent} changeHandler={handleSub} />

                </Grid>
              </Grid>


            </Grid>


            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={6}>

                  <BasicTextField name="userAgent" label="User agent" value={userAgent} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>

                  <BasicTextField name="isCitizen" label="isCitizen" value={isCitizen} changeHandler={handleSub} />
                </Grid>

              </Grid>
            </Grid> */}
          </Grid>
        }
        <Grid container sx={{ margin: '3px', width: '99%', padding: "5px 5px 10px 5px" }} >
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }} >
              <Button variant="contained" sx={{ backgroundColor: "#0ae", '&.MuiButton-root:hover': { bgcolor: '#0ae' } }} onClick={handleSubmit} disabled={listingLoading}>
                {listingLoading ? "Loading" : "Submit"}
              </Button>
              <Button variant="contained" style={{ marginLeft: '10px', backgroundColor: "#F5F5F5", color: "black" }} onClick={handleReset}>
                Reset
              </Button>
            </Grid>
            </Grid>
        {
          listingUrl &&

          <Box
            p={3}
            className="listing-request-container card"
            ref={listResultRef}
          >
            <Box className='api-status '>
              <Typography className="card-heading" sx={{ wordWrap: 'break-word', marginBottom: "4px" }} component='div' variant='h5'>
                {listingUrl}
              </Typography>
              {/* {Boolean(listingData) && (<Typography className="card-sub-heading">
                Total Results: {listingData.total}
              </Typography>)} */}

            </Box>

            {Object.keys(listingData).length>0 && (<Grid container className='list-card' spacing={2} sx={{ margin: '3px', width: '99%', padding: "5px" }} >
              
                
                    <Grid item xs={12} lg={4} sm={6} md={3}  sx={{ display: 'flex' }}>
                      <AreaCard imageUrl={listingData.imageURL} headerContent={listingData.headContent} description={listingData.descriptionLong} />
                    </Grid>
                
              


            </Grid>)}
          </Box>
        }
      </Paper>

    </>
  )
}

export default AdminTCPA_API














// .replace(/<\/?ul>/g, '').replace(/<\/?li>/g, '')