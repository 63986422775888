import React from 'react';
import { Bar,  } from 'react-chartjs-2';
// import {  generateNumberArray } from '../../Store/Slices/Publisher-role/Dashboard/utils';


interface CustomChartProps {
  data: any
  options: any;
}

const CustomChart:React.FC<CustomChartProps> = ({ data, options }) => {
  return <Bar data={data} options={options} />;
};



interface intraCharts{
  chartData:any,
  chartOptions:any,
}
const IntraChart: React.FC<intraCharts> = (props) => {
  return (
      <CustomChart data={props.chartData} options={props.chartOptions} />
  );
};

export default IntraChart;
