import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Button,
  CardMedia,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  DialogActions,
  CircularProgress,
  IconButton,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { FC, useEffect, useState } from 'react';
import { Editor, SelectionState } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier
} from 'draft-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { getWebContent, updateWebContent } from '../../../../../Services/seoApis';
import { CircleLoader } from 'react-spinners';
import { setApiLoading, setSnackbarOpen, setSuccessMessage } from '../../../../../Store/Slices/SEOHandlerApisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../Store/hooks';

interface contentDialogFields {
  contentEditor: string;
  websiteDomainName: string;
  areaStudy: any;
  mProgram: any;
}

const contentValidation = Yup.object().shape({
  contentEditor: Yup.string()
    .trim()
    .required('Editor content is required')
    .test(
      'no-empty',
      'Content must not consist of only whitespace characters',
      value => {
        // Check if the trimmed value is not empty
        return value.trim() !== '';
      }
    ),
});

const AddEditWebContent: FC<any> = ({
  openPopup,
  setDialogOpen,
  selectedValue,
}) => {
  const [touchedEditor, setTouchedEditor] = useState(false);
 const [dataFlag, setDataFlag] = useState<boolean>(true);
 const dispatch = useAppDispatch();
 const apiLoading = useAppSelector((state) => state.seoHandlerApi.apiLoading);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const formik = useFormik<contentDialogFields>({
    initialValues: {
      contentEditor: '',
      websiteDomainName: selectedValue?.websiteDomainName,
      areaStudy: selectedValue?.areaStudy,
      mProgram: selectedValue?.mProgram,
    },
    onSubmit: values => {
      // console.log('values', values);
      dispatch(setApiLoading(true));
      // const updatedValues = {
      //   contentEditor: formik.values.contentEditor,
      // };
      // console.log('updatedValues', updatedValues);
      // setEditorState(EditorState.createEmpty());
      // // formik.resetForm();
      const updateData = async () => {
        try {
          const getData = await updateWebContent(values);
          setDialogOpen(false);
          dispatch(setSuccessMessage('Updated Content Settings'));
          dispatch(setSnackbarOpen(true));
        } catch (error) {
          console.error('Error fetching data:', error);
          setDialogOpen(false);
      dispatch(setApiLoading(false));
        }
      dispatch(setApiLoading(false));
      };
      updateData();
    },
    validationSchema: contentValidation,
  });

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    // rawContentState?.blocks.forEach(value => {
    //   if (value.text) {
    //     console.log(draftToHtml(rawContentState));
    //   }
    // });

    formik.setFieldValue('contentEditor', draftToHtml(rawContentState));
  }, [editorState]);

  const handleEditorFocus = () => {
    setTouchedEditor(true);
    formik.handleBlur('contentEditor');
  };

  const handleClose = () => setDialogOpen(false);

  const loadInitialValue = (initialContent: string) => {
    if (initialContent) {
      const contentBlock = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const getData = await getWebContent(
          `?websiteDomainName=${selectedValue?.websiteDomainName}&areaStudy=${selectedValue?.areaStudy}&mProgram=${selectedValue?.mProgram}`
        );
        if (getData?.length) {
          console.log('getData', getData[0]);
          formik.setValues(getData[0]);
          loadInitialValue(getData[0]?.contentEditor);
          setDataFlag(false);
        }
        else{
          setDataFlag(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataFlag(false);
      }
    };
    getData();
  }, []);

  const CustomOption: FC<any> = ({ editorState, onChange }) => {
    const removeBackGroundColor = () => {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      let htmlContent = draftToHtml(rawContentState);
      htmlContent = htmlContent.replace(/(style="[^"]*)background-color:\s*[^;"]*;?([^"]*")/g, '$1$2');
      const contentBlock = htmlToDraft(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
  };
  
    return <IconButton sx={{border: '1px solid #F1F1F1',borderRadius: '2px',height: '100%'}} onClick={removeBackGroundColor}><InvertColorsOffIcon sx={{fontSize: '14px'}}/></IconButton>;
  };
  return (
    <>
    <Dialog
      open={openPopup}
      onClose={handleClose}
      BackdropProps={{ style: { opacity: '1' } }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "90%",
           maxWidth: "90%", 
           margin:'0',
           maxHeight:'90%',
           height:'90%',
          },
        },
      }}
    >
       {dataFlag ? (
      <Box className="loading-spinner-container" sx={{
        minHeight: "500px",
        minWidth:'500px',
      }}>
        <CircleLoader color="#00BFFF" loading={dataFlag ? true : false} size={60} />
      </Box>
    ):
      (<><DialogTitle>Content Settings</DialogTitle>
      <div className="close-button" onClick={handleClose}>
        <CloseIcon style={{ fontSize: '32px' }} />
      </div>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper"
                editorClassName="contentEditor"
                onEditorStateChange={editorState => {
                  setEditorState(editorState);
                  // Set the editor content to the formik field
                  // formik.setFieldValue(
                  //   'contentEditor',
                  //   editorState.getCurrentContent().getPlainText()
                  // );
                }}
                onChange={() => formik.setFieldTouched('contentEditor', false)}
                onFocus={handleEditorFocus}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'emoji',
                    'remove',
                    'history',
                  ],
                }}
                toolbarCustomButtons={[<CustomOption />]}
              />
              {formik.errors.contentEditor && formik.touched.contentEditor ? (
                <Box color="#db2424">{formik.errors.contentEditor}</Box>
              ) : null}
              {/* <FormHelperText error>
                              {errors.note?.message}
                            </FormHelperText> */}
            </Grid>
          </Grid>
        </Box>{' '}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
        >
         {
          apiLoading ? <CircularProgress color="inherit" size={24} /> : 'UPDATE'
        }
        </Button>
      </DialogActions></>)
       }
    </Dialog>
  </>);
};

export default AddEditWebContent;
