import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, StepIconProps, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AdCopy from "./Step components/AdCopy";
import ProgramTargeting from "./Step components/ProgramTargeting";
import StepButton from "../../../Component/Button/StepButton";
import { useAppSelector, useAppDispatch } from "../../../Store/hooks";
import { campaignFormSelector, resetActiveStep, resetState, updateStepsBasedOnClick } from "../../../Store/Slices/CreateAdvCampaignSlice";
import { useNavigate } from "react-router-dom";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
// Custom StepIcon component with a small horizontal bar as the icon
const SmallHorizontalActiveBar = styled("div")(({ theme }) => ({
    backgroundColor: "#34C759",// Change to your preferred color
    borderRadius: "100px",
    width:"100%" ,
    height: 4,
}));



const SmallHorizontalBar = styled("div")(({ theme }) => ({
    backgroundColor: "#C1C9D2;",// Change to your preferred color
    borderRadius: "100px",
    width:"100%" ,
    height: 4,
}));

interface IStepper {
    steps: string[],
    getContent: (step: number) => React.ReactElement,
    isEdit:boolean,
    isClone:boolean
}


const StepperForm: React.FC<IStepper> = ({ steps, getContent ,isEdit, isClone}) => {
    const dispatch = useAppDispatch();
    const currentState = useAppSelector(campaignFormSelector)
    const { activeStep, pageLoading } = currentState
 const navigate=useNavigate();
const handelStepChange=(index:number)=>{
    // console.log("active step index", index)
    dispatch(updateStepsBasedOnClick(index))
}

useEffect(()=>{
  if(activeStep===steps.length ){
    navigate('/advertiser-campaigns')
}
},[activeStep])
//(isEdit && !pageLoading)
    return (
        <div className='stepper-container'>
            <Stepper  sx={{  overflow:'auto', paddingBottom:'16px'}}  activeStep={activeStep} alternativeLabel >
                {steps.map((label, index) => (
                    <Step key={label} className="root-step-test" onClick={()=>{((isEdit || isClone) && !pageLoading) && handelStepChange(index)}}>
                        <StepLabel
                            StepIconComponent={({ active }) =>
                                active ? <SmallHorizontalActiveBar /> : <SmallHorizontalBar />
                            }
                            className="step-label-test"
                            sx={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "20px",
                                letterSpacing: "0px",
                                textAlign: "center",
                               
                            }}
                            
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            {/* Add your form content here */}
            <Box sx={{marginTop:"10px"}}>
                {activeStep === steps.length ? (
                    <div>
                        <p>All steps completed</p>
                        <Button onClick={
                            () => {
                                
                            dispatch(resetState(0))
                            navigate("/advertiser-campaigns")
                            }
                            } >
                            Go to campaigns page
                        </Button>
                    </div>
                ) : (
                    <Box>


                        {getContent(activeStep)}


                        <Box className="button-container">


                            <StepButton steps={steps} />
                        </Box>

                    </Box>

                )}

            </Box>
        </div>
    );
};

export default StepperForm;
