import React, { useState } from 'react'
import MMTabs from '../../../../Component/Tabs/MMTabs'
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks'
import { JsonData, ProgramData, programTargetingData } from '../../../../Mock/ProgramTargetingData';
import { campaignFormSelector } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { mProgramResponseToComponentProps } from '../../../../Utils/utils';



const ProgramTargeting = () => {

  const advCampaignFormState = useAppSelector(campaignFormSelector);

  function generateMProgramList(key: string = "1") {
    const jsonData: ProgramData = {
      ...advCampaignFormState.value.mPrograms
    }
    // //console.log("MProgram same as db / payload", jsonData);

    const mProgramComponentValue: JsonData = mProgramResponseToComponentProps(Object.values(jsonData))
   

    //console.log("mProgramComponentValue", mProgramComponentValue)
    if (mProgramComponentValue.hasOwnProperty(key)) {
      return mProgramComponentValue[key];
    }

  }


  console.log("programR")
  return (
    <MMTabs tabContent={programTargetingData} generateTabPanelContentHandler={generateMProgramList} />

  )
}

export default ProgramTargeting
