
import React, { useState } from 'react';
import * as Yup from 'yup';

const idsValidation = /^\d{5}\D*$/

export const seoValidation = Yup.object({
    //   website: Yup.string().required("Please select a Website"),
    seoTitle: Yup.string().required('SEO Title is required'),
    seoDescription: Yup.string().required('SEO Description is required'),
    seoCanonicalUrl: Yup.string().required("SEO Canonical URL is required"),
    seoImage: Yup.mixed().required('SEO Image is required'),

    seoKeywords: Yup.array()
        .of(Yup.string().required(" SEO Keywords are required"))
        .min(1, "Please enter at least one SEO Keyword") // Add a minimum length check if necessary
        .required("SEO Keywords are required"),


    ogType: Yup.string().required("OG Type is required"),
    ogLocale: Yup.string().required("OG Locale is required"),
    ogTitle: Yup.string().required("OG Title is required"),


    twitterCard: Yup.string().required("Twitter Card is required"),
    twitterTitle: Yup.string().required("Twitter Title is required"),
    twitterSite: Yup.string().required("Twitter Site is required"),
    twitterDescription: Yup.string().required("Twitter Description is required"),
    twitterImage: Yup.string().required("Twitter Image is required"),


})

export const websiteNames = [
    { name: 'Compare Online Degrees (www.compareonlinedegrees.com)', value: 'website1' },


    { name: 'Business (www.compareonlinebusinessdegrees.com)', value: 'website2' },


    { name: 'Criminal Justice (www.compareonlinecriminologydegrees.com)', value: 'website3' },

    { name: 'Healthcare (www.findmedicalcareercolleges.com)', value: 'website4' },

    { name: 'Computers & IT (www.findonlinecodingbootcamps.com)', value: 'website5' },

    { name: 'Educations & Teaching (www.onlineteachingcolleges.com) ', value: 'website6' },

    { name: 'Psychology (www.compareonlinepsychologydegrees.com)', value: 'website7' },

    { name: 'Nursing (www.findonlinenursingcolleges.com)', value: 'website8' },

    { name: 'Vocational Training (www.findcareercolleges.com)', value: 'website9' },

    { name: 'Liberal Arts (www.onlinehumanitiesdegrees.com)', value: 'website10' }
]


// export const websiteInfoValidation = Yup.object().shape({

//     template: Yup.string().required("Please select a template"),
//     advertiserMessage: Yup.string().test({
//         name: 'conditionalRequired',
//         test: function (value: any) {
//             // Check if advertiserDisToggle is true and advertiserMessage is empty
//             if (this.parent.advertiserDisToggle && !value) {
//                 return false; // Fail validation if advertiserMessage is empty
//             }
//             return true; // Pass validation otherwise
//         },
//         message: 'Please select an advertiser message is required'
//     }),

//     advertiserLink: Yup.string().test({
//         name: 'conditionalRequired',
//         test: function (value: any) {
//             if (this.parent.advertiserDisToggle && !value) {
//                 return false;
//             }
//             return true;
//         },
//         message: 'Please enter an advertiser link is required'
//     }),

//     areaStudy: Yup.string().required('Please select an area of study'),
//     degreeInterest: Yup.string().required('Please select a degree of interest'),
//     mProgram: Yup.string().required('Please select a mProgram'),
//     widgetButton: Yup.string().required('Please enter a widget button text'),
//     bannerRequestInfo: Yup.string().required('Please enter a widget button text'),

//     // copyright: Yup.string().test({
//     //     name: 'conditionalRequired',
//     //     test: function (value: any) {
//     //         if (this.parent.copyrightToggle && !value) {
//     //             return false;
//     //         }
//     //         return true;
//     //     },
//     //     message: 'Please enter a copyright is required'
//     // }),


//     uploadImage1: Yup.string().required('Please upload an image'),
//     uploadImage2: Yup.string().required('Please upload an image'),
//     favicon: Yup.string().required('Please upload a favicon'),

//     advertiserDisclosure: Yup.string().test({
//         name: 'conditionalRequired',
//         test: function (value: any) {
//             if (this.parent.advertiserDisToggle && !value) {
//                 return false;
//             }
//             return true;
//         },
//         message: 'Advertiser Disclosure is required'
//     }),


//     footerContent: Yup.string().test({
//         name: 'conditionalRequired',
//         test: function (value: any) {
//             if (this.parent.footerContentToggle && !value) {
//                 return false;
//             }
//             return true;
//         },
//         message: 'Please enter some footer content is required'
//     }),

//     // footerLinks: Yup.string().test({
//     //     name: 'conditionalRequired',
//     //     test: function (value: any) {
//     //         if (this.parent.footerContentToggle && !value) {
//     //             return false;
//     //         }
//     //         return true;
//     //     },
//     //     message: 'Please enter some footer content is required'
//     // }),

//     footerLinks: Yup.array().of(
//         Yup.object().shape({
//           key: Yup.string().required('Key is required'),
//           value: Yup.string().required('Value is required'),
//         })
//       ),

// })

export const websiteInfoValidation = Yup.lazy(values => {
    return Yup.object().shape({
        template: Yup.string().required("Please select a template"),
        advertiserMessage: values.advertiserDisToggle === true ? Yup.string().required('Please enter an advertiser message') : Yup.string().nullable(),
        advertiserLink: values.advertiserDisToggle === true ? Yup.string().required('Please enter an advertiser link') : Yup.string().nullable(),
        areaStudy: Yup.string().required('Please select an area study'),
        degreeInterest: Yup.string().required('Please select a degree interest'),
        mProgram: Yup.string().required('Please select a mProgram'),
        widgetButton: Yup.string().required('Please enter a widget button text'),
        bannerRequestInfo: Yup.string().required('Please enter a banner request information text'),
        favIcon: Yup.string().required('Please upload a favicon'),
        // navMenus: Yup.string().when('template', {
        //     is: (val:any) => val === '1' ,  
        //     then: Yup.string()
        //         .required('navMenus is required when template is 1')
        //         .length(6, 'navMenus must be exactly 6 characters when template is 1'),
        //     otherwise: Yup.string().nullable() // Optional if template is not 1
        // }),

        navMenus: values.template === '1' ? Yup.array().required('Menu list is required when template is 1').length(6, 'Menu list must be exactly 6 characters when template is 1') : Yup.array().nullable(),
        advertiserDisclosure: values.advertiserDisToggle === true ? Yup.string().required('Please enter advertiser disclosure text') : Yup.string().nullable(),
    });
});

export const headerFooterInfoValidation = Yup.lazy(values => {
    return Yup.object().shape({
        footerContent: values.footerContentToggle === true ? Yup.string().required('Please enter some footer contents') : Yup.string().nullable(),
        footerLinksToggle: Yup.boolean(),
        footerLinks: values.footerLinksToggle === true ?
            Yup.array().of(
                Yup.object().shape({
                    key: Yup.string().required('Key is required'),
                    value: Yup.string().required('Value is required')
                })
            ).required('footerLinks is required') :
            Yup.array().nullable()
    });
});


export const defaultUrlValidationSchema = Yup.object().shape({
    pubId: Yup.string().matches(idsValidation, 'Please enter valid 5-digits pub Id').required('Please enter a publisher Id'),
    pubCampaignId: Yup.string().matches(idsValidation, 'Please enter valid 5-digits pub campaign Id').required('Please enter a publisher campaign Id'),
    defaultURL: Yup.array().of(Yup.object().shape({ key: Yup.string().required('Please enter a default URL key'), value: Yup.string().required('Please enter a default URL value') }))
});


export const faqValidationSchema = Yup.lazy(values => {
    return Yup.object().shape({
        faqs: values.faqToggle === false ?
            Yup.array().of(
                Yup.object().shape({
                    key: Yup.string().required('Faq Title is required'),
                    value: Yup.string().required('Faq Params is required')
                })
            ).required('Faq is required') : Yup.array().nullable(),
        faqToggle: Yup.boolean()
    });
})

// faqTitle: Yup.array().of(Yup.object().shape({title: Yup.string().required('Title is required')})),
// faqPara: Yup.string().required('Para is required'),
