import React ,{useState, useEffect}from 'react'
import { Box, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
import { getAdvByVendor, getCampaignsByAdvertiser } from '../../../../service/Vendor';
import AdvOverall from './AdvOverall';
import { createCampaignBarDatasets } from '../../../../Store/Slices/Publisher-role/Dashboard/utils';
import { AdvIntraday } from './AdvIntraday';
import AdvRawReport from './AdvRawReport';
// import { useLocation } from 'react-router-dom';
const DashboardVendor = () => {
  const location=useLocation()
  const [campaignDropdownData, setCampaignDropDownData] = useState<any[]>([])
  const [advertiserDropdownData, setAdvertiserDropDownData] = useState<any[]>([])
  const [barDataSet, setbarDataSet] = useState<any>()
  const [loading, setLoading] = useState(true);
  const [campLoading, setCampLoading] = useState(true);
  const [selectedAdvertiser, setSelectedAdvertiser]=useState<string>('')
  const fetchData = async () => {
    try {
      setLoading(true)
      setCampLoading(true)
      let vendorName = localStorage.getItem("userSortid");
      if (vendorName) {
        const res: any = await getAdvByVendor(vendorName)
        console.log("res", res);
        const advDDdata=res?.result.map((item:any, index:number)=>{
        return {
          label:item.GroupName,
          value:item.mPubID
        }
        }) 
        
        setAdvertiserDropDownData(advDDdata)
        setSelectedAdvertiser(advDDdata[0].value)
        setLoading(false)
        setCampLoading(false)
      }
    } catch (error) {

    }
  }
  const fetchAdvCampaignsDropdownData=async(advertiserId:string)=>{
    setCampLoading(true)
    const advCampaignResponse= await getCampaignsByAdvertiser(selectedAdvertiser);
    console.log("advResponse", advCampaignResponse)
    const campaignDD=advCampaignResponse.result.map((item:any)=>{
      return {
        label:item.campaignname,
        value:item.SORTID
      }
    })
    setCampaignDropDownData(campaignDD)
     const campaignBarDataSets = createCampaignBarDatasets(advCampaignResponse.result)
     setbarDataSet(campaignBarDataSets)
    // const advResponse=advCampaignResponse.result.map((item:any)=>{t
    //   return {
    //     label: item.campaignname,
    //     value:item.SORTID
    //   }
    // })

    // setCampaignDropDownData(advResponse);
    setCampLoading(false)

  }

useEffect(()=>{
// console.log("advertiser selected", selectedAdvertiser)
if(selectedAdvertiser){
fetchAdvCampaignsDropdownData(selectedAdvertiser)
}
},[selectedAdvertiser])
  useEffect(()=>{
 fetchData()
  },[])

  const handleAdvertiser= async(value:string)=>{
 console.log("value ", value);
 setSelectedAdvertiser(value)
  }
console.log("campaignsDropDown,",campaignDropdownData)
  const renderContent = () => {
    switch (location.pathname) {
      case '/adv-intraday':
        return <AdvIntraday campaignDropDownMenus={campaignDropdownData}
         defaultBarDataSet={barDataSet}  
         dashboardType="advertiser"
         advertiserDropDownMenus={advertiserDropdownData}
         selectedAdvertiser={selectedAdvertiser}
         advertiserChangeHandler={handleAdvertiser}
         IsCampaignLoading={campLoading}
         isAdvLoading={loading}
         />
        // return null
      case '/adv-overall':
        return <AdvOverall  
         campaignDropDownMenus={campaignDropdownData} 
         defaultBarDataSet={barDataSet}
          dashboardType="advertiser" 
          advertiserDropDownMenus={advertiserDropdownData}
          selectedAdvertiser={selectedAdvertiser}
          advertiserChangeHandler={handleAdvertiser}
          IsCampaignLoading={campLoading}
         isAdvLoading={loading}
          />
      // return null
      case '/adv-raw-report':
        return <AdvRawReport 
        campaignDropDownMenus={campaignDropdownData} 
        dashboardType="advertiser" 
          advertiserDropDownMenus={advertiserDropdownData}
          selectedAdvertiser={selectedAdvertiser}
          advertiserChangeHandler={handleAdvertiser}
          IsCampaignLoading={campLoading}
          isAdvLoading={loading}
               />


        default:

        // Handle unknown paths or render a default component
        break;
    }
  };
  
  
  

  return (
    <>{
      // (campLoading) ?
      // <Box p={3} display='flex' justifyContent={'center'} alignItems={'center'} height={"100vh"}>
         
      //   <CircleLoader color="#00BFFF"loading={campLoading? true : false} size={60} />
    
      // </Box>
      //      : 
      
        
           renderContent()

    }
    </>
  )
}

export default DashboardVendor