
import React, { useEffect, useState } from 'react'
import { Box, Button, CardMedia, Chip, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material'
import { useFormik } from 'formik';
import EditIcon from '@mui/icons-material/Edit';

import { seoValidation, websiteNames } from '../validation';
// import { Helmet } from 'react-helmet';
import MuiAlert from '@mui/material/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { createSEOAsync, setCmsEditFlag, setErrorMessage, setSnackbarOpen, setSuccessMessage} from '../../../Store/Slices/SEOHandlerApisSlice';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import SeoAccount from './Account';
import { fetchSeoApiData } from '../../../Services/seoApis';
import { uploadSEOToS3 } from '../../../service/S3 Upload';
import { NameType } from 'aws-sdk/clients/secretsmanager';
import { setTempData } from '../../../Store/Slices/SEOWebsiteInfoApiSlice';

import UploadCMSController from './UploadCMS';

interface FormValues {
  id: string;
  website: string;
  seoTitle: string;
  seoCanonicalUrl: string;
  seoDescription: string;
  seoImage: any;
  seoKeywords: string[];
  ogType: string;
  ogLocale: string;
  ogTitle: string;
  twitterCard: string;
  twitterTitle: string;
  twitterSite: string;
  twitterDescription: string;
  twitterImage: any;

}


const CreateSeoConfig = ({ onSubmission = () => { } }: { onSubmission?: (values: any) => void }) => {

  const navigate = useNavigate();
  // const {rowData,fetchInitialData}= props;
  const location = useLocation();
  const { rowData, fetchInitialData } = location.state;
  const dispatch = useAppDispatch();
  const baseUrl = window.location.origin;
  const [inputValue, setInputValue] = useState<string>('');
  const [chips, setChips] = useState<string[]>(rowData && rowData.seoKeywords ? [...rowData.seoKeywords] : []);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [seoAlertOpen, setSeoAlertOpen] = React.useState(false);
  const [twitterAlertOpen, setTwitterAlertOpen] = React.useState(false);


  const [seoHandlerData, setSeoHandlerData] = useState({
    website: rowData ? rowData.website : '',
    seoTitle: rowData ? rowData.seoTitle : '',
    seoCanonicalUrl: rowData ? rowData.seoCanonicalUrl : '',
    seoDescription: rowData ? rowData.seoDescription : '',
    seoKeywords: rowData ? rowData.seoKeywords : [],
    seoImage: rowData ? rowData.seoImage : null,
    ogType: rowData ? rowData.ogType : '',
    ogLocale: rowData ? rowData.ogLocale : '',
    ogTitle: rowData ? rowData.ogTitle : '',
    twitterCard: rowData ? rowData.twitterCard : '',
    twitterTitle: rowData ? rowData.twitterTitle : '',
    twitterSite: rowData ? rowData.twitterSite : '',
    twitterDescription: rowData ? rowData.twitterDescription : '',
    twitterImage: rowData ? rowData.twitterImage : null,
  });


  const [seoImage, setSeoImage] = useState<string>(rowData && rowData.seoImage ? rowData.seoImage : '');

  //const [seoImage, setSeoImage] = useState('');

  const [twitterImage, setTwitterImage] = useState<string>(rowData && rowData.twitterImage ? rowData.twitterImage : '')


  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
    resetForm
  } = useFormik<FormValues>({

    initialValues: {
      id:"",
      website: rowData ? rowData.website : '',
      seoTitle: rowData ? rowData.seoTitle : '',
      seoCanonicalUrl: rowData ? rowData.seoCanonicalUrl : '',
      seoDescription: rowData ? rowData.seoDescription : '',
      seoImage: rowData ? rowData.seoImage : null,
      seoKeywords: rowData ? rowData.seoKeywords : [],
      ogType: rowData ? rowData.ogType : '',
      ogLocale: rowData ? rowData.ogLocale : '',
      ogTitle: rowData ? rowData.ogTitle : '',
      twitterCard: rowData ? rowData.twitterCard : '',
      twitterTitle: rowData ? rowData.twitterTitle : '',
      twitterSite: rowData ? rowData.twitterSite : '',
      twitterDescription: rowData ? rowData.twitterDescription : '',
      twitterImage: rowData ? rowData.twitterImage : null,
    
    },


    onSubmit: async (values) => {
      dispatch(setSuccessMessage(''));
      dispatch(setErrorMessage(''));
  

      const updatedValues = {
        ...values,
        id: rowData.id,
        website: values.website,
        seoTitle: values.seoTitle,
        seoDescription: values.seoDescription,
        seoImage: seoImage,
        seoKeywords: chips,
        ogType: values.ogType,
        ogLocale: values.ogLocale,
        ogTitle: values.ogTitle,
        twitterCard: values.twitterCard,
        twitterTitle: values.twitterTitle,
        twitterSite: values.twitterSite,
        twitterDescription: values.twitterDescription,
        twitterImage: twitterImage,

      };
   
      try {
        // For editing an existing record, send a PUT request with the ID
        dispatch(createSEOAsync(updatedValues)).then(() => {
          dispatch(setSuccessMessage('SEO Content Updated Successfully'))
          onSubmission(updatedValues);
          fetchSeoApiData(dispatch);
        });
        dispatch(setSnackbarOpen(true));
     
        // setSeoHandlerData(updatedValues);
      } catch (error) {
        dispatch(setErrorMessage('Failed to create advertiser'));
        dispatch(setSnackbarOpen(false));
  
      }

      // dispatch(updateSEOAsync(updatedValues));
      // onSubmission(updatedValues);

      // setSeoHandlerData(updatedValues);
      // resetForm();
      // setChips([]);
      // setInputValue('');
      // setSeoImage('');
      // setTwitterImage('');
      navigate('/cms-website');

    },

    validationSchema: seoValidation,
  });


  const handleSEOFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeoAlertOpen(false);
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];
   
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 500 && height <= 250) {
              
        
              const objectURL = uploadSEOToS3(reader.result, `${new Date().getTime()}`, file);
              objectURL.then((res:any) => {
                setFieldValue('seoImage', res);
                // console.log("file details....", res)
                setSeoImage(res as any);
              })
            } else {
              event.target.value = '';
              // Display an error alert
              setSeoAlertOpen(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        alert("Please select a jpeg or png image file")
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }
  };

  const handleSeoAlertClose = () => {
    setSeoAlertOpen(false);
  };

  const handleTwitterAlertClose = () => {

    setTwitterAlertOpen(false);
  };

  const handleTwitterFileChange = (event: any) => {
    //const file = event.currentTarget.files[0];

    setTwitterAlertOpen(false);
    const fileInput = event.currentTarget;
    const file = fileInput.files && fileInput.files[0];

    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 500 && height <= 250) {
              // setFieldValue('twitterImage', file);
          
              // setTwitterImage(file.name);
              const objectURL = uploadSEOToS3(reader.result, `${new Date().getTime()}`, file);
              objectURL.then((res:any) => {
                setFieldValue('twitterImage', res);
                console.log("file details....", res)
                setTwitterImage(res as any);
              })
            } else {
              event.target.value = '';
              // Display an error alert
              setTwitterAlertOpen(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);

      } else {
        // Display an error alert
        alert("Please select a jpeg or png image file")
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }


    if (file) {

    }
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setInputValue(event.target.value);
  
    // Check if the input value is empty, and reset it to the placeholder
    if (!event.target.value.trim()) {
      setInputValue('');
    }
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setChips((prevChips) => [...prevChips, inputValue.trim()]);
      setInputValue('');
    }
  };

  const removeChip = (index: number) => {
    setChips((prevChips) => prevChips.filter((_, i) => i !== index));

  };



  return (
    <Paper elevation={3} className="card">

        <Box className="card" display="flex">
          <Box>
            <Typography className="card-heading" style={{ paddingTop: '20px', paddingLeft: "20px" }}>Edit Settings</Typography>
            <Typography className="card-sub-heading" style={{ paddingLeft: "20px" }}>Fill the details below to edit settings

            </Typography>
          </Box>

        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" px={3} py={8} maxWidth="800px" margin="0px auto">

          <form className="seo-form" id="seoForm" onSubmit={handleSubmit}>
            <Box>
              <Box mb={3}>
                <Typography variant="h6" fontWeight={500} gutterBottom>
                  General Settings
                </Typography>
              </Box>
              <Grid container spacing={4} alignItems="flex-start">

                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel>Websites *</InputLabel>
                    <Select
                      label="Websites *"
                      name="website"
                      onChange={handleChange}
                      value={values.website}
                      onBlur={handleBlur}
                      disabled

                    >
                      {websiteNames.map((website: any, index: any) => (
                        <MenuItem value={website.name} key={index}>
                          {website.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {errors.website && touched.website ? (
                    <Box color="#db2424">{errors.website} </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="SEO Title *"
                    name="seoTitle"
                    onChange={handleChange}
                    value={values.seoTitle}
                    onBlur={handleBlur}
                  
                  />
                  {errors.seoTitle && touched.seoTitle ? (
                    <Box color="#db2424">{errors.seoTitle} </Box>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="SEO Canonical URL *"
                    name="seoCanonicalUrl"
                    onChange={handleChange}
                    value={values.seoCanonicalUrl}
                    onBlur={handleBlur}
                  />
                  {errors.seoCanonicalUrl && touched.seoCanonicalUrl ? (
                    <Box color="#db2424">{errors.seoCanonicalUrl} </Box>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    className='ad-content-textarea'
                    sx={{ background: '#ffffff' }}
                    label="SEO Description *"
                    name="seoDescription"
                    onChange={handleChange}
                    value={values.seoDescription}
                    onBlur={handleBlur}
                    multiline
                    maxRows={4}
                  />
                  {errors.seoDescription && touched.seoDescription ? (
                    <Box color="#db2424">{errors.seoDescription} </Box>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="file"
                    id="seoImage"
                    inputProps={{ accept: 'image/*' }}
                    style={{ display: 'none' }}
                    onChange={handleSEOFileChange}

                  />
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap={3}>
                      <Tooltip title={seoImage || ''} arrow>
                        <TextField value={seoImage} variant="outlined" fullWidth
                          label="SEO Image *"
                          helperText="Dimension (500*250)"
                        />
                      </Tooltip>
                      <Button
                        variant='contained'
                        size='large'
                        sx={{
                          backgroundColor: '#1976d2',
                          color: '#fff',
                          '&.MuiButtonBase-root:hover': {
                            bgcolor: '#1976d2 !important',
                          },
                          '&:hover': { backgroundColor: '#1976d2' },
                          border: '1px solid #1976d2',
                          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                          borderRadius: '8px',
                          whiteSpace: "nowrap",
                          width: '140px',
                          boxSizing: 'content-box'
                        }}
                        onClick={() => {
                          // Trigger the hidden file input
                          const fileInput = document.getElementById('seoImage') as HTMLInputElement;
                          fileInput.click();
                        }}
                      >
                        <Typography>Upload Image</Typography>
                      </Button>
                    </Box>
                    {seoAlertOpen && (
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity="error"
                        onClose={handleSeoAlertClose}
                        sx={{ marginTop: '10px' }}
                      >
                        Image dimensions must be 500x250 pixels or smaller.
                      </MuiAlert>
                    )}
                    {errors.seoImage && touched.seoImage && typeof errors.seoImage === 'string' ? (
                      <Box color="#db2424">{errors.seoImage}</Box>
                    ) : null}

                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box  height="50%" width='50%' display="flex" >
                    {seoImage ? (
                      <CardMedia component="img" image={seoImage} />
                    ) : (
                      "No image"
                    )}
                  </Box>
                </Grid>


                <Grid item xs={12} md={12}>
                  <Box className="chips-container">
                    <label htmlFor="">SEO Keywords *</label>
                    <TextField
                      id="seoKeywords"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleInputKeyPress}
                      onBlur={() => {
                        setFieldTouched('seoKeywords', true);
                        setFieldValue('seoKeywords', chips);
                      }}
                      placeholder="Add SEO Keywords"
                      className='tag-input'
                      rows={4}
                      multiline
                    />
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {chips.map((chip, index) => (
                        <Chip
                          key={index}
                          label={chip}
                          onDelete={() => removeChip(index)}
                          className="chip"
                        />
                      ))}
                    </Box>
                  </Box>
                  {chips.length < 1 && errors.seoKeywords && touched.seoKeywords ? (
                    <Box color="#db2424">{errors.seoKeywords} </Box>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
            <Box mb={3} mt={5}>
              <Typography variant="h6" fontWeight={500} gutterBottom>
                OG Settings
              </Typography>
            </Box>
            <Grid container item xs={12} spacing={4} alignItems="flex-start">
              <Grid item xs={12} md={6}>
                {/* ogType */}
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="OG Type *"
                  name="ogType"
                  onChange={handleChange}
                  value={values.ogType}
                  onBlur={handleBlur}
                />
                {errors.ogType && touched.ogType ? (
                  <Box color="#db2424">{errors.ogType} </Box>
                ) : null}
              </Grid>

              <Grid item xs={12} md={6}>
                {/* ogLocale */}
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="OG Locale *"
                  name="ogLocale"
                  onChange={handleChange}
                  value={values.ogLocale}
                  onBlur={handleBlur}
                />
                {errors.ogLocale && touched.ogLocale ? (
                  <Box color="#db2424">{errors.ogLocale} </Box>
                ) : null}
              </Grid>

              <Grid item xs={12} md={6}>
                {/* ogTitle */}
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="OG Title *"
                  name="ogTitle"
                  onChange={handleChange}
                  value={values.ogTitle}
                  onBlur={handleBlur}
                />
                {errors.ogTitle && touched.ogTitle ? (
                  <Box color="#db2424">{errors.ogTitle} </Box>
                ) : null}
              </Grid>
            </Grid>
            <Box mb={3} mt={5}>
              <Typography variant="h6" fontWeight={500} gutterBottom>
                Twitter Settings
              </Typography>
            </Box>
            <Grid container item xs={12} spacing={4} alignItems="flex-start">
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="Twitter Card *"
                  name="twitterCard"
                  onChange={handleChange}
                  value={values.twitterCard}
                  onBlur={handleBlur}
                />
                {errors.twitterCard && touched.twitterCard ? (
                  <Box color="#db2424">{errors.twitterCard} </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* ogType */}
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="Twitter Title *"
                  name="twitterTitle"
                  onChange={handleChange}
                  value={values.twitterTitle}
                  onBlur={handleBlur}
                />
                {errors.twitterTitle && touched.twitterTitle ? (
                  <Box color="#db2424">{errors.twitterTitle} </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* ogType */}
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="Twitter Site *"
                  name="twitterSite"
                  onChange={handleChange}
                  value={values.twitterSite}
                  onBlur={handleBlur}
                />
                {errors.twitterSite && touched.twitterSite ? (
                  <Box color="#db2424">{errors.twitterSite} </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="Twitter Description*"
                  name="twitterDescription"
                  onChange={handleChange}
                  value={values.twitterDescription}
                  onBlur={handleBlur}

                />
                {errors.twitterDescription && touched.twitterDescription ? (
                  <Box color="#db2424">{errors.twitterDescription} </Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="file"
                  id="twitterImage"
                  inputProps={{ accept: 'image/*' }}
                  style={{ display: 'none' }}
                  onChange={handleTwitterFileChange}
                />
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" gap={3}>
                    <Tooltip title={twitterImage || ''} arrow>
                      <TextField
                        fullWidth
                        value={twitterImage}
                        label="Twitter Image *"
                        variant="outlined"
                        helperText="Dimension (500*250)"
                      />
                    </Tooltip>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{
                        backgroundColor: '#1976d2',
                        color: '#fff',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: '#1976d2 !important',
                        },
                        '&:hover': { backgroundColor: '#1976d2' },
                        border: '1px solid #1976d2',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        borderRadius: '8px',
                        whiteSpace: "nowrap",
                        width: '140px',
                        boxSizing: 'content-box'
                      }}
                      onClick={() => {
                        // Trigger the hidden file input
                        const fileInput = document.getElementById('twitterImage') as HTMLInputElement;
                        fileInput.click();
                      }}
                    >
                      Upload
                    </Button>
                  </Box>
                  {twitterAlertOpen && (
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      severity="error"
                      onClose={handleTwitterAlertClose}
                      sx={{ marginTop: '10px' }}
                    >
                      Image dimensions must be 500x250 pixels or smaller.
                    </MuiAlert>
                  )}

                  {errors.twitterImage && touched.twitterImage && typeof errors.twitterImage === 'string' ? (
                    <Box color="#db2424">{errors.twitterImage}</Box>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={6}>
                  <Box  height="50%" width='50%' display="flex" >
                    {twitterImage ? (
                      <CardMedia component="img" image={twitterImage} />
                    ) : (
                      "No image"
                    )}
                  </Box>
                </Grid>
                 </Grid>

            <Box display="flex" justifyContent="flex-end" gap="16px" mt={7} pt={5} borderTop="1px solid #999">

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "#1976d2",

                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#1976d2 !important"
                  },
                  "&:hover": { backgroundColor: "#1976d2" },
                  border: "1px solid #1976d2",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

                  color: "#ffff",

                  textTransform: 'none',
                  fontSize: '18px',
                  borderRadius: '8px',
                  minWidth: '150px',
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
  );
};

export default CreateSeoConfig;

export const SEOActionIcons = React.memo((props: any) => {
  const { SEOID, fetchInitialData } = props;
  const dispatch = useAppDispatch();
  const seoApiData = useAppSelector((state:any) => state.seoHandlerApi.seoApiData);
  let navigate = useNavigate();

  const getRowDataById = (SEOID: string) => {
    return seoApiData.find((row: any) => row.websiteName === SEOID);
  };
  const rowData = getRowDataById(SEOID);
console.log(rowData,"CMS")
  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon color="primary" onClick={() =>{
           dispatch(setTempData(rowData));
          navigate("/edit-cmsWebsite", { state: { rowData: rowData } });
          dispatch(setCmsEditFlag(true));
        }}></EditIcon></Tooltip>

      {/* <Tooltip title={ "upload File"}>
        <ImportExportIcon color={ "primary"} onClick={()=>{
           dispatch(setTempData(rowData));
        }} />
      </Tooltip> */}
       {/* <UploadCMSController /> */}
       {/* commented for prod deployment */}
       {/* <UploadCMSController  rowData={rowData} reload={props.reload}/> */}

    </div>
  )
});