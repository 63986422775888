import React from 'react'
import { ISvgIcons } from './AdvertiserSVGIcon'

const ExternalAPIsIcon:React.FC<ISvgIcons> = ({iconColor}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1120_1624)">
    <g clip-path="url(#clip1_1120_1624)">
    <path d="M5.15631 15.0468L7.44451 9.03893C7.49124 8.92465 7.65305 8.92447 7.70007 9.0387L9.96745 15.0468M5.86994 13.5506H9.26536M18 8.95311V15.0468M12.2437 8.95311V15.0468M12.2437 8.95311C12.2437 8.95311 12.248 10.2796 12.248 10.6728C12.248 11.1515 12.2556 12.4 12.2556 12.4C12.2556 12.4 13.4695 12.3924 13.9405 12.3924C14.8902 12.3924 15.6938 11.6225 15.6938 10.6728C15.6938 9.72303 14.8902 8.95311 13.9405 8.95311L12.2437 8.95311ZM8.66443 23.1186H15.3357V20.8338C16.3049 20.4675 17.2022 19.9552 17.9893 19.3095L19.9613 20.448L23.2969 14.6706L21.3166 13.5272C21.3977 13.0289 21.4509 12.5212 21.4509 12C21.4509 11.4787 21.3977 10.9711 21.3166 10.4727L23.2969 9.32937L19.9613 3.55195L17.9893 4.69045C17.2022 4.04475 16.3049 3.53245 15.3357 3.16622V0.881348H8.66443V3.16622C7.69515 3.53245 6.79792 4.04475 6.01079 4.69045L4.03882 3.55195L0.703247 9.32937L2.68352 10.4727C2.60238 10.9711 2.54918 11.4787 2.54918 12C2.54918 12.5212 2.60238 13.0289 2.68352 13.5272L0.703247 14.6706L4.03882 20.448L6.01079 19.3095C6.79792 19.9552 7.69515 20.4675 8.66443 20.8338V23.1186Z" stroke={iconColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_1120_1624">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    <clipPath id="clip1_1120_1624">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default ExternalAPIsIcon