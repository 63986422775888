import { createSlice , PayloadAction} from "@reduxjs/toolkit";

interface pubUserCampaignSlice{
    campaignData:any[],
    pageLoading:boolean,
}

const initial_state:pubUserCampaignSlice={
    campaignData:[],
    pageLoading:false
}
export const publisherUserCampaignSlice=createSlice({
    name: "Publisher Overall dashboard slice",
  initialState: initial_state,
  reducers:{
   storeResData:(state, action:PayloadAction<any>)=>{
   state.campaignData=action.payload;

   },
   storeLoadingData:(state, action)=>{
   state.pageLoading=action.payload
   }
}})


export const { storeLoadingData, storeResData} =
publisherUserCampaignSlice.actions;
// export const publisherOverallDashboardSelector = (state: RootState) => state.publisherDashboardOverallData
export const userPubCamp=publisherUserCampaignSlice.reducer;