import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableBody,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import {
  useFormik,
  FormikProvider,
  FieldArray,
  ErrorMessage,
  getIn,
} from 'formik';
import { IOSSwitch } from '../../../Component/Table/MMTable';
import * as Yup from 'yup';
import { baseURLConfig } from '../../../config/envConfig';
import { generateHeader } from '../../../service/Auth';
import axios from 'axios';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MuiAlert from '@mui/material/Alert';

interface EditBudgetDetails {
  campaignData: any[];
}

const frequencyValue = [
  { key: 1, value: 'Daily' },
  { key: 2, value: 'Weekly' },
  { key: 3, value: 'Monthly' },
];

const capsTypeValidation = function (
  value: string | undefined,
  context: Yup.TestContext
) {
  const { clickCapFilteringStatus } = context.parent;
  return clickCapFilteringStatus
    ? Yup.string().required('Please Select Caps Type').isValidSync(value)
    : true;
};

const capsInputValidation = function (
  value: string | undefined,
  context: Yup.TestContext
) {
  const { clickCapFilteringStatus } = context.parent;
  return clickCapFilteringStatus
    ? Yup.string().required('Click Amount is mandatory').isValidSync(value)
    : true;
};

const validationSchema = Yup.object().shape({
  campaignData: Yup.array().of(
    Yup.object().shape({
      clickCapSettings: Yup.object().shape({
        clickCapFilteringStatus: Yup.boolean(),
        clickCapType: Yup.string().test(
          'caps-type-validation',
          'Please Select Caps Type',
          capsTypeValidation
        ),
        clickCapInput: Yup.string()
          .test(
            'caps-input-validation',
            'Click Amount is mandatory',
            capsInputValidation
          )
          .matches(/^\d+(\.\d{1,2})?$/, 'Invalid Click Amount Format'),
      }),
    })
  ),
});

const updateBudgetData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(
      `${baseURLConfig.baseURl}/update/advertisers/campaignCapBudget`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const EditCapsFiltering = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { advertiserData, campaignData } = location.state;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formik = useFormik<EditBudgetDetails>({
    initialValues: {
      campaignData: campaignData.map((item: any) => ({
        ...item,
        clickCapSettings: {
          ...item.clickCapSettings,
          clickCapType: item?.clickCapSettings?.clickCapType || '',
          clickCapFilteringStatus: item?.clickCapSettings?.clickCapFilteringStatus || false,

          clickCapInput: item?.clickCapSettings?.clickCapInput || '',
        },
      })),
    },
    onSubmit: values => {
      setIsSubmitting(true);
      let campaignsWithoutName = values?.campaignData.map(
        ({ campaignname,budgetSettings, ...rest }) => rest
      );
      console.log('campaignsWithoutName',campaignsWithoutName)
      const updateData = async () => {
        try {
          const getData = await updateBudgetData(campaignsWithoutName);
          setIsSubmitting(false);
          setSuccessMessage('Click Caps Updated Successfully');
          setSnackbarOpen(true);
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error fetching data:', error);
        }
      };
      updateData();
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    console.log('formik', formik?.errors);
    console.log('campaignData', campaignData);
    // Assuming campaignData is properly set here, if not, handle accordingly.
  }, [formik?.errors]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (isSnackbarOpen) {
      setTimeout(() => {
        navigate('/caps-filtering');
      }, 1000);
    }
  }, [isSnackbarOpen]);
  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        className="card"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading"> Click Caps</Typography>
            <Typography className="card-sub-heading">
              Edit Click Caps
            </Typography>
          </Box>
        </Box>

        <Box className="no-more-tables responsive-tbl">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="Campaigns">Campaigns</TableCell>
                <TableCell key="status" width="20%" align={'center'}>
                  Click Caps Status
                </TableCell>
                <TableCell key="type" width="25%" align={'center'}>
                  Caps Type
                </TableCell>
                <TableCell key="input" width="25%" align={'center'}>
                  Click Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <FormikProvider value={formik}>
              <TableBody>
                <FieldArray
                  name="campaignData"
                  render={arrayHelpers => (
                    <>
                      {formik.values.campaignData.map(
                        (campaign: any, index: number) => {
                          const touchedType = getIn(
                            formik.touched,
                            `campaignData.${index}.clickCapSettings.clickCapType`
                          );
                          const errorType = getIn(
                            formik.errors,
                            `campaignData.${index}.clickCapSettings.clickCapType`
                          );
                          const touchedInput = getIn(
                            formik.touched,
                            `campaignData.${index}.clickCapSettings.clickCapInput`
                          );
                          const errorInput = getIn(
                            formik.errors,
                            `campaignData.${index}.clickCapSettings.clickCapInput`
                          );

                          return (
                            <TableRow key={index}>
                              <TableCell data-title="Campaign">
                              {`${campaign.campaignname} - ${campaign.SORTID}` }
                              </TableCell>
                              <TableCell
                                sx={{ textAlign: 'center' }}
                                data-title="Cap Filtering Status"
                              >
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      checked={
                                        campaign.clickCapSettings
                                          ?.clickCapFilteringStatus || false
                                      }
                                      onChange={() => {
                                        formik.setFieldValue(
                                          `campaignData[${index}].clickCapSettings.clickCapFilteringStatus`,
                                          !campaign.clickCapSettings
                                            ?.clickCapFilteringStatus
                                        );
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </TableCell>
                              <TableCell data-title="Caps Type" align="center">
                                <FormControl
                                  sx={{
                                    width: isDesktop ? '60%' : '100%',
                                    margin: 'auto', // Center align the TextField within TableCell
                                  }}
                                  size="small"
                                  disabled={
                                    !campaign.clickCapSettings
                                    ?.clickCapFilteringStatus
                                  }
                                >
                                  <InputLabel>Select Type</InputLabel>
                                  <Select
                                    value={
                                      formik.values.campaignData[index]
                                        .clickCapSettings?.clickCapType
                                    }
                                    onChange={e => {
                                      formik.setFieldValue(
                                        `campaignData[${index}].clickCapSettings.clickCapType`,
                                        e.target.value
                                      );
                                    }}
                                    name={`campaignData[${index}].clickCapSettings.clickCapType`}
                                    label="Select Caps Type"
                                    error={touchedType && Boolean(errorType)}
                                    sx={{
                                      textAlign: 'left', // Center align the Select dropdown text
                                    }}
                                  >
                                    {frequencyValue.map(val => (
                                      <MenuItem key={val.key} value={val.value}>
                                        {val.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {touchedType && errorType ? (
                                  <Box color="#db2424">{errorType}</Box>
                                ) : null}
                              </TableCell>
                              <TableCell data-title="Caps Input" align="center">
                                <TextField
                                  label="Click Amount"
                                  name={`campaignData[${index}].clickCapInput`}
                                  variant="outlined"
                                  size="small"
                                  value={
                                    formik.values.campaignData[index]
                                      .clickCapSettings?.clickCapInput
                                  }
                                  sx={{
                                    width: isDesktop ? '60%' : '100%',
                                    margin: 'auto',
                                  }}
                                  disabled={
                                    !campaign.clickCapSettings
                                    ?.clickCapFilteringStatus
                                  }
                                  fullWidth
                                  onChange={e => {
                                    const { name, value } = e.target;

                                    // Regex to allow only numbers and one decimal point
                                    if (
                                      /^[1-9]\d*$/.test(value) ||
                                      value === ''
                                    ) {
                                      formik.setFieldValue(
                                        `campaignData[${index}].clickCapSettings.clickCapInput`,
                                        e.target.value
                                      );
                                    }
                                  }}
                                  error={touchedInput && Boolean(errorInput)}
                                />
                                {touchedInput && errorInput ? (
                                  <Box color="#db2424">{errorInput}</Box>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </>
                  )}
                />
              </TableBody>
            </FormikProvider>
          </Table>
        </Box>
        <Box
          className="mProgram-btn-container"
          sx={{
            textAlign: 'right',
            paddingRight: '30px',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Button
            onClick={() => {
              navigate('/caps-filtering');
            }}
            sx={{
              backgroundColor: '#FFF',
              border: '1px solid #0AE',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              borderRadius: '8px',
              color: '#00AAEE',
              width: '100px',
              height: '45px',
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            sx={{
              backgroundColor: ' #00AAEE',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#00AAEE !important',
              },
              '&:hover': { backgroundColor: '#00AAEE' },
              border: '1px solid #0AE',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              borderRadius: '8px',
              color: '#ffff',
              height: '45px',
              marginLeft: '10px',
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default EditCapsFiltering;
