import { createAsyncThunk, createSlice ,current} from "@reduxjs/toolkit";
import { loginUserService, logoutUserService } from "../../../service/Auth";


interface userInfo{
    email:string,
    password:string
}
export const loginUser = createAsyncThunk("MM/loginUser", async (userInfo:userInfo) => {
    let response = await loginUserService(userInfo.email, userInfo.password)
  
    return response
  
  });

  export const logOutUser = createAsyncThunk("MM/logout", async () => {
    let response = await logoutUserService()
  
    return response
  
  });

  interface IinitialState{
    userType:any,
    user:any | null,
    loading:boolean, 
    check: boolean,
    error: null | any,
    openChangePasswordModal: boolean,
    newPassword: string,
    confirmPassword: string,
    createPasswordError: string,
    confirmPasswordError:string;
    changePasswordNewUserStatus:string,

    changePasswordLoading: boolean,
     createNewPassword:{
      newPassword:string,
      confirmPassword:string
     }
    
  }
const INITIAL_STATE:IinitialState={
  userType:'',
  user: {},
  loading: false,
  check: true,
  error: null,
  openChangePasswordModal: false,
  newPassword: "",
  confirmPassword: "",
  createPasswordError: "",
  changePasswordNewUserStatus: "",
  changePasswordLoading: false,
  createNewPassword:{
    newPassword:"",
    confirmPassword:""
   },
   confirmPasswordError:""
}

const userSlice = createSlice({
    name: "Auth",
    // initialState: {
    //   user: {
  
    //   },
    //   loading: false,
    //   check: true,
    //   error: {},
    //   openChangePasswordModal: false,
    //   newPassword: "",
    //   confirmPassword: "",
    //   createPasswordError: "",
    //   changePasswordNewUserStatus: "",
    //   changePasswordLoading: false
  
    // },
    initialState:INITIAL_STATE,
    reducers: {
      handelLogout: (state, action) => {
        localStorage.removeItem('userId')
        localStorage.removeItem('userRole')
        localStorage.removeItem('userSortid')
        localStorage.removeItem('userName')
        state.user={};
        state.error=null;
        state.loading=false;
        // return { ...state, user: null, error: null, loading: false }
      },

      handelUserSliceReset:(state, action)=>{
return INITIAL_STATE
      },
      storeUserInfo: (state, action) => {
        const userId = action.payload
        // console.log("UserId", userId)
        return { ...state, user: action.payload }
      },
      clearErrorMessage: (state, action) => {
        return {
          ...state, error: null, user: {}, loading: false
        }
      },
      clearNewPasswordModalValue:(state,action)=>{
        return {
          ...state, createNewPassword:{
            newPassword:"",
            confirmPassword:""
           }
        }
      },
      handelChangePasswordModalOpen: (state, action) => {
        // console.log("atioon payload modal oprn", action.payload)
        return { ...state, openChangePasswordModal: action.payload }
      }
      , handelNewPassword: (state, action) => {
        return {
          ...state, newPassword: action.payload
        }
      },
      handelConfirmPassword: (state, action) => {
        return {
          ...state, confirmPassword: action.payload
        }
      },
      handelPasswordError: (state, action) => {
        return {
          ...state, createPasswordError: action.payload
        }
      },
      setUserType: (state, action) => {
        return {
          ...state, userType: action.payload
        }},
      handelConfirmPasswordError: (state, action) => {
        return {
          ...state, confirmPasswordError: action.payload
        }
      },
  
    updateCreateNewPassword:(state, action)=>{
        if(action.payload.type==='newPassword'){
          state.createNewPassword.newPassword=action.payload.value
        }else if(action.payload.type==='confirmPassword'){
          state.createNewPassword.confirmPassword=action.payload.value
        }
    }
    },
    extraReducers:(builder) =>{
       builder.addCase(loginUser.pending,(state)=>{
        state.loading = true;
       })
       .addCase(loginUser.fulfilled, (state, action)=>{
        // let updateUser = { ...state.user };
        let updateUser = action.payload
        // console.log("user payload response ", updateUser)
        localStorage.setItem("userId", JSON.stringify(action.payload))
        localStorage.setItem("userRole", updateUser.attributes && updateUser.attributes["custom:groupType"] ? updateUser.attributes["custom:groupType"] : '');
      localStorage.setItem("userSortid", updateUser.attributes && updateUser.attributes["custom:group"] ? updateUser.attributes["custom:group"] : '');
      localStorage.setItem("userName", updateUser.username && updateUser.username ? updateUser.username : '');
        state.user =action.payload;
        //  console.log("user payload response==", updateUser,updateUser.attributes["custom:groupType"])
        state.loading = false;
       }).addCase(loginUser.rejected, (state,action)=>{
        // console.log("error in redux thunk", action)
        state.error = action.error
        state.loading = false;
       })
       .addCase(logOutUser.pending, ()=>{
        // console.log("logOutUser.pending,")
       })
       .addCase(logOutUser.fulfilled, (state, action)=>{
        // console.log("logOutUser.Fullfilled,")
        state.error=null
        state.user={}
        state.loading=false
        localStorage.removeItem("userId`")
        localStorage.removeItem("userRole")
        localStorage.removeItem("userSortid")
        localStorage.removeItem('userName')

       })
       .addCase(logOutUser.rejected, ()=>{
        console.log("logOutUser.rejected,")
       })
       
       
    },
    
  });
  export default userSlice.reducer;
  export const { handelLogout, storeUserInfo, clearNewPasswordModalValue,clearErrorMessage, handelChangePasswordModalOpen, handelNewPassword, handelConfirmPassword, handelPasswordError , updateCreateNewPassword, handelConfirmPasswordError, handelUserSliceReset,setUserType} = userSlice.actions;
//   extraReducers: {
//     [loginUser.fulfilled]: (state, action) => {
//       //If we have to totally replace the existing array:
//       // state.todos = action.payload;

//       //if we want to add the json to an existing array
//       let updateUser = { ...state.user };
//       updateUser = action.payload
//       localStorage.setItem("userId", JSON.stringify(action.payload))
//       state.user = updateUser;
//       state.loading = false;
//     },
//     [loginUser.pending]: (state) => {
//       state.loading = true;
//     },
//     [loginUser.rejected]: (state, action) => {
//       console.log("error in redux thunk", action)
//       state.error = action.error
//       state.loading = false;

//     },
  //   [changePasswordNewUser.fulfilled]: (state, action) => {
  //     // updateUser(action.payload.username)
  //     // state.openChangePasswordModal=false
  //     state.changePasswordNewUserStatus = "success"
  //     const dispatch = useDispatch();
  //     dispatch(update_User())

  //     console.log("action.payload changePasswordNewUser.fulfilled", action.payload)
  //   },
  //   [changePasswordNewUser.pending]: (state) => {
  //     state.changePasswordLoading = true;
  //     state.changePasswordNewUserStatus = ""
  //   },
  //   [changePasswordNewUser.rejected]: (state, action) => {
  //     console.log("error in redux thunk", action)
  //     state.error = action.error
  //     state.changePasswordNewUserStatus = ""
  //     state.openChangePasswordModal = false
  //     state.changePasswordLoading = false;

  //   },
  //   [update_User.fulfilled]: (state, action) => {
  //     // updateUser(action.payload.username)
  //     state.changePasswordLoading = false;
  //     state.openChangePasswordModal = false
  //     // state.update_User="success"
  //     state.changePasswordNewUserStatus = ""
  //     localStorage.clear();
  //     console.log("action.payload update_User.fulfilled", action.payload)
  //   },
  //   [update_User.pending]: (state) => {
  //     state.changePasswordLoading = true;
  //     state.openChangePasswordModal = true
  //     // state.changePasswordNewUserStatus=""
  //     // state.update_UserStatus=""
  //   },
  //   [update_User.rejected]: (state, action) => {
  //     console.log("error in redux thunk", action)
  //     state.error = action.error
  //     // state.update_UserStatus=""
  //     state.openChangePasswordModal = false
  //     state.changePasswordLoading = false;

  //   }
//   }