import { Auth, } from 'aws-amplify';
export const  loginUserService=async (email:string, password:string)=>{
  
    const response=await Auth.signIn(email, password)
  return response;
}

export const  logoutUserService=async ()=>{
  
    await Auth.signOut()
}

export const  generateOtpService=async (userName:string)=>{
  let response;
  try{
    response=await Auth.forgotPassword(userName)
   }catch(error){
  console.log("error ", error )
  response=error
  }
  
  // const response=await Auth.forgotPassword(userName)
return response;
}
export const fetchToken=async()=>{
  const session= await Auth.currentSession();
  // console.log("idToken", session)
  const idToken = session.getIdToken().getJwtToken();
  // console.log("idToken", idToken)
  return idToken
}



export const generateHeader=async()=>{
  const idToken = await fetchToken()
  const headers = { 
     'Authorization': idToken,
     'Content-Type': 'application/json', 
   }

   return headers;
}