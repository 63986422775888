// import moment from 'moment/moment';
// import { ChartConfiguration, Scriptable, ChartOptions, CartesianScaleOptions, CoreScaleOptions, Scale } from 'chart.js/auto';
// const backgroundColors = ["#4198D7", "#F5C767", "#7A61BA"]
import _ from 'lodash';
import IntradayJson from '../../../../Mock/Dashboard Data/IntraChartsAPIResponse.json';
import IntradayJson1 from '../../../../Mock/Dashboard Data/groupIntra.json';
// const barColors = ["#4c78a8", "#bab0ac", "#9d755d", "#ff9da7", "#b279a2", "#eeca3b", "#54a24b", "#72b7b2", "#e45756", "#f58518"]
var colors = [
  "#4C78A8",
  "#F58518",
  "#E45756",
  "#FFE2E5",
  "#72B7B2",
  "#DCFCE7",
  "#F3E8FF",
  "#54A24B",
  "#0ae",
  "#EECA3B",
  "#B279A2",
  "#FF9DA7",
  "#9D755D",
  "#BAB0AC",
  "#6C5B7B",
  "#E1B16A",
  "#A2D4AB",
  "#EAC8C8",
  "#8E8C6D",
  "#D6BCC0",
  "#C7B8EA",
  "#96C9DB",
  "#D7C9A7",
  "#797979",
  "#D7C9A7",
  "#7DBCC9",
  "#D9D9D9",
  "#BED3F3",
  "#FDB462",
  "#BDD7EE",
  "#C4AD66",
  "#4C78A8"
];

interface overallAPiresponse {

  "Date/Time": string,
  "Total Commission": number,
  "campaignName": string,
  "CLICKS": number

}


interface formatedOverallApiResponse {
  "Date": string | Date ,
  "Commission": string | number,
  "campaign": string,
  "CLICKS": number,
}

interface ClickData {
  campaignName: string;
  CLICKS: number;
  TotalCommission: number;
  Date2: string;
}

export interface ReducedClickData {
  campaign: string;
  CLICKS: number;
  Commission: number;
  Date: string;
}

interface Entry {
  Ad_Requests: number;
  CLICKS: number;
  "Total Commission": number;
  CPC: number;
  CTR: number;
  formatDate: string;  
  publisherName?:string;
  advertiserName?:string
  campaignName?:string
  campaignId?:string;
  Date:string;
"Avg position":number

}
interface chartsInputData {
  [key: string]: ClickData[];
}

interface intraTableInputData {
  [key: string]: Entry[];
}


export const createCampaignBarDatasets = (campaignsArray: any) => {
  const length = campaignsArray?.length;
  const arrOfBackgroundColors = Array.from({ length }, (_, index) => {
    return colors[index % colors.length];

  });
  const campaignBarDataSets = Array.from(campaignsArray, (element: any, index) => {
    const obj: any = {
      label: element.campaignname,
      value: element.SORTID,
      data: [],
      // type: "bar",
      backgroundColor: arrOfBackgroundColors[index],
      yAxisID: "A",
      borderColor: "",
      borderWidth: 0,
      order: 2,
      fill: false,
      stack: "all-bars"
    }

    return obj;
  })


  return campaignBarDataSets;
}

export const groupResponse = (ApiRes: formatedOverallApiResponse[]) => {
  const groupedResponse = ApiRes.reduce((acc: any, curr: any) => {
    if (acc[curr.campaign]) {
      acc[curr.campaign].push(curr);
    } else {
      acc[curr.campaign] = [curr];
    }
    return acc;
  }, {});
  return groupedResponse;
}
export const groupResponseByBrandName = (ApiRes: any) => {
  const groupedResponse = ApiRes.reduce((acc: any, curr: any) => {
    if (acc[curr.brandId]) {
      acc[curr.brandId].push(curr);
    } else {
      acc[curr.brandId] = [curr];
    }
    return acc;
  }, {});
  return groupedResponse;
}

export const createBarChartJson = (apiResponse: formatedOverallApiResponse[], campaignBarDataSets: any) => {
  const group_Response = groupResponse(apiResponse)
  const uniqueCampaigns = [...new Set(apiResponse.map((item) => item.campaign))];
  const barDatasets = campaignBarDataSets.filter((item: any) =>
    uniqueCampaigns.includes(item.label)
  );

  const label = apiResponse
  .map((item) => item.Date) // Convert Date to timestamp (number)



const uniqueLabel = Array.from(new Set(label));
  const fd = barDatasets.map((item: any) => {
    const items = group_Response[item.label]
    // console.log("items", items)
    if (items) {
      return {
        ...item,
        // data: group_Response[item.label] && group_Response[item.label].map((content) => content.CLICKS.toString())
        data: group_Response[item.label] && getClicksValue(group_Response[item.label], uniqueLabel)
      }
    }
  })
  const { totalCommission, onlyCommission } = calculateTotalCommission(apiResponse);
  const totalrevenue = {

    label: "Total Commission",
    type: "line",
    data: onlyCommission.map((c) => c.Commission),
    backgroundColor: "#000000",
    yAxisID: "B",
    borderColor: "#000000",
    borderWidth: 2,
    order: "1",

  }
  fd.push(totalrevenue);

  const chartData = {
    labels: uniqueLabel,
    datasets: fd
  }
  return chartData
}



export const createBarIntraChartJson = (apiResponse: ReducedClickData[], campaignBarDataSets: any) => {
  const group_Response = groupResponse(apiResponse)
  const uniqueCampaigns = [...new Set(apiResponse.map((item) => item.campaign))];
  const barDatasets = campaignBarDataSets.filter((item: any) =>
    uniqueCampaigns.includes(item.label)
  );
  const label = apiResponse
    .map((item) => item.Date) // Convert Date to timestamp (number)
    const uniqueLabel = Array.from(new Set(label));
  
  // console.log("Unique label", uniqueLabel.map((item:number)=>item * 1000))
    const fd = barDatasets.map((item: any) => {
    const items = group_Response[item.label]
    // console.log("items", items)
    if (items) {
      return {
        ...item,
        // data: group_Response[item.label] && group_Response[item.label].map((content) => content.CLICKS.toString())
        data: group_Response[item.label] && getClicksValue(group_Response[item.label], uniqueLabel),
        order:2
      }
    }
  })
  const {  onlyCommission } = calculateTotalCommission(apiResponse);
  const totalrevenue = {

    label: "Total Commission",
    type: "line",
    data: onlyCommission.map((c) => c.Commission),
    backgroundColor: "#000000",
    yAxisID: "B",
    borderColor: "#000000",
    borderWidth: 2,
    order: 1

  }

  fd.push(totalrevenue);
  console.log("final Dataset", fd)
  const chartData = {
    labels: uniqueLabel.sort((a, b) => {
      // Convert timestamps to minutes for comparison
      let aMinutes = parseInt(a.split(":")[0]) * 60 + parseInt(a.split(":")[1]);
      let bMinutes = parseInt(b.split(":")[0]) * 60 + parseInt(b.split(":")[1]);
  
      return aMinutes - bMinutes;
  }),
    datasets: fd
  }
  return chartData
}

function calculateTotalCommission(response1: any) {
  let commissionByDate: any = {};

  // Loop through each campaign and sum the commission by date
  response1.forEach((campaign: any) => {
    const date = campaign.Date;
    let commission;
    if(typeof campaign.Commission==="number"){
      commission = campaign.Commission;
    }else{
      commission = parseFloat(campaign.Commission.replace(/[^0-9.-]+/g, ""));
    }
     
    if (commissionByDate[date]) {
      commissionByDate[date] += commission;
    } else {
      commissionByDate[date] = commission;
    }
  });

  // Convert commissionByDate object to an array of objects
  const totalCommission = Object.keys(commissionByDate).map(date => {
    return { Date: date, Commission: `$${commissionByDate[date].toFixed(2)}` };
  });

  const onlyCommission = Object.keys(commissionByDate).map(date => {
    return { Commission: `${commissionByDate[date].toFixed(2)}` };
  });

  return { totalCommission, onlyCommission };
}
const getClicksValue = (data: any, label: any) => {
  // console.log("label", label)
  // console.log("data", data)
  const clicksData = label.map((labelDate: any) => {
    const dataObj = data.find((obj: any) => obj.Date === labelDate);
    return dataObj ?parseInt(dataObj.CLICKS)  : 0;
  });
  return clicksData;
}

export const generateIntradayChartsData = (apiResponse: formatedOverallApiResponse[], campaignBarDataSets: any) => {
  const group_Response = groupResponse(apiResponse)
  const uniqueCampaigns = [...new Set(apiResponse.map((item) => item.campaign))];
  const labels= apiResponse.map((item) =>item.Date) 
  const chartData = campaignBarDataSets.map((chartProtoType: any) => {
    const eachResponses = group_Response[chartProtoType.label]
    // console.log("eachResponses", eachResponses)
    if (eachResponses) {
      return {
        ...chartProtoType,
        data: eachResponses.map((eachResponse: any) => {
          return {
            x: eachResponse['Date'], y: eachResponse['CLICKS']
          }
        }),
         type: "bar",
        // data: group_Response[chartProtoType.label] && getClicksValue(group_Response[chartProtoType.label], labels)


      }
    }
    return "undefined";

  })
  const { totalCommission, onlyCommission } = calculateTotalCommission(apiResponse);
  const totalrevenue = {

    label: "Total Commission",
    type: "line",
    data: onlyCommission.map((c) => c.Commission),
    backgroundColor: "#000000",
    yAxisID: "B",
    borderColor: "#000000",
    borderWidth: 2,
    order: "1"

  }

  // chartData.push(totalrevenue)
  const data={
    labels:labels,
    datasets:chartData.filter((element:any) => element !== "undefined")
  }
 
return data;
// return data
}

export const overallPageBarOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    A: {
      id: "A",
      type: "linear" as const,
      position: "left" as const,
      title: {
        display: true,
        text: "Clicks"
      },
      ticks: {
        callback: function (value: any, index: any, ticks: any) {
          if (value <= 2500 || value >= 0) {
            return value;
          }
        },
        stepSize: 0
      },
    },
    B: {
      id: "B",
      type: "linear" as const,
      position: "right" as const,
      title: {
        display: true,
        text: "Commission"
      },
      grid: { display: false },
      ticks: {
        callback: function (value: any, index: any, ticks: any) {
          return '$' + value;
        },
        stepSize: 0
      },
    },
    x: {
      stacked: true,
      grid: { display: false },
      // type:"time",
      title: {
        display: true,
        text: "Date"
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title:function(context:any){
        //  console.log(context,'cc')
           return  formatDate(context[0].label)
        },
        label: function (context: any) {
          // console.log(context,'cc line ')
          if (context.dataset.type === 'line') {
            var label = context.dataset.label;
            var value = context.parsed.y;
            return label + ':  $ ' + value;
          }
        }
      }
    }
  }
};

export const advOverallPageBarOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    A: {
      id: "A",
      type: "linear" as const,
      position: "left" as const,
      title: {
        display: true,
        text: "Clicks"
      },
      ticks: {
        callback: function (value: any, index: any, ticks: any) {
          if (value <= 2500 || value >= 0) {
            return value;
          }
        },
        stepSize: 0
      },
    },
    B: {
      id: "B",
      type: "linear" as const,
      position: "right" as const,
      title: {
        display: true,
        text: "Revenue"
      },
      grid: { display: false },
      ticks: {
        callback: function (value: any, index: any, ticks: any) {
          return '$' + value;
        },
        stepSize: 0
      },
    },
    x: {
      stacked: true,
      grid: { display: false },
      // type:"time",
      title: {
        display: true,
        text: "Date"
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title:function(context:any){
        //  console.log(context,'cc')
           return  formatDate(context[0].label)
        },
        label: function (context: any) {
          // console.log(context,'cc line ')
          if (context.dataset.type === 'line') {
            var label = context.dataset.label;
            var value = context.parsed.y;
            return label + ':  $ ' + value;
          }
        }
      }
    }
  }
};

function formatDate(dateString:string) {
  const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
  ];

  // Split the date string into month, day, and year
  const [month, day, year] = dateString.split("-").map(Number);

  // Get the month name from the months array
  const monthName = months[month - 1];

  // Return the formatted date string
  return `${monthName} ${day} ${year}`;
}

export const pubIntraDayChartOptions= {
  responsive:true,
  // responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      type: 'time',
      // min:"12:00",
      title: {
        display: true,
        text: 'Time',
      },
      time: {
       unit: 'hour',
         parser: 'HH:mm',
        stepSize: 1,
        displayFormats: {
          hour: 'HH:mm',
        },
      },
    },
    A: {
      id: 'A',
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Clicks',
      },
      ticks: {
        callback: (value:any) => (value <= 2500 && value >= 0 ? value : ''),
        stepSize: 0,
      },
    },
    B: {
      id: 'B',
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Commission',
      },
      grid: {
        display: false,
      },
      ticks: {
        callback: (value:any) => `$${value}`,
        stepSize: 0,
      },
    },
  },
};

export const advIntraDayChartOptions= {
  responsive:true,
  // responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      type: 'time',
      // min:"12:00",
      title: {
        display: true,
        text: 'Time',
      },
      time: {
       unit: 'hour',
         parser: 'HH:mm',
        stepSize: 1,
        displayFormats: {
          hour: 'HH:mm',
        },
      },
    },
    A: {
      id: 'A',
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Clicks',
      },
      ticks: {
        callback: (value:any) => (value <= 2500 && value >= 0 ? value : ''),
        stepSize: 0,
      },
    },
    B: {
      id: 'B',
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Revenue',
      },
      grid: {
        display: false,
      },
      ticks: {
        callback: (value:any) => `$${value}`,
        stepSize: 0,
      },
    },
  },
};

// export const intraDayOptions: any = {
//   responsive: true,
//   spanGaps: true,
//   maintainAspectRatio: false,
//   interaction: {
//     mode: 'index' as const,
//     intersect: false,
//   },
//   scales: {
//     A: {
//       // id: "A",
//       type: "linear" as const,
//       position: "left" as const,
//       title: {
//         display: true,
//         text: "Clicks"
//       },
//       ticks: {
//         callback: function (value: any, index: any, ticks: any) {
//           if (value <= 2500 || value >= 0) {
//             return value;
//           }
//         },
//         stepSize: 0
//       },
//     },
//     B: {
//       // id: "B",
//       type: "linear" as const,
//       position: "right" as const,
//       title: {
//         display: true,
//         text: "Commission"
//       },
//       grid: { display: false },
//       ticks: {
//         callback: function (value: any, index: any, ticks: any) {
//           return '$' + value;
//         },
//         stepSize: 0
//       },
//     },
//     x: {
//       stacked: true,
//       type: 'time',
//       time: {
//         unit: 'hour'
//       },
//       tick: {
//         source: 'data'
//       }
//       // time: {
//       //   unit: 'hour',
//       //   unitStepSize: 1,
//       //   displayFormats: {
//       //     hour: 'HH:mm', // Format for hours
//       //   },
//       // },
//       // grid: { display: false },
//       // title: {
//       //   display: true,
//       //   text: "Time"
//       // },
//     }

//   },
//   plugins: {
//     tooltip: {
//       callbacks: {
//         label: function (context: any) {
//           if (context.dataset.type === 'line') {
//             var label = context.dataset.label;
//             var value = context.parsed.y;
//             return label + ':  $ ' + value;
//           } else {
//             // console.log("TRACKSSSSSSSSSSS")
//             const labelx = context.dataset.label || '';
//             const dataPoint = context.parsed.y;
//             const date = new Date(context.parsed.x);
//             // console.log("x-data bar", `${labelx}: ${dataPoint} at ${date.toLocaleTimeString()}`)
//             // Customize the tooltip label
//             return `${labelx}: ${dataPoint} at ${date.toLocaleTimeString()}`;
//           }


//         }
//       }
//     }
//   }
// };
// export const advIntraDayOptions: any = {
//   responsive: true,
//   spanGaps: true,
//   maintainAspectRatio: false,
//   interaction: {
//     mode: 'index' as const,
//     intersect: false,
//   },
//   scales: {
//     A: {
//       // id: "A",
//       type: "linear" as const,
//       position: "left" as const,
//       title: {
//         display: true,
//         text: "Clicks"
//       },
//       ticks: {
//         callback: function (value: any, index: any, ticks: any) {
//           if (value <= 2500 || value >= 0) {
//             return value;
//           }
//         },
//         stepSize: 0
//       },
//     },
//     B: {
//       // id: "B",
//       type: "linear" as const,
//       position: "right" as const,
//       title: {
//         display: true,
//         text: "Commission"
//       },
//       grid: { display: false },
//       ticks: {
//         callback: function (value: any, index: any, ticks: any) {
//           return '$' + value;
//         },
//         stepSize: 0
//       },
//     },
//     x: {
//       stacked: true,
//       type: 'time',
//       time: {
//         unit: 'hour'
//       },
//       tick: {
//         source: 'data'
//       }
//       // time: {
//       //   unit: 'hour',
//       //   unitStepSize: 1,
//       //   displayFormats: {
//       //     hour: 'HH:mm', // Format for hours
//       //   },
//       // },
//       // grid: { display: false },
//       // title: {
//       //   display: true,
//       //   text: "Time"
//       // },
//     }

//   },
//   plugins: {
//     tooltip: {
//       callbacks: {
//         label: function (context: any) {
//           if (context.dataset.type === 'line') {
//             var label = context.dataset.label;
//             var value = context.parsed.y;
//             return label + ':  $ ' + value;
//           } else {
//             // console.log("TRACKSSSSSSSSSSS")
//             const labelx = context.dataset.label || '';
//             const dataPoint = context.parsed.y;
//             const date = new Date(context.parsed.x);
//             // console.log("x-data bar", `${labelx}: ${dataPoint} at ${date.toLocaleTimeString()}`)
//             // Customize the tooltip label
//             return `${labelx}: ${dataPoint} at ${date.toLocaleTimeString()}`;
//           }


//         }
//       }
//     }
//   }
// };

type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

export function debounce<T extends (...args: any[]) => any>(func: T, wait: number): DebouncedFunction<T> {
  let timeout: NodeJS.Timeout | null;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

// GROUP BY HOUR FOR INTRACHARTS

// Function to calculate the hour from a date string
const getHourFromDate = (dateString: string): number => {
  const date = new Date(dateString);
  return date.getHours();
};


export const groupByCampaign=(data:any)=>{
// Group click data by campaign name and hour
const groupedData = _.groupBy(data, (entry) => `${entry.campaignName}`);
return groupedData;
}



export function reduceClickData(input: chartsInputData): ReducedClickData[] {
  const reducedData: { [key: string]: ReducedClickData } = {};

  Object.keys(input).forEach(campaignName => {
      input[campaignName].forEach(click => {
          const date = new Date(click.Date2);
          const hours = date.getHours();
          // const timeFrame = hours >= 12 ? `${hours % 12 || 12}pm` : `${hours}am`;
          const timeFrame = hours < 10 ? `0${hours}:00` : `${hours}:00`;
          const key = `${campaignName}_${timeFrame}`;

          if (!reducedData[key]) {
              reducedData[key] = {
                  campaign: campaignName,
                  CLICKS: 0,
                  Commission: 0,
                  Date: timeFrame
              };
          }

          reducedData[key].CLICKS += click.CLICKS;
          reducedData[key].Commission += click.TotalCommission;
      });
  });

  return Object.values(reducedData);
}

export function reduceIntraTableData(input: intraTableInputData, intraType:string ): Entry[] {
  const reducedData: { [key: string]: Entry } = {};
const pubKey= intraType==='advertiser'?'advertiserName':'publisherName'
  Object.keys(input).forEach(campaignName => {
      input[campaignName].forEach(click => {
          const date = new Date(click.formatDate);
          const hours = date.getHours();
          // const timeFrame = hours >= 12 ? `${hours % 12 || 12}pm` : `${hours}am`;
          const timeFrame = hours < 10 ? `0${hours}:00` : `${hours}:00`;
          const key = `${campaignName}_${timeFrame}`;

          if (!reducedData[key]) {
            const requiredDate=date.toLocaleString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
               hour12:false,
            })
              reducedData[key] = {
                  campaignName: campaignName,
                  CLICKS: 0,
                  "Total Commission": 0.0,
                  Ad_Requests:0,
                  CPC:click.CPC,
                  CTR:0,
                  formatDate: click.formatDate,
                  // publisherName:click.publisherName,
                  "Avg position":0.0,
                  Date:  requiredDate + " " + timeFrame  ,
                  [pubKey]:click[pubKey],
                  campaignId:click.campaignId
                 
                  
              };
          }

          reducedData[key].CLICKS += click.CLICKS;
          // reducedData[key].Commission += click.Total_Commission;
          reducedData[key].Ad_Requests += click.Ad_Requests;
          // reducedData[key].CLICKS += click.CLICKS;
          reducedData[key][ "Total Commission"]+= click[ "Total Commission"];
           reducedData[key].CPC += click.CPC;
          reducedData[key].CTR +=click.CTR;
          reducedData[key]["Avg position"]+=click[ "Avg position"];
      });
          // Calculate CTR and CPC averages
    Object.keys(reducedData).forEach(key => {
      const entry = reducedData[key];
      if (entry.CLICKS > 0) {
          entry.CTR = entry.CLICKS / entry.Ad_Requests;
          entry.CPC = entry["Total Commission"] / entry.CLICKS;
          //  entry['Avg position']= entry['Avg position']/input[campaignName].length
      }
  });
  });

  return Object.values(reducedData);
}


export function calculateMetrics(data: any[]) {
  let totalClicks = 0;
  let totalCommission = 0.0;
  let totalRequests = 0;
  let totalCTR = 0.0;
  // let totalCPC = 0.0;

  data.forEach((ad:any) => {
      totalClicks += parseInt(ad.CLICKS);
      totalCommission += ad['Total Commission']?parseFloat(ad['Total Commission']):0.0;
      totalRequests += parseFloat(ad.Ad_Requests);
      totalCTR += ad.CTR;
      // totalCPC += ad.CPC?parseFloat(ad.CPC):0.0;
  });

  const averageCTR = (totalClicks / totalRequests) * 100;
   const averageCPC = totalCommission / totalClicks;

  const finalCommission=totalCommission.toFixed(2)
  const finalAverageCTR= Number.isNaN( averageCTR)?0.00:  averageCTR.toFixed(2)
 const finalAverageCPC= Number.isNaN( averageCPC)? 0.00:  averageCPC.toFixed(2)
  
console.log("ctr v", finalAverageCTR);
console.log("cpc v", finalAverageCPC);

  return {
    totalClicks:String(totalClicks),
      finalCommission,
      totalRequests:String(totalRequests),
      finalAverageCTR:`${finalAverageCTR} %`  ,
      finalAverageCPC
  };
}

// const metrics = calculateMetrics(data);



interface CampaignData {
  id: number;
  campaignName: string;
  CLICKS: number;
  "Total Commission": string;
  Ad_Requests: number;
  CPC: string;
  CTR: string;
  formatDate: string;
  AvgPosition: number;
  Date: string;
  publisherName: string;
  "Date/Time": string;
}

export function aggregateCampaignData(data: CampaignData[]): CampaignData[] {
  const aggregatedDataMap = new Map<string, CampaignData>();

  data.forEach((item) => {
      const key = item["Date/Time"];
      if (aggregatedDataMap.has(key)) {
          const existingEntry = aggregatedDataMap.get(key)!;
          existingEntry.CLICKS += item.CLICKS;
          existingEntry.Ad_Requests += item.Ad_Requests;
          existingEntry["Total Commission"] = (
              parseFloat(existingEntry["Total Commission"].replace('$', '')) +
              parseFloat(item["Total Commission"].replace('$', ''))
          ).toFixed(2);
          existingEntry.CPC = (
              parseFloat(existingEntry.CPC.replace('$', '')) +
              parseFloat(item.CPC.replace('$', ''))
          ).toFixed(2);
          existingEntry.campaignName += `, ${item.campaignName}`; // Concatenate campaign names
      } else {
          aggregatedDataMap.set(key, { ...item });
      }
  });

  return Array.from(aggregatedDataMap.values());
}






// const convertData = (data:any[]): any[] => {
//   return data.map(item => ({
//       ID: item.ID,
//       Ad_Requests: parseInt(item.Ad_Requests),
//       Avg_position: parseFloat(item.Avg_position),
//       campaignName: item.campaignName,
//       CLICKS: parseInt(item.CLICKS),
//       CPC: parseFloat(item.CPC.replace("$ ", "")),
//       CTR: parseFloat(item.CTR.replace(" %", "")),
//       "Date/Time": item["Date/Time"],
//       publisherName: item.publisherName,
//       "Total Commission": parseFloat(item[ "Total Commission"].replace("$ ", ""))
//   }));
// };

// const aggregateData = (data: any[]): any[] => {
//   return data.reduce((acc, curr) => {
//       const key = curr["Date/Time"];
//       if (!acc[key]) {
//           acc[key] = {
//             "Date/Time": key,
//               Ad_Requests: 0,
//               CLICKS: 0,
//               CPC: 0,
//               "Total Commission": 0,
//               CTR: 0
//           };
//       }

//       acc[key].Ad_Requests += curr.Ad_Requests;
//       acc[key].CLICKS += curr.CLICKS;
//       acc[key].CPC += curr.CPC;
//       acc[key]["Total Commission"] += curr["Total Commission"];
//       acc[key].CTR += curr.CTR;

//       return acc;
//   }, {} as Record<string, any>);
// };

// const calculateMetrics1 = (groupedData: any[]): any[] => {
//   for (const key in groupedData) {
//       if (groupedData.hasOwnProperty(key)) {
//           groupedData[key].CTR = (groupedData[key].CLICKS /groupedData[key].Ad_Requests ) * 100;
//           groupedData[key].CPC = groupedData[key].CLICKS/groupedData[key]["Total Commission"];
//       }
//   }
//   return Object.values(groupedData);
// };

// export const calculateOverallSummary=(data:any[])=>{
//   const typedData = convertData(data);
//   const groupedData = aggregateData(typedData);
//   const aggregatedData = calculateMetrics1(groupedData);
  
//   console.log(aggregatedData);
//   return aggregatedData;
// }



