export const scheduling_jsonData = {
    "Monday": {
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        },
    },
    "Tuesday": {
        // Repeat the same structure as Monday for Tuesday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    "Wednesday": {
        // Repeat the same structure as Monday for Wednesday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    "Thursday": {
        // Repeat the same structure as Monday for Thursday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    "Friday": {
        // Repeat the same structure as Monday for Friday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    "Saturday": {
        // Repeat the same structure as Monday for Saturday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    "Sunday": {
        // Repeat the same structure as Monday for Sunday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    }
}


export const jsonData_someData = {
    "Monday": {
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": false
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": false
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": false
        },
        "11PM": {
            "include": false
        }
    },
    // "Tuesday": {
    //     // Repeat the same structure as Monday for Tuesday
    //     "12AM": {
    //         "include": true
    //     },
    //     "1AM": {
    //         "include": true
    //     },
    //     "2AM": {
    //         "include": true
    //     },
    //     "3AM": {
    //         "include": true
    //     },
    //     "4AM": {
    //         "include": true
    //     },
    //     "5AM": {
    //         "include": true
    //     },
    //     "6AM": {
    //         "include": true
    //     },
    //     "7AM": {
    //         "include": true
    //     },
    //     "8AM": {
    //         "include": true
    //     },
    //     "9AM": {
    //         "include": true
    //     },
    //     "10AM": {
    //         "include": true
    //     },
    //     "11AM": {
    //         "include": true
    //     },
    //     "12PM": {
    //         "include": true
    //     },
    //     "1PM": {
    //         "include": true
    //     },
    //     "2PM": {
    //         "include": true
    //     },
    //     "3PM": {
    //         "include": true
    //     },
    //     "4PM": {
    //         "include": true
    //     },
    //     "5PM": {
    //         "include": true
    //     },
    //     "6PM": {
    //         "include": true
    //     },
    //     "7PM": {
    //         "include": true
    //     },
    //     "8PM": {
    //         "include": true
    //     },
    //     "9PM": {
    //         "include": true
    //     },
    //     "10PM": {
    //         "include": true
    //     },
    //     "11PM": {
    //         "include": true
    //     }
    // },
    // "Wednesday": {
    //     // Repeat the same structure as Monday for Wednesday
    //     "12AM": {
    //         "include": true
    //     },
    //     "1AM": {
    //         "include": true
    //     },
    //     "2AM": {
    //         "include": true
    //     },
    //     "3AM": {
    //         "include": true
    //     },
    //     "4AM": {
    //         "include": true
    //     },
    //     "5AM": {
    //         "include": true
    //     },
    //     "6AM": {
    //         "include": true
    //     },
    //     "7AM": {
    //         "include": true
    //     },
    //     "8AM": {
    //         "include": true
    //     },
    //     "9AM": {
    //         "include": true
    //     },
    //     "10AM": {
    //         "include": true
    //     },
    //     "11AM": {
    //         "include": true
    //     },
    //     "12PM": {
    //         "include": true
    //     },
    //     "1PM": {
    //         "include": true
    //     },
    //     "2PM": {
    //         "include": true
    //     },
    //     "3PM": {
    //         "include": true
    //     },
    //     "4PM": {
    //         "include": true
    //     },
    //     "5PM": {
    //         "include": true
    //     },
    //     "6PM": {
    //         "include": true
    //     },
    //     "7PM": {
    //         "include": true
    //     },
    //     "8PM": {
    //         "include": true
    //     },
    //     "9PM": {
    //         "include": true
    //     },
    //     "10PM": {
    //         "include": true
    //     },
    //     "11PM": {
    //         "include": true
    //     }
    // },
    "Thursday": {
        // Repeat the same structure as Monday for Thursday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    // "Friday": {
    //     // Repeat the same structure as Monday for Friday
    //     "12AM": {
    //         "include": true
    //     },
    //     "1AM": {
    //         "include": true
    //     },
    //     "2AM": {
    //         "include": true
    //     },
    //     "3AM": {
    //         "include": true
    //     },
    //     "4AM": {
    //         "include": true
    //     },
    //     "5AM": {
    //         "include": true
    //     },
    //     "6AM": {
    //         "include": true
    //     },
    //     "7AM": {
    //         "include": true
    //     },
    //     "8AM": {
    //         "include": true
    //     },
    //     "9AM": {
    //         "include": true
    //     },
    //     "10AM": {
    //         "include": true
    //     },
    //     "11AM": {
    //         "include": true
    //     },
    //     "12PM": {
    //         "include": true
    //     },
    //     "1PM": {
    //         "include": true
    //     },
    //     "2PM": {
    //         "include": true
    //     },
    //     "3PM": {
    //         "include": true
    //     },
    //     "4PM": {
    //         "include": true
    //     },
    //     "5PM": {
    //         "include": true
    //     },
    //     "6PM": {
    //         "include": true
    //     },
    //     "7PM": {
    //         "include": true
    //     },
    //     "8PM": {
    //         "include": true
    //     },
    //     "9PM": {
    //         "include": true
    //     },
    //     "10PM": {
    //         "include": true
    //     },
    //     "11PM": {
    //         "include": true
    //     }
    // },
    "Saturday": {
        // Repeat the same structure as Monday for Saturday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    },
    "Sunday": {
        // Repeat the same structure as Monday for Sunday
        "12AM": {
            "include": true
        },
        "1AM": {
            "include": true
        },
        "2AM": {
            "include": true
        },
        "3AM": {
            "include": true
        },
        "4AM": {
            "include": true
        },
        "5AM": {
            "include": true
        },
        "6AM": {
            "include": true
        },
        "7AM": {
            "include": true
        },
        "8AM": {
            "include": true
        },
        "9AM": {
            "include": true
        },
        "10AM": {
            "include": true
        },
        "11AM": {
            "include": true
        },
        "12PM": {
            "include": true
        },
        "1PM": {
            "include": true
        },
        "2PM": {
            "include": true
        },
        "3PM": {
            "include": true
        },
        "4PM": {
            "include": true
        },
        "5PM": {
            "include": true
        },
        "6PM": {
            "include": true
        },
        "7PM": {
            "include": true
        },
        "8PM": {
            "include": true
        },
        "9PM": {
            "include": true
        },
        "10PM": {
            "include": true
        },
        "11PM": {
            "include": true
        }
    }
}
