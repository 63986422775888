let userRole=localStorage.getItem("userRole")


export interface AdvData {
  "Adv id": number;
  "Advertiser Name": string;
  "Brand Name": string;
  "User": string;
  "Created on": string;
  "Status": boolean;
  "Action"?: string
}

function createData(
  Adv_ID: number,
  Advertiser_Name: string,
  Brand_Name: string,
  User: string,
  Created_on: string,
  Status: boolean,

) {
  return {
    Adv_ID,
    Advertiser_Name,
    Brand_Name,
    User,
    Created_on,
    Status
  };
}

function tcpaData(
  tcpaCampaign_id: number,
  tcpaCampaign_name: string,
  Adv_ID: number,
  Advertiser_Name: string,
  Status: string,

) {
  return {
    tcpaCampaign_id,
    tcpaCampaign_name,
    Adv_ID,
    Advertiser_Name,
    Status
  };
}

function createBrandName(
  mBrandID?: number,
  mBrandName?: string,
  Created_on?: string,
  
) {
  return {
    mBrandID,
    mBrandName,
    Created_on

  };
}

function createRawdata(
  reportName?: string,
  reportType?: string,
  rawStatus?: string,
  lastExecution?: string,
  download?: string,
) {
  return {
    reportName,
    reportType,
    rawStatus,
    lastExecution,
    download,

  };
}

function createSeoData(
  Website: string,
  Seo_Title: string,
  Seo_Canonical_Url: string,
  Og_title: string,
  Og_locale: string,
  Og_type: string,
  Twitter_Title: string,
  Twitter_Site: string,
  Twitter_Card: string

) {
  return {
    Website,
    Seo_Title,
    Seo_Canonical_Url,
    Og_title,
    Og_locale,
    Og_type,
    Twitter_Title,
    Twitter_Site,
    Twitter_Card
  };
}


export interface tableRowData {
  Adv_id: number | number,
  Advertiser_Name: string,
  Brand_Name: string,
  User: string,
  Created_on: string,
  Status: boolean,
  action?: string
}


//common interface for adv camp and adv account table We need it for reuse one table component with table sorting and pagination feature.
export interface advTable {
  User?: string,
  Created_on?: string,
  // Publisher_Name: string,
  campaign_id?: number | number,
  campaign_name?: string
  Advertiser_Name: string,
  Brand_Name?: string,
  Brand_ID?: string,
  //  Publi_ID?: string,
  Adv_ID?: number,
  Status?: boolean,
  action?: string,
  publisherID?: number,
  publisherName?: string,
  website?: string,
  mBrandID?: number,
  mBrandName?: string,
  reportName: string,
  reportType: string,
  lastExecution: string,
  download: string,
  rawStatus: string,
  primaryLogo?: string,
secondaryLogo?: string,
error?:boolean
tcpaCampaign_id:number | number,
tcpaCampaign_name: string,
isOFF?:'Active' | "Paused"  
budgetFlag:Boolean,
capFlag:Boolean,
vertical:string,
vendorName?:string
}

export interface SEOHeadCell {

  id: any;
  label: string;
  numeric: boolean;
  key: string
}

export interface HeadCell {

  id: keyof advTable;
  label: string;
  numeric: boolean;
  key: string
}
export const advAccountHeadCells: HeadCell[] = [
  {
    id: 'Status',
    numeric: false,
    label: "Status",
    key: "Status",
  },
  {
    numeric: true,
    id: "Adv_ID",
    label: 'Adv ID',
    key: "Adv_id"
  },
  {
    id: "Advertiser_Name",
    numeric: false,
    key: "Advertiser_Name",
    label: "Advertiser Name",
  },
  {
    id: "Brand_Name",
    numeric: false,
    key: "Brand_Name",
    label: "Brand Name",
  },
  {
    id: 'vendorName',
    numeric: false,
    key:"vendorName",
    label:"Vendor Name",
  },
  {
    id: "vertical",
    numeric: false,
    key: "vertical",
    label: "Vertical",
  },


  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },

];

export type Order = 'asc' | 'desc';


export const mProgramActivationHeadCell: HeadCell[] = [
  {
    numeric: true,
    id: "publisherID",
    label: 'Publisher ID',
    key: "PublisherID"
  },
  {
    numeric: false,
    id: "publisherName",
    label: 'Publisher Name',
    key: "PublisherName"
  }, {
    numeric: false,
    id: "Created_on",
    label: 'Created On',
    key: "Created_on?"
  },
  {
    numeric: false,
    id: "website",
    label: 'Website',
    key: "website"
  },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },
]

export interface EnhancedTableProps {
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof advTable) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[]
  rowCount?: number;
}



export const advRows = [
  createData(22146, "Adv1", "brand1", 'user1', '11/17/2020 3:37:36 PM', true,),
  createData(22147, "Adv2", "brand2", 'user2', '11/17/2020 3:37:36 PM', true,),
  createData(22148, "Adv3", "brand3", 'user3', '11/17/2020 3:37:36 PM', true,),
  createData(22149, "Adv4", "brand4", 'user4', '11/17/2020 3:37:36 PM', false,),
  createData(22150, "Adv5", "brand5", 'user5', '11/17/2020 3:37:36 PM', false,),
  createData(43707, "YTI Career institute", "brand1", 'user4370', '11/17/2020 3:37:36 PM', true,),
  createData(67904, "UEI College", "brand2", 'user2', '5/28/2022 12:42:08 AM', false,),
  createData(50567, "Southern New Hampshire University", "brand3", 'user3', "3/4/2021 4:56:43 AM", true,),
  createData(29905, "South University", "brand4", 'user4', "5/28/2022 12:39:10 AM", false,),
  createData(91529, "South College", "brand5", 'user5', "5/20/2022 9:48:54 PM", false,),
  createData(40798, "Purdue University Global", "brand1", 'user1', "12/5/2020 5:08:27 PM", true,),
  createData(79732, "Prod_Advertiser", "brand2", 'user2', "10/11/2022 11:35:01 AM", true,),
  createData(44950, "Post University", "brand3", 'user3', "11/6/2021 4:40:58 AM", true,),
  createData(44181, "Penn Foster College [ISM]", "brand41", 'user014', "6/29/2023 5:21:26 PM", false,),
  createData(22150, "Liberty University", "brand5", 'user5', "1/16/2021 6:18:56 AM", false,),

  createData(34174, "Grand Canyon University", "brand1", 'user1', "10/12/2022 10:13:28 PM", true,),

  createData(78764, "Florida Career College", "brand2", 'user2452', "5/28/2022 12:40:44 AM", true,),

  createData(75374, "Colorado Technical University", "brand3", 'user3', "12/05/2020 6:24:56 PM", true,),

  createData(23064, "College-Matcher", "brand4", 'user4', "6/15/2022 4:10:45 AM", false,),

  createData(23292, "Capella University", "brand5", 'user5', "11/6/2021 4:24:53 AM", false,),

  createData(80794, "Bryant & Stratton College", "brand1", 'user1', "12/5/2020 6:23:10 PM", true,),

  createData(49225, "Arizona State University Online", "brand2", 'user2', "7/13/2023 7:28:48 PM", true,),

  createData(33997, "All-Star Directories", "brand3", 'user3', "11/6/2021 4:35:07 AM", true,),

  createData(23374, "Advertiser_Check", "brand4", 'user4', "10/17/2022 3:18:52 PM", false,),

  createData(25587, "Advertiser5", "brand5", 'user5', '11/17/2020 3:37:36 PM', false,),
];

export const tcpaRows = [
  tcpaData(22146, "camp_name1", 33997, 'All-Star Directories', 'Active',),
  tcpaData(22147, "camp_name2", 25083, 'Alvernia University', "Inactive",),
  tcpaData(22148, "camp_name3", 12398, 'American Intercontinental University','Active',),
  tcpaData(22149, "camp_name4", 49255, 'Arizona College', "Inactive",),
  tcpaData(22150, "camp_name5", 83161, 'Arizona State University', "Inactive",),
  tcpaData(43707,"camp_name1", 54927, 'Cal Southern University', 'Active',),
]
export const brandNameDummy = [
  createRawdata('11111', "Abilene Christian University",'Generated', "11/17/2020 3:37:36 PM",'pending'),
  createRawdata('11111', "All-State Career School",'Generated', "11/17/2020 3:37:36 PM",'pending'),
  createRawdata('11111', "Ashford University",'Generated', "11/17/2020 3:37:36 PM",'pending'),
  createRawdata('11111', "Cogswell College",'Generated', "11/17/2020 3:37:36 PM",''),
  createRawdata('11111', "CollegeAmerica", 'Generated',"11/17/2020 3:37:36 PM",''),
  createRawdata('11111', "ECPI University",'Generated', "11/17/2020 3:37:36 PM",''),
  createRawdata('11111', "Everglades University", 'Generated',"11/17/2020 3:37:36 PM",''),
  createRawdata('11111', "Florida Career College", 'Generated',"11/17/2020 3:37:36 PM"),
  createRawdata('11111', "Fortis College", 'Generated',"11/17/2020 3:37:36 PM"),
  createRawdata('11111', "Full Sail University - Online", 'Generated',"11/17/2020 3:37:36 PM"),
  // createRawdata(11121, "Grand Canyon University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11122, "Grantham University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11123, "Gwinnett College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11124, "Independence University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11125, "Indiana Wesleyan University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11126, "Institute of Technology", "11/17/2020 3:37:36 PM"),
  // createRawdata(11127, "Johnson & Wales University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11128, "Keiser University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11129, "Liberty University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11130, "Lincoln Tech", "11/17/2020 3:37:36 PM"),
  // createRawdata(11131, "Los Angeles Pacific University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11132, "Mid America Christian University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11133, "Miller-Motte College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11134, "Northcentral University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11135, "Notre Dame College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11138, "Peru State College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11139, "Platt College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11140, "Point Park University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11141, "Post University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11142, "Purdue University Global", "11/17/2020 3:37:36 PM"),
  // createRawdata(11143, "Rasmussen College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11144, "Regent University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11145, "Saint Leo University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11146, "South Texas Vocational Technical Institute", "11/17/2020 3:37:36 PM"),
  // createRawdata(11147, "Southern Careers Institute", "11/17/2020 3:37:36 PM"),
  // createRawdata(11148, "Southern New Hampshire University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11149, "Strayer University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11150, "Sullivan University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11151, "The Chicago School of Professional Psychology", "11/17/2020 3:37:36 PM"),
  // createRawdata(11152, "The College of Health Care Professions", "11/17/2020 3:37:36 PM"),
  // createRawdata(11153, "UEI College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11154, "Ultimate Medical Academy", "11/17/2020 3:37:36 PM"),
  // createRawdata(11155, "University of the Cumberlands", "11/17/2020 3:37:36 PM"),
  // createRawdata(11156, "University of the People", "11/17/2020 3:37:36 PM"),
  // createRawdata(11157, "Vista College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11158, "Walden University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11159, "Compare Top Schools", "11/17/2020 3:37:36 PM"),
  // createRawdata(11160, "Allen school of Health Sciences", "11/17/2020 3:37:36 PM"),
  // createRawdata(11161, "Alvernia University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11162, "American Career College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11163, "Bryant & Stratton College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11164, "Carrington College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11165, "Campbellsville University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11166, "CBD College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11167, "Charter College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11168, "College-Matcher", "11/17/2020 3:37:36 PM"),
  // createRawdata(11169, "Colorado Christian University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11170, "Columbia Southern University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11171, "Concordia University - St. Paul", "11/17/2020 3:37:36 PM"),
  // createRawdata(11172, "CyberTex Institute of Technology", "11/17/2020 3:37:36 PM"),
  // createRawdata(11173, "East Central University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11174, "Eastern Oregon University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11175, "Florida Technical College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11177, "King University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11178, "Saint Paul's School of Nursing", "11/17/2020 3:37:36 PM"),
  // createRawdata(11179, "Saybrook University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11180, "Shawnee State University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11181, "South University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11182, "Thomas Jefferson University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11184, "ClassesUSA", "11/17/2020 3:37:36 PM"),
  // createRawdata(11185, "Point University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11186, "OnlineColleges", "11/17/2020 3:37:36 PM"),
  // createRawdata(11187, "School Connection", "11/17/2020 3:37:36 PM"),
  // createRawdata(11188, "Education Connection", "11/17/2020 3:37:36 PM"),
  // createRawdata(11193, "Flatiron School", "11/17/2020 3:37:36 PM"),
  // createRawdata(11194, "Udemy", "11/17/2020 3:37:36 PM"),
  // createRawdata(11195, "Florida Tech", "11/17/2020 3:37:36 PM"),
  // createRawdata(11196, "Trident University International", "11/17/2020 3:37:36 PM"),
  // createRawdata(11197, "South College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11198, "Berkeley College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11199, "Western Governors University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11200, "YTI Career Institute", "11/17/2020 3:37:36 PM"),
  // createRawdata(11201, "All-Star Directories", "11/17/2020 3:37:36 PM"),
  // createRawdata(11202, "American Intercontinental University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11203, "Averett University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11204, "Babson College", "11/17/2020 3:37:36 PM"),
  // createRawdata(11205, "Become A Nurse Online", "11/17/2020 3:37:36 PM"),
  // createRawdata(11206, "California Baptist University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11207, "CareerStep.com", "11/17/2020 3:37:36 PM"),
  // createRawdata(11208, "Central Michigan University", "11/17/2020 3:37:36 PM"),
  // createRawdata(11209, "ChooseCollegeOnline.com", "11/17/2020 3:37:36 PM"),

];


const createCampaignData = (campaign_id: number, campaign_name: string, Adv_ID: number, Advertiser_Name: string, Brand_ID: number, Brand_Name: string, Status: boolean) => {
  return {
    campaign_id,
    campaign_name,
    Adv_ID,
    Advertiser_Name,
    Brand_ID,
    Brand_Name,
    Status
  }
}

const mapStoredValuesToSeoRows = (storedValues: any) => {
  return [
    createSeoData(
      storedValues.websites,
      storedValues.seo_title,
      storedValues.seo_canonical_url,
      storedValues.og_title,
      storedValues.og_locale,
      storedValues.og_type,
      storedValues.twitter_title,
      storedValues.twitter_site,
      storedValues.twitter_card
    ),
    // Add more entries as needed, following the same pattern
  ];
};

// Use the mapping function
// export const mappedSeoRows = mapStoredValuesToSeoRows(storedValues) ||{};
// console.log('mappedSeoRows',mappedSeoRows)

// export const seoRows = [
//   createSeoData('compareonlinedegrees', "Seo-title", "Seo-Canonical-url", 'og-title', 'og-locale', 'og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Healthcare', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Computer & It', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Physchology', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Nursing', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Education', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Vocational Training', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Liberal Arts', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
//   createSeoData('Criminal Justice', "Seo-title", "Seo-Canonical-url", 'Og-title', 'Og-locale', 'Og-type', 'twitter-title', 'twitter-site', 'twitter-card'),
// ];

export const seoRows = [
  createSeoData('www.compareonlinedegrees.com', "SEO-Title", "SEO-Canonical-URL", 'OG-title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.compareonlinebusinessdegrees.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.compareonlinecriminologydegrees.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.findmedicalcareercolleges.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.findonlinecodingbootcamps.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.onlineteachingcolleges.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.compareonlinepsychologydegrees.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.findonlinenursingcolleges.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
  createSeoData('www.onlinehumanitiesdegrees.com', "SEO-Title", "SEO-Canonical-URL", 'OG-Title', 'OG-Locale', 'OG-Type', 'Twitter-Title', 'Twitter-Site', 'Twitter-Card'),
];
export const advCampaignHeadCells: HeadCell[] = [
  {
    numeric: false,
    id: "Status",
    label: 'Status',
    key: "Status",
  },
  {
    numeric: false,
    id: "budgetFlag",
    label:  userRole === 'any' ? 'Error':"",
    key: "budgetFlag",
  },
  {
    numeric: true,
    id: "campaign_id",
    label: 'Campaign ID',
    key: "campaign_id"
  },
  {
    numeric: false,
    id: "capFlag",
    label:  userRole === 'any' ? 'Error':"",
    key: "capFlag",
  },
  {
    numeric: false,
    id: "campaign_name",
    label: 'Campaign Name',
    key: "campaign_name"
  },
  {
    numeric: false,
    id: "Adv_ID",
    label: 'Advertiser ID',
    key: "Adv_ID",
  },
  
  {
    numeric: false,
    id: "Advertiser_Name",
    label: 'Advertiser Name',
    key: "Advertiser_Name",
  },
  {
    numeric: false,
    id: "vertical",
    label: 'Vertical',
    key: "vertical",
  },

  // {
  //   numeric: true,
  //   id:"Brand_ID", 
  //   label: 'Brand ID',
  //   key:"Brand_ID", 
  // },
  // {
  //   numeric: false,
  //   id: "Brand_Name",
  //   label: 'Brand Name',
  //   key: "Brand_Name",
  // },
  // {
  //   numeric: false,
  //   id: "Status",
  //   label: 'Status',
  //   key: "Status",
  // },
  {
    numeric: false,
    id: "error",
    label:  userRole === 'any' ? 'Error':"",
    key: "error",
  },
  {
    numeric: false,
    id: "isOFF",
    label:  userRole === 'any' ? 'Error':"",
    key: "isOFF",
  },
  {
    id: 'action',
    numeric: false,
    label: userRole === 'Advertiser' ? "" : "Action", // Conditional label rendering
    key: "",
  },
  
]





///////////////////////////for Publisher page

// export interface PubliData {
//   "Pubi id": number;
//   "Publisher Name": string;
//   "Brand Name": string;
//   "User": string;
//   "Created on" :string;
//   "Status":boolean;
//   "Action"?:string
// }
export interface PubliData {
  "mPubID": number;
  "publisherName": string;
  "Brand Name": string;
  "website": string;
  "createdOn": string;
  "status": boolean;
  "Action"?: string
}

// function createPubliData(
//   Publi_ID: number,
//   Publisher_Name: string,
//   Brand_Name: string,
//   User: string,
//   Created_on: string,
//   Status:boolean,

// ){
//   return {
//    Publi_ID,
//    Publisher_Name,
//    Brand_Name,
//    User,
//    Created_on, 
//    Status
//   };
// }

export interface publiTableRowData {
  Publi_id: number | number,
  Publisher_Name: string,
  Brand_Name: string,
  User: string,
  Created_on: string,
  Status: boolean,
  action?: string
}


//common interface for adv camp and adv account table We need it for reuse one table component with table sorting and pagination feature.
export interface publiTable {
  Website?: string,
  Created_on?: string,
  campaign_id?: number | number,
  campaign_name?: string
  Publisher_Name: string,
  Brand_Name?: string,
  Brand_ID?: string,
  Publisher_ID?: string,
  Status?: boolean,
  action?: string,
  vertical?:string
}


export interface PubliHeadCell {

  id: keyof publiTable;
  label: string;
  numeric: boolean;
  key: string
}
export const publiAccountHeadCells: PubliHeadCell[] = [
  {
    id: 'Status',
    numeric: false,
    label: "Status",
    key: "Status",
  },
  {
    numeric: true,
    id: "Publisher_ID",
    label: 'Publisher ID',
    key: "Publisher_ID"
  },
  {
    id: "Publisher_Name",
    numeric: false,
    key: "Publisher_Name",
    label: "Publisher Name",
  },
  // {
  //   id: "Brand_Name",
  //   numeric: false,
  //   key:"Brand_Name",
  //   label: "Brand Name" ,
  // },

  {
    id: 'Created_on',
    numeric: false,
    label: "Created On",
    key: "",
  },
  {
    id: 'Website',
    numeric: false,
    key: "Website",
    label: "Website",
  },
 

  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },

];

export type Order_publi = 'asc' | 'desc';


export interface PubliEnhancedTableProps {
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof publiTable) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order_publi;
  orderBy: string;
  headCells: PubliHeadCell[]
  rowCount?: number;
}


const createPubliCampaignData = (campaign_id: number, campaign_name: string, Publi_ID: number, Publisher_Name: string, Brand_ID: number, Brand_Name: string, Status: boolean) => {
  return {
    campaign_id,
    campaign_name,
    Publi_ID,
    Publisher_Name,
    Brand_ID,
    Brand_Name,
    Status
  }
}


export const publiCampaignHeadCells: PubliHeadCell[] = [
  {
    numeric: false,
    id: "Status",
    label: 'Status',
    key: "Status",
  },
  {
    numeric: true,
    id: "campaign_id",
    label: 'Campaign ID',
    key: "campaign_id"
  },
  {
    numeric: false,
    id: "campaign_name",
    label: 'Campaign Name',
    key: "campaign_name"
  },
  {
    numeric: false,
    id: "Publisher_ID",
    label: 'Publisher ID',
    key: "Publisher_ID",
  },
  {
    numeric: false,
    id: "Publisher_Name",
    label: 'Publisher Name',
    key: "Publisher_Name",
  },
  {
    numeric: false,
    id: "vertical",
    label: 'Vertical',
    key: "vertical",
  },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },

]



export const seoHandlerHeadCells: SEOHeadCell[] = [

  {
    numeric: false,
    id: "website",
    label: 'Website',
    key: "website"
  },
  {
    numeric: false,
    id: "websiteUrl",
    label: 'Website URL',
    key: "websiteUrl"
  },
  // {
  //   numeric: false,
  //   id: "seo_title",
  //   label: 'Title',
  //   key: "seo_title"
  // },
  // {
  //   numeric: false,
  //   id: "seo_keywords",
  //   label: 'Keywords',
  //   key: "seo_keywords"
  // },
  // {
  //   numeric: false,
  //   id: "seo_canonical_url",
  //   label: 'Seo Canonical Url',
  //   key: "Seo_Canonical_Url",
  // },
  // {
  //   numeric: false,
  //   id: "og_title",
  //   label: 'Og Title',
  //   key: "Og_title",
  //   },
  //   {
  //     numeric: false,
  //     id: "Og_Locale",
  //     label: 'Og Locale',
  //     key: "Og_Locale",
  //   },
  //   {
  //     numeric: false,
  //     id: "Og_Type",
  //     label: 'Og Type',
  //     key: "Og_Type ",
  //   },
  //   {
  //     numeric: false,
  //     id: "twitter_Title",
  //     label: 'Twitter Title',
  //     key: "twitter title ",
  //   },
  //   {
  //     numeric: false,
  //     id: "Twitter_Site",
  //     label: 'Twitter Site',
  //     key: "twitter site",
  //   },
  //   {
  //     numeric: false,
  //     id: "Twitter_Card",
  //     label: 'Twitter Card',
  //     key: "twiiter card",
  //   },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },
]
export const publiUserCampaignHeadCells: PubliHeadCell[] = [
  {
    numeric: false,
    id: "Status",
    label: 'Status',
    key: "Status",
  },
  {
    numeric: true,
    id: "campaign_id",
    label: 'Campaign ID',
    key: "campaign_id"
  },
  {
    numeric: false,
    id: "campaign_name",
    label: 'Campaign Name',
    key: "campaign_name"
  },
  {
    numeric: false,
    id: "Publisher_ID",
    label: 'Publisher ID',
    key: "Publisher_ID",
  },
  {
    numeric: false,
    id: "Publisher_Name",
    label: 'Publisher Name',
    key: "Publisher_Name",
  },
  {
    id: 'vertical',
    numeric: false,
    label: "vertical",
    key: "vertical",
  },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },
]


export const advertiserList: any[] = [

  {
    numeric: false,
    id: "advertiser",
    label: 'Advertiser',
    key: "advertiser"
  },

    {
      id: 'action',
      numeric: false,
      label: "Action",
      key: "",
    },
]
export const mBrandsHeadCell: HeadCell[] = [
 
  {
    numeric: true,
    id: "mBrandID",
    label: 'Brand ID',
    key: "mBrandID"
  },
  {
    numeric: false,
    id: "mBrandName",
    label: 'Brand Name',
    key: "mBrandName"
  },
  {
    id: 'vertical',
    numeric: false,
    label: "Vertical",
    key: "",
  },
  {
    id: 'Created_on',
    numeric: false,
    label: "Created On",
    key: "",
  },
 
  // {
  //   numeric: false,
  //   id: "primaryLogo",
  //   label: 'Primary',
  //   key: "primaryLogo"
  // },
  // {
  //   numeric: false,
  //   id: "secondaryLogo",
  //   label: 'Secondary',
  //   key: "secondaryLogo"
  // },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  }, 
 

]

export const tcpaHeadCell: HeadCell[] = [
  {
    numeric: true,
    id: "tcpaCampaign_id",
    label: 'Campaign ID',
    key: "tcpaCampaign_id"
  },
  {
    numeric: false,
    id: "tcpaCampaign_name",
    label: 'Campaign Name',
    key: "tcpaCampaign_name"
  },
  {
    numeric: false,
    id: "Adv_ID",
    label: 'Adv ID',
    key: "Adv_ID",
  },
  {
    numeric: false,
    id: "Advertiser_Name",
    label: 'Advertiser Name',
    key: "Advertiser_Name",
  },
  // {
  //   numeric: false,
  //   id: "primaryLogo",
  //   label: 'Primary',
  //   key: "primaryLogo"
  // },
  // {
  //   numeric: false,
  //   id: "secondaryLogo",
  //   label: 'Secondary',
  //   key: "secondaryLogo"
  // },
  {
    id: 'Status',
    numeric: false,
    label: "Status",
    key: "Status",
  },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },
 

]

export const rawReportHeadCell: HeadCell[] = [
  {
    numeric: false,
    id: "reportName",
    label: 'Report Name',
    key: "reportName"
  },
  {
    numeric: false,
    id: "reportType",
    label: 'Report Type',
    key: "reportType"
  },
  {
    id: 'rawStatus',
    numeric: false,
    label: "Status",
    key: "rawStatus",
  },
  {
    id: 'lastExecution',
    numeric: false,
    label: "Last Execution",
    key: "lastExecution",
  },
  {
    id: 'download',
    numeric: false,
    label: "Download",
    key: "",
  },

]


export const mPubMarginHeadCell: any[] = [
  {
    numeric: true,
    id: "publisherID",
    label: 'Publisher ID',
    key: "PublisherID"
  },
  {
    numeric: false,
    id: "publisherName",
    label: 'Publisher Name',
    key: "PublisherName"
  },
  {
    numeric: false,
    id: "Created_on",
    label: 'Created On',
    key: "Created_on?"
  },
  {
    numeric: false,
    id: "website",
    label: 'Website',
    key: "website"
  },
  {
    id: 'action',
    numeric: false,
    label: "Action",
    key: "",
  },
]

export const cpcThresholdHeadCell: any[] = [
  {
    numeric: true,
    id: "publisherID",
    label:'Publisher ID',
    key: "PublisherID"
  },
  {
    numeric: false,
    id: "publisherName",
    label:'Publisher Name',
    key: "PublisherName"
  },
  {
    numeric: false,
    id: "Created_on",
    label:'Created On',
    key: "Created_on?"
  },
  {
    id: 'action',
    numeric: false,
    label:"Action",
    key: "",
  },
]