// src/api/api.ts
import axios from 'axios';
import{ baseURLConfig }from '../../config/envConfig';
import {  generateHeader } from '../Auth';


export const fetchAllAdvertiserData = async (path: string, queryParams: any) => {
    const baseURL = baseURLConfig.baseURl
    const headers=await generateHeader();
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    const finalURL = baseURL + `/${path}?${queryParamsObject}`
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url:finalURL,
      headers: headers
    };
  
  // console.log("Fetch all PUB CAMPAIGNS data URL", config)
  const response = await axios.request(config)
  // console.log("response ", response)
  return response;


};



export const createAdvCampaigns=async(requestPayload:any, apiMethod:string)=>{
    const baseURL=baseURLConfig.baseURl;
    const apiUrl=`${baseURL}/advertisers/campaign`
    // console.log("create adv campaign API  URL", apiUrl)
    let error:null| any=null;
    let response:null| any=null
    let apiResponse:null|any=null
    const headers=await generateHeader();
    try{

      if(apiMethod==="POST"){
         apiResponse = await axios.post(apiUrl, requestPayload, {headers});
      }else if(apiMethod==="PUT"){
        apiResponse = await axios.put(apiUrl, requestPayload,{headers});
      }
     
      // console.log('Response:', response.data);
      // return response.data;
      response=apiResponse.data;
    }catch (apiError) {
      // Handle any errors that occur during the request
      console.error('Error:', apiError);
      error=apiError
    }

    return {
      error, 
      response
    }

}

export const getAdvCampaigns=async (cursor:null | string)=>{
  const headers=await generateHeader();
  const baseURL = baseURLConfig.baseURl
  // https://3xkv7zjm2i.execute-api.us-east-1.amazonaws.com/staging/v3/advertisers/campaign?ID=campaign
  const advertiserCampApiUrl = `${baseURL}/advertisers/campaign?ID=campaign`;
  let url = advertiserCampApiUrl;
  if (cursor !== null) {
    url += `&cursor=${cursor}`;
  }

    let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url:url,
    headers: headers
  };
  const response = await axios.request(config)
  return response.data;

}

export const updateAllPubCamByAdvCamp=async (payload:any)=>{
  const headers=await generateHeader();
  const baseURL = baseURLConfig.baseURl
  const path='/update/updateAdvertiserPubCampaigns'
  // let config = {
  //   method: 'put',
  //   maxBodyLength: Infinity,
  //   url:baseURL + path,
  //   headers: headers
  // };
  // const response = await axios.request(config)
  const apiUrl=baseURL + path
  try{
   const response= await axios.put(apiUrl, payload,{headers});
   return response.status;
  }catch(error){
 return error;
  }
 

}


//  export const fetchAllAdvCampaigns=async()=>{
//   const baseURL = baseURLConfig.baseURl
//   const headers=await generateHeader();
//   const finalURL = `${baseURL}/advertisers/campaign?ID=campaign`
//   let config = {
//     method: 'get',
//     maxBodyLength: Infinity,
//     url:finalURL,
//     headers: headers
//   };

// // console.log("Fetch all PUB CAMPAIGNS data URL", config)
// const response = await axios.request(config)
// // console.log("response ", response)
// return response;

// //   const response = await fetch(`${baseURL}/advertisers/campaign?ID=campaign`,{headers});
// //  const jsonData = await response.json();
//  }


// For ADV UI

export const getAdvDashboardReports=async (path:string, queryParams:any)=>{
  // const defaultStartDate=`2023-01-01`
// const defaultEndDate=`2023-02-10`
const baseURL = baseURLConfig.advertiserDashboardBaseUrl;

// const formattedCampaignIDs: string = queryParams.mPubCampaignID.split(', ').join(', ');
// const formattedAreaStudy: string = queryParams.areaStudy?queryParams.areaStudy.split(', ').join(', '):""

const myInit = {
    queryStringParameters: {
      advertiserId: queryParams.mPubID?queryParams.mPubID:'79557',
        mAdvCampaignId:queryParams.mPubCampaignID?queryParams.mPubCampaignID:'',
      
        areaStudy:queryParams.areaStudy?queryParams.areaStudy:'',
        chartType:queryParams.chartType
        
    },
    // headers: {
    //   "X-COG-KEY": idToken.jwtToken
    // }
  };
  const response=await axios.get(baseURL+path, {
    params:myInit.queryStringParameters
  })
  // const reportsData = response; 
   console.log("response::::::::::::::::::", response.data)
 
   return response.data.data.filter((item:any) => item.campaignName !== "")


}


export const getAdvertiserDashboardOverallTableReports=async (path:string,queryParams:any)=>{
  // const { idToken } = await Auth.currentSession();
  // const defaultStartDate=`2023-01-01`
  // const defaultEndDate=`2023-02-10`
  const baseURL = baseURLConfig.advertiserDashboardBaseUrl;
  const myInit = {
    queryStringParameters: {
      advertiserId: queryParams.mPubID?queryParams.mPubID:'79557',
        mAdvCampaignId:queryParams.mPubCampaignID?queryParams.mPubCampaignID:'',
      
        areaStudy:queryParams.areaStudy?queryParams.areaStudy:'',
        chartType:queryParams.chartType
        
    },
    // headers: {
    //   "X-COG-KEY": idToken.jwtToken
    // }
  };
  console.log("Base URL::::::::::::::::::", baseURL+path)
    const response=await axios.get(baseURL+path, {
      params:myInit.queryStringParameters
    })
    // const reportsData = response; 
     console.log("response::::::::::::::::::", response.data)
   
      return response.data


}



// https://api-portal-v3.media-matchers.com/mmp/v3/getBrandMapping
export const getMBrandsDetails=async ()=>{
  const path='getBrandMapping'
  const baseURL = baseURLConfig.baseURl
  const headers=await generateHeader();
  const finalURL = baseURL + `/${path}`
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url:finalURL,
    headers: headers
  };

// console.log("Fetch all PUB CAMPAIGNS data URL", config)
const response = await axios.request(config)
// console.log("response ", response)
return response.data;

}