import axios from "axios";
import { baseURLConfig } from "../config/envConfig";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { setLoading} from "../Store/Slices/SEOHandlerApisSlice";
import { generateHeader } from "../service/Auth";
import { setCopmleteMbrandData, setMBrandData, setMBrandTableData } from "../Store/Slices/ExternalApiSlice";
import { setfilterSearchFlag } from "../Store/Slices/PublisherTabApisData";
import { setBlockScroll, setIsoLoading, setNoDataFlag } from "../Store/Slices/advertiserTabApisData";

const baseURL = baseURLConfig.baseURl;

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;


export const fetchMBrandApiData = async (dispatch: DispatchType) => {
  try {
     const headers = await generateHeader();
     dispatch(setLoading(true))
    const response = await fetch(`${baseURL}/getBrandMapping`,{headers});
    const jsonData = await response.json();
    const compApiData = jsonData.data.values.map((item: any) => ({
      mBrandID: item.mBrandId,
      mBrandName: item.mBrandName,
       vertical: item?.vertical,
      Created_on: item.createdOn,
    })).sort((a:any, b:any) => a.mBrandName.localeCompare(b.mBrandName));
 
    dispatch(setMBrandTableData(compApiData));
    dispatch(setMBrandData(compApiData));
    dispatch(setCopmleteMbrandData(jsonData.data.values));
        return compApiData
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    // dispatch(setIsoLoading(false));
    dispatch(setLoading(false))
  }
};

export const createMbrand = async (payload:any) => {
  
  try {
    const headers=await generateHeader();
    const response = await axios.post(`${baseURL}/create/brands`, payload, {headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMbrand = async ( payload:any) => {
  try {
    const headers=await generateHeader();
    const response = await axios.put(`${baseURL}/update/brands`, payload,{headers});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMbrand = async (payload:any) => {
  try {
    const headers=await generateHeader();
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url:`${baseURL}/delete/brands?mBrandId=${payload}`,
      headers:headers,
      //  data:payload.data
    };
    const response=await axios.request(config)
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const filterBrandByVerticalSearch = async (payload: Object, dispatch: DispatchType) => {
  dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  try {
    const headers = await generateHeader();
    const response = await axios.post(`${baseURL}/filterByVertical?ID=mBrandName`, payload, { headers });

    const compApiData = response.data[0]?.values?.map((item: any) => ({
      mBrandID: item.mBrandId,
      mBrandName: item.mBrandName,
      vertical: item?.vertical,
      Created_on: item.createdOn,
    })) || [];
    dispatch(setMBrandTableData(compApiData));
    dispatch(setMBrandData(compApiData));
    dispatch(setCopmleteMbrandData(response.data[0]?.values));

    if (payload &&  compApiData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    dispatch(setIsoLoading(false));
  }
}


