import React from 'react'
import { ISvgIcons } from './AdvertiserSVGIcon'
const CheckedSvgIcon = () => {
  return (
<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6747 1.28822C13.2415 0.854483 12.5381 0.854756 12.1044 1.28822L5.03702 8.35589L1.89587 5.21476C1.46213 4.78102 0.759044 4.78102 0.325304 5.21476C-0.108435 5.6485 -0.108435 6.35159 0.325304 6.78533L4.25157 10.7116C4.46831 10.9283 4.7525 11.037 5.03672 11.037C5.32094 11.037 5.6054 10.9286 5.82213 10.7116L13.6747 2.85876C14.1084 2.42532 14.1084 1.72193 13.6747 1.28822Z" fill="#34C759"/>
</svg>

  )
}

export default CheckedSvgIcon