import React, { useState } from 'react'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import AlertMMDialog from '../../../Component/Dialog/MMDialog';
import FileUploadBtn, { customHeaders } from '../../../Component/Button/FileUploadBtn';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { campaignFormSelector, resetState } from '../../../Store/Slices/CreateAdvCampaignSlice';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent'
import SnackbarMessage from '../../../Component/Snackbar';
// import { filterAdvertiserSearch , fetchAdvertiserCampApiData} from '../../../Services/advertiserTabApis';
// import { fetchCompApiData } from '../../../Services/publisherTabApis';
// import { fetchInitialCampData } from '../Campaigns';
import { updateIsReload } from '../../../Store/Slices/advertiserTabApisData';
import Papa from 'papaparse';
import { csvData } from '../../../Mock/CSV file data/programConfig';
import DownloadIcon from '@mui/icons-material/Download';
import { generateAreaStudyName } from '../../../Mock/ProgramTargetingData';
interface IImportExport {
  editHandler: (campaignID: string) => void;
  campaignId: string;
  fileError: boolean;
}
const ImportExportCampaigns = (props: IImportExport) => {
  const [dialogOpen, setDilogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isFileUploadError, setIsFileUploadError] = useState(false)
  const dispatch = useAppDispatch();
  // const advCampaignData = useAppSelector(campaignFormSelector);
  // console.log("adv camp dara", advCampaignData)
  const handleExportIcon = () => {
    setDilogOpen(true);
    props.editHandler(props.campaignId)
  }

  const handleClickOpen = () => {
    setDilogOpen(true);
  };

  const handleClose = () => {
    // filterAdvertiserSearch()
    dispatch(resetState(''))
    setDilogOpen(false);
    // dispatch(updateIsReload(true))

  };

  const handleSnackBarOpen = (flag: boolean) => {
    setSnackbarOpen(flag)
  };

  const handleSnackBarType = (type: string) => {
    setSnackbarType(type)
  };

  const handleSnackBarMessage = (message: string) => {
    setSnackbarMessage(message)
  };

  const handleSnackBarClose = () => {
    if (isFileUploadError) {
      setSnackbarOpen(false)
    } else {
      setSnackbarOpen(false)
      setDilogOpen(false);
      dispatch(updateIsReload(true))
    }

  };

  const handleError = (flag: boolean) => {
    setIsFileUploadError(flag)
  }
  // console.log('advCampaignTableData==',advCampaignTableData)

  return (
    <>
      <Tooltip title={props.fileError ? "Error! in Uploaded CSV file" : "upload program config"}>
        <ImportExportIcon color={props.fileError ? "error" : "primary"} onClick={handleExportIcon} />
      </Tooltip>
      <AlertMMDialog open={dialogOpen} openHandler={handleClickOpen} closeHandler={handleClose} children={UploadUI} dialogTitle={"Upload the CSV file for update in campaign Program"} snackbarOpenHandler={handleSnackBarOpen} snackbarTypeHandler={handleSnackBarType} snackbarMessageHandler={handleSnackBarMessage} isUploadError={isFileUploadError} setFileError={handleError} />
      <SnackbarMessage open={snackbarOpen} onClose={handleSnackBarClose} type={snackbarType} message={snackbarMessage} />

    </>
  )
}

export default ImportExportCampaigns


const UploadUI = (props: any) => {
  const advCampaignData = useAppSelector(campaignFormSelector);
  const handleDownloadCsv = () => {
    // const csvContent = generateCsvContent();
    const records = Object.values(advCampaignData.value.mPrograms).map((item: any) => ({
      "areaStudy Name": generateAreaStudyName(item.areaStudy),
      areaStudy: item.areaStudy,
      "mProgram Name": item.label,
      mProgram: item.value,
      Status: item.enable ? 'on' : 'off',
      "tracking url": item.trackingUrl || '',
      CPC: item.bid,
      Description: item.bonusDescription ? item.bonusDescription : ""
    }));
    var csv
    csv=Papa.unparse({
          fields:customHeaders.map((item)=>item.label),
          data:records
       })
    
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${advCampaignData.SORTID}-program-config.csv`;
    link.click();
  }

  const handleDownload = () => {
    handleDownloadCsv()
  }

  return (

    <Box className='upload-container' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "20px" }}>
      <FileUploadBtn  {...props} />
      <Button title={advCampaignData.campaignProgramCSVFileName ? "Download last uploaded file" : "Sample file"}
        onClick={handleDownload}
        disabled={(advCampaignData.csvRadioSelected === 'remove')}
      >
        <DownloadIcon style={{ marginTop: "13px", fontSize: '40px' }} />

      </Button>

    </Box>

  )
}
