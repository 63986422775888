import  {programTargetingData, reportTypeData }from '../ProgramTargetingData';

export const areaStudyDropdown=programTargetingData.map((item)=>{
    return {
        label:item.label,
        value:item.value
    }
})
// previousMonth
// monthToYesterday

export const timeLineDropDown=[
    {
        label:"Yesterday",
        value:"last1days"
    },
    {
        label:"Last 7 Days",
        value:"last7days"
    },
    {
        label:"Month-to-Yesterday",
        value:"monthToYesterday"
    },
   
    {
        label:"Last 30 Days",
        value:"last30days"
    },
    {
        label:"Last Month",
        value:"previousMonth"
    },
    // {
    //     label:"Last Month",
    //     value:"lastMonth"
    // },
]

export const intraDayTimeLineDropDown=[
    {
        label:"Last 3 Hour",
        value:"last3hr"
    },
    {
        label:"last 6 Hour",
        value:"last6hr"
    },
    {
        label:"last 9 Hour",
        value:"last9hr"
    },
    {
        label:"Last Hour",
        value:"lastHour"
    },
   
    // {
    //     label:"Last Month",
    //     value:"lastMonth"
    // },
]
export const reportType=reportTypeData.map((item)=>{
    return {
        label:item.label,
        value:item.value
    }
})


export const chartDataIntraDay={
    "labels": [
        "1hr",
        "2hr",
        "3hr",
        "4h",
        "5h",
        "6h",
        "7h",
        "8h",
        "9h"
    ],
    "datasets": [
        {
            "label": "sandeep-pub-camp-1-stage",
            // "value": "78339",
            "data": [
                5,
                3,
                4,
                4,
                4,
                1,
                1,
                2,
                6
            ],
            "backgroundColor": "#4C78A8",
            "yAxisID": "A",
            "borderColor": "",
            "borderWidth": 0,
            "order": 2,
            "fill": false,
            "stack": "all-bars"
        },
        {
            "label": "Total Commission",
            "type": "line",
            "data": [
                "87.36",
                "52.50",
                "70.00",
                "38.41",
                "70.00",
                "43.75",
                "8.61",
                "35.00",
                "110.58"
            ],
            "backgroundColor": "#000000",
            "yAxisID": "B",
            "borderColor": "#000000",
            "borderWidth": 2,
            "order": "1"
        }
    ]
}