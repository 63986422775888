import React, { FC, useEffect, useState } from 'react'
import SchedulingTable from './CampaignScheduling'
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { createTcpaCampAsync } from '../../../../Store/Slices/tcpaCampSlice';
// import CalenderPage from '../../../../Mock/Poc/FullCalender'

// import { schedulingComponentData } from '../../../../Mock/GeoTargetingData'
// import MMTabs from '../../../../Component/Tabs/MMTabs'
// import SchedulingTable from '../../../../Mock/Poc/CellSelectionTable'

// const Scheduling = () => {
const Scheduling: FC<any> = ({ submitRef, handleNext }) => {
  const dispatch: any = useAppDispatch();
  const location = useLocation();
  const currentState = useAppSelector(campaignFormSelector)
  const scheduling = useAppSelector((state) => state.CreateAdvCampaignReducer.value.scheduling);
  const [responseValue, setResponseValue] = useState<null | any>(null)
  const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);
  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaEditJsonData,tcpaEditFlag,tcpaCampaignIdOnEdit,excludeStates} = tcpaCampSlice

  // useEffect(() => {
  //   if (location.pathname === '/createTcpaCamp') {
  //     submitRef.current = () => {
  //       handleSubmit();
  //     }
  //   }
  // }, [scheduling]);

  // const handleSubmit = async () => {
  //   let requestPayload;
  //   requestPayload = {
  //       ...responseValue,
  //       configTimeOfDay: currentState.value.scheduling,
  //       campaignType: "tcpaCampaign",
  //        tcpaCampaignId: tcpaEditFlag? tcpaCampaignIdOnEdit: tcpaCampId,
  //   }

  //   try{
  //     const response = await dispatch(createTcpaCampAsync({ payload: requestPayload, method: 'put' }));
  //     console.log('tcparesponse',response);
  //     handleNext();
  //   }catch(err){
  //     console.error(err);
  //   }
  // }

  return (

    <>
      {/* <SchedulingTable /> */}
      <SchedulingTable />
    </>

  )
}

export default Scheduling